import { useQueryClient } from '@tanstack/react-query';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateMissionAction } from 'src/Hooks/Missions/useCreateMissionAction';
import { QueryKeys } from 'src/Hooks/types';
import { toFetchStatus } from 'src/Utils/fetch-status';

import ContactModal from './ContactModal.component';

const MemoContactModal = memo(ContactModal);

const EnhancedContactModal = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createMissionActionMutation = useCreateMissionAction();
  const missionFetchStatus = toFetchStatus(createMissionActionMutation);
  const actionCreationFetchStatus = missionFetchStatus;

  const onActionCreationSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchMissionDetails] });
    navigate(-1);
  }, [navigate, queryClient]);

  return (
    <MemoContactModal
      fetchStatus={actionCreationFetchStatus}
      onActionCreationSuccess={onActionCreationSuccess}
      newMissionAction={params => createMissionActionMutation.mutate(params)}
    />
  );
};

export default EnhancedContactModal;
