import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Filter } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useCallback, useState } from 'react';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import SelectService from 'src/Components/Selects/SelectService';
import SingleDatePickerPopOver from 'src/Components/SingleDatePickerPopOver';
import { useSearchContractsWithReset } from 'src/Redux/Search/Hooks';
import { Salesphase } from 'src/Services/API';
import styles from './FilterContractsModal.module.scss';
import { Props } from './FilterContractsModal.types';

const FilterContractsModal = ({
  selectedQualification,
  setSelectedQualification,
  selectedCompany,
  setSelectedCompany,
  selectedService,
  setSelectedService,
  selectedSalesphases,
  setSelectedSalesphases,
  onCompanySelection,
  onValidateClick,
  onCancelClick,
  date: selectedDate,
  onDateChange,
  open,
}: Props) => {
  const [qualification, setQualification] = useState(selectedQualification);
  const [company, setCompany] = useState(selectedCompany);
  const [service, setService] = useState(selectedService);
  const [date, setDate] = useState(selectedDate);
  const [salesphases, setSalesphases] = useState<Salesphase[]>(selectedSalesphases);

  const reset = useCallback(() => {
    setQualification(selectedQualification);
    setCompany(selectedCompany);
    setService(selectedService);
    setDate(selectedDate);
    setSalesphases(selectedSalesphases);
  }, [selectedQualification, selectedCompany, selectedService, selectedDate, selectedSalesphases]);

  const saveFilters = useCallback(() => {
    setSelectedQualification(qualification);
    setSelectedCompany(company);
    setSelectedService(service);
    setSelectedSalesphases(salesphases);
    onDateChange(date);
    onValidateClick();
  }, [
    setSelectedQualification,
    qualification,
    setSelectedCompany,
    company,
    setSelectedService,
    service,
    setSelectedSalesphases,
    salesphases,
    onDateChange,
    date,
    onValidateClick,
  ]);

  const searchWithReset = useSearchContractsWithReset();

  return (
    <ModalDeprecated
      key={'key'}
      nested
      title="filtres contrats"
      open={open ?? true}
      icon={<Filter />}
      onClose={onCancelClick}
      footerActionsRight={[
        <Button.Secondary onClick={onCancelClick}>annuler</Button.Secondary>,
        <Button.Primary onClick={saveFilters}>valider</Button.Primary>,
      ]}
      footerActionsLeft={[<Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>]}
    >
      <div>
        <ul className={styles.list}>
          <li>
            <WithLightTitle title="qualification" className={styles.filter}>
              <SelectQualification
                // We specify no society / brand to have the same behavior as OSM
                canBeReset
                selectedQualification={qualification}
                setSelectedQualification={setQualification}
              />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="compte" className={styles.filter}>
              <SelectCompany
                canBeReset
                selectedCompany={company}
                setSelectedCompany={selectedCompany => {
                  setCompany(selectedCompany);
                  setService(undefined);
                  if (selectedCompany?.companyId !== undefined)
                    onCompanySelection(selectedCompany.companyId);
                }}
              />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="service" className={styles.filter}>
              <SelectService
                disabled={company === undefined}
                selectedService={service}
                setSelectedService={setService}
              />
            </WithLightTitle>
          </li>
          <li>
            <WithLightTitle title="afficher les contrats en cours le :" className={styles.filter}>
              <div className={styles.datePickerContainer}>
                <SingleDatePickerPopOver
                  calendar={true}
                  onChange={date => {
                    setDate(date);
                    searchWithReset();
                  }}
                />
              </div>
            </WithLightTitle>
          </li>
        </ul>
      </div>
    </ModalDeprecated>
  );
};

export default FilterContractsModal;
