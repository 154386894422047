import { Eye, OtherHorizontal } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  DataTable,
  Loader,
  Menu,
  MenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { TemporaryWorkerWithoutDetail } from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import { TemporaryWorkerStatusBadge } from '../../TemporaryWorkerDetail/Components/TemporaryWorkerStatusBadge';
import styles from './TemporaryWorkersTable.module.scss';
import { TemporaryWorkersTableProps } from './TemporaryWorkersTable.types';
import { Table } from '@tanstack/react-table';

export const TemporaryWorkersTable = ({
  source,
  temporaryWorkers,
  globalFilter,
  isFetching,
  isLoading,
  hasNextPage,
}: TemporaryWorkersTableProps) => {
  const navigate = useNavigate();

  const columns = useMemo((): ColumnDef<TemporaryWorkerWithoutDetail>[] => {
    const columns: (ColumnDef<TemporaryWorkerWithoutDetail> | false)[] = [
      {
        id: 'name',
        header: 'nom',
        accessorFn: formatName,
        cell: ({ getValue }) => <span className={styles.name}>{getValue() as string}</span>,
      },
      {
        id: 'agency',
        header: "unité d'inscription principale",
        accessorFn: temporaryWorker => temporaryWorker.agencyId,
      },
      {
        id: 'qualification',
        header: 'qualification dernier contrat',
        accessorFn: temporaryWorker => temporaryWorker.qualification?.label?.toLowerCase(),
      },
      {
        id: 'address',
        header: 'lieu de résidence',
        accessorFn: temporaryWorker =>
          [temporaryWorker.zipCode, temporaryWorker.city].filter(Boolean).join(' '),
      },
      {
        id: 'lastContractEndDate',
        header: 'date fin contrat',
        accessorFn: temporaryWorker =>
          temporaryWorker.lastContractEndDate &&
          moment(temporaryWorker.lastContractEndDate).format('L'),
      },
      {
        id: 'availability',
        header: 'prochaine dispo',
        accessorFn: temporaryWorker =>
          temporaryWorker.availabilityForConsultantDate &&
          moment(temporaryWorker.availabilityForConsultantDate).format('L'),
      },
      {
        id: 'status',
        header: () => <div className={styles.statusHeader}>statut</div>,
        cell: ({ row }) => (
          <div className={styles.statusColumn} data-no-padding-left data-full-height>
            <TemporaryWorkerStatusBadge status={row.original.temporaryWorkerStatus} />
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              trigger={
                <Button variant="tertiary" size="medium">
                  <OtherHorizontal variant="fill" />
                </Button>
              }
            >
              <MenuItem
                onClick={() =>
                  navigate(
                    ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath({
                      talentId: row.original.candidateId,
                    })
                  )
                }
              >
                <Eye />
                détail talent
              </MenuItem>
            </Menu>
          </div>
        ),
      },
    ];
    return columns.filter(Boolean);
  }, [navigate]);

  const table = useRef<Table<TemporaryWorkerWithoutDetail>>(null);

  return (
    <>
      {isLoading || (temporaryWorkers?.length === 0 && hasNextPage) ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des intérimaires, cela peut prendre du temps
        </div>
      ) : !temporaryWorkers ? (
        <div className={styles.illustration}>
          <ServerErrorYellow />
          désolé, une erreur s'est produite lors du chargement des intérimaires
        </div>
      ) : (
        <>
          <DataTable
            size="medium"
            columns={columns}
            data={temporaryWorkers}
            getRowId={candidate => candidate.candidateId}
            rowStyle="cards"
            enableColumnFilters={false}
            enableSorting={false}
            state={{ globalFilter }}
            empty={
              <>
                {isFetching ? (
                  <div className={styles.illustration}>
                    <Loader size="large" />
                    chargement des intérimaires, cela peut prendre du temps
                  </div>
                ) : (
                  <div className={styles.illustration}>
                    <VidePasteque />
                    nous n'avons trouvé aucun intérimaire correspondant à votre recherche
                  </div>
                )}
              </>
            }
            hideHeadersIfEmpty
            onRowClick={row =>
              navigate(
                ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath(
                  { talentId: row.original.candidateId },
                  undefined,
                  source
                )
              )
            }
            ref={table}
          />
          {!!table.current?.getRowCount() && isFetching && (
            <div className={styles.illustration}>
              <Loader size="small" />
              chargement des intérimaires suivants
            </div>
          )}
        </>
      )}
    </>
  );
};
