import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { ExportService, FetchExternalDemandsParams } from 'src/Services/API';
import { MutationKeys } from '../types';

export const useExportExternalDemands = (
  params: Omit<FetchExternalDemandsParams, 'agencyId'>,
  extraOptions?: UseMutationOptions<void, unknown, void>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.exportExternalDemands],
    async () => {
      await ExportService.exportControllerInitializeExternalDemandsExport({
        agencyId,
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        ReactGA.event('export-external-demands', { brandCode, agencyId, zoneId, regionId });
        toast.success(
          "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
          { autoClose: 5000 }
        );
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
