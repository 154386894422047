import {
  Button,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useRef } from 'react';
import { illusEpi } from 'src/Assets_DEPRECATED';
import { Props } from './EpiEditionModal.types';
import {
  ExclusiveSelectionGroup,
  ToggleSwitch,
  TripleChoiceButton,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { EpiSchema, EpiSchemaType } from './EpiEditionModal.schema';
import { WhoGaveEpi, YesNoUnknown } from 'src/Services/API';
import styles from './EpiEditionModal.module.scss';
import { UseFormReturn } from 'react-hook-form';

const WhoGaveItKeyValue = [
  { key: WhoGaveEpi.EU, label: 'EU' },
  { key: WhoGaveEpi.RI, label: 'RI' },
  { key: WhoGaveEpi.UNKNOWN, label: 'info inconnue' },
];

const EpiEditionModal = ({ epi, onEpiUpdate, onEpiDelete, onClose, open }: Props) => {
  const ref = useRef<PopupActions>(null);

  const { control, handleSubmit, setValue } = useFormWithZodResolver({
    schema: EpiSchema,
    defaultValues: {
      hasBeenWorn: epi.hasBeenWorn,
      whoGaveIt: epi.whoGaveIt,
      inContract: epi.inContract,
    },
  }) as UseFormReturn<EpiSchemaType>;

  const epiHandleSubmit = () =>
    handleSubmit((values: EpiSchemaType) => {
      const updatedEpi = {
        id: epi.id,
        label: epi.label,
        hasBeenWorn: values.hasBeenWorn,
        whoGaveIt: values.whoGaveIt,
        inContract: values.inContract,
      };
      values.hasBeenWorn ||
      values.whoGaveIt !== WhoGaveEpi.UNKNOWN ||
      values.inContract !== YesNoUnknown.Unknown
        ? onEpiUpdate(updatedEpi)
        : onEpiDelete(updatedEpi);
      ref.current?.close();
    });

  useEffect(() => {
    setValue('hasBeenWorn', epi.hasBeenWorn);
    setValue('whoGaveIt', epi.whoGaveIt);
    setValue('inContract', epi.inContract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epi.hasBeenWorn, epi.whoGaveIt, epi.inContract]);

  return (
    <ModalDeprecated
      ref={ref}
      open={open}
      onClose={onClose}
      icon={<img src={illusEpi} alt="epi" />}
      title={`détails EPI "${epi.label}"`}
      footerActionsRight={[
        <Button.Secondary onClick={ref.current?.close}>annuler</Button.Secondary>,
        <Button.Primary onClick={epiHandleSubmit()}>valider</Button.Primary>,
      ]}
    >
      <div className={styles.hasBeenWorn}>
        <p>cet EPI a-t-il été porté ?</p>
        <ToggleSwitch name="hasBeenWorn" control={control} />
      </div>
      <WithLightTitle title="qui a fourni cet EPI ?" className={styles.lightTitle}>
        <ExclusiveSelectionGroup
          name="whoGaveIt"
          control={control}
          values={WhoGaveItKeyValue}
          getKey={({ key }) => key}
          getStringValue={({ label }) => label}
          getSelectedValue={value => WhoGaveItKeyValue.find(({ key }) => key === value)}
          getSelectedFormValue={value => value?.key}
        />
      </WithLightTitle>
      <WithLightTitle title="est-il mentionné dans le contrat ?" className={styles.lightTitle}>
        <TripleChoiceButton name="inContract" control={control} />
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default EpiEditionModal;
