import { useNavigate } from 'react-router-dom';

import { Nouveaute } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  ModalActions,
  ModalContent,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { ROUTES } from 'src/Routes/Routes.types';
import { formatStatus } from '../ExternalDemandsList/utils';
import { ExternalDemandStatusBadge } from '../ExternalDemandStatusBadge';
import styles from './EventDetails.module.scss';
import { EventDetailsProps } from './EventDetails.types';
import { formatName } from 'src/Utils/formatName';

export const EventDetails = ({ item: event, close, source }: EventDetailsProps) => {
  const navigate = useNavigate();

  const { data } = useFetchMissionDetails(event.parent.commandId);

  return (
    <>
      <ModalContent header={<Nouveaute />} title={`détails du roulement ${event.sourceId}`}>
        <div className={styles.grid}>
          <WithLightTitle title="statut" className={styles.status}>
            <div>
              <ExternalDemandStatusBadge element={event} />
              <div>{formatStatus(event)}</div>
            </div>
          </WithLightTitle>
          <WithLightTitle title="établissement">
            {event.parent.clientName ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="siret">
            {event.parent.siret ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="domaine">
            {event.parent.domain ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="qualification">
            {event.parent.qualification ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="service">
            {event.parent.service ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="date et heure">
            <div>{moment(event.dateMission).tz('CET').format('DD MMMM YYYY')}</div>
            <div>{event.schedule}</div>
          </WithLightTitle>
          <WithLightTitle title={`id ${source.toLowerCase()}`}>{event.sourceId}</WithLightTitle>
          <WithLightTitle title="admin">
            {formatName(data?.contact) || 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="motif">{event.motive ?? 'information inconnue'}</WithLightTitle>
          <WithLightTitle title="email">
            {data?.contact?.email ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="date limite de traitement">
            {event.limitDate
              ? moment(event.limitDate).tz('CET').format('DD MMMM YYYY [à] HH:mm')
              : 'information inconnue'}
          </WithLightTitle>
        </div>
      </ModalContent>
      <ModalActions side="left">
        <Button variant="tertiary" size="medium" onClick={close}>
          fermer
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button
          variant="secondary"
          size="medium"
          onClick={() =>
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
              state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                siret: event.parent?.siret,
              }),
            })
          }
        >
          voir les correspondances
        </Button>
      </ModalActions>
    </>
  );
};
