import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { illusReposi } from 'src/Assets_DEPRECATED';
import { useFetchBlacklistedCompaniesByCandidate } from 'src/Hooks/Candidate/useFetchBlacklistedCompaniesByCandidate';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import BlacklistedModal from 'src/Modals/BlacklistedModal';
import RepositioningValidationModal from 'src/Modals/RepositioningValidationModal/RepositioningValidationModal.component';
import { getMissionsSortOrder, getRepositioningMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrand,
} from 'src/Redux/Perimeter/Selectors';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { DBCDIIStatus, EnumSearchMissionsBodySortOrder, Mission } from 'src/Services/API';
import { DistancePopover } from '../DistancePopover';
import { QualificationsPopover } from '../QualificationsPopover';
import CGCMissionPropositionModal from './CGCMissionPropositionModal';
import CGCModal from './CGCModal';
import { FiltersMenu } from './FiltersMenu';
import { MissionCard } from './MissionCard';
import styles from './RepositioningMissions.module.scss';
import { Props } from './RepositioningMissions.types';

export const RepositioningMissions = ({ candidateId, statut, candidateData, source }: Props) => {
  const { inView, ref } = useInView();

  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const agencyId = useSelector(getCurrentAgency);
  const currentBrand = useSelector(getCurrentBrand);

  const { data: isCGC } = useIsCGC();
  const addToMissionMutation = useAddCandidateToMissions({
    onSettled: () => {
      setTimeout(() => addToMissionMutation.reset(), 500);
    },
  });
  const qualificationQuery = useFetchCandidateQualifications(candidateData.id);

  useEffect(() => {
    setSelectedQualifications(
      qualificationQuery.data?.map(qualification => qualification.id).filter(Boolean)
    );
  }, [qualificationQuery.data]);

  const [filteredDistance, setFilteredDistance] = useState<number>();
  const [selectedAgencies, setSelectedAgencies] = useState([agencyId]);
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>();
  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const [repositioningValidationModalOpen, setRepositioningValidationModalOpen] = useState(false);
  const [blacklistedModalOpen, setBlacklistedModalOpen] = useState(false);
  const [isMissionSuggestionOpen, setIsMissionSuggestionOpen] = useState(false);

  const {
    data: missionsWithCount,
    fetchNextPage: fetchNextPageMission,
    isFetchingNextPage: isFetchingNextPageMission,
    isLoading,
  } = useFetchRepositioningMissions({
    qualificationIds: selectedQualifications,
    agencies: selectedAgencies,
    candidateId,
  });

  const { data: blacklistedCompaniesByTalent } = useFetchBlacklistedCompaniesByCandidate(
    candidateData.id
  );
  const sharedCompanies = useMemo(() => {
    const blacklistedCompanies =
      blacklistedCompaniesByTalent?.map(company => ({
        id: company.company?.id,
        name: company.company?.label,
      })) || [];

    const selectedMissionsCompaniesIds = selectedMissions.map(mission => mission.company?.id);

    return blacklistedCompanies
      .filter(company => selectedMissionsCompaniesIds.includes(company.id))
      .filter(company => company.id);
  }, [blacklistedCompaniesByTalent, selectedMissions]);

  const missions: Mission[] = useMemo(
    () =>
      (missionsWithCount?.pages.map(page => page.missions!) ?? [])
        .flat(1)
        .filter(mission => !mission.endDate || moment(mission.endDate).isAfter(moment())),
    [missionsWithCount?.pages]
  );

  useEffect(() => {
    if (inView && !isFetchingNextPageMission) {
      fetchNextPageMission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // TODO server-side sorting
  const sortedMissions = useMemo(() => {
    const filteredMissions: Mission[] =
      filteredDistance === undefined
        ? missions
        : missions.filter(mission => (mission.distanceToTT ?? 0) <= filteredDistance);
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
              ? 1
              : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
              ? 1
              : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = filteredMissions?.filter(
          mission => mission.startDate !== undefined
        );
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
                ? 1
                : -1;
          }),
          ...(filteredMissions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(filteredMissions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
                ? 1
                : -1;
          }),
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, missionsWithCount, filteredDistance]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  const addToMissions = useCallback(() => {
    addToMissionMutation.mutate({
      candidateId,
      missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
      isRepositioningTalent: true,
    });
  }, [addToMissionMutation, candidateId, selectedMissions]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Checkbox
          checked={
            sortedMissions.length === selectedMissions.length && selectedMissions.length !== 0
          }
          halfChecked={selectedMissions.length > 0}
          onClick={() => {
            selectedMissions.length === sortedMissions.length
              ? setSelectedMissions([])
              : setSelectedMissions(sortedMissions);
          }}
        />
        {isCGC ? (
          <Button
            size="medium"
            variant="secondary"
            disabled={selectedMissions.length === 0}
            onClick={() => {
              setIsMissionSuggestionOpen(true);
              ReactGA.event(ANALYTICS_EVENT.CLICK_ORDER_SUGGEST, {
                ...userInfo,
                suggestedOrderCount: selectedMissions.length,
                statut,
              });
            }}
          >
            {selectedMissions.length > 1
              ? 'suggérer sur les commandes'
              : 'suggérer sur la commande'}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="secondary"
            disabled={selectedMissions.length === 0}
            onClick={() => {
              if (sharedCompanies.length > 0) {
                setBlacklistedModalOpen(true);
              } else {
                addToMissionMutation.mutate(
                  {
                    candidateId,
                    missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
                  },
                  {
                    onSuccess: () => {
                      ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_RATTACHEMENT, {
                        ...userInfo,
                        rattachementOrderCount: selectedMissions.length,
                        toggle: source,
                      });

                      setSelectedMissions([]);
                      setRepositioningValidationModalOpen(true);
                    },
                  }
                );
              }
            }}
            mutationStatus={addToMissionMutation.status}
          >
            {selectedMissions.length > 1 ? 'rattacher aux commandes' : 'rattacher à la commande'}
          </Button>
        )}
        {!isCGC &&
          candidateData.cdiiStatus !== DBCDIIStatus.processing &&
          candidateData.cdiiStatus !== DBCDIIStatus.suggested && (
            <CGCModal
              candidateId={candidateId}
              candidateFirstName={candidateData.firstName ?? ''}
              candidateLastName={candidateData.name ?? ''}
              statut={candidateData.cdiiStatus?.toString() ?? ''}
            />
          )}
        <div className={styles.spacer} />
        {!isCGC &&
          currentBrand.agencies.length <= 10 &&
          (selectedAgencies.length > 1 ? (
            <Button onClick={() => setSelectedAgencies([agencyId])}>revenir à {agencyId}</Button>
          ) : (
            <Button onClick={() => setSelectedAgencies(currentBrand.agencies)}>
              élargir à tout mon périmètre
            </Button>
          ))}
        <DistancePopover distance={filteredDistance} setDistance={setFilteredDistance} />
        <QualificationsPopover
          allQualifications={qualificationQuery.data ?? []}
          setSelectedQualifications={setSelectedQualifications}
          selectedQualifications={selectedQualifications ?? []}
        />
        <FiltersMenu />
      </div>
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des missions
        </div>
      ) : !missionsWithCount ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !sortedMissions.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Il n'y a aucune commande correspondant aux qualifications sélectionnées.
          <br />
          Astuce: vérifier que les qualifications principales et secondaires sont à jour dans le
          dossier du talent
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.leftPartContainer}>
            <div className={styles.missionsContainer}>
              {sortedMissions.map(mission => {
                const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                return (
                  <MissionCard
                    key={mission.missionId}
                    companyName={mission.company?.name ?? ''}
                    serviceName={mission.service?.name}
                    startDate={mission.startDate}
                    agencyId={mission.agencyId}
                    status={mission.salesPhase?.label}
                    isChecked={isSelected}
                    isSelected={missionToDisplay === mission.missionId}
                    qualificationName={mission.qualification?.label ?? ''}
                    onClick={() => setMissionToDisplay(mission.missionId)}
                    onCheckBoxClick={() => {
                      isSelected
                        ? setSelectedMissions(
                            selectedMissions.filter(
                              missionItem => mission.missionId !== missionItem.missionId
                            )
                          )
                        : setSelectedMissions([...selectedMissions, mission]);
                    }}
                    flag={{
                      count: mission.distanceToTT,
                      className:
                        mission.distanceToTT !== undefined
                          ? mission.distanceToTT <= 50
                            ? styles.kmFlagBelow
                            : styles.kmFlagAbove
                          : styles.kmFlagNone,
                      label: 'km',
                    }}
                  />
                );
              })}

              <div ref={ref} />
            </div>
          </div>
          {selectedMissions.length >= 2 ? (
            <div className={styles.detailContainerMore}>
              <div className={styles.detailContainerTitle}>Sélection</div>
              <div className={styles.detailContainerContent}>
                {selectedMissions.length} commandes sélectionnées
              </div>
              <img
                src={illusReposi}
                alt="repositionnement-illus"
                className={styles.illusRepositioning}
              />
            </div>
          ) : (
            <div className={styles.detailContainer}>
              <MissionDetail missionId={missionToDisplay} candidateId={candidateData.id} />
            </div>
          )}
        </div>
      )}
      <RepositioningValidationModal
        open={repositioningValidationModalOpen}
        setOpen={setRepositioningValidationModalOpen}
        selectedMissionNumber={selectedMissions.length}
        missionId={selectedMissions.at(0)?.missionId}
        missionAgencyId={selectedMissions.at(0)?.agencyId}
        isRepositioningTalent={false}
      />
      {candidateData.id && (
        <BlacklistedModal
          candidateId={candidateData.id}
          companies={sharedCompanies}
          isOpen={blacklistedModalOpen}
          setIsOpen={setBlacklistedModalOpen}
          addToMissions={addToMissions}
        />
      )}
      <CGCMissionPropositionModal
        candidateData={candidateData}
        selectedMissions={selectedMissions}
        statut={statut}
        open={isMissionSuggestionOpen}
        onClose={() => setIsMissionSuggestionOpen(false)}
      />
    </div>
  );
};
