import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentAnalysisService } from 'src/Services/API';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { MutationKeys, QueryKeys } from '../types';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

interface WorkAccidentAnalysisCreationParams {
  analysisId: string;
  lastName: string;
  firstName: string;
  startDate?: Date;
  endDate?: Date;
  candidateId: string;
  accidentId: string;
  stoppageDuration: number;
}

export const useCreateWorkAccidentAnalysis = (
  extraOptions?: UseMutationOptions<string, unknown, WorkAccidentAnalysisCreationParams, unknown>
) => {
  const queryClient = useQueryClient();

  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.createWorkAccidentAnalysis],
    async (params: WorkAccidentAnalysisCreationParams) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerCreateWorkAccident({
        id: params.analysisId,
        body: {
          brandCode,
          agencyId,
          lastName: params.lastName,
          firstName: params.firstName,
          startDate: params.startDate ?? new Date(),
          endDate: params.endDate ?? new Date(),
          candidateId: params.candidateId,
          accidentId: params.accidentId,
          stoppageDuration: params.stoppageDuration,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentAnalyses]);
        queryClient.invalidateQueries([QueryKeys.fetchAccidentsUnderMinStoppageDuration]);
        ReactGA.event(ANALYTICS_EVENT.CREATE_WORK_ACCIDENT_ANALYSIS, { ...userInfo });
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
