import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidatesService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

export interface SendToCGCParams {
  candidateId: string;
  candidateFirstName: string;
  candidateLastName: string;
  cgcId: string;
  comment: string;
}
export const useSendToCGC = (
  extraOptions?: UseMutationOptions<void, unknown, SendToCGCParams, MutationKeys[]>
) => {
  const queryContext = useQueryClient();

  const agencyId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.sendToCGC],
    async ({
      candidateId,
      candidateFirstName,
      candidateLastName,
      cgcId,
      comment,
    }: SendToCGCParams) => {
      await CandidatesService.candidatesControllerSendToCgc({
        id: candidateId,
        agencyId,
        candidateFirstName,
        candidateName: candidateLastName,
        cgcId,
        sosComment: comment,
      });
    },
    {
      onSuccess: () => {
        ReactGA.event(ANALYTICS_EVENT.CLICK_SOS_CONFIRMATION, {
          ...userInfo,
        });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
      onError: (...args) => {
        if ((args as any)[0].response.data.statusCode === 409) {
          toast.error('le candidat est déjà disponible pour agence');
        }
      },
      ...extraOptions,
    }
  );
};
