import { z } from 'zod';
import {
  FetchTemporaryWorkersAgencyFilters,
  FetchTemporaryWorkersSort,
  FetchTemporaryWorkersSortCriteria,
  SortTypeEnum,
  TemporaryWorkerStatus,
} from 'src/Services/API';
import { AvailabilityDateInterval } from '../AgencyTemporaryWorkersTopBarContent/AgencyTemporaryWorkersFilters/AgencyTemporaryWorkersFiltersModalContent/AgencyTemporaryWorkersFiltersModalContent.types';

export interface AgencyTemporaryWorkersListProps {
  globalFilter: string;
  filters: FetchTemporaryWorkersAgencyFilters;
  sort: FetchTemporaryWorkersSort;
}

export const temporaryWorkersAgencyFiltersSchema: z.Schema<FetchTemporaryWorkersAgencyFilters> =
  z.object({
    qualifications: z.array(z.string()),
    availabilityDateInterval: z.nativeEnum(AvailabilityDateInterval),
    status: z.array(z.nativeEnum(TemporaryWorkerStatus)),
  });

export const defaultAgencyFilters: z.infer<typeof temporaryWorkersAgencyFiltersSchema> = {
  qualifications: [],
  availabilityDateInterval: AvailabilityDateInterval.SEVEN,
  status: Object.values(TemporaryWorkerStatus),
};

export const temporaryWorkersAgencySortSchema: z.Schema<FetchTemporaryWorkersSort> = z.object({
  sortCriteria: z.nativeEnum(FetchTemporaryWorkersSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultAgencySort: z.infer<typeof temporaryWorkersAgencySortSchema> = {
  sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
  sortType: SortTypeEnum.ASC,
};
