import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, SearchInput } from '@randstad-lean-mobile-factory/react-components-core';
import { AgencyTemporaryWorkersTopBarContentProps } from './AgencyTemporaryWorkersTopBarContent.types';
import { AgencyTemporaryWorkersFilters } from './AgencyTemporaryWorkersFilters';
import { AgencyTemporaryWorkersSortingMenu } from './AgencyTemporaryWorkersSortingMenu';

export const AgencyTemporaryWorkersTopBarContent = ({
  globalFilter,
  setGlobalFilter,
  filters,
  setFilters,
  sort,
  setSort,
}: AgencyTemporaryWorkersTopBarContentProps) => (
  <>
    <AgencyTemporaryWorkersFilters filters={filters} setFilters={setFilters} />
    <SearchInput
      size="small"
      value={globalFilter}
      onValueChange={setGlobalFilter}
      placeholder="rechercher"
      collapsed
    />
    <AgencyTemporaryWorkersSortingMenu
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending />
        </Button>
      }
      sort={sort}
      setSort={setSort}
    />
  </>
);
