import { uniqBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFetchAgencyTemporaryWorkers } from 'src/Hooks/Candidate/useFetchAgencyTemporaryWorkers';
import { TemporaryWorkersSource } from '../TemporaryWorkersList.types';
import { TemporaryWorkersTable } from '../TemporaryWorkersTable';
import styles from './AgencyTemporaryWorkersList.module.scss';
import { AgencyTemporaryWorkersListProps } from './AgencyTemporaryWorkersList.types';

export const AgencyTemporaryWorkersList = ({
  globalFilter,
  filters,
  sort,
}: AgencyTemporaryWorkersListProps) => {
  const {
    data: agencyData,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useFetchAgencyTemporaryWorkers({ filters, sort });

  const data = useMemo(
    () =>
      uniqBy(
        agencyData?.pages.flatMap(page => page.temporaryWorkers),
        candidate => candidate.candidateId
      ),
    [agencyData]
  );

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll, globalFilter]);

  useEffect(() => {
    if (data?.length === 0 && hasNextPage) fetchNextPage();
  }, [data, hasNextPage, fetchNextPage]);

  return (
    <div className={styles.table} onScroll={handleScroll} ref={scrollViewRef}>
      <TemporaryWorkersTable
        globalFilter={globalFilter}
        source={TemporaryWorkersSource.agency}
        temporaryWorkers={data}
        isLoading={isLoading}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </div>
  );
};
