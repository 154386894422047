import { useMemo, useState } from 'react';

import {
  Document,
  DocumentPdf,
  Edit,
  Eye,
  OtherHorizontal,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Card,
  CardClickable,
  CardContent,
  Loader,
  Menu,
  MenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useCreateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useCreateWorkAccidentAnalysis';
import { useGenerateWorkAccidentAnalysisPdf } from 'src/Hooks/WorkAccidentAnalysis/useGenerateWorkAccidentAnalysisPdf';
import { useUnarchiveWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUnarchiveWorkAccidentAnalysis';
import { ROUTES } from 'src/Routes/Routes.types';
import { formatName } from 'src/Utils/formatName';
import { openFile } from 'src/Utils/openFile';
import { ArchiveWorkAccidentAnalysisModal } from '../ArchiveWorkAccidentAnalysisModal';
import { WorkAccidentAnalysisCardProps } from './WorkAccidentAnalysisCard.types';

export const WorkAccidentAnalysisCard = ({
  workAccidentAnalysis,
  status,
  color = 'white',
}: WorkAccidentAnalysisCardProps) => {
  const navigate = useNavigate();
  const createWorkAccidentAnalysis = useCreateWorkAccidentAnalysis();
  const generateWorkAccidentAnalysisPdf = useGenerateWorkAccidentAnalysisPdf();
  const unarchiveWorkAccidentAnalysis = useUnarchiveWorkAccidentAnalysis(workAccidentAnalysis.id);

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const cardContent = useMemo(
    () => (
      <>
        <CardContent
          title={formatName(workAccidentAnalysis.candidate)}
          supportingLines={[
            workAccidentAnalysis.startDate && moment(workAccidentAnalysis.startDate).format('L'),
            [workAccidentAnalysis.company?.name, workAccidentAnalysis.contractQualification?.label]
              .filter(Boolean)
              .join(' | '),
          ].filter(Boolean)}
        />
        <Menu
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          trigger={
            <Button variant="tertiary" size="medium">
              <OtherHorizontal variant="fill" />
            </Button>
          }
          enableStopPropagation
        >
          {status === 'TODO' ? (
            <MenuItem
              onClick={() =>
                createWorkAccidentAnalysis.mutate(
                  {
                    accidentId: workAccidentAnalysis.workAccidentId ?? '',
                    candidateId: workAccidentAnalysis.candidate?.id ?? '',
                    firstName: workAccidentAnalysis.candidate?.firstName ?? '',
                    lastName: workAccidentAnalysis.candidate?.lastName ?? '',
                    analysisId: workAccidentAnalysis.id,
                    startDate: workAccidentAnalysis.startDate,
                    endDate: workAccidentAnalysis.endDate,
                    stoppageDuration: workAccidentAnalysis.stoppageDuration ?? 0,
                  },
                  {
                    onSuccess: analysisId =>
                      navigate(
                        ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                          analysisId,
                        })
                      ),
                  }
                )
              }
            >
              <Edit /> créer l'analyse AT
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                navigate(
                  ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                    analysisId: workAccidentAnalysis.id,
                  })
                )
              }
            >
              {status === 'IN_PROGRESS' ? (
                <>
                  <Edit /> reprendre l'analyse AT
                </>
              ) : (
                <>
                  <Eye /> consulter l'analyse AT
                </>
              )}
            </MenuItem>
          )}
          {status === 'ARCHIVED' ? (
            <MenuItem onClick={() => unarchiveWorkAccidentAnalysis.mutate()}>
              <Document /> déclasser l'analyse AT
            </MenuItem>
          ) : status !== 'DONE' ? (
            <MenuItem onClick={() => setIsArchiveModalOpen(true)}>
              <Document /> classer l'analyse AT
            </MenuItem>
          ) : null}
          {(status === 'IN_PROGRESS' || status === 'DONE') && (
            <MenuItem
              keepOpenOnClick
              onClick={() => {
                generateWorkAccidentAnalysisPdf.mutate(workAccidentAnalysis.id, {
                  onSuccess: buffer => openFile(buffer, 'application/pdf'),
                });
              }}
              disabled={generateWorkAccidentAnalysisPdf.isLoading}
            >
              {generateWorkAccidentAnalysisPdf.isLoading ? (
                <Loader size="xsmall" />
              ) : (
                <DocumentPdf />
              )}
              PDF analyse AT
            </MenuItem>
          )}
        </Menu>
        {(status === 'TODO' || status === 'IN_PROGRESS') && (
          <ArchiveWorkAccidentAnalysisModal
            workAccidentAnalysis={workAccidentAnalysis}
            status={status}
            open={isArchiveModalOpen}
            onClose={() => setIsArchiveModalOpen(false)}
          />
        )}
      </>
    ),
    [
      createWorkAccidentAnalysis,
      generateWorkAccidentAnalysisPdf,
      isArchiveModalOpen,
      navigate,
      status,
      unarchiveWorkAccidentAnalysis,
      workAccidentAnalysis,
    ]
  );

  if (status === 'TODO') return <Card color={color}>{cardContent}</Card>;
  else
    return (
      <CardClickable
        color={color}
        onClick={() =>
          navigate(
            ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
              analysisId: workAccidentAnalysis.id,
            })
          )
        }
      >
        {cardContent}
      </CardClickable>
    );
};
