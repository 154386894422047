import { useEffect, useMemo, useState } from 'react';

import {
  Badge,
  Loader,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { TopBar } from 'src/Components/TopBar';
import { TopBarContent } from './TopBarContent';
import { useFetchExternalDemands } from 'src/Hooks/ExternalDemands/useFetchExternalDemands';
import { getSelectedSource } from 'src/Redux/ExternalDemands/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AppelMedicalEventSourceDto,
  DisplayStatusDto,
  FetchExternalDemandsParams,
} from 'src/Services/API';
import styles from './DemandsList.module.scss';
import { ExternalDemandsList } from './ExternalDemandsList';
import { formatFilters, formatSorting } from './ExternalDemandsList/utils';
import { externalDemandsActions } from 'src/Redux/ExternalDemands/Slice';
import { useFetchUniqueColumnValues } from 'src/Hooks/ExternalDemands/useFetchUniqueColumnValues';
import { useCountExternalDemands } from 'src/Hooks/ExternalDemands/useCountExternalDemands';

export const DemandsListRoute = () => {
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);
  const selectedSource = useSelector(getSelectedSource);
  const [globalFilter, setGlobalFilter] = useState('');
  const dispatch = useDispatch();

  const displayStatus = showArchivedDemands
    ? DisplayStatusDto.archived
    : DisplayStatusDto.displayable;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 20, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);

  const searchParams = useMemo(
    (): Omit<FetchExternalDemandsParams, 'limit' | 'page'> => ({
      source: selectedSource,
      displayStatus,
      ...formatFilters(columnFilters),
      globalFilter,
      sort: formatSorting(sorting),
    }),
    [columnFilters, displayStatus, globalFilter, selectedSource, sorting]
  );

  useEffect(() => {
    // Reset pagination when filters are changed
    setPagination(pagination => ({ pageIndex: 0, pageSize: pagination.pageSize }));
  }, [searchParams]);

  const paginatedSearchParams = useMemo(
    () => ({
      ...searchParams,
      limit: pagination.pageSize,
      page: pagination.pageIndex,
    }),
    [pagination.pageIndex, pagination.pageSize, searchParams]
  );
  const { data, isLoading: isExternalDemandsLoading } =
    useFetchExternalDemands(paginatedSearchParams);
  const { data: uniqueColumnValues } = useFetchUniqueColumnValues();
  const { data: count, isLoading: isCountLoading } = useCountExternalDemands(displayStatus);

  return (
    <>
      <div className={styles.container}>
        <TopBar title={showArchivedDemands ? 'demandes archivées' : 'demandes'}>
          <TopBarContent
            searchParams={searchParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </TopBar>
        <ToggleMenu
          value={selectedSource}
          onChange={source => dispatch(externalDemandsActions.setSelectedSource(source))}
          className={styles.toggleMenu}
        >
          {[AppelMedicalEventSourceDto.ARTEMIHS, AppelMedicalEventSourceDto.HUBLO].map(source => (
            <ToggleMenuItem value={source}>
              {source.toLowerCase()}
              {isCountLoading ? (
                <Loader size="xsmall" />
              ) : (
                count && <Badge color="currentColor">{count[source]}</Badge>
              )}
            </ToggleMenuItem>
          ))}
        </ToggleMenu>
        {isExternalDemandsLoading || isCountLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <div>chargement des demandes externes, cela peut prendre du temps</div>
          </div>
        ) : !(data && count) ? (
          <div className={styles.error}>
            désolé, une erreur s'est produite pendant la recherche de commandes
          </div>
        ) : count[selectedSource] === 0 ? (
          <div className={styles.noResult}>aucun résultat pour cette agence</div>
        ) : (
          <ExternalDemandsList
            data={data.demands}
            source={selectedSource}
            globalFilter={globalFilter}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            sorting={sorting}
            setSorting={setSorting}
            pagination={pagination}
            setPagination={setPagination}
            count={data.count}
            uniqueColumnValues={uniqueColumnValues}
          />
        )}
      </div>
    </>
  );
};
