import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'src/Redux/RootReducer';

import MissionCreationState from './Types';

export const getMissionCreationState = (state: RootState) => state.missionCreation;

export const getSelectedCompany = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedCompany
);

export const getSelectedQualification = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedQualification
);

export const isValidCompanyQualificationCouple = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) =>
    state.selectedCompany !== undefined && state.selectedQualification !== undefined
);

export const getSelectedCreationWay = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedCreationWay
);

export const isValidCreationWay = createSelector(
  [getMissionCreationState, isValidCompanyQualificationCouple],
  (state: MissionCreationState, isValidCouple: boolean) =>
    isValidCouple && state.selectedCreationWay !== undefined
);

export const isValidPreviousMissionSelected = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedPreviousMission !== undefined
);

export const isValidPreviousPositionStudySelected = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedPreviousPositionStudy !== undefined
);

export const getPreviousMissions = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.previousMissions
);

export const getSelectedPreviousMission = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedPreviousMission
);

export const getMissionDataFetchStatus = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.missionDataFetchStatus
);

export const getProcessedMission = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.processedMission
);

export const isValidProcessedMission = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => {
    return (
      state.numberPositions !== undefined &&
      state.processedMission.startDate !== undefined &&
      (state.isFromYouplanMission ||
        moment(state.processedMission.startDate) > moment(new Date()).subtract(3, 'days')) &&
      (state.processedMission.endDate === undefined ||
        moment(state.processedMission.endDate).isSameOrAfter(
          moment(state.processedMission.startDate)
        )) &&
      state.processedMission.contactId !== undefined
    );
  }
);

export const getNewMissionId = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.newMissionId
);

export const getMissionCreationFetchStatus = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.missionCreationFetchStatus
);

export const getPositionStudiesCountSelector = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.positionStudiesCount
);

export const getPreviousPositionStudies = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.previousPositionStudies
);

export const getPreviousPositionStudiesFetchStatus = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.positionStudiesFetchStatus
);

export const getSelectedPreviousPositionStudy = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.selectedPreviousPositionStudy
);

export const getPreviousMissionsFetchStatus = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.missionsFetchStatus
);

export const getIsFromYouplanMission = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.isFromYouplanMission
);

export const getYouplanMissionIds = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.youplanMissionIds
);

export const getYouplanMissionCandidates = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.youplanMissionCandidates
);
export const getNumberPositions = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.numberPositions
);

export const getCandidateId = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.candidateId
);

export const getIsFromMyRegion = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.isFromMyRegion
);

export const getIsTalent = createSelector(
  [getMissionCreationState],
  (state: MissionCreationState) => state.isTalent
);

export default getMissionCreationState;
