import {
  Button,
  DropDown,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RefuseSuggestedIcon } from 'src/Assets_DEPRECATED';
import { useFetchRelatedCGC } from 'src/Hooks/Agencies/useFetchRelatedCGC';
import { useRefuseSuggestedCCdii } from 'src/Hooks/Candidate/useRefuseSuggestedCdii';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { QueryKeys } from 'src/Hooks/types';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { RefuseCandidateState } from 'src/Services/Routing';
import styles from './RefuseSuggestedCandidateModal.module.scss';
import { rejectionReason } from './utils';
import { useAddBlacklistedCompanyByCandidate } from 'src/Hooks/Candidate/useAddBlacklistedCompanyByCandidate';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';

const RefuseSuggestedCandidateModal = () => {
  const ref = useRef<PopupActions>(null);
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as RefuseCandidateState).modalParameters;
  const { data: isCGC } = useIsCGC();
  const relatedCGCQuery = useFetchRelatedCGC();
  const addBlacklistedCompany = useAddBlacklistedCompanyByCandidate();
  const { data: missionDetails } = useFetchMissionDetails(modalParameters?.missionId);
  const agencyId = useSelector(getCurrentAgency);
  const relatedCGCFetchStatus = toFetchStatus(relatedCGCQuery);
  const refuseSuggestedCdiiMutation = useRefuseSuggestedCCdii();
  const fetchStatus = toFetchStatus(refuseSuggestedCdiiMutation);
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState<string | undefined>();
  const queryClient = useQueryClient();
  return (
    <ModalDeprecated
      ref={ref}
      open
      onClose={() => navigate(-1)}
      icon={<RefuseSuggestedIcon className={styles.icon} />}
      title="refus de suggestion"
      footerActionsRight={[
        <Button.Secondary key="cgcModalCancelButton" onClick={() => ref.current?.close()}>
          annuler
        </Button.Secondary>,
        <FetchButton
          key="cgcModalValidateButton"
          disabled={
            !comment ||
            comment.length < 30 ||
            relatedCGCFetchStatus === FETCH_STATUS.PENDING ||
            reason === undefined
          }
          title="envoyer"
          fetchStatus={fetchStatus}
          onClick={() => {
            if (reason === 'talent et client ne souhaitent plus collaborer') {
              if (modalParameters?.id && missionDetails?.company?.companyId)
                addBlacklistedCompany.mutate({
                  candidateId: modalParameters.id,
                  companyId: missionDetails.company.companyId,
                });
            }

            refuseSuggestedCdiiMutation.mutate({
              candidateId: modalParameters?.id ?? '',
              firstName: modalParameters?.firstName ?? '',
              lastName: modalParameters?.name,
              cgcId: isCGC ? agencyId : (relatedCGCQuery.data?.id ?? ''),
              comment,
              agencyId,
              suggestedMissionsIds: [modalParameters?.missionId].filter(Boolean),
              companiesNames: modalParameters?.companyName
                ? [modalParameters.companyName]
                : undefined,
              gender: modalParameters?.gender,
              reason: reason,
              refuseAll: true,
            });
          }}
          onSuccess={() => {
            ref.current?.close();
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.fetchMissionDetails, modalParameters?.missionId],
            });
          }}
        />,
      ]}
    >
      {(relatedCGCQuery.isFetching || relatedCGCQuery.isLoading) && (
        <ContentLoader key="cdii-refuse-loader" width="100%" height="2.5rem">
          <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
          <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
        </ContentLoader>
      )}
      {((relatedCGCQuery.isSuccess && relatedCGCQuery.data !== undefined) ||
        (relatedCGCQuery.isError && isCGC)) && (
        <p className={styles.text}>
          quelles sont les raisons qui vous poussent à refuser ce talent ?
        </p>
      )}
      <WithLightTitle title="motif">
        <DropDown
          placeholder="choisir un motif"
          items={rejectionReason}
          keyValueExtractor={(reason: string) => {
            return {
              key: reason,
              value: reason,
            };
          }}
          selectedItem={reason}
          onSelectItem={(value?: string) => {
            setReason(value);
          }}
        />
      </WithLightTitle>
      <WithLightTitle
        title="commentaire (30 caractères min)"
        className={styles.textAreaTitle}
        rightTitleComponent={<p className={styles.count}>{`${comment.length}/4000 caractères`}</p>}
      >
        <TextArea
          placeholder="aucun commentaire renseigné"
          className={styles.textArea}
          value={comment}
          minLength={30}
          maxLength={4000}
          onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
        />
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default RefuseSuggestedCandidateModal;
