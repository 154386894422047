import moment from 'moment';
import { OSMCommonModelDateDto } from 'src/Services/API';

const toHHMMTimeFormat = (seconds: number, separator: string): string => {
  const duration = moment.duration(seconds, 'seconds');
  return `${Math.floor(duration.asHours())
    .toString()
    .padStart(2, '0')}${separator}${duration.minutes().toString().padStart(2, '0')}`;
};

export const OSMToTimeInput = (time: number): string => {
  return toHHMMTimeFormat(time, ':');
};

export const OSMToTimeDisplay = (time: number): string => {
  return toHHMMTimeFormat(time, 'h');
};

export const timeInputToOSM = (time: string): number => {
  return moment.duration(time).asSeconds();
};

export function toDate(date: undefined): undefined;
export function toDate(date: OSMCommonModelDateDto): Date;
export function toDate(date: OSMCommonModelDateDto | undefined): Date | undefined;
export function toDate(date: OSMCommonModelDateDto | undefined): Date | undefined {
  if (date === undefined) return undefined;
  return new Date(
    date.year || new Date().getUTCFullYear(),
    (date.month || new Date().getUTCMonth() + 1) - 1,
    date.day
  );
}

export function toDateFormatted(input: undefined, format: string): undefined;
export function toDateFormatted(input: OSMCommonModelDateDto, format: string): string;
export function toDateFormatted(
  input: OSMCommonModelDateDto | undefined,
  format: string
): string | undefined;
export function toDateFormatted(
  input: OSMCommonModelDateDto | undefined,
  format: string
): string | undefined {
  if (!input) return undefined;
  const date = toDate(input);
  return moment(date).format(format);
}
