import { useMemo, useState } from 'react';

import {
  Information,
  SortDescending,
  SortDown,
  SortUp,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@randstad-lean-mobile-factory/react-components-core';
import { WorkAccidentAnalysisCard } from '../WorkAccidentAnalysisCard';
import styles from './WorkAccidentAnalysisKanbanColumn.module.scss';
import { WorkAccidentAnalysisKanbanColumnProps } from './WorkAccidentAnalysisKanbanColumn.types';
import { orderBy } from 'lodash-es';
import { formatName } from 'src/Utils/formatName';

export const WorkAccidentAnalysisKanbanColumn = ({
  data,
  status,
}: WorkAccidentAnalysisKanbanColumnProps) => {
  const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
  const [sortCriteria, setSortCriteria] = useState<'name' | 'startDate'>('startDate');

  const sortedData = useMemo(
    () =>
      orderBy(
        data,
        [
          workAccidentAnalysis => {
            if (sortCriteria === 'name') return formatName(workAccidentAnalysis.candidate);
            if (sortCriteria === 'startDate') return workAccidentAnalysis.startDate;
          },
        ],
        [sortType]
      ),
    [data, sortCriteria, sortType]
  );

  return (
    <div className={styles.column}>
      <div className={styles.header}>
        <div className={styles.title}>
          {{ TODO: 'à faire', IN_PROGRESS: 'en cours', DONE: 'fait' }[status]}
        </div>
        {status === 'TODO' && (
          <Tooltip title="Accident de travail avec un arrêt de travail > 4 jours">
            <Button variant="tertiary" size="medium">
              <Information />
            </Button>
          </Tooltip>
        )}
        <Menu
          trigger={
            <Button variant="tertiary" size="medium">
              <SortDescending />
            </Button>
          }
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem
            selected={sortType === 'asc'}
            onClick={() => setSortType('asc')}
            keepOpenOnClick
          >
            <SortUp />
            ascendant
          </MenuItem>
          <MenuItem
            selected={sortType === 'desc'}
            onClick={() => setSortType('desc')}
            keepOpenOnClick
          >
            <SortDown />
            descendant
          </MenuItem>

          <div className={styles.menuGroup}>trier par</div>

          <MenuItem
            selected={sortCriteria === 'name'}
            onClick={() => setSortCriteria('name')}
            keepOpenOnClick
          >
            intérimaire
          </MenuItem>
          <MenuItem
            selected={sortCriteria === 'startDate'}
            onClick={() => setSortCriteria('startDate')}
            keepOpenOnClick
          >
            date de déclaration
          </MenuItem>
        </Menu>
      </div>
      <div className={styles.content}>
        {sortedData.map(workAccidentAnalysis => (
          <WorkAccidentAnalysisCard
            key={workAccidentAnalysis.id}
            workAccidentAnalysis={workAccidentAnalysis}
            status={status}
          />
        ))}
      </div>
    </div>
  );
};
