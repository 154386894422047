import { useAuth0 } from '@auth0/auth0-react';
import {
  Administration,
  Chrono,
  Partners,
  RandstadCircle,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ParametersPopup as ParametersPopupPrimitive,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAdmin } from 'src/Hooks/Admin/useIsAdmin';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { PerimeterSelectionModal } from 'src/Modals/PerimeterSelectionModal';
import { ROUTES } from 'src/Routes/Routes.types';
import { ParametersPopupProps } from './ParametersPopup.types';

export const ParametersPopup = (props: ParametersPopupProps) => {
  const navigate = useNavigate();

  const { logout } = useAuth0();
  const user = useAuthenticatedUser();

  const { data: isAdmin } = useIsAdmin();

  const ref = useRef<PopoverActions>(null);
  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <>
      <ParametersPopupPrimitive
        onClose={() => setIsPerimeterModalOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
        firstName={user.givenName}
        lastName={user.familyName}
        email={user.email}
        onLogout={() =>
          logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI } })
        }
        {...props}
        ref={ref}
      >
        {isAdmin && (
          <>
            <Button
              variant="tertiary"
              onClick={() => {
                ref.current?.close();
                navigate(ROUTES.TOOLS.ESTABLISHMENTS.buildPath({}));
              }}
            >
              <Partners /> établissements
            </Button>
            <Button
              variant="tertiary"
              onClick={() => {
                ref.current?.close();
                navigate(ROUTES.TOOLS.FEATURE_FLAGS.buildPath({}));
              }}
            >
              <Administration /> feature flags
            </Button>
            <Button
              variant="tertiary"
              onClick={() => {
                ref.current?.close();
                navigate(ROUTES.TOOLS.CRON_JOBS.buildPath({}));
              }}
            >
              <Chrono /> tâches programmées
            </Button>
          </>
        )}
        <Button
          variant="tertiary"
          onClick={() => {
            ref.current?.close();
            setIsPerimeterModalOpen(true);
          }}
        >
          <RandstadCircle variant="fill" /> choix de vos unités
        </Button>
      </ParametersPopupPrimitive>
      <PerimeterSelectionModal
        open={isPerimeterModalOpen}
        onClose={() => setIsPerimeterModalOpen(false)}
      />
    </>
  );
};
