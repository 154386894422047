import { Export, Folder } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, SearchInput } from '@randstad-lean-mobile-factory/react-components-core';
import { useMatch, useNavigate } from 'react-router-dom';
import { useExportExternalDemands } from 'src/Hooks/ExternalDemands/useExportExternalDemands';
import { ROUTES } from 'src/Routes/Routes.types';
import { ExternalDemandsTopBarComponentProps } from './TopBarContent.types';

export const TopBarContent = ({
  searchParams,
  globalFilter,
  setGlobalFilter,
}: ExternalDemandsTopBarComponentProps) => {
  const navigate = useNavigate();
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);

  const exportExternalDemands = useExportExternalDemands(searchParams, {
    onSettled: () => {
      setTimeout(() => exportExternalDemands.reset(), 500);
    },
  });

  return (
    <>
      <Button
        variant="secondary"
        size="large"
        onClick={() => {
          if (showArchivedDemands) {
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({}));
          } else {
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.buildPath({}));
          }
        }}
      >
        <Folder />
        {showArchivedDemands ? 'demandes non archivées' : 'demandes archivées'}
      </Button>
      {!showArchivedDemands && (
        <Button
          size="large"
          onClick={() => exportExternalDemands.mutate()}
          mutationStatus={exportExternalDemands.status}
        >
          <Export /> exporter
        </Button>
      )}
      <SearchInput
        size="medium"
        value={globalFilter}
        onValueChange={setGlobalFilter}
        placeholder="rechercher"
        collapsed
      />
    </>
  );
};
