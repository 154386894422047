import { CandidateSearchActivityPlaning } from 'src/Services/API';
import { CdiiSource } from '../CdiiList.types';

export interface CdiiTableProps {
  globalFilter: string;
  source: CdiiSource;
  cdiis?: CandidateSearchActivityPlaning[];
  isLoading: boolean;
  isFetching: boolean;
  hasNextPage: boolean | undefined;
}

/** @deprecated this type should not exist */
export enum CDII_STATUS {
  INTERMISSION = 'intermission',
  SOON_AVAILABLE = 'bientôt dispo',
  MISSION = 'en mission',
  AVAILABLE = 'sos envoyé',
  SUGGESTED = 'suggéré',
  PROCESSING = 'en traitement',
  ABSENT = 'absent',
}
