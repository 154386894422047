import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { WorkAccidentAnalysisService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchWorkAccidentFileData = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  return useMutation(
    [MutationKeys.fetchWorkAccidentFileData],
    async (fileId: string) => {
      const workAccident =
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentFileData(
          { id: fileId },
          { responseType: 'arraybuffer' }
        );
      return workAccident;
    },
    {
      ...extraOptions,
    }
  );
};
