import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import {
  getNumberPositions,
  getProcessedMission,
  getSelectedCompany,
  getSelectedQualification,
} from 'src/Redux/MissionCreation/Selectors';
import MissionCreationValidation from './MissionCreationValidation.component';

const MemoMissionCreationValidation = memo(MissionCreationValidation);

const EnhancedMissionCreationValidation = () => {
  const user = useAuthenticatedUser();
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const processedMission = useSelector(getProcessedMission);
  const numberPositions = useSelector(getNumberPositions);
  return (
    <MemoMissionCreationValidation
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      processedMission={processedMission}
      userFirstName={user.givenName}
      userLastName={user.familyName}
      numberPositions={numberPositions}
    />
  );
};

export default EnhancedMissionCreationValidation;
