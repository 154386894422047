import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { getCurrentMissionId } from 'src/Redux/Missions/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import MissionDetail from './MissionDetail.component';
import { OwnProps } from './MissionDetail.types';

const MemoMissionDetail = memo(MissionDetail);

const EnhancedMissionDetail = (props: OwnProps) => {
  const params = useMatch(ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL)?.params;
  const selectedMissionId = useSelector(getCurrentMissionId);
  return (
    <MemoMissionDetail
      missionId={props.missionId ?? params?.missionId ?? selectedMissionId}
      {...props}
    />
  );
};

export default EnhancedMissionDetail;
