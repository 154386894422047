import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, SearchInput } from '@randstad-lean-mobile-factory/react-components-core';
import { AgencyCdiiTopBarContentProps } from './AgencyCdiiTopBarContent.types';
import { AgencyCdiiSortingMenu } from './AgencyCdiiSortingMenu';

export const AgencyCdiiTopBarContent = ({
  globalFilter,
  setGlobalFilter,
  sort,
  setSort,
}: AgencyCdiiTopBarContentProps) => (
  <>
    <SearchInput
      size="small"
      value={globalFilter}
      onValueChange={setGlobalFilter}
      placeholder="rechercher"
      collapsed
    />
    <AgencyCdiiSortingMenu
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending />
        </Button>
      }
      sort={sort}
      setSort={setSort}
    />
  </>
);
