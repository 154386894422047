import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

export interface AuthenticatedUser {
  givenName?: string;
  familyName?: string;
  email?: string;
}

export const useAuthenticatedUser = (): AuthenticatedUser => {
  const { user } = useAuth0();
  return useMemo(
    () => ({
      givenName: user?.given_name,
      familyName: user?.family_name,
      email: user?.email,
    }),
    [user]
  );
};
