import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentAnalysisService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useGenerateWorkAccidentAnalysisPdf = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const user = useAuthenticatedUser();

  return useMutation(
    [MutationKeys.generateWorkAccidentAnalysisPdf],
    async (analyseId: string) => {
      const analyse =
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkingAnalysePdf(
          { id: analyseId, body: { brandCode, user } },
          { responseType: 'arraybuffer' }
        );
      return analyse;
    },
    {
      ...extraOptions,
    }
  );
};
