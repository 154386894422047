import { memo, useCallback } from 'react';
import { OwnProps } from './YouplanMissionActions.types';
import ActionPopupMenuContent from './YouplanMissionActions.component';
import { useDispatch } from 'react-redux';
import { fetchYouplanMissionForNewMission } from 'src/Redux/MissionCreation/Thunks';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MISSION_CREATION_STEP } from 'src/ModalRoutes_DEPRECATED/MissionCreationModal/MissionCreationModal.types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from 'src/Redux/RootReducer';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';

const MemoActionPopupMenuContent = memo(ActionPopupMenuContent);

const EnhancedYouplanMissionActions = ({ mission, candidateId, trigger }: OwnProps) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const location = useLocation();
  const isPendingMission =
    !mission.youplanStatus?.isInConflict &&
    mission.salesPhase?.id !== 'AN' &&
    mission.youplanStatus?.action === undefined;
  const onInfoClick = useCallback(() => {
    navigate(MODAL_ROUTES.YOUPLAN_MISSION_DETAILS.route.buildPath({}), {
      state: {
        background: location,
        missionId: mission.missionId,
        isPendingMission: isPendingMission,
      },
    });
  }, [navigate, isPendingMission, location, mission.missionId]);
  const onCreateMission = useCallback(() => {
    dispatch(missionCreationActions.setYouplanMissionIds([mission.missionId ?? '']));
    dispatch(fetchYouplanMissionForNewMission(mission.missionId ?? ''));
    dispatch(missionCreationActions.setYouPlanMissionCandidates(mission.candidates ?? []));
    navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
      state: {
        modalParameters: {
          openingStep: MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY,
        },
        background: location,
      },
    });
  }, [dispatch, navigate, location, mission]);

  return (
    <MemoActionPopupMenuContent
      onInfoClick={onInfoClick}
      onCreateMission={onCreateMission}
      mission={mission}
      candidateId={candidateId}
      trigger={trigger}
    />
  );
};

export default EnhancedYouplanMissionActions;
