import { MissionDetail } from 'src/Services/API';

export const getSalaryDisplay = (mission: MissionDetail) => {
  if (mission?.salary) {
    return `${mission.salary} € par mois`;
  } else if (mission?.salaryHourBase) {
    const hourBase = `${mission.salaryHourBase} €/h`;
    const weekHours = mission.weekHours ? `${mission.weekHours} ` : "un nombre inconnu d'";
    return `${hourBase} pour ${weekHours}heures par semaine`;
  } else {
    return 'aucun salaire renseigné';
  }
};

export const habilitationsPriorityMapping: Record<
  number,
  { color: 'blue' | 'yellow' | 'error'; label: string }
> = {
  1: { color: 'blue', label: 'souhaitée' },
  2: { color: 'yellow', label: 'important' },
  3: { color: 'error', label: 'obligatoire' },
};
