import { ToggleMenu, ToggleMenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedState } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { AgencyTemporaryWorkersList } from './AgencyTemporaryWorkersList';
import {
  defaultAgencyFilters,
  defaultAgencySort,
} from './AgencyTemporaryWorkersList/AgencyTemporaryWorkersList.types';
import { RegionalTemporaryWorkersList } from './RegionalTemporaryWorkersList';
import {
  defaultRegionalFilters,
  defaultRegionalSort,
} from './RegionalTemporaryWorkersList/RegionalTemporaryWorkersList.types';
import styles from './TemporaryWorkersList.module.scss';
import { TemporaryWorkersSource } from './TemporaryWorkersList.types';
import { RegionalTemporaryWorkersTopBarContent } from './RegionalTemporaryWorkersTopBarContent';
import { AgencyTemporaryWorkersTopBarContent } from './AgencyTemporaryWorkersTopBarContent';

export const TemporaryWorkersListRoute = () => {
  const navigate = useNavigate();
  const {
    agencyFilters: initialAgencyFilters,
    agencySort: initialAgencySort,
    regionalFilters: initialRegionalFilters,
    regionalSort: initialRegionalSort,
  } = useTypedState(ROUTES.TALENTS.TEMPORARY_WORKERS);

  const userInfo = useSelector(getAnalyticsUserInfo);

  const [agencyGlobalFilter, setAgencyGlobalFilter] = useState('');
  const [agencyFilters, setAgencyFilters] = useState(initialAgencyFilters ?? defaultAgencyFilters);
  const [agencySort, setAgencySort] = useState(initialAgencySort ?? defaultAgencySort);
  const [regionalGlobalFilter, setRegionalGlobalFilter] = useState('');
  const [regionalFilters, setRegionalFilters] = useState(
    initialRegionalFilters ?? defaultRegionalFilters
  );
  const [regionalSort, setRegionalSort] = useState(initialRegionalSort ?? defaultRegionalSort);

  const [selectedSource, setSelectedSource] = useState(
    useTypedHash(ROUTES.TALENTS.TEMPORARY_WORKERS) ?? TemporaryWorkersSource.agency
  );

  useEffect(() => {
    navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}, {}, selectedSource), {
      replace: true,
      state: ROUTES.TALENTS.TEMPORARY_WORKERS.buildState({
        agencyFilters,
        agencySort,
        regionalFilters,
        regionalSort,
      }),
    });
  }, [navigate, selectedSource, regionalFilters, agencyFilters, agencySort, regionalSort]);

  return (
    <>
      <TopBar
        title={
          <ToggleMenu
            value={selectedSource}
            onChange={source => {
              setSelectedSource(source);
              if (source === TemporaryWorkersSource.agency) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_MYTT, {
                  ...userInfo,
                });
              }
              if (source === TemporaryWorkersSource.regional) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_TTMYREGION, {
                  ...userInfo,
                });
              }
            }}
          >
            <ToggleMenuItem
              value={TemporaryWorkersSource.agency}
              title="retrouvez dans cette liste tous les INT inscrits sur votre unité"
            >
              intérimaires de mon unité
            </ToggleMenuItem>
            <ToggleMenuItem
              value={TemporaryWorkersSource.regional}
              title="retrouvez dans cette liste tous les INT rattachés aux agences proches de la vôtre en fonction de la distance kilométrique sélectionnée"
            >
              intérimaires de ma région
            </ToggleMenuItem>
          </ToggleMenu>
        }
        comments={
          selectedSource === TemporaryWorkersSource.agency
            ? 'retrouvez dans cette liste tous les INT inscrits sur votre unité'
            : 'retrouvez dans cette liste tous les INT rattachés aux agences proches de la vôtre en fonction de la distance kilométrique sélectionnée'
        }
      >
        {selectedSource === TemporaryWorkersSource.agency ? (
          <AgencyTemporaryWorkersTopBarContent
            globalFilter={agencyGlobalFilter}
            setGlobalFilter={setAgencyGlobalFilter}
            filters={agencyFilters}
            setFilters={setAgencyFilters}
            sort={agencySort}
            setSort={setAgencySort}
          />
        ) : selectedSource === TemporaryWorkersSource.regional ? (
          <RegionalTemporaryWorkersTopBarContent
            globalFilter={regionalGlobalFilter}
            setGlobalFilter={setRegionalGlobalFilter}
            filters={regionalFilters}
            setFilters={setRegionalFilters}
            sort={regionalSort}
            setSort={setRegionalSort}
          />
        ) : null}
      </TopBar>
      <div className={styles.container}>
        {selectedSource === TemporaryWorkersSource.agency ? (
          <AgencyTemporaryWorkersList
            globalFilter={agencyGlobalFilter}
            filters={agencyFilters}
            sort={agencySort}
          />
        ) : selectedSource === TemporaryWorkersSource.regional ? (
          <RegionalTemporaryWorkersList
            globalFilter={regionalGlobalFilter}
            filters={regionalFilters}
            sort={regionalSort}
          />
        ) : null}
      </div>
    </>
  );
};
