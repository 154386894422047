import {
  Button,
  Loader,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';

import { ReactElement, useRef, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';
import { UploadedFilesStatus, UploadFileResult, VariablesimulatorService } from 'src/Services/API';
import { FileInput } from '../FileInput';

import { illusSuccess } from 'src/Assets_DEPRECATED';
import { illusAdmin } from 'src/Assets_DEPRECATED';
import { illusAlert } from 'src/Assets_DEPRECATED';

import styles from './VariableSimulatorAdmin.module.scss';
import { Props } from './VariableSimulatorAdmin.types';

import { FETCH_STATUS } from 'src/Redux/Types';
import { useQuery } from '@tanstack/react-query';

import { toFetchStatus } from 'src/Services/Async';
import moment from 'moment';

enum StateComponent {
  DEFAULT,
  UPLOADING,
  MESSAGE,
}

interface FileComponentProps {
  name: string;
  section: string;
  title: string;
  selectedFile: File | undefined;
  fileResult: UploadFileResult | undefined;
  fetchStatus: FETCH_STATUS;
  onChangeData: (item: File | undefined) => void;
}
const FileComponent = (props: FileComponentProps) => (
  <WithLightTitle title={props.section}>
    <div>
      <FileInput
        name={props.name}
        selectedFile={props.selectedFile}
        onChangeData={data => props.onChangeData(data)}
        titleNode={
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div className={styles.inputFileTitle}>{props.title}</div>

                {props.fetchStatus === FETCH_STATUS.PENDING && (
                  <div style={{ color: '#contrast_grey_strong', fontSize: '0.75rem' }}>
                    'Le statut est en cours de récupération...'
                  </div>
                )}

                {props.fetchStatus !== FETCH_STATUS.PENDING && (
                  <>
                    <div style={{ color: '#contrast_grey_strong', fontSize: '0.75rem' }}>
                      Dernière MAJ le {moment(props.fileResult?.uploadedAt).format()} par{' '}
                      {props.fileResult?.siid}
                    </div>
                    <div style={{ color: '#contrast_grey_strong', fontSize: '0.75rem' }}>
                      Fichier: {props.fileResult?.fileName}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  </WithLightTitle>
);

const VariableSimulatorAdmin = ({ trigger, ...rest }: Props) => {
  const innerRef = useRef<PopupActions>(null);
  const innerRef2 = useRef<PopupActions>(null);

  const uploadedFilesStatusFetchData = useQuery<UploadedFilesStatus>(
    ['useHistoricFetch'],
    async () => {
      return await VariablesimulatorService.variableSimulatorControllerGetUploadedFilesStatus();
    },
    {
      enabled: true,
      retry: false,
    }
  );

  const uploadedFilesStatusFetchStatus = toFetchStatus(uploadedFilesStatusFetchData);

  const [error, setError] = useState<string>();
  const [selectedRHPIFile, setSelectedRHPIFile] = useState<File>();
  const [selectedUnitFile, setSelectedUnitFile] = useState<File>();
  const [selectedCoefficientFile, setSelectedCoefficientFile] = useState<File>();
  const [selectedTrancheFile, setSelectedTrancheFile] = useState<File>();
  const [selectedRHPIFunctionFile, setSelectedRHPIFunctionFile] = useState<File>();

  const [stateComponent, setStateComponent] = useState<StateComponent>(StateComponent.DEFAULT);

  const [messageIcon, setMessageIcon] = useState<ReactElement>(
    <img src={illusSuccess} alt="admin" />
  );
  const [displayMessageContent, setDisplayMessageContent] = useState('');
  const [displayMessageTitle, setDisplayMessageTitle] = useState('');
  const [waitingMode, setWaitingMode] = useState(false);

  const submitData = async () => {
    if (selectedRHPIFile) {
      await VariablesimulatorService.variableSimulatorControllerUploadRhpiFile({
        file: selectedRHPIFile,
      });
    }

    if (selectedUnitFile) {
      await VariablesimulatorService.variableSimulatorControllerUploadUnitFile({
        file: selectedUnitFile,
      });
    }

    if (selectedCoefficientFile) {
      await VariablesimulatorService.variableSimulatorControllerUploadCoefficientFile({
        file: selectedCoefficientFile,
      });
    }

    if (selectedTrancheFile) {
      await VariablesimulatorService.variableSimulatorControllerUploadTrancheFile({
        file: selectedTrancheFile,
      });
    }

    if (selectedRHPIFunctionFile) {
      await VariablesimulatorService.variableSimulatorControllerUploadRhpiFunctionFile({
        file: selectedRHPIFunctionFile,
      });
    }

    return Promise.resolve(true);
  };

  const displayMessageInfo = (message: string) => {
    setStateComponent(StateComponent.MESSAGE);
    setDisplayMessageContent(message);
    setDisplayMessageTitle('Info');
    setMessageIcon(<img src={illusSuccess} alt="Success" />);
  };

  const displayMessageError = (message: string, code?: number) => {
    const extCode = code === undefined ? '' : '  code(' + code + ')';
    setStateComponent(StateComponent.MESSAGE);
    setDisplayMessageContent(message + extCode);
    setDisplayMessageTitle('Erreur');
    setMessageIcon(<img src={illusAlert} alt="alert" />);
  };

  const validateData = () => {
    return undefined;
  };

  const onValidate = () => {
    const _errorMsg = validateData();
    setError(_errorMsg !== undefined ? _errorMsg : undefined);
    if (_errorMsg === undefined) {
      displayMessageInfo('envoi des données...');
      setWaitingMode(true);
      submitData()
        .then(() => {
          setWaitingMode(false);
          setSelectedRHPIFile(undefined);
          setSelectedUnitFile(undefined);
          setSelectedTrancheFile(undefined);
          setSelectedCoefficientFile(undefined);
          setSelectedRHPIFunctionFile(undefined);
          displayMessageInfo(`les fichier(s) sont transférés avec succès`);
        })
        .catch(error => {
          setWaitingMode(false);
          setSelectedRHPIFile(undefined);
          setSelectedUnitFile(undefined);
          setSelectedTrancheFile(undefined);
          setSelectedCoefficientFile(undefined);
          setSelectedRHPIFunctionFile(undefined);
          displayMessageError((error as any)?.message, (error as any)?.code);
        });
    }
  };

  return (
    <>
      {stateComponent === StateComponent.DEFAULT && (
        <ModalDeprecated
          title={`Administration`}
          trigger={trigger}
          ref={innerRef}
          {...rest}
          icon={<img src={illusAdmin} alt="alert" />}
          footerActionsLeft={[]}
          footerActionsRight={[
            <Button.Secondary
              onClick={() => {
                innerRef.current?.close();
                setStateComponent(StateComponent.DEFAULT);
              }}
            >
              annuler
            </Button.Secondary>,
            <Button
              disabled={
                selectedRHPIFile === undefined &&
                selectedUnitFile === undefined &&
                selectedCoefficientFile === undefined &&
                selectedTrancheFile === undefined &&
                selectedRHPIFunctionFile === undefined
              }
              onClick={() => {
                onValidate();
              }}
            >
              valider
            </Button>,
          ]}
        >
          <FileComponent
            name={'rhpi'}
            section={'uploader le fichier salariés RHPI en format csv'}
            title={'sélectionner le fichier salariés RHPI en format csv'}
            selectedFile={selectedRHPIFile}
            fileResult={uploadedFilesStatusFetchData.data?.rhpiFile}
            fetchStatus={uploadedFilesStatusFetchStatus}
            onChangeData={setSelectedRHPIFile}
          />
          <FileComponent
            name={'unit'}
            section={'uploader le fichier synthèse unité en format csv'}
            title={'sélectionner le fichier synthèse unité en format csv'}
            selectedFile={selectedUnitFile}
            fileResult={uploadedFilesStatusFetchData.data?.unitFile}
            fetchStatus={uploadedFilesStatusFetchStatus}
            onChangeData={setSelectedUnitFile}
          />
          <FileComponent
            name={'coefficient'}
            section={'"uploader le fichier de coefficient"'}
            title={'sélectionner le fichier coefficient en format csv'}
            selectedFile={selectedCoefficientFile}
            fileResult={uploadedFilesStatusFetchData.data?.coefficientFile}
            fetchStatus={uploadedFilesStatusFetchStatus}
            onChangeData={setSelectedCoefficientFile}
          />
          <FileComponent
            name={'tranche'}
            section={'"uploader le fichier de tranche"'}
            title={'sélectionner le fichier tranche en format csv'}
            selectedFile={selectedTrancheFile}
            fileResult={uploadedFilesStatusFetchData.data?.trancheFile}
            fetchStatus={uploadedFilesStatusFetchStatus}
            onChangeData={setSelectedTrancheFile}
          />
          <FileComponent
            name={'rhpi function'}
            section={'"uploader le fichier des fonctions"'}
            title={'sélectionner le fichier des fonctions en format csv'}
            selectedFile={selectedRHPIFunctionFile}
            fileResult={uploadedFilesStatusFetchData.data?.rhpiFunctionFile}
            fetchStatus={uploadedFilesStatusFetchStatus}
            onChangeData={setSelectedRHPIFunctionFile}
          />

          <div> {error} </div>
        </ModalDeprecated>
      )}

      {stateComponent === StateComponent.MESSAGE && (
        <ModalDeprecated
          title={displayMessageTitle}
          trigger={trigger}
          defaultOpen={true}
          ref={innerRef2}
          {...rest}
          icon={messageIcon}
          onClose={() => {
            setStateComponent(StateComponent.DEFAULT);
          }}
          footerActionsRight={[
            <>
              {!waitingMode && (
                <Button
                  onClick={() => {
                    innerRef2.current?.close();
                  }}
                >
                  fermer
                </Button>
              )}

              {waitingMode && <Loader heightInRem={4} />}
            </>,
          ]}
        >
          <div className={styles.text}>{displayMessageContent}</div>
        </ModalDeprecated>
      )}
    </>
  );
};

export default VariableSimulatorAdmin;
