import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './SubscribeModal.types';
import { DeleteAlerte } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { useUpdateCandidateSubscription } from 'src/Hooks/Candidate/useUpdateCandidateSubscription';
import { useCallback } from 'react';

// TODO We should have a onClose as a prop instead of a setOpen/setRepositiongValidationModalOpen, because a component does not need to know the context in which it is used
// TODO this should not be in src/Modals, it is not a general purpose modal.
const SubscribeModal = ({
  open,
  setOpen,
  candidateId,
  decryptedAgenciesIds,
  setRepositiongValidationModalOpen,
}: Props) => {
  const updateSubscription = useUpdateCandidateSubscription({
    onSuccess: () => {
      setTimeout(() => onClose(), 500);
    },
    onError: () => {
      setTimeout(() => updateSubscription.reset(), 500);
    },
  });

  const onClose = useCallback(() => {
    setOpen(false);
    setRepositiongValidationModalOpen(true);
  }, [setOpen, setRepositiongValidationModalOpen]);

  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<DeleteAlerte />} title="Inscription sur l'unité">
        Vous venez de rattacher un talent à une commande n'ayant pas été créée sur une des agences
        d'inscription du talent. Voulez-vous l'inscrire sur cette unité ?
      </ModalContent>
      <ModalActions side="left">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button
          variant="primary"
          onClick={() => {
            updateSubscription.mutate({ candidateId, decryptedAgenciesIds });
          }}
          mutationStatus={updateSubscription.status}
        >
          valider
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default SubscribeModal;
