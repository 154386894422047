import {
  Badge,
  CardCarousel,
  CardCell,
  CardRow,
  HorizontalCardWithTitle,
  StyledTable,
  TableBody,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { TickCircleGreen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useNavigate } from 'react-router-dom';
import { emailLine, mobileLine } from 'src/Assets_DEPRECATED';
import { useFetchCompanyActions } from 'src/Hooks/Companies/useFetchCompanyActions';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import {
  EnumCreateActionBodyActionTarget,
  EnumCreateActionBodyActionType,
  MissionDetail,
} from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import styles from './MissionInformations.module.scss';
import { Habilitation } from '@randstad-lean-mobile-factory/react-assets/dist/pictos';
import { habilitationsPriorityMapping } from './MissionInformations.utils';

const MissionInformations = ({ mission }: { mission: MissionDetail }) => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const companyActions = useFetchCompanyActions(mission?.company?.companyId, mission?.agencyId);
  return (
    <div className={styles.bottomContainer}>
      <div className={styles.row}>
        <CardCarousel title="contact">
          {[
            <HorizontalCardWithTitle
              className={styles.contact}
              key="contact-card"
              title={formatName(mission.contact) ?? ''}
              titleClassname={styles.contactTitles}
              subtitles={[mission?.contact?.position].filter(Boolean)}
              subtitlesClassname={styles.contactSubTitles}
              rightActions={
                <div className={styles.contactActionsContainer}>
                  <div className={styles.buttonContainer}>
                    <div
                      onClick={() => {
                        navigate(MODAL_ROUTES.CONTACT_MISSIONS.route.buildPath({}), {
                          state: {
                            modalParameters: {
                              type: EnumCreateActionBodyActionType.EMAIL,
                              target: EnumCreateActionBodyActionTarget.CONTACT,
                              contactName: formatName(mission?.contact),
                              contactId: mission?.contact?.contactId,
                              contactInfo: [
                                { info: mission?.contact?.email ?? '', infoType: 'email' },
                              ],
                            },
                            background: location,
                          },
                        });
                      }}
                      className={styles.addMissionButton}
                    >
                      <img src={emailLine} alt="email" />
                    </div>
                    <p>e-mail</p>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      onClick={() => {
                        navigate(MODAL_ROUTES.CONTACT_MISSIONS.route.buildPath({}), {
                          state: {
                            modalParameters: {
                              type: EnumCreateActionBodyActionType.CALL,
                              target: EnumCreateActionBodyActionTarget.CONTACT,
                              contactName: formatName(mission?.contact),
                              contactId: mission?.contact?.contactId,
                              contactInfo: [
                                { info: mission?.contact?.phone ?? '', infoType: 'bureau' },
                                {
                                  info: mission?.contact?.mobilePhoneNumber ?? '',
                                  infoType: 'mobile',
                                },
                              ].filter(elem => elem.info),
                            },
                            background: location,
                          },
                        });
                      }}
                      className={styles.addMissionButton}
                    >
                      <img src={mobileLine} alt="mobile" />
                    </div>
                    <p>appel</p>
                  </div>
                </div>
              }
            />,
          ]}
        </CardCarousel>

        <CardCarousel title="dernières actions">
          {companyActions.data && companyActions.data.length > 0
            ? companyActions.data.map(action => {
                return (
                  <div className={styles.lastActionsCard} key={`action-card-${action.actionId}`}>
                    <div className={styles.lastActionsCardLeft}>
                      <div className={styles.lastActionsCardTitle}>
                        {formatName(action.contacts?.at(0)) ?? "pas d'interlocuteur"}
                      </div>
                      <div className={styles.lastActionsCardSubtitles}>{action.target?.label}</div>
                      <div className={styles.lastActionsCardSubtitles}>{`${
                        action.actors && action.actors[0] ? action.actors[0].name : ''
                      }`}</div>
                    </div>
                    <div className={styles.lastActionsCardRight}>
                      {action.status?.label === 'Réalisée' && <TickCircleGreen />}
                    </div>
                  </div>
                );
              })
            : [
                <div className={styles.lastActionsCardEmpty} key="filler-card">
                  aucune action renseignée
                </div>,
              ]}
        </CardCarousel>
      </div>
      <div className={styles.details}>détails</div>
      <div>
        <div className={styles.habilitationsHeader}>
          <div className={styles.habilitationsHeaderLabel}>habilitations</div>
          <Badge
            size="xsmall"
            color="navy"
            saturation="medium"
            className={styles.habilitationsBadge}
          >
            {mission.habilitations ? mission.habilitations.length : 0}
          </Badge>
        </div>
        {mission.habilitations && mission.habilitations.length !== 0 ? (
          <div>
            <StyledTable size="medium" color="beige">
              <TableBody>
                {mission.habilitations.map(habilitation => {
                  const badge = habilitation.priorityId
                    ? habilitationsPriorityMapping[
                        habilitation.priorityId as keyof typeof habilitationsPriorityMapping
                      ]
                    : null;
                  return (
                    <CardRow key={habilitation.id}>
                      <CardCell>
                        <Habilitation />
                        <div className={styles.habilitation}>{habilitation.label}</div>
                      </CardCell>
                      <CardCell className={styles.rightSide}>
                        {badge && <Badge color={badge.color}>{badge.label}</Badge>}
                      </CardCell>
                    </CardRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </div>
        ) : (
          <div className={styles.text}>aucune habilitation n'a été renseignée</div>
        )}
      </div>
      <WithLightTitle
        title="tâches à réaliser"
        titleClassName={styles.withLightTitle}
        children={
          <div className={styles.text}>
            {mission.positionFeatures ?? "aucune tâche n'a été renseignée"}
          </div>
        }
        className={styles.tasks}
      />
      <div className={styles.row}>
        <WithLightTitle
          title="commentaires privés"
          titleClassName={styles.withLightTitle}
          children={
            <div className={styles.text}>
              {mission.privateComment ?? "aucun commentaire n'a été  renseigné"}
            </div>
          }
          className={styles.commentaries}
        />
        <WithLightTitle
          title="commentaires publics"
          titleClassName={styles.withLightTitle}
          children={
            <div className={styles.text}>
              {mission.comment ?? "aucun commentaire n'a été  renseigné"}
            </div>
          }
          className={styles.commentaries}
        />
      </div>
    </div>
  );
};

export default MissionInformations;
