import moment from 'moment/moment';
import { DBCDIIStatus, EnumActivityPlanningDayStatus } from 'src/Services/API';
import { CDII_STATUS } from './CdiiList/CdiiTable/CdiiTable.types';

/** @deprecated status computation should be done in backend */
export const getEndMission = (
  missionEnd: { year?: number; month?: number; day?: number } | undefined,
  statut?: EnumActivityPlanningDayStatus
) => {
  const today = moment();
  const missionEndDate = moment(
    `${missionEnd?.year}/${missionEnd?.month}/${missionEnd?.day}`,
    'YYYY/MM/DD'
  ).add(1, 'day');
  if (
    statut === EnumActivityPlanningDayStatus.ValidatedAbsence ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsence ||
    statut === EnumActivityPlanningDayStatus.ValidatedAbsenceCompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsenceCompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.ValidatedAbsenceUncompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsenceUncompletedTimeTrackings
  )
    return CDII_STATUS.ABSENT;
  else if (today > missionEndDate || missionEnd?.year === undefined)
    return CDII_STATUS.INTERMISSION;
  else if (Math.round((missionEndDate.diff(today, 'days') / 7) * 5) <= 2)
    return CDII_STATUS.SOON_AVAILABLE;
  else return CDII_STATUS.MISSION;
};

/** @deprecated status computation should be done in backend */
export const getRepositionedStatus = (priorityStatus: DBCDIIStatus | undefined) => {
  switch (priorityStatus) {
    case DBCDIIStatus.available:
      return CDII_STATUS.AVAILABLE;
    case DBCDIIStatus.suggested:
      return CDII_STATUS.SUGGESTED;
    case DBCDIIStatus.processing:
      return CDII_STATUS.PROCESSING;
    default:
      return undefined;
  }
};
