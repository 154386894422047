import { TemporaryWorkerStatusBadge } from '../TemporaryWorkerStatusBadge';
import styles from './TalentDetailsHeader.module.scss';
import { Props } from './TalentDetailsHeader.types';
import { formatName } from 'src/Utils/formatName';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { Tooltip } from '@randstad-lean-mobile-factory/react-components-core';

export const TalentDetailsHeader = ({ talent }: Props) => {
  const agencyId = useSelector(getCurrentAgency);

  const agencies = useMemo(() => {
    const allAgencies = orderBy(talent.subscribedAgencies, [
      agency => (agency === talent.mainAgencyCode ? 0 : agency === agencyId ? 1 : 2),
      agency => agency,
    ]);
    if (!allAgencies.length) return "pas d'agence";
    if (allAgencies.length <= 3) return allAgencies.join(', ');
    return (
      <>
        {allAgencies.slice(0, 3).join(', ')} et{' '}
        <Tooltip title={allAgencies.join(', ')}>
          <>{pluralFormat(allAgencies.length - 3, 'autre')}</>
        </Tooltip>
      </>
    );
  }, [agencyId, talent.mainAgencyCode, talent.subscribedAgencies]);

  return (
    <div className={styles.container}>
      <div className={styles.talentName}>{formatName(talent)}</div>
      <div className={styles.agencyAndBadge}>
        <div className={styles.unity}>
          {agencies}
          {' | '}
          {talent?.candidateType === 'Intérimaire Complet' ? 'intérimaire' : 'non précisé'}
          {' | '}
        </div>
        <div className={styles.badge}>
          <TemporaryWorkerStatusBadge status={talent.temporaryWorkerStatus} />
        </div>
      </div>
    </div>
  );
};

export default TalentDetailsHeader;
