import { AjoutAT } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  ComboBox,
  ModalActions,
  ModalContent,
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useCreateWorkAccidentAnalysis';
import { useFetchAccidentsUnderMinStoppageDuration } from 'src/Hooks/WorkAccidentAnalysis/useFetchAccidentsUnderMinStoppageDuration';
import { useFetchWorkAccidentsByAgencies } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentsByAgencies';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AgencyWorkAccidents,
  DateFilterStrategy,
  OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto,
  WorkStoppage,
} from 'src/Services/API';
import { toDate } from 'src/Utils/datetime';
import { formatName } from 'src/Utils/formatName';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './CreateWorkAccidentAnalysisModalContent.module.scss';
import { CreateWorkAccidentAnalysisModalContentProps } from './CreateWorkAccidentAnalysisModalContent.types';

export const CreateWorkAccidentAnalysisModalContent = ({
  onClose,
}: CreateWorkAccidentAnalysisModalContentProps) => {
  const navigate = useNavigate();

  const { data: allWorkStoppages, isSuccess } = useFetchAccidentsUnderMinStoppageDuration();
  const createWorkAccidentAnalysis = useCreateWorkAccidentAnalysis({
    onSuccess: analysisId => {
      setTimeout(() => {
        onClose();
        navigate(
          ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({ analysisId })
        );
      }, 500);
    },
    onError: () => {
      setTimeout(() => createWorkAccidentAnalysis.reset(), 500);
    },
  });

  const [keyword, setKeyword] = useState('');
  const [allCandidates, setAllCandidates] = useState<
    OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto[]
  >([]);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(null);

  const [selectedCandidateWorkStoppages, setSelectedCandidateWorkStoppages] = useState<
    WorkStoppage[]
  >([]);
  const [selectedCandidateWithoutWorkStoppages, setSelectedCandidateWithoutWorkStoppages] =
    useState<AgencyWorkAccidents[]>([]);
  const [selectedWorkStoppage, setSelectedWorkStoppage] = useState<string | undefined>(undefined);
  const [selectedAccident, setSelectedAccident] = useState<string | undefined>(undefined);

  const fetchAllAccidents = useFetchWorkAccidentsByAgencies({
    startDate: moment().subtract(2, 'years').toDate(),
    dateFilterStrategy: DateFilterStrategy.STARTAFTERSTARTDATE,
  });

  const reset = () => {
    setKeyword('');
    setSelectedCandidate(null);
    setSelectedWorkStoppage(undefined);
    setSelectedAccident(undefined);
  };

  useEffect(() => {
    const newAllCandidates: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto[] = [];
    allWorkStoppages?.forEach(workStoppage => {
      if (
        workStoppage.candidate &&
        !newAllCandidates.find(candidate => candidate.id === workStoppage.candidate?.id)
      ) {
        newAllCandidates.push(workStoppage.candidate);
      }
    });
    const accidentIdsWithStoppage = allWorkStoppages?.map(stoppage => stoppage.workAccidentId);
    if (accidentIdsWithStoppage && fetchAllAccidents.data) {
      const accidentsWithoutStoppage = fetchAllAccidents.data.filter(acc => {
        return !accidentIdsWithStoppage.includes(acc.accidentId);
      });
      const candidatesWithoutStoppage = accidentsWithoutStoppage.map(acc => {
        return {
          id: acc.candidate?.id ?? '',
          lastName: acc.candidate?.name ?? '',
          name: acc.candidate?.firstName ?? '',
        };
      });
      newAllCandidates.push(...candidatesWithoutStoppage);
    }
    setAllCandidates(newAllCandidates);
  }, [allWorkStoppages, fetchAllAccidents.data]);

  useEffect(() => {
    setSelectedCandidateWorkStoppages(
      allWorkStoppages?.filter(workStoppage => workStoppage.candidate?.id === selectedCandidate) ??
        []
    );
  }, [allWorkStoppages, selectedCandidate]);

  useEffect(() => {
    setSelectedCandidateWithoutWorkStoppages(
      fetchAllAccidents.data?.filter(acc => acc.candidate?.id === selectedCandidate) ?? []
    );
  }, [fetchAllAccidents.data, selectedCandidate]);

  return (
    <>
      <ModalContent header={<AjoutAT />} title="ajouter une analyse AT">
        <WithLightTitle
          title="choisir le nom de l'intérimaire concerné"
          className={styles.candidateSelection}
        >
          <ComboBox
            id="SelectCandidateName"
            value={selectedCandidate}
            onSearch={keyword => setKeyword(keyword)}
            searchResults={
              keyword.length > 0
                ? (allCandidates
                    .filter(candidate =>
                      `${candidate?.name} ${candidate?.lastName}`
                        .toLowerCase()
                        .includes(keyword.toLowerCase())
                    )
                    .sort((a, b) =>
                      `${a.name} ${a.lastName}` < `${b.name} ${b.lastName}` ? -1 : 1
                    )
                    .sort((a, b) =>
                      `${a.name} ${a.lastName}`.toLowerCase().indexOf(keyword.toLowerCase()) <
                      `${b.name} ${b.lastName}`.toLowerCase().indexOf(keyword.toLowerCase())
                        ? -1
                        : 1
                    )
                    .map(candidate => candidate.id) ?? [])
                : []
            }
            keyValueExtractor={candidateId => ({
              key: candidateId ?? 'unknown',
              value: (allCandidates
                .filter(candidate => candidate.id === candidateId)
                ?.map(candidate => `${candidate?.name} ${candidate?.lastName}`) ?? ['unknown'])[0],
            })}
            onChange={searchResult => setSelectedCandidate(searchResult ?? null)}
            minLengthToSearch={1}
            disablePortal={false}
          />
        </WithLightTitle>
        {isSuccess &&
          (selectedCandidateWorkStoppages.length > 0 ||
            selectedCandidateWithoutWorkStoppages.length > 0) && (
            <>
              <div className={styles.workStoppagesCount}>
                {pluralFormat(
                  selectedCandidateWorkStoppages.length +
                    selectedCandidateWithoutWorkStoppages.length,
                  'absence identifiée'
                )}{' '}
                pour cet intérimaire
              </div>
              {selectedCandidateWorkStoppages.length > 0 &&
                selectedCandidateWorkStoppages.map(workStoppage => (
                  <Fragment key={workStoppage.id}>
                    <div className={styles.separator} />
                    <div className={styles.workStoppageRadioButtonContainer}>
                      <RadioButtonGroup
                        name="workStoppages"
                        radioButtonsInfo={[
                          {
                            id: workStoppage.id ?? ' ',
                            value: workStoppage.id ?? ' ',
                            label: (
                              <div className={styles.workStoppageTexts}>
                                <div className={styles.workStoppageTitle}>
                                  {`${workStoppage.candidate?.name} ${workStoppage.candidate?.lastName}`}
                                </div>
                                <div className={styles.workStoppageSubtitle}>
                                  {workStoppage.startDate
                                    ? moment(workStoppage.startDate).format('DD/MM/Y')
                                    : '--/--/--'}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        onChange={() => setSelectedWorkStoppage(workStoppage.id)}
                        selected={selectedWorkStoppage}
                      />
                    </div>
                  </Fragment>
                ))}
              {selectedCandidateWithoutWorkStoppages.length > 0 &&
                selectedCandidateWithoutWorkStoppages.map(acc => (
                  <Fragment key={acc.accidentId}>
                    <div className={styles.separator} />
                    <div className={styles.workStoppageRadioButtonContainer}>
                      <RadioButtonGroup
                        name="workStoppages"
                        radioButtonsInfo={[
                          {
                            id: acc.accidentId ?? ' ',
                            value: acc.accidentId ?? ' ',
                            label: (
                              <div className={styles.workStoppageTexts}>
                                <div className={styles.workStoppageTitle}>
                                  {formatName(acc.candidate)}
                                </div>
                                <div className={styles.workStoppageSubtitle}>
                                  {`${
                                    acc.date
                                      ? moment(toDate(acc.date)).format('DD/MM/Y')
                                      : '--/--/--'
                                  }`}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        onChange={() => setSelectedAccident(acc.accidentId)}
                        selected={selectedAccident}
                      />
                    </div>
                  </Fragment>
                ))}
              <div className={styles.separator} />
            </>
          )}
      </ModalContent>
      <ModalActions side="left">
        <Button variant="tertiary" onClick={reset}>
          tout réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          disabled={!selectedWorkStoppage && !selectedAccident}
          onClick={() => {
            const fullSelectedWorkStoppage = allWorkStoppages?.find(
              workStoppage => workStoppage.id === selectedWorkStoppage
            );
            if (fullSelectedWorkStoppage && fullSelectedWorkStoppage?.workAccidentId) {
              createWorkAccidentAnalysis.mutate({
                accidentId: fullSelectedWorkStoppage.workAccidentId,
                candidateId: fullSelectedWorkStoppage.candidate?.id ?? '',
                firstName: fullSelectedWorkStoppage.candidate?.name ?? '',
                lastName: fullSelectedWorkStoppage.candidate?.lastName ?? '',
                analysisId: fullSelectedWorkStoppage.id ?? '',
                startDate: fullSelectedWorkStoppage.startDate,
                endDate: fullSelectedWorkStoppage.endDate,
                stoppageDuration: fullSelectedWorkStoppage.stoppageDuration ?? 0,
              });
            }
            const fullSelectedAccident = fetchAllAccidents.data?.find(
              accident => accident.accidentId === selectedAccident
            );
            if (fullSelectedAccident) {
              const { year, month, day } = fullSelectedAccident.date ?? {};
              createWorkAccidentAnalysis.mutate({
                accidentId: fullSelectedAccident.accidentId ?? '',
                candidateId: fullSelectedAccident.candidate?.id ?? '',
                firstName: fullSelectedAccident.candidate?.firstName ?? '',
                lastName: fullSelectedAccident.candidate?.name ?? '',
                analysisId: fullSelectedAccident.accidentId ?? '',
                startDate: year && month && day ? new Date(year, month - 1, day) : undefined,
                endDate: undefined,
                stoppageDuration: 0,
              });
            }
          }}
          mutationStatus={createWorkAccidentAnalysis.status}
        >
          valider
        </Button>
      </ModalActions>
    </>
  );
};
