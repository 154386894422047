import { memo } from 'react';
import PopOverActions from './PopOverActions.component';
import { useSelector } from 'react-redux';
import { getCurrentContract } from 'src/Redux/Contracts/Selectors';

const MemoPopOverActions = memo(PopOverActions);

const EnhancedPopOverActions = () => {
  const currentContract = useSelector(getCurrentContract);
  return (
    <MemoPopOverActions
      isRenewable={currentContract?.isRenewable ?? false}
      contractId={currentContract?.contractId}
    />
  );
};

export default EnhancedPopOverActions;
