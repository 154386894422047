import { CandidateSearchActivityPlaning } from 'src/Services/API';
import { CdiiSource } from '../CdiiList/CdiiList.types';

export interface Props {
  candidateId: string;
  /** @deprecated we should get everything we need from the candidateId directly */
  candidateData: CandidateSearchActivityPlaning;
  statut: string;
  source?: CdiiSource;
}

export enum REPOSITIONING_CDII_TAB {
  COMMANDES = 'mes commandes',
  QUALIFICATION_RANDSTAD = 'ppa Randstad',
  QUALIFICATION_INTERNE = 'ppa interne',
  SKILL_MATCHING = 'matching de compétence',
}
