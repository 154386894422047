import { ReactComponent as ContractSvg } from './contract.svg';
import { ReactComponent as MissionSvg } from './mission.svg';
import { ReactComponent as YouplanSvg } from './youplan.svg';
import { ReactComponent as CDIWorkersSvg } from './CDIWorkers.svg';
import { ReactComponent as TalentsSvg } from './talents.svg';

/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Contract = ContractSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Mission = MissionSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Youplan = YouplanSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CDIWorkers = CDIWorkersSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Talents = TalentsSvg;
