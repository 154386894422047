import { FetchCdiiSort, FetchCdiiSortCriteria } from 'src/Hooks/Candidate/useFetchAgencyCdiis';
import { SortTypeEnum } from 'src/Services/API';
import { z } from 'zod';

export interface RegionalCdiiListProps {
  globalFilter: string;
  sort: FetchCdiiSort;
}

export const cdiiRegionalSortSchema: z.Schema<FetchCdiiSort> = z.object({
  sortCriteria: z.nativeEnum(FetchCdiiSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultRegionalSort: z.infer<typeof cdiiRegionalSortSchema> = {
  sortCriteria: FetchCdiiSortCriteria.AVAILABILITY,
  sortType: SortTypeEnum.ASC,
};
