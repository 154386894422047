import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  ModalDeprecated,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { HeaderDeleteAlert } from 'src/Assets_DEPRECATED';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumWorkAccidentAnalysisStatus, WhyAnalysis } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './Analysis.module.scss';
import { useFetchWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalysis';

const ranking = [
  '1ʳᵉ',
  '2ᵉ',
  '3ᵉ',
  '4ᵉ',
  '5ᵉ',
  '6ᵉ',
  '7ᵉ',
  '8ᵉ',
  '9ᵉ',
  '10ᵉ',
  '11ᵉ',
  '12ᵉ',
  '13ᵉ',
  '14ᵉ',
  '15ᵉ',
];

const initialWhyAnalysis: WhyAnalysis = {
  name: '',
  description: '',
  fiveWhys: [],
  actions: [],
};

export const AnalysisRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.WHY_ANALYSIS
  );
  const currentAnalysis = useFetchWorkAccidentAnalysis(analysisId);
  const isEditable = currentAnalysis.data?.status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const currentAnalysisFetchStatus = toFetchStatus(currentAnalysis);
  const updateWorkAccidentAnalysisMutation = useUpdateWorkAccidentAnalysis(analysisId);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [analysis, setAnalysis] = useState<WhyAnalysis>(initialWhyAnalysis);

  useEffect(
    () => {
      if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis?.data?.whyAnalysis !== undefined &&
        currentAnalysis.data.whyAnalysis !== analysis
      ) {
        setAnalysis(currentAnalysis.data.whyAnalysis);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnalysisFetchStatus]
  );
  const updateWorkAnalysis = useCallback(() => {
    isEditable && updateWorkAccidentAnalysisMutation.mutate({ whyAnalysis: analysis });
  }, [isEditable, analysis, updateWorkAccidentAnalysisMutation]);

  useEffect(() => {
    window.addEventListener('beforeunload', updateWorkAnalysis);
    return () => {
      window.removeEventListener('beforeunload', updateWorkAnalysis);
    };
  }, [updateWorkAnalysis]);

  function onModifyFiveWhyElement({
    index,
    value,
    isRootCause,
    rootCauseName,
  }: {
    index: number;
    value?: string;
    isRootCause?: boolean;
    rootCauseName?: string;
  }) {
    setAnalysis(prevState => {
      const nextStateFiveWhy = [...(prevState.fiveWhys ?? [])];
      nextStateFiveWhy[index].value = value ?? '';
      nextStateFiveWhy[index].isRootCause = isRootCause ?? false;
      nextStateFiveWhy[index].rootCauseName = rootCauseName ?? '';

      return {
        ...prevState,
        fiveWhys: nextStateFiveWhy,
      };
    });
    updateWorkAnalysis();
  }

  function onDeleteFiveWhyElement({ index }: { index: number }) {
    setAnalysis(prevState => {
      return {
        ...prevState,
        fiveWhys: [...(prevState.fiveWhys ?? [])].splice(index - 1, 1),
      };
    });
    updateWorkAccidentAnalysisMutation.mutate({
      whyAnalysis: { ...analysis, fiveWhys: [...(analysis.fiveWhys ?? [])].splice(index - 1, 1) },
    });
    setIsOpen(false);
  }

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>3.</div>
      <div className={styles.content}>
        <div className={styles.title}>analyse des 5 pourquoi</div>
        <div onBlur={updateWorkAnalysis}>
          <div
            className={classnames({
              [styles.titleButton]: (analysis.fiveWhys ?? []).length > 1,
            })}
          >
            <div className={styles.subTitle}>comprendre la/les cause(s) racine(s)</div>
          </div>
          <WithLightTitle
            title="nom de la lésion"
            className={styles.lightTitle}
            children={
              isEditable ? (
                <TextInput
                  value={analysis.name ?? initialWhyAnalysis.name}
                  containerClassName={styles.textInput}
                  placeholder={`ex : luxation de l'épaule`}
                  onChange={(event: any) =>
                    setAnalysis(prevState => {
                      return { ...prevState, name: (event.target as HTMLTextAreaElement).value };
                    })
                  }
                />
              ) : (
                <div>{analysis.name ?? 'non renseigné'}</div>
              )
            }
          />
          <WithLightTitle
            title={'Décrivez en quelques mots la lésion'}
            className={styles.lightTitle}
            rightTitleComponent={
              isEditable ? (
                <div className={styles.numberOfChar}>
                  {(analysis.description ?? initialWhyAnalysis.description ?? '').length}/
                  {REPORT_MAX_LENGTH} caractères
                </div>
              ) : (
                <></>
              )
            }
            children={
              isEditable ? (
                <TextArea
                  value={analysis.description ?? initialWhyAnalysis.description ?? ''}
                  className={styles.textArea}
                  placeholder={'en quelques mots…'}
                  onChange={(event: any) =>
                    setAnalysis(prevState => {
                      return {
                        ...prevState,
                        description: (event.target as HTMLTextAreaElement).value,
                      };
                    })
                  }
                />
              ) : (
                <div>{analysis.description ?? 'non renseigné'}</div>
              )
            }
          />
          {analysis.fiveWhys?.map((fiveWhyElement, index) => (
            <div className={styles.itemBox}>
              <WithLightTitle
                title={`${ranking[index]} Pourquoi ? ex : cette fracture`}
                className={styles.optionalLightTitle}
                key={fiveWhyElement.whyIndex}
                rightTitleComponent={
                  isEditable ? (
                    <div
                      className={classnames(
                        styles.numberOfChar,
                        {
                          [styles.firstWhyNumberOfChar]: index === 0,
                        },
                        { [styles.nextWhysNumberOfChar]: index !== 0 }
                      )}
                      key={`${index} first count`}
                    >
                      {fiveWhyElement.value?.length}/{REPORT_MAX_LENGTH} caractères
                    </div>
                  ) : (
                    <></>
                  )
                }
                children={
                  isEditable ? (
                    <>
                      <div className={styles.itemBox}>
                        <TextArea
                          value={fiveWhyElement.value}
                          className={classnames(styles.optionalTextArea, {
                            [styles.firstOptionalTextArea]: index === 0,
                          })}
                          placeholder={`en quelques mots… ex : Parce que M.Dupont a chuté dans les escaliers`}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                            setAnalysis(prevState => {
                              const updatedFiveWhys = [...(prevState.fiveWhys ?? [])];
                              updatedFiveWhys[index] = {
                                ...updatedFiveWhys[index],
                                value: event.target.value,
                              };
                              return { ...prevState, fiveWhys: updatedFiveWhys };
                            });
                          }}
                        />
                        {isEditable && index !== 0 && (
                          <Button.Secondary
                            className={styles.deleteAnalysis}
                            disabled={(analysis.fiveWhys ?? []).length === 1}
                            onClick={() => {
                              fiveWhyElement.isRootCause
                                ? setIsOpen(true)
                                : onDeleteFiveWhyElement({ index: index });
                            }}
                          >
                            <Trashcan className={styles.deleteAnalysisIcon} />
                            {fiveWhyElement.isRootCause && (
                              <ModalDeprecated
                                title={"message d'alerte"}
                                children={
                                  <WithLightTitle
                                    title={`le ${moment().format('DD/MM/YYYY à HH:mm')}`}
                                    children={
                                      <div>
                                        Vous souhaitez supprimer cette cause racine. En la
                                        supprimant, les actions client et agence associées
                                        disparaitront. Souhaitez-vous continuer ?
                                      </div>
                                    }
                                  />
                                }
                                icon={<HeaderDeleteAlert />}
                                iconClassName={styles.modalHeader}
                                open={isOpen}
                                footerActionsRight={[
                                  <Button.Secondary
                                    onClick={() => {
                                      setIsOpen(false);
                                    }}
                                  >
                                    annuler
                                  </Button.Secondary>,
                                  <Button.Primary
                                    onClick={() => {
                                      onDeleteFiveWhyElement({ index: index });
                                    }}
                                  >
                                    oui je supprime
                                  </Button.Primary>,
                                ]}
                              />
                            )}
                          </Button.Secondary>
                        )}
                      </div>

                      <div className={styles.itemRow}>
                        <Checkbox
                          checked={fiveWhyElement.isRootCause}
                          disabled={!isEditable}
                          onChange={() => {
                            onModifyFiveWhyElement({
                              index,
                              value: fiveWhyElement.value,
                              isRootCause: !fiveWhyElement.isRootCause,
                              rootCauseName: fiveWhyElement.rootCauseName,
                            });
                          }}
                        />
                        <div>définir comme cause racine</div>
                      </div>
                      {fiveWhyElement.isRootCause && (
                        <WithLightTitle
                          title="nom donné à la cause racine"
                          className={styles.optionalLightTitle}
                          children={
                            isEditable ? (
                              <TextInput
                                value={fiveWhyElement.rootCauseName}
                                containerClassName={styles.textInput}
                                placeholder={'ex : chute d’un pont roulant'}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  setAnalysis(prevState => {
                                    const updatedFiveWhys = [...(prevState.fiveWhys ?? [])];
                                    updatedFiveWhys[index] = {
                                      ...updatedFiveWhys[index],
                                      rootCauseName: event.target.value,
                                    };
                                    return { ...prevState, fiveWhys: updatedFiveWhys };
                                  });
                                }}
                              />
                            ) : (
                              <div>{fiveWhyElement.rootCauseName ?? 'non renseigné'}</div>
                            )
                          }
                        />
                      )}
                    </>
                  ) : (
                    <div>{fiveWhyElement.value ?? 'non renseigné'}</div>
                  )
                }
              />
            </div>
          ))}
        </div>

        {isEditable && (
          <Button.Tertiary
            className={styles.newAnalysis}
            disabled={(analysis.fiveWhys ?? []).length > 14}
            onClick={() => {
              setAnalysis(prevState => {
                return {
                  ...prevState,
                  fiveWhys: [
                    ...(prevState.fiveWhys ?? []),
                    { value: '', isRootCause: false, rootCauseName: '' },
                  ],
                };
              });
              updateWorkAnalysis();
            }}
          >
            <PlusCircle className={styles.plusCircle} /> ajouter un autre pourquoi
          </Button.Tertiary>
        )}
      </div>
    </div>
  );
};
