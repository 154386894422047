import { default as randstadSvg } from './randstad.svg';
import { default as gearSvg } from './gear.svg';
import { default as personSvg } from './person.svg';
import { default as rightArrowSvg } from './rightArrow.svg';
import { default as leftArrowSvg } from './leftArrow.svg';
import { default as downArrowSvg } from './downArrow.svg';
import { default as redCloudSvg } from './redCloud.png';
import { default as logoSvg } from './logo.svg';
import { default as phoneSvg } from './phone.svg';
import { default as emailSvg } from './email.svg';
import { default as smsSvg } from './sms.svg';
import { default as phoneRoundSvg } from './phoneRound.svg';
import { default as emailRoundSvg } from './emailRound.svg';
import { default as emailLineSvg } from './emailLine.svg';
import { default as mobileLineSvg } from './mobileLine.svg';
import { default as smsRoundSvg } from './smsRound.svg';
import { default as watermelonSvg } from './pasteque.svg';
import { default as magnifyingGlassSvg } from './loupe.svg';
import { default as plainCrossSvg } from './plainCross.svg';
import { default as checkSvg } from './check.svg';
import { default as binocularSvg } from './binocular.svg';
import { default as eyeSvg } from './eye.svg';
import { default as threeDotsSvg } from './threeDots.svg';
import { default as extendSvg } from './extend.svg';
import { default as whiteCheckSvg } from './whiteCheck.svg';
import { default as whiteCrossSvg } from './whiteCross.svg';
import { default as calendarSvg } from './calendar.svg';
import { default as clockSvg } from './clock.svg';
import { default as addSvg } from './add.svg';
import { default as bubblesSvg } from './bubble.svg';
import { default as blueCrossSvg } from './blueCross.svg';
import { default as illusDetailSvg } from './illusDetail.svg';
import { default as whiteAddSvg } from './whiteAdd.svg';
import { default as handSvg } from './hand.svg';
import { default as plainBinocularSvg } from './plainBinocular.svg';
import { default as toolsSvg } from './tools.svg';
import { default as illusAdminSvg } from './illusAdmin.svg';
import { default as illusSuccessSvg } from './illusSuccess.svg';
import { default as illusAlertSvg } from './illusAlert.svg';
import { default as teaCupSvg } from './teaCup.svg';
import { default as addPeopleSvg } from './addPeople.svg';
import { default as hurtPersonSvg } from './hurtPerson.svg';
import { default as illusEpiSvg } from './illusEpi.svg';
import { default as illusReposiSvg } from './illusReposi.svg';

import { ReactComponent as InfoSvg } from './info.svg';
import { ReactComponent as IllusEdpFormationHabilitationSvg } from './illusEdpFormationHabilitation.svg';
import { ReactComponent as PenSvg } from './pen.svg';
import { ReactComponent as LinedPenSvg } from './linedPen.svg';
import { ReactComponent as DocumentLineSvg } from './documentLine.svg';
import { ReactComponent as AgencyOrganizationActionsSvg } from './agencyOrganizationActions.svg';
import { ReactComponent as AgencyTechnicalActionsSvg } from './agencyTechnicalActions.svg';
import { ReactComponent as AgencyManagementActionsSvg } from './agencyManagementActions.svg';
import { ReactComponent as ClientOrganizationActionsSvg } from './clientOrganizationActions.svg';
import { ReactComponent as ClientTechnicalActionsSvg } from './clientTechnicalActions.svg';
import { ReactComponent as ClientManagementActionsSvg } from './clientManagementActions.svg';
import { ReactComponent as AgencyOrganizationActionsModalHeaderSvg } from './agencyOrganizationActionsModalHeader.svg';
import { ReactComponent as AgencyTechnicalActionsModalHeaderSvg } from './agencyTechnicalActionsModalHeader.svg';
import { ReactComponent as AgencyManagementActionsModalHeaderSvg } from './agencyManagementActionsModalHeader.svg';
import { ReactComponent as ClientOrganizationActionsModalHeaderSvg } from './clientOrganizationActionsModalHeader.svg';
import { ReactComponent as ClientTechnicalActionsModalHeaderSvg } from './clientTechnicalActionsModalHeader.svg';
import { ReactComponent as ClientManagementActionsModalHeaderSvg } from './clientManagementActionsModalHeader.svg';
import { ReactComponent as HeaderDeleteAlertSvg } from './headerDeleteAlerte.svg';
import { ReactComponent as EyeIllustrationSvg } from './eyeIllustration.svg';
import { ReactComponent as ActionsPlanDotSvg } from './actionsPlanDot.svg';
import { ReactComponent as RefuseSuggestedIconSvg } from './refuseSuggestedIcon.svg';
import { ReactComponent as IllusWarningSvg } from './IllusWarning.svg';
import { ReactComponent as ExportIconSvg } from './export.svg';
import { ReactComponent as PointingFingerSvg } from './pointingFinger.svg';

/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const randstad = randstadSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const gear = gearSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const person = personSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const rightArrow = rightArrowSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const leftArrow = leftArrowSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const downArrow = downArrowSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const redCloud = redCloudSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const logo = logoSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const phone = phoneSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const email = emailSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const sms = smsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const phoneRound = phoneRoundSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const emailRound = emailRoundSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const emailLine = emailLineSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const mobileLine = mobileLineSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const smsRound = smsRoundSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const watermelon = watermelonSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const magnifyingGlass = magnifyingGlassSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const plainCross = plainCrossSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const check = checkSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const binocular = binocularSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const eye = eyeSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const threeDots = threeDotsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const extend = extendSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const whiteCheck = whiteCheckSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const whiteCross = whiteCrossSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const calendar = calendarSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const clock = clockSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const add = addSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const bubbles = bubblesSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const blueCross = blueCrossSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusDetail = illusDetailSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const whiteAdd = whiteAddSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const hand = handSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const plainBinocular = plainBinocularSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const tools = toolsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusAdmin = illusAdminSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusSuccess = illusSuccessSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusAlert = illusAlertSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const teaCup = teaCupSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const addPeople = addPeopleSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const hurtPerson = hurtPersonSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusEpi = illusEpiSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const illusReposi = illusReposiSvg;

/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Info = InfoSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const IllusEdpFormationHabilitation = IllusEdpFormationHabilitationSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Pen = PenSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LinedPen = LinedPenSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const DocumentLine = DocumentLineSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyOrganizationActions = AgencyOrganizationActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyTechnicalActions = AgencyTechnicalActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyManagementActions = AgencyManagementActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientOrganizationActions = ClientOrganizationActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientTechnicalActions = ClientTechnicalActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientManagementActions = ClientManagementActionsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyOrganizationActionsModalHeader = AgencyOrganizationActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyTechnicalActionsModalHeader = AgencyTechnicalActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const AgencyManagementActionsModalHeader = AgencyManagementActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientOrganizationActionsModalHeader = ClientOrganizationActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientTechnicalActionsModalHeader = ClientTechnicalActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ClientManagementActionsModalHeader = ClientManagementActionsModalHeaderSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const HeaderDeleteAlert = HeaderDeleteAlertSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const EyeIllustration = EyeIllustrationSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ActionsPlanDot = ActionsPlanDotSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const RefuseSuggestedIcon = RefuseSuggestedIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const IllusWarning = IllusWarningSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ExportIcon = ExportIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const PointingFinger = PointingFingerSvg;

export * from './MenuIcons';
