import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { throttle } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useFetchCompanies } from 'src/Hooks/Companies/useFetchCompanies';
import { toFetchStatus } from 'src/Services/Async';

import { FETCH_STATUS } from 'src/Redux/Types';
import { Props } from './SelectCompany.types';
import styles from './SelectCompany.module.scss';

const SelectCompany = ({ selectedCompany, setSelectedCompany, canBeReset }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchCompanies(keyword);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  return (
    <ComboBox
      id="SelectCompany"
      placeholder="1 caractère minimum"
      className={styles.container}
      disableClearable={!canBeReset}
      value={selectedCompany ?? null}
      onSearch={keyword => throttledSetKeyword(keyword)}
      searchResults={data ?? []}
      withSubLabel
      keyValueExtractor={({ companyId, companyName, siret, companyAddress, societyStatus }) => ({
        key: companyId ?? 'unknown',
        value: `${companyName} (${siret})`,
        subValue: `[${societyStatus}] | ${companyAddress?.postalCode} - ${companyAddress?.city}`,
      })}
      onChange={searchResult => setSelectedCompany(searchResult ?? undefined)}
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={1}
    />
  );
};

export default SelectCompany;
