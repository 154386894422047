import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { CandidatesService } from 'src/Services/API';
import { useIsCGC } from '../SkillsManagementCenters/useIsCGC';

export const useFetchSuggestedCandidates = (params: {
  qualificationId?: string;
  zipCode?: string;
}) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);
  const { data: isCGC } = useIsCGC();

  return useQuery(
    [QueryKeys.fetchSuggestedCandidates, params],
    async () => {
      return await CandidatesService.candidatesControllerFetchSuggestedCandidates({
        agencyId,
        brandCode,
        ...params,
      });
    },
    {
      enabled: isCGC === false,
      refetchOnWindowFocus: false,
    }
  );
};
