import { ModalProps } from '@randstad-lean-mobile-factory/react-components-core/dist/Modals/Modal/Modal.types';
import { AgencyTemporaryWorkersFiltersProps } from '../AgencyTemporaryWorkersFilters.types';

export interface AgencyTemporaryWorkersFiltersModalContentProps
  extends Pick<ModalProps, 'onClose'>,
    AgencyTemporaryWorkersFiltersProps {}

export enum AvailabilityDateInterval {
  SEVEN = 7,
  FIFTEEN = 15,
}
