import { useSearchContractsWithReset } from 'src/Redux/Search/Hooks';
import SingleDatePickerPopOver from '../../../../Components/SingleDatePickerPopOver';

export const ContractsTopBarComponent = () => {
  const searchContractsWithReset = useSearchContractsWithReset();

  return (
    <>
      de mon agence en cours le
      <SingleDatePickerPopOver calendar={false} onChange={searchContractsWithReset} />
    </>
  );
};
