import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Checkbox,
  ComboBox,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { debounce, isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useFetchCityList } from 'src/Hooks/Cities/useFetchCityList';
import {
  getSelectedPreviousMission,
  getSelectedPreviousPositionStudy,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { RootState } from 'src/Redux/RootReducer';
import { City, OSMCommonModelLocationModelOutputLocationDto } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { formatAddress } from 'src/Utils/address';
import styles from './WorkLocation.module.scss';
import { WorkLocationProps } from './WorkLocation.types';

const SecondContent = ({ selectedCompany, mission }: WorkLocationProps) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const previousPositionStudy = useSelector(getSelectedPreviousPositionStudy);
  const previousMission = useSelector(getSelectedPreviousMission);

  const setSelectedWorkLocation = useCallback(
    (workLocation: OSMCommonModelLocationModelOutputLocationDto) => {
      dispatch(missionCreationActions.setSelectedWorkLocation({ workLocation }));
    },
    [dispatch]
  );

  const initialWorkLocation = useMemo(
    () => ({
      address:
        previousPositionStudy?.workLocation?.address ??
        previousMission?.workLocation?.address ??
        ([
          selectedCompany?.companyAddress?.address1,
          selectedCompany?.companyAddress?.address2,
          selectedCompany?.companyAddress?.address3,
        ]
          .filter(Boolean)
          .join(', ') ||
          undefined),
      cityName:
        previousPositionStudy?.workLocation?.cityName ??
        previousMission?.workLocation?.cityName ??
        selectedCompany?.companyAddress?.city,
      zipCode:
        previousPositionStudy?.workLocation?.zipCode ??
        previousMission?.workLocation?.zipCode ??
        selectedCompany?.companyAddress?.postalCode,
      inseeCode:
        previousPositionStudy?.workLocation?.inseeCode ?? previousMission?.workLocation?.inseeCode,
    }),
    [previousMission, previousPositionStudy, selectedCompany]
  );

  const companyAddress = useMemo(
    () => ({
      address: [
        selectedCompany?.companyAddress?.address1,
        selectedCompany?.companyAddress?.address2,
        selectedCompany?.companyAddress?.address3,
      ]
        .filter(Boolean)
        .join(', '),
      cityName: selectedCompany?.companyAddress?.city,
      zipCode: selectedCompany?.companyAddress?.postalCode,
    }),
    [selectedCompany]
  );

  const [selected, setSelected] = useState(
    (!mission.workLocation &&
      isEqual(formatAddress(initialWorkLocation), formatAddress(companyAddress))) ||
      isEqual(formatAddress(companyAddress), formatAddress(mission.workLocation))
  );

  useEffect(() => {
    if (!mission.workLocation || selected) {
      setSelectedWorkLocation(initialWorkLocation);
      setSelectedCity({
        name: initialWorkLocation.cityName,
        insee: initialWorkLocation.inseeCode,
        zipCode: initialWorkLocation.zipCode,
      });
    }
    if (!selected) setSelectedWorkLocation(companyAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const {
    data: cities,
    mutate: searchCitiesRaw,
    isSuccess,
    isError,
    isLoading,
  } = useFetchCityList();

  const searchCities = useMemo(() => debounce(searchCitiesRaw, 500), [searchCitiesRaw]);

  const [selectedCity, setSelectedCity] = useState<City>({
    name: mission.workLocation?.cityName ?? initialWorkLocation.cityName,
    zipCode: mission.workLocation?.zipCode ?? initialWorkLocation.zipCode,
    insee: mission.workLocation?.inseeCode ?? initialWorkLocation.inseeCode,
  });

  return (
    <>
      <div className={styles.address}>
        <WithLightTitle title="adresse client">
          <TextInput
            defaultValue={formatAddress(companyAddress) || "pas d'adresse renseignée"}
            className={styles.addressText}
            disabled
          />
        </WithLightTitle>
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          checked={selected}
          onChange={() => setSelected(!selected)}
          label="lieu de délégation identique"
        />
      </div>
      {!selected && (
        <>
          <div className={styles.delegationAdress}>
            <WithLightTitle title="adresse complète">
              <TextInput
                value={mission.workLocation?.address ?? ''}
                placeholder="adresse de délégation"
                onChange={(event: any) => {
                  setSelectedWorkLocation({
                    ...mission.workLocation,
                    address: (event.target as HTMLTextAreaElement).value,
                  });
                }}
              />
            </WithLightTitle>
          </div>
          <div className={styles.cityContainer}>
            <WithLightTitle title="ville">
              <ComboBox
                id="city"
                placeholder="nom d'une ville"
                value={selectedCity}
                keyValueExtractor={(searchResult: City) => ({
                  key: searchResult.uId ?? '',
                  value: `${searchResult.name} (${searchResult.zipCode})`,
                })}
                onSearch={searchCities}
                onChange={item => {
                  if (item) {
                    setSelectedCity(item);
                    setSelectedWorkLocation({
                      ...mission.workLocation,
                      cityName: item.name,
                      zipCode: item.zipCode,
                      inseeCode: item.insee,
                    });
                  }
                }}
                searchResults={cities ?? []}
                fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
                minLengthToSearch={1}
              />
            </WithLightTitle>
          </div>
        </>
      )}
    </>
  );
};

export default SecondContent;
