import { useQuery } from '@tanstack/react-query';
import { CronJobDetails, CronJobsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCronJobDetails = (cronJobId: string) => {
  const cronJobDetails = useQuery<CronJobDetails>(
    [QueryKeys.fetchCronJobDetails, cronJobId],
    async () => {
      return await CronJobsService.cronJobsControllerGetCronJobDetails({ cronJob: cronJobId });
    },
    {
      refetchInterval: data => {
        if (!data) return Infinity;
        if (data.currentExecution) return 1000;
        return Infinity;
      },
    }
  );

  return cronJobDetails;
};
