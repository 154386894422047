import { useQuery } from '@tanstack/react-query';
import { CronJob, CronJobsService, CronJobConfig } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCronJobConfigs = () => {
  return useQuery<CronJobConfig[]>([QueryKeys.fetchCronJobConfigs], async () => {
    const cronJobConfigs = await CronJobsService.cronJobsControllerFetchCronJobConfigs();

    return Object.values(CronJob).map((cronJobId): CronJobConfig => {
      const cronJob = cronJobConfigs.find(cronJob => cronJob.cronJobId === cronJobId);
      if (!cronJob)
        return {
          cronJobId,
          cronTime: '* * * * *',
          enabled: false,
        };
      return cronJob;
    });
  });
};
