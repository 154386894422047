import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  CardCell,
  CardRow,
  Loader,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
} from '@randstad-lean-mobile-factory/react-components-core';

import { TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchCronJobConfigs } from 'src/Hooks/CronJobs/useFetchCronJobConfigs';
import { ROUTES } from '../../Routes.types';
import styles from './CronJobs.module.scss';
import moment from 'moment';

export const CronJobsRoute = () => {
  const navigate = useNavigate();

  const { data: cronJobs, isLoading } = useFetchCronJobConfigs();

  return (
    <div className={styles.container}>
      <TopBar disablePerimeterSelection title="tâches programmées" />
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des tâches
          </div>
        ) : !cronJobs ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : cronJobs.length === 0 ? (
          <div className={styles.illustration}>
            <VidePasteque />
            aucune tâche en cours
          </div>
        ) : (
          <StyledTable size="small">
            <TableHeader>
              <TableRow>
                <TableHead>tâche</TableHead>
                <TableHead>activation</TableHead>
                <TableHead>dernière exécution</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {cronJobs.map(cronJob => {
                return (
                  <CardRow
                    color="white"
                    key={cronJob.cronJobId}
                    onClick={() =>
                      navigate(
                        ROUTES.TOOLS.CRON_JOBS.CRON_JOB_DETAILS.buildPath({
                          cronJobId: cronJob.cronJobId,
                        })
                      )
                    }
                    isClickable
                  >
                    <CardCell>{cronJob.cronJobId}</CardCell>
                    <CardCell>{cronJob.enabled ? 'activé' : 'désactivé'}</CardCell>
                    <CardCell>
                      {cronJob.lastExecution
                        ? moment(cronJob.lastExecution).format('LLLL')
                        : 'jamais exécuté'}
                    </CardCell>
                  </CardRow>
                );
              })}
            </TableBody>
          </StyledTable>
        )}
      </div>
    </div>
  );
};
