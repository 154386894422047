export function formatAddress(address: undefined): undefined;
export function formatAddress(address: {
  address1: string;
  address2?: string;
  address3?: string;
}): string;
export function formatAddress(address: {
  address1?: string;
  address2: string;
  address3?: string;
}): string;
export function formatAddress(address: {
  address1?: string;
  address2?: string;
  address3: string;
}): string;
export function formatAddress(address: {
  address1?: string;
  address2?: string;
  address3?: string;
}): string | undefined;
export function formatAddress(
  address:
    | {
        address1?: string;
        address2?: string;
        address3?: string;
      }
    | undefined
): string | undefined;
export function formatAddress(
  address:
    | {
        address1?: string;
        address2?: string;
        address3?: string;
      }
    | undefined
): string | undefined {
  if (!address) return;
  if (!address.address1 && !address.address2 && !address.address3) return;
  return [address.address2, address.address1, address.address3].filter(Boolean).join(', ');
}
