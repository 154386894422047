import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAPI } from 'src/Redux/RootReducer';

import { ConsultantInformation, VariablesimulatorService } from 'src/Services/API';

export const fetchVariableSimulator = createAsyncThunk<
  { consultantInformation: ConsultantInformation },
  string,
  ThunkAPI
>('variableSimulator/fetch', async (siid, thunkAPI) => {
  const consultantInformation =
    await VariablesimulatorService.variableSimulatorControllerGetConsultantInformation({ siid });

  return { consultantInformation };
});
