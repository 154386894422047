import { Button, DataTable, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { RedBullet } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import HideableSection from 'src/Components/HideableSection';
import SearchableDropDown from 'src/Components/SearchableDropDown';
import { useFetchCompanyServicesBySiret } from 'src/Hooks/Companies/useFetchCompanyServicesBySiret';
import { TypeFieldDto, UniqueSiret } from 'src/Services/API';
import { DEFAULT_SOURCE_VALUE } from 'src/Utils/constants';

import { useQueryClient } from '@tanstack/react-query';
import { useUpdateMailParameters } from 'src/Hooks/MailParameters/useUpdateMailParameters';
import { useFetchMailParametersBySiret } from 'src/Hooks/MailParameters/useFetchMailParametersBySiret';
import { QueryKeys } from 'src/Hooks/types';
import { MailParameter } from '../MailParametersList.types';
import styles from './MailParameterTable.module.scss';
import {
  MailParameterTableProps,
  OSMOSE_IGNORE_LABEL,
  OSMOSE_IGNORE_VALUE,
} from './MailParameterTable.types';

const MailParametersTable = ({
  source,
  siret,
  clientName,
  missing,
  qualifications,
  domains,
  isOpen,
  onToggle,
}: MailParameterTableProps) => {
  const queryClient = useQueryClient();

  const {
    data: mailParameters,
    isLoading: mailParametersLoading,
    refetch: refetchMailParameters,
  } = useFetchMailParametersBySiret(source, siret, isOpen);
  const {
    data: servicesData,
    isLoading: servicesLoading,
    refetch: refetchServices,
  } = useFetchCompanyServicesBySiret(siret, isOpen);

  const isLoading = mailParametersLoading || servicesLoading;
  const refetch = useCallback(() => {
    refetchMailParameters();
    refetchServices();
  }, [refetchMailParameters, refetchServices]);

  const data = useMemo(
    () =>
      mailParameters?.map(mailParameter => ({
        ...mailParameter,
        updatedOsmoseLabel: mailParameter.osmoseLabel,
      })),
    [mailParameters]
  );

  const services = useMemo(
    () => servicesData?.map(service => service.name).filter(Boolean) ?? [],
    [servicesData]
  );
  const { mutate: updateMailParameter } = useUpdateMailParameters();

  const [sortState, setSortState] = useState<SortingState>([{ id: 'isError', desc: true }]);

  const displayTitle = useMemo(() => {
    if (siret === DEFAULT_SOURCE_VALUE) return 'Paramètres agence';
    if (clientName) return `${clientName} (${siret})`;
    return siret;
  }, [clientName, siret]);

  const isError = useCallback(
    (mailParameter: MailParameter) => {
      const baseItems = {
        [TypeFieldDto.QUALIFICATION]: qualifications,
        [TypeFieldDto.DOMAIN]: domains,
        [TypeFieldDto.SERVICE]: services,
      }[mailParameter.typeField];

      if (!baseItems.length) return false;

      if (!mailParameter.updatedOsmoseLabel)
        return mailParameter.sourceLabel !== DEFAULT_SOURCE_VALUE;

      if (baseItems.includes(mailParameter.updatedOsmoseLabel)) return false;

      if (mailParameter.updatedOsmoseLabel === OSMOSE_IGNORE_VALUE) return false;

      return true;
    },
    [domains, qualifications, services]
  );

  const columns = useMemo(
    (): ColumnDef<MailParameter>[] => [
      {
        id: 'type',
        header: 'type',
        accessorFn: element =>
          ({
            [TypeFieldDto.DOMAIN]: 'domaine',
            [TypeFieldDto.SERVICE]: 'service',
            [TypeFieldDto.QUALIFICATION]: 'qualification',
          })[element.typeField],
        filterFn: 'arrIncludes',
      },
      {
        id: 'value',
        header: `valeur ${source.toLowerCase()}`,
        accessorFn: element => element.sourceLabel,
        filterFn: 'arrIncludes',
      },
      {
        id: 'osmose',
        header: 'valeur osmose',
        sortUndefined: 'first',
        accessorFn: element => element.osmoseLabel,
        cell: ({ row }) => {
          const mailParameter = row.original;

          let items = {
            [TypeFieldDto.QUALIFICATION]: qualifications,
            [TypeFieldDto.DOMAIN]: domains,
            [TypeFieldDto.SERVICE]: services,
          }[mailParameter.typeField];
          if (mailParameter.sourceLabel !== DEFAULT_SOURCE_VALUE)
            items = [OSMOSE_IGNORE_LABEL].concat(items);

          return (
            <>
              <SearchableDropDown
                data-full-height
                className={styles.dropDown}
                items={items}
                placeholder="sélectionnez"
                canBeReset
                selectedItem={
                  mailParameter.updatedOsmoseLabel === OSMOSE_IGNORE_VALUE
                    ? OSMOSE_IGNORE_LABEL
                    : mailParameter.updatedOsmoseLabel &&
                        items.includes(mailParameter.updatedOsmoseLabel)
                      ? mailParameter.updatedOsmoseLabel
                      : undefined
                }
                onSelectItem={(osmoseLabel?: string) => {
                  if (mailParameter.osmoseLabel === osmoseLabel) return;
                  const previousLabel = mailParameter.osmoseLabel;
                  if (osmoseLabel === OSMOSE_IGNORE_LABEL) {
                    osmoseLabel = OSMOSE_IGNORE_VALUE;
                  }
                  mailParameter.updatedOsmoseLabel = osmoseLabel;
                  updateMailParameter(
                    { ...mailParameter, source, siret },
                    {
                      onSuccess: () => {
                        const previousError = isError({
                          ...mailParameter,
                          updatedOsmoseLabel: previousLabel,
                        });
                        const nextError = isError({
                          ...mailParameter,
                          updatedOsmoseLabel: osmoseLabel,
                        });
                        if (previousError !== nextError)
                          queryClient.setQueryData(
                            [QueryKeys.fetchUniqueSirets],
                            (uniqueSirets: UniqueSiret[] | undefined) => {
                              if (!uniqueSirets) return;
                              return uniqueSirets.map(uniqueSiret => {
                                if (uniqueSiret.siret !== siret) return uniqueSiret;
                                return {
                                  ...uniqueSiret,
                                  parameterStats: uniqueSiret.parameterStats.map(stats => {
                                    if (stats.source !== source) return stats;
                                    const missing = nextError
                                      ? stats.missing + 1
                                      : stats.missing - 1;
                                    return { ...stats, missing };
                                  }),
                                };
                              });
                            }
                          );
                      },
                      onError: () => {
                        mailParameter.updatedOsmoseLabel = mailParameter.osmoseLabel;
                      },
                    }
                  );
                }}
                error={isError(mailParameter)}
              />
            </>
          );
        },
        filterFn: 'arrIncludes',
      },
      {
        id: 'isError',
        accessorFn: mailParameter =>
          isError({ ...mailParameter, updatedOsmoseLabel: mailParameter.osmoseLabel }),
      },
    ],
    [source, isError, qualifications, domains, services, updateMailParameter, queryClient, siret]
  );

  const someErrors = useMemo(
    () =>
      (domains.length > 0 &&
        qualifications.length > 0 &&
        (siret === DEFAULT_SOURCE_VALUE || services.length > 0) &&
        data?.some(isError)) ||
      missing > 0,
    [domains.length, qualifications.length, siret, services.length, data, isError, missing]
  );

  return (
    <HideableSection
      defaultOpenState={isOpen}
      titleIcon={someErrors && <RedBullet className={styles.bullet} />}
      title={displayTitle}
      dataReady={data && data.length > 0}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      {isLoading ? (
        <div className={styles.loadingScreen}>
          <Loader heightInRem={4} className={styles.loader} />
          <div>chargement des correspondances, cela peut prendre du temps</div>
        </div>
      ) : !data || !servicesData ? (
        <div className={styles.error}>
          Une erreur s'est produite pendant la recherche des correspondances
          <Button size="small" onClick={() => refetch()}>
            réessayer
          </Button>
        </div>
      ) : (
        <DataTable
          size="small"
          headerColor="white"
          containerClassName={styles.table}
          stickyHeader
          data={data}
          columns={columns}
          filterFromLeafRows
          enableMultiSort={false}
          state={{ sorting: sortState, columnVisibility: { isError: false } }}
          onSortingChange={setSortState}
          empty="aucun résultat ne correspond à vos filtres"
        />
      )}
    </HideableSection>
  );
};

export default MailParametersTable;
