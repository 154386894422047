import { useQuery } from '@tanstack/react-query';
import { WorkAccidentAnalysisService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidentAnalysis = (analysisId: string) => {
  return useQuery(
    [QueryKeys.fetchWorkAccidentAnalyses, analysisId],
    async () => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentAnalysis(
        { analysisId }
      );
    },
    {}
  );
};
