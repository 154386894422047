import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import {
  EnumCoreMissionCandidateCandidateAdvancement,
  EnumCoreMissionCandidateCompanyAdvancement,
  MissionsService,
  SearchMissionsBody,
} from 'src/Services/API';
import { QueryKeys } from '../types';
import { uniq } from 'lodash-es';

export const useFetchMissions = (
  params: Omit<SearchMissionsBody, 'limit' | 'offset' | 'brandCode' | 'agencyId' | 'search'>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchMissions, params],
    async ({ pageParam = 0 }) => {
      const salesphases: string[] | undefined = params.salesphases
        ? uniq(params.salesphases.map(salesphase => (salesphase === 'SP' ? 'AS' : salesphase)))
        : undefined;

      const { missions } = await MissionsService.missionsControllerGetMissions({
        body: {
          brandCode,
          agencyIds: [agencyId],
          offset: pageParam * SEARCH_LIMIT,
          limit: SEARCH_LIMIT,
          search: true,
          ...params,
          salesphases,
        },
      });

      if (!missions) return { missions: [], totalMissions: 0, nextPage: undefined };

      const correctedSalesphaseMissions = missions.map(mission => {
        const attachedCandidatesCount =
          mission.candidates?.filter(
            candidate =>
              candidate.candidateAdvancement ===
                EnumCoreMissionCandidateCandidateAdvancement.accepté &&
              candidate.companyAdvancement === EnumCoreMissionCandidateCompanyAdvancement.accepté
          ).length ?? 0;
        const isPartiallyFulfilled =
          attachedCandidatesCount > 0 &&
          mission?.numberPositions &&
          attachedCandidatesCount < mission?.numberPositions;
        return {
          ...mission,
          salesPhase:
            mission.salesPhase?.id === 'AS' && isPartiallyFulfilled
              ? { id: 'SP', label: 'servie partiellement' }
              : mission.salesPhase,
        };
      });

      if (
        (!params.salesphases?.includes('SP') && !params.salesphases?.includes('AS')) ||
        (params.salesphases?.includes('SP') && params.salesphases?.includes('AS'))
      ) {
        return {
          missions: correctedSalesphaseMissions,
          totalMissions: correctedSalesphaseMissions.length,
          nextPage: missions.length === SEARCH_LIMIT ? pageParam + 1 : undefined,
        };
      }

      const filteredMissions = correctedSalesphaseMissions.filter(mission => {
        if (!params.salesphases) return true;
        if (!mission.salesPhase?.id) return false;
        return params.salesphases.includes(mission.salesPhase.id);
      });

      return {
        missions: filteredMissions,
        totalMissions: filteredMissions.length,
        nextPage: missions.length === SEARCH_LIMIT ? pageParam + 1 : undefined,
      };
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
