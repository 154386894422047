import { SortDown, SortUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { SortTypeEnum, FetchTemporaryWorkersSortCriteria } from 'src/Services/API';
import styles from './RegionalTemporaryWorkersSortingMenu.module.scss';
import { RegionalTemporaryWorkersSortingMenuProps } from './RegionalTemporaryWorkersSortingMenu.types';

export const RegionalTemporaryWorkersSortingMenu = ({
  trigger,
  sort,
  setSort,
}: RegionalTemporaryWorkersSortingMenuProps) => (
  <Menu
    trigger={trigger}
    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <MenuItem
      selected={sort.sortType === SortTypeEnum.ASC}
      onClick={() => setSort(sort => ({ ...sort, sortType: SortTypeEnum.ASC }))}
      keepOpenOnClick
    >
      <SortUp size="xsmall" />
      ascendant
    </MenuItem>
    <MenuItem
      selected={sort.sortType === SortTypeEnum.DESC}
      onClick={() => setSort(sort => ({ ...sort, sortType: SortTypeEnum.DESC }))}
      keepOpenOnClick
    >
      <SortDown size="xsmall" />
      descendant
    </MenuItem>

    <div className={styles.menuGroup}>trier par</div>

    <MenuItem
      selected={sort.sortCriteria === FetchTemporaryWorkersSortCriteria.MainAgency}
      onClick={() =>
        setSort(sort => ({ ...sort, sortCriteria: FetchTemporaryWorkersSortCriteria.MainAgency }))
      }
      keepOpenOnClick
    >
      agence
    </MenuItem>
    <MenuItem
      selected={sort.sortCriteria === FetchTemporaryWorkersSortCriteria.ZipCode}
      onClick={() =>
        setSort(sort => ({ ...sort, sortCriteria: FetchTemporaryWorkersSortCriteria.ZipCode }))
      }
      keepOpenOnClick
    >
      lieu de résidence
    </MenuItem>
    <MenuItem
      selected={
        sort.sortCriteria === FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate
      }
      onClick={() =>
        setSort(sort => ({
          ...sort,
          sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
        }))
      }
      keepOpenOnClick
    >
      prochaine dispo
    </MenuItem>
  </Menu>
);
