import { useQuery } from '@tanstack/react-query';
import { FeatureFlag, FeatureFlagConfig, FeatureFlagsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchFeatureFlagConfigs = () => {
  return useQuery<FeatureFlagConfig[]>([QueryKeys.fetchFeatureFlagConfigs], async () => {
    const featureFlagConfigs =
      await FeatureFlagsService.featureFlagsControllerFetchFeatureFlagsConfigs();

    return Object.values(FeatureFlag).map((featureFlagId): FeatureFlagConfig => {
      const featureFlag = featureFlagConfigs.find(
        featureFlag => featureFlag.featureFlagId === featureFlagId
      );
      if (!featureFlag)
        return {
          featureFlagId,
          enabledGlobally: process.env.REACT_APP_ENV !== 'production',
          enabledBrands: [],
          enabledRegions: [],
          enabledAgencies: [],
        };
      return {
        featureFlagId,
        enabledGlobally: featureFlag.enabledGlobally,
        enabledBrands: featureFlag.enabledBrands.map(brand => ({
          brandCode: brand.brandCode,
          activationDate: brand.activationDate && new Date(brand.activationDate),
        })),
        enabledRegions: featureFlag.enabledRegions.map(region => ({
          regionId: region.regionId,
          activationDate: region.activationDate && new Date(region.activationDate),
        })),
        enabledAgencies: featureFlag.enabledAgencies.map(agency => ({
          agencyId: agency.agencyId,
          activationDate: agency.activationDate && new Date(agency.activationDate),
        })),
      };
    });
  });
};
