import { useMutation } from '@tanstack/react-query';

import { WorkAccidentAnalysisService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUploadWorkAccidentFile = () => {
  return useMutation(
    [MutationKeys.uploadWorkAccidentFiles],
    async (params: { id: string; file: File }) => {
      const updatedNameWorkAccident =
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerUploadWorkAccidentFile({
          id: params.id,
          file: params.file,
        });
      return updatedNameWorkAccident;
    },
    {}
  );
};
