import styles from './TalentDetailsHabilitations.module.scss';
import { Props } from './TalentDetailsHabilitations.types';
import {
  Badge,
  CardCell,
  CardRow,
  StyledTable,
  TableBody,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Habilitation } from '@randstad-lean-mobile-factory/react-assets/dist/pictos';

export const TalentDetailsHabilitations = ({ habilitations }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.habilitationsHeader}>
        <div className={styles.habilitationsHeaderLabel}>habilitations</div>
        <Badge size="xsmall" color="navy" saturation="medium" className={styles.habilitationsBadge}>
          {habilitations ? habilitations?.length : 0}
        </Badge>
      </div>
      {habilitations && habilitations.length !== 0 ? (
        <div>
          <StyledTable size="medium" color="beige">
            <TableBody>
              {habilitations.map(habilitation => {
                return (
                  <CardRow key={habilitation.id}>
                    <CardCell>
                      <Habilitation />
                      <div className={styles.habilitation}>{habilitation.label}</div>
                    </CardCell>
                  </CardRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </div>
      ) : (
        <div className={styles.noHabilitationsText}>aucune habilitation n'a été renseignée</div>
      )}
    </div>
  );
};

export default TalentDetailsHabilitations;
