import { ThunkDispatch } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { useFetchContractDetail } from 'src/Hooks/Contracts/useFetchContractDetail';
import { useRenewContract } from 'src/Hooks/Contracts/useRenewContract';
import { QueryKeys } from 'src/Hooks/types';
import { useFetchYouplanMissionDetail } from 'src/Hooks/Youplan/useFetchYouplanMissionDetail';
import { reloadCurrentContract } from 'src/Redux/Contracts/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import { toFetchStatus } from 'src/Services/Async';
import ContractRenewalModalContent from './ContractRenewalModalContent.component';

const MemoContractRenewalModalContent = memo(ContractRenewalModalContent);

const EnhancedContractRenewalModalContent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const state = useLocation().state as { contractId?: string; youplanMissionId?: string };
  const { data: currentContract } = useFetchContractDetail(state.contractId);
  const { data: youplanMission } = useFetchYouplanMissionDetail({
    missionId: state.youplanMissionId,
  });
  const renewalMutation = useRenewContract();
  const renewalFetchStatus = toFetchStatus(renewalMutation);
  const onRenewal = useCallback(
    (renewalDate: Date) => {
      renewalMutation.mutate({
        contractId: currentContract?.contractId!,
        contactId: currentContract?.contactId!,
        youplanMissionId: state.youplanMissionId,
        renewalDate,
      });
    },
    [currentContract, renewalMutation, state.youplanMissionId]
  );
  const onRenewalSuccess = useCallback(() => dispatch(reloadCurrentContract()), [dispatch]);
  const onClose = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
    navigate(-1);
  }, [navigate, queryClient]);
  return (
    <MemoContractRenewalModalContent
      contract={currentContract}
      onRenewal={onRenewal}
      onRenewalSuccess={onRenewalSuccess}
      renewalFetchStatus={renewalFetchStatus}
      suggestedEndDate={youplanMission?.endDate}
      onClose={onClose}
    />
  );
};

export default EnhancedContractRenewalModalContent;
