import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MutationKeys, QueryKeys } from '../types';
import { WorkAccidentAnalysisService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

interface CloseAction {
  workAccidentId: string;
  actionId: string;
}

export const useCloseWorkAccidentActionPlan = (
  extraOptions?: UseMutationOptions<unknown, unknown, CloseAction, unknown>
) => {
  const queryClient = useQueryClient();
  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(
    [MutationKeys.closeATActionPlan, brandCode],
    async (params: CloseAction) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerCloseWorkAccidentActionPlan(
        {
          actionId: params.actionId,
          brandCode,
          workAccidentId: params.workAccidentId,
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentAnalyses]);
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentActionPlans]);
        toast.success("l'action a bien été clôturée");
      },
      onError: () => {
        toast.error("l'action n'a pas pu être clôturée ");
      },
    }
  );
};
