import {
  Button,
  ComboBox,
  DropDown,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ClosePop,
  MoinsCircle,
  PlusCircle,
  PointingFingerCut,
  Refresh,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import styles from './VariableSimulator.module.scss';
import {
  ENUM_FTE_TRANCHE,
  fteTrancheForMarketAndPool,
  fteTrancheForScale3LargeAndOnsite,
  fteTrancheForScale4LargeAndOnsite,
} from './VariableSimulator.types';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { useMutation, useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { redCloud } from 'src/Assets_DEPRECATED';
import { QueryKeys } from 'src/Hooks/types';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getConsultantInformation, getFetchStatus } from 'src/Redux/VariableSimulator/Selectors';
import { fetchVariableSimulator as _fetchVariableSimulator } from 'src/Redux/VariableSimulator/Thunks';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import {
  EnumConsultantInformationDataStatus,
  FeatureFlag,
  PostVSSimulateBody,
  UnitData,
  VariablesimulatorService,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import ComponentResult from './ComponentResult/ComponentResult.component';
import FTEInput from './FTEInput/FTEInput.component';
import VariableSimulatorAdmin from './VariableSimulatorAdmin/VariableSimulatorAdmin.component';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';

const C_USE_K_EURO = false;
const C_SSID_FOR_TEST = '12345678';

const VariableSimulator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const fetchStatus = useSelector(getFetchStatus);
  const consultantInformation = useSelector(getConsultantInformation);

  const isDebugEnabled = useFeatureFlag(FeatureFlag.VARIABLE_SIMULATOR_DEBUG);

  const fetchVariableSimulator = useCallback(
    (siid: string) => {
      dispatch(_fetchVariableSimulator(siid));
    },
    [dispatch]
  );
  const simulateFetchData = useMutation(async (simulationData: PostVSSimulateBody) => {
    return await VariablesimulatorService.variableSimulatorControllerSimulate({
      body: simulationData,
    });
  });
  const simulateFetchStatus = toFetchStatus(simulateFetchData);

  const functionCategoriesFetchData = useQuery<string[]>(
    [QueryKeys.fetchFunctionCategories],
    async () => {
      return await VariablesimulatorService.variableSimulatorControllerGetFunctionCategories();
    },
    { refetchOnWindowFocus: false }
  );

  const functionCategoriesFetchStatus = toFetchStatus(functionCategoriesFetchData);

  const [margeBruteBaseVariableText, setMargeBruteBaseVariableText] = useState<
    string | undefined
  >();
  const [margeBrutePlacementText, setMargeBrutePlacementText] = useState<string | undefined>();
  const [fteTranche, setFTETranche] = useState<ENUM_FTE_TRANCHE>();

  const [coefficientOfPresence, setCoefficientOfPresence] = useState<number | undefined>(100);

  const [categoryFunction, setCategoryFunction] = useState<string>();

  const [error, setError] = useState<string | undefined>();
  const [modeDebug, setModeDebug] = useState(false);
  const [extendBlockSimulation, setExtendBlockSimulation] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<string | undefined>(undefined);
  const [selectedUnitData, setSelectedUnitData] = useState<UnitData | undefined>(undefined);

  const [localSSID, setLocalSSID] = useState<string>();

  const [fteScale, setFTEScale] = useState(4);
  const [unitList, setUnitList] = useState<string[]>([]);
  const [disableAllInput, setDisableAllInput] = useState(false);

  //data for Simualation manuelle
  const [fteTrancheForSM, setFTETrancheForSM] = useState<ENUM_FTE_TRANCHE>();
  const [categoryFunctionForSM, setCategoryFunctionForSM] = useState<string>();
  const [unitTypeForSM, setUnitTypeForSM] = useState<string>();
  const [fteScaleForSM, setFTEScaleForSM] = useState(4);
  const [simulationManual, setSimulationManual] = useState(false);
  const unitTypeList: string[] = ['MARKET_UNIT', 'LARGE_VOLUME_UNIT', 'ONSITE_UNIT', 'POOL_UNIT'];

  const userInfo = useSelector(getAnalyticsUserInfo);

  useEffect(() => {
    const _fteScale = isOnSiteUnit(categoryFunction) ? 3 : 4;
    setFTEScale(_fteScale);
    if (selectedUnitData !== undefined) {
      if (_fteScale === 3) {
        setFTETranche(convertFteLargeAndOnsiteToEnum(selectedUnitData.fte));
      } else if (_fteScale === 4) {
        setFTETranche(convertFteMarketAndPoolToEnum(selectedUnitData.fte));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFunction, localSSID, selectedUnitData]);

  useEffect(() => {
    if (consultantInformation) {
      setLocalSSID(consultantInformation.siid);
      setCoefficientOfPresence(consultantInformation.coefOfPresence * 100);
      setCategoryFunction(consultantInformation.categoryFunction);
      const error =
        consultantInformation.dataStatus ===
        EnumConsultantInformationDataStatus.CONSULTANT_NOT_FOUND
          ? 'Identifiant utilisateur inconnu'
          : consultantInformation.unitDatas.length === 0
            ? 'Aucune unité trouvée dans votre périmètre'
            : undefined;
      setDisableAllInput(error !== undefined);
      setError(error);

      setUnitList(consultantInformation.unitDatas.map(item => item.code));

      const resultat = consultantInformation.unitDatas.filter(entry => entry.code === selectedUnit);

      if (resultat.length > 0) {
        const unitData = resultat[0];
        setSelectedUnitData(unitData);
        setFTETranche(
          ['LARGE_VOLUME_UNIT', 'ONSITE_UNIT'].includes(unitData.typology)
            ? convertFteLargeAndOnsiteToEnum(unitData.fte)
            : convertFteMarketAndPoolToEnum(unitData.fte)
        );
      } else {
        setSelectedUnit(
          consultantInformation.unitDatas.length > 0
            ? consultantInformation.unitDatas[0].code
            : undefined
        );
      }
      //special case in recette:
      if (consultantInformation.siid === C_SSID_FOR_TEST) {
        setMargeBruteBaseVariableText('40301');
        setMargeBrutePlacementText('2111');
      }
    } else {
      setDisableAllInput(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    consultantInformation,
    setLocalSSID,
    setCoefficientOfPresence,
    setCategoryFunction,
    setSelectedUnitData,
    setFTETranche,
    setSelectedUnit,
  ]);

  useEffect(() => {
    const resultat = consultantInformation?.unitDatas.filter(entry => entry.code === selectedUnit);
    if (resultat && resultat.length > 0) {
      const unitData = resultat[0];
      setSelectedUnitData(unitData);
      setFTETranche(
        ['LARGE_VOLUME_UNIT', 'ONSITE_UNIT'].includes(unitData.typology)
          ? convertFteLargeAndOnsiteToEnum(unitData.fte)
          : convertFteMarketAndPoolToEnum(unitData.fte)
      );
    } else {
      setSelectedUnitData(undefined);
      setFTETranche(undefined);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedUnit, setSelectedUnitData, setFTETranche]);

  useEffect(() => {
    setFTETrancheForSM(0);
    unitTypeForSM === 'ONSITE_UNIT' ? setFTEScaleForSM(3) : setFTEScaleForSM(4);
  }, [unitTypeForSM]);

  const convertFteLargeAndOnsiteToEnum = (fte: number) => {
    const _fteScale = isOnSiteUnit(categoryFunction) ? 3 : 4;
    if (_fteScale === 3) {
      if (fte < 2) return ENUM_FTE_TRANCHE.TRANCHE_0;
      else if (fte < 4) return ENUM_FTE_TRANCHE.TRANCHE_1;
      else return ENUM_FTE_TRANCHE.TRANCHE_2;
    } else if (_fteScale === 4) {
      if (fte < 2) return ENUM_FTE_TRANCHE.TRANCHE_0;
      else if (fte < 3) return ENUM_FTE_TRANCHE.TRANCHE_1;
      else if (fte < 4) return ENUM_FTE_TRANCHE.TRANCHE_2;
      else return ENUM_FTE_TRANCHE.TRANCHE_3;
    }
  };

  const convertFteMarketAndPoolToEnum = (fte: number) => {
    if (fte < 1.5) {
      return ENUM_FTE_TRANCHE.TRANCHE_0;
    } else if (fte < 2.5) {
      return ENUM_FTE_TRANCHE.TRANCHE_1;
    } else if (fte < 3) {
      return ENUM_FTE_TRANCHE.TRANCHE_2;
    } else if (fte < 4) {
      return ENUM_FTE_TRANCHE.TRANCHE_3;
    } else {
      return ENUM_FTE_TRANCHE.TRANCHE_4;
    }
  };

  const onSelectedUnit = useCallback(
    (item: string | undefined) => {
      setSelectedUnit(item);
    },
    [setSelectedUnit]
  );

  const onSetMBVariable = (text: string) => {
    setMargeBruteBaseVariableText(text);
    simulateFetchData.reset();
  };

  const onSetMBPlacement = (text: string) => {
    setMargeBrutePlacementText(text);
    simulateFetchData.reset();
  };

  const onSetLocalSSID = useCallback(
    (text: string) => {
      setLocalSSID(text);
    },
    [setLocalSSID]
  );

  useEffect(() => {
    fetchVariableSimulator('me');
  }, [fetchVariableSimulator]);

  const onReset = useCallback(() => {
    setMargeBruteBaseVariableText('');
    setMargeBrutePlacementText('');
    setCoefficientOfPresence(100);
    setError(undefined);
    simulateFetchData.reset();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    setMargeBruteBaseVariableText,
    setMargeBrutePlacementText,
    setCoefficientOfPresence,
    setError,
  ]);

  const onRefreshData = () => {
    if (localSSID) {
      fetchVariableSimulator(localSSID);
    }
  };

  const isLVUUnit = (unitData: UnitData) => {
    return unitData.typology === 'LARGE VOLUME UNIT' || unitData.typology === 'CLIENT UNIT';
  };

  const isPoolUnit = (unitData: UnitData) => {
    return unitData.typology === 'POOL UNIT';
  };

  const isMarketUnit = (unitData: UnitData) => {
    return unitData.typology === 'MARKET UNIT';
  };

  const isOnSiteUnit = (categoryFunction: string | undefined) => {
    return categoryFunction ? categoryFunction.includes('ONSITE') : false;
  };

  const analyticEventSimulate = () => {
    ReactGA.event(ANALYTICS_EVENT.VARIABLE_SIMULATOR_SIMULATE, {
      ...userInfo,
      selectedUnit,
    });
  };

  const checkAndPrepareDataForSimulation = (
    _simulationManual: boolean
  ): PostVSSimulateBody | undefined => {
    const convertMargeBrute = (margeBruteText: string | undefined) => {
      if (margeBruteText === undefined) {
        return undefined;
      }
      const margeBruteNumber = parseFloat(margeBruteText);
      return Number.isNaN(margeBruteNumber)
        ? undefined
        : margeBruteNumber * (C_USE_K_EURO ? 1000 : 1);
    };

    setError(undefined);
    const _margeBruteBaseVariable = convertMargeBrute(margeBruteBaseVariableText);
    const _margeBrutePlacement = convertMargeBrute(margeBrutePlacementText);

    //common test
    if (_margeBruteBaseVariable === undefined) {
      setError('Merci de renseigner une marge brute variable');
      return;
    } else if (_margeBrutePlacement === undefined) {
      setError('Merci de renseigner une marge brute placement');
      return;
    }

    if (_simulationManual) {
      if (fteTrancheForSM === undefined) {
        setError("Nombre de FTE de l'unité inconnue");
        return;
      } else if (unitTypeForSM === undefined) {
        setError('Aucun type unité  renseigné');
        return;
      } else if (categoryFunctionForSM === undefined) {
        setError("Votre fonction ne permet pas d'utiliser le simulateur");
        return;
      }

      return {
        unitType: unitTypeForSM,
        categoryFunction: categoryFunctionForSM,
        fteTranche: fteTrancheForSM,
        coefficientOfPresence: 100,
        analyticalCoef: 1,
        margeBruteBaseVariable: _margeBruteBaseVariable,
        margeBrutePlacement: _margeBrutePlacement,
      };
    } else {
      const _analyticalCoef =
        selectedUnitData?.analyticalCoef !== undefined
          ? selectedUnitData?.analyticalCoef / 100
          : undefined;

      if (consultantInformation === undefined) {
        setError('Identifiant utilisateur inconnu');
        return;
      } else if (_analyticalCoef === undefined) {
        setError('Vous ne possédez pas de coefficient analytique sur cette unité');
        return;
      } else if (coefficientOfPresence === undefined) {
        setError('Aucun coefficient de présence renseigné');
        return;
      } else if (categoryFunction === undefined) {
        setError("Votre fonction ne permet pas d'utiliser le simulateur");
        return;
      } else if (fteTranche === undefined) {
        setError("Nombre de FTE de l'unité inconnue");
        return;
      }

      //definir data source
      const _unitTypeData = isOnSiteUnit(categoryFunction)
        ? 'ONSITE_UNIT'
        : isLVUUnit(selectedUnitData!)
          ? 'LARGE_VOLUME_UNIT'
          : isPoolUnit(selectedUnitData!)
            ? 'POOL_UNIT'
            : 'MARKET_UNIT';

      return {
        unitType: _unitTypeData,
        categoryFunction: categoryFunction,
        fteTranche: fteTranche,
        coefficientOfPresence: coefficientOfPresence,
        analyticalCoef: _analyticalCoef,
        margeBruteBaseVariable: _margeBruteBaseVariable,
        margeBrutePlacement: _margeBrutePlacement,
      };
    }
  };

  const onCalcul = async (_simulationManual: boolean) => {
    setSimulationManual(_simulationManual);
    simulateFetchData.reset();
    const _simulationData = checkAndPrepareDataForSimulation(_simulationManual);

    if (_simulationData) {
      //send googleAnalytic simulate
      analyticEventSimulate();
      simulateFetchData.mutate(_simulationData);
    }
  };

  const LoadingCard = () => (
    <div className={styles.container}>
      <ContentLoader uniqueKey="loadingCard" height="4.75rem" width="100%">
        {[
          { width: '80%', height: '1.5rem', y: '0' },
          { width: '60%', height: '0.65rem', y: '1.8rem' },
          { width: '60%', height: '0.65rem', y: '2.7rem' },
          { width: '70%', height: '0.75rem', y: '4rem' },
        ].map((props, idx) => (
          <rect key={idx} x="0" rx="4" ry="4" {...props} />
        ))}
      </ContentLoader>
    </div>
  );

  const displayFteTranche = () => {
    if (fteTranche === undefined || selectedUnitData?.typology === undefined) {
      return '--';
    } else if (isLVUUnit(selectedUnitData) || isOnSiteUnit(categoryFunction)) {
      if (fteScale === 3) {
        return fteTranche < 3 ? fteTrancheForScale3LargeAndOnsite[fteTranche]?.slice(4) : '**';
      }

      if (fteScale === 4) {
        return fteTrancheForScale4LargeAndOnsite[fteTranche]?.slice(4);
      }
    } else if (isPoolUnit(selectedUnitData) || isMarketUnit(selectedUnitData)) {
      return fteTrancheForMarketAndPool[fteTranche]?.slice(4);
    }
    return '--';
  };

  return (
    <>
      <div className={styles.overlay} onClick={() => navigate(-1)} />
      <div className={styles.container}>
        <PointingFingerCut className={styles.illus} />
        <Button.Tertiary
          onClick={() => {
            navigate(-1);
          }}
          className={styles.closeButton}
        >
          <ClosePop />
        </Button.Tertiary>
        <div className={styles.title}>calcul variable</div>
        <div className={styles.intro}>
          <div>
            <p>
              Cet outil vous permet d'estimer votre prime mensuelle variable en sélectionnant
              l'unité de référence pour la simulation Il ne vous reste plus qu'à saisir :{' '}
            </p>
            <p>- la marge brute base variable de l'unité</p>
            <p>- la marge placement de l'unité</p>
            <p>Cliquez sur "lancer le calcul" et découvrez le montant de votre variable du mois.</p>
          </div>
        </div>
        <div className={styles.resultAutomatic}>
          <ComponentResult
            displayResult={!simulationManual}
            mutation={simulateFetchData}
            fetchStatus={simulateFetchStatus}
          />
        </div>

        <WithLightTitle className={styles.section} title="unité">
          <DropDown
            key={'_unit_'}
            disabled={disableAllInput}
            items={unitList ?? []}
            placeholder="choisir l'unité…"
            canBeReset={true}
            keyValueExtractor={(searchResult: string) => {
              return {
                key: searchResult,
                value: searchResult,
              };
            }}
            selectedItem={selectedUnit}
            onSelectItem={onSelectedUnit}
          />
        </WithLightTitle>

        <div className={styles.blockInput}>
          <WithLightTitle className={styles.section} title="marge brute variable">
            <TextInput
              value={margeBruteBaseVariableText}
              type={'number'}
              placeholder={'ex : 67'}
              onChange={(event: any) =>
                onSetMBVariable((event.target as HTMLTextAreaElement).value)
              }
              rightIcon={<div className={styles.uniteSmall}>{C_USE_K_EURO ? 'k' : ''}€</div>}
            />
          </WithLightTitle>

          <WithLightTitle
            className={styles.section}
            title={
              isOnSiteUnit(categoryFunction)
                ? 'chiffre d’affaires unité'
                : 'marge brute de placement'
            }
          >
            <TextInput
              value={margeBrutePlacementText}
              type={'number'}
              placeholder={'ex : 4'}
              onChange={(event: any) =>
                onSetMBPlacement((event.target as HTMLTextAreaElement).value)
              }
              rightIcon={<div className={styles.uniteSmall}>{C_USE_K_EURO ? 'k' : ''}€</div>}
            />
          </WithLightTitle>
        </div>

        {fetchStatus !== FETCH_STATUS.PENDING && (
          <div className={styles.blockInput}>
            <WithLightTitle className={styles.section} title="coef. de présence">
              <div className={styles.coefficient}>
                {coefficientOfPresence !== undefined ? coefficientOfPresence?.toFixed(0) : '--'}%
              </div>
            </WithLightTitle>

            <WithLightTitle className={styles.section} title="coef. analytique">
              <div className={styles.coefficient}>
                {selectedUnitData?.analyticalCoef?.toFixed(0) ?? '--'}%
              </div>
            </WithLightTitle>

            <WithLightTitle className={styles.section} title="Typologie">
              <div className={styles.coefficient}>{selectedUnitData?.typology ?? '--'}</div>
            </WithLightTitle>

            <WithLightTitle className={styles.section} title="FTE tranche">
              <div className={styles.coefficient}>{displayFteTranche()}</div>
            </WithLightTitle>
          </div>
        )}

        {fetchStatus === FETCH_STATUS.PENDING && <LoadingCard />}
        {/* SIMULATION MANUAL*/}

        <div>
          {
            <div className={styles.crossDebug}>
              <Button.Tertiary
                onClick={() => {
                  setExtendBlockSimulation(!extendBlockSimulation);
                }}
                className={styles.crossButton}
              >
                {!extendBlockSimulation && <PlusCircle></PlusCircle>}
                {extendBlockSimulation && <MoinsCircle></MoinsCircle>}
              </Button.Tertiary>
            </div>
          }
          {!extendBlockSimulation && (
            <div className={styles.titleDebug}>Simuler par une autre fonction</div>
          )}

          {extendBlockSimulation && (
            <>
              <div className={styles.blockDebug}>
                <div className={styles.blockDebugLine}>
                  <WithLightTitle className={styles.section} title="unit type">
                    <DropDown
                      key={'Type-for-sm'}
                      className={styles.inputFieldDebug}
                      items={unitTypeList}
                      placeholder="choisir le type de l'unité"
                      canBeReset={true}
                      keyValueExtractor={(item: string) => {
                        return { key: item, value: item };
                      }}
                      selectedItem={unitTypeForSM}
                      onSelectItem={(item?: string) => setUnitTypeForSM(item)}
                    />
                  </WithLightTitle>

                  <WithLightTitle className={styles.section} title="Function">
                    <div className={styles.functionContainer}>
                      <ComboBox
                        id="SelectFunction"
                        disableClearable
                        useNativeOptionFiltering
                        placeholder={'Toutes les catégories'}
                        value={categoryFunctionForSM}
                        searchResults={functionCategoriesFetchData.data ?? []}
                        keyValueExtractor={(item: any) => ({
                          key: item,
                          value: item.replace(/_/g, ' '),
                        })}
                        onChange={setCategoryFunctionForSM}
                        fetchStatus={functionCategoriesFetchStatus}
                        minLengthToSearch={0}
                      />
                    </div>
                  </WithLightTitle>

                  <div>
                    <FTEInput
                      fteScale={fteScaleForSM}
                      fteTranche={fteTrancheForSM}
                      onSelect={setFTETrancheForSM}
                      unitType={unitTypeForSM}
                      disabled={unitTypeForSM === undefined}
                    />
                    {unitTypeForSM === undefined && (
                      <div className={styles.error}>
                        <p>sélectionnez un type d'unité</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.blockDebugLine}>
                  <div>
                    <div> coef of analytic: 100%</div>
                    <div> coef of presence: 100%</div>
                    <div className={styles.footerButton}>
                      <Button
                        style={{ width: '150%' }}
                        onClick={() => {
                          onCalcul(true);
                        }}
                      >
                        lancer le calcul
                      </Button>
                    </div>
                  </div>

                  <div className={styles.resultManual}>
                    <ComponentResult
                      displayResult={simulationManual}
                      mutation={simulateFetchData}
                      fetchStatus={simulateFetchStatus}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {isDebugEnabled && (
          <div>
            {
              <div className={styles.crossDebug}>
                <Button.Tertiary
                  onClick={() => {
                    setModeDebug(!modeDebug);
                  }}
                  className={styles.crossButton}
                >
                  {!modeDebug && <PlusCircle></PlusCircle>}
                  {modeDebug && <MoinsCircle></MoinsCircle>}
                </Button.Tertiary>
                {modeDebug && (
                  <Button.Tertiary onClick={onRefreshData} className={styles.crossButton}>
                    <Refresh />
                  </Button.Tertiary>
                )}
              </div>
            }
            {!modeDebug && <div className={styles.titleDebug}>Simulation par ssid</div>}

            {modeDebug && (
              <>
                <div className={styles.blockDebug}>
                  <div className={styles.blockDebugLine}>
                    <WithLightTitle className={styles.section} title="ssid">
                      <TextInput
                        value={localSSID}
                        className={styles.inputFieldDebug}
                        placeholder={''}
                        onChange={(event: any) =>
                          onSetLocalSSID((event.target as HTMLTextAreaElement).value)
                        }
                      />
                    </WithLightTitle>

                    <WithLightTitle className={styles.section} title="Function">
                      <div className={styles.functionContainer}>
                        <ComboBox
                          id="SelectFunction"
                          disableClearable
                          useNativeOptionFiltering
                          placeholder={'Toutes les catégories'}
                          value={categoryFunction}
                          searchResults={functionCategoriesFetchData.data ?? []}
                          keyValueExtractor={(item: any) => ({
                            key: item,
                            value: item.replace(/_/g, ' '),
                          })}
                          onChange={setCategoryFunction}
                          fetchStatus={functionCategoriesFetchStatus}
                          minLengthToSearch={0}
                          disabled={true}
                        />
                      </div>
                    </WithLightTitle>

                    <FTEInput
                      fteScale={fteScale}
                      fteTranche={fteTranche}
                      onSelect={setFTETranche}
                      unitType={selectedUnitData?.typology
                        .toString()
                        .replaceAll(' ', '_')
                        .toUpperCase()}
                      disabled={true}
                    />
                  </div>
                  <div className={styles.blockDebugLine}>
                    <div>
                      <div> ssid: {consultantInformation?.siid}</div>
                      <div> name: {consultantInformation?.name}</div>
                      <div> rhpi function: {consultantInformation?.consultantFunction}</div>
                      <div> coef of presence: {consultantInformation?.coefOfPresence}</div>
                    </div>

                    <div>
                      <div> fte: {selectedUnitData?.fte}</div>
                      <div> coef analytic : {selectedUnitData?.analyticalCoef}</div>
                    </div>
                  </div>

                  <div className={styles.helpDebug}>
                    exemple de SSID: 2620 , T0007523 , 1677 , SI080224 | SI042129 (admin) | spécial
                    SSID: 12345678 ==&gt;(lvu unit=312€) (market Unit=409€) (onSiteUnit=214€) |
                    OnSite user: SI004343{' '}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {fetchStatus === FETCH_STATUS.REJECTED && (
          <div className={styles.error}>
            <img src={redCloud} alt="error-img" />
            <p>Une erreur est survenue</p>
          </div>
        )}

        <div
          className={classnames(styles.errorContainer, {
            [styles.expand]: error !== undefined,
          })}
        >
          <p className={styles.error}>{error}</p>
        </div>
        <div className={styles.footer}>
          <div className={styles.sideFooter}>
            <div className={styles.footerButton}>
              <Button.Secondary
                style={{ width: '90%' }}
                disabled={disableAllInput}
                onClick={onReset}
              >
                réinitialiser
              </Button.Secondary>
            </div>
            <div className={styles.footerButton}>
              <Button
                style={{ width: '90%' }}
                disabled={disableAllInput}
                onClick={() => {
                  onCalcul(false);
                }}
              >
                lancer le calcul
              </Button>
            </div>
            {consultantInformation?.isAdmin && (
              <div className={styles.footerButton}>
                <VariableSimulatorAdmin
                  trigger={<button>administration</button>}
                ></VariableSimulatorAdmin>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VariableSimulator;
