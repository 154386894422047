import classnames from 'classnames';

import { Props } from './KVLine.types';
import styles from './KVLine.module.scss';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';

const KVLine = ({ title, value, emptyMessage, textAlign }: Props) => (
  <WithLightTitle title={title} textAlign={textAlign}>
    <p
      className={classnames(styles.value, { [styles.emptyMessage]: value === undefined })}
      style={{ textAlign: textAlign || 'left' }}
    >
      {value || emptyMessage}
    </p>
  </WithLightTitle>
);

export default KVLine;
