import { z } from 'zod';
import {
  FetchTemporaryWorkersRegionalFilters,
  FetchTemporaryWorkersSort,
  FetchTemporaryWorkersSortCriteria,
  SortTypeEnum,
} from 'src/Services/API';

export interface RegionalTemporaryWorkersListProps {
  globalFilter: string;
  filters: FetchTemporaryWorkersRegionalFilters;
  sort: FetchTemporaryWorkersSort;
}

export const temporaryWorkersRegionalFiltersSchema: z.Schema<FetchTemporaryWorkersRegionalFilters> =
  z.object({
    qualifications: z.array(z.string()),
    distanceInKm: z.number().optional(),
  });

export const defaultRegionalFilters: z.infer<typeof temporaryWorkersRegionalFiltersSchema> = {
  qualifications: [],
  distanceInKm: 60,
};

export const temporaryWorkersRegionalSortSchema: z.Schema<FetchTemporaryWorkersSort> = z.object({
  sortCriteria: z.nativeEnum(FetchTemporaryWorkersSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultRegionalSort: z.infer<typeof temporaryWorkersRegionalSortSchema> = {
  sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
  sortType: SortTypeEnum.ASC,
};
