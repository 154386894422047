import {
  Button,
  PopupActions,
  PopupMenu,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  Bubble,
  CircleError,
  Eye,
  Flux,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { QueryKeys } from 'src/Hooks/types';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  EnumCandidateCandidateAdvancement,
  EnumCreateActionBodyActionTarget,
  MissionDetail,
} from 'src/Services/API';
import CandidateContactPopup from '../CandidateContactPopup';
import styles from './SuggestedCandidateActions.module.scss';
import { Props } from './SuggestedCandidateActions.types';

const SuggestedCandidateActions = ({ candidate, missionId, companyName }: Props) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const ref = useRef<PopupActions>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const addToMissionMutation = useAddCandidateToMissions({
    onSuccess: () => {
      queryClient.setQueryData(
        [QueryKeys.fetchMissionDetails, missionId],
        (oldData?: MissionDetail) => {
          if (oldData !== undefined) {
            return {
              ...oldData,
              suggestedCandidates: oldData?.suggestedCandidates?.filter(
                candidateSuggested => candidate.candidateId !== candidateSuggested.candidateId
              ),
              candidates: [
                ...(oldData?.candidates ?? []),
                {
                  ...candidate,
                  candidateAdvancement: EnumCandidateCandidateAdvancement.message,
                  lastContact: candidate.lastContact
                    ? {
                        ...candidate.lastContact,
                        at: candidate.lastContact?.at.toDate() ?? new Date(),
                      }
                    : undefined,
                },
              ],
            };
          }
        }
      );
      navigate(ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({ missionId }));
    },
    onError: () => {
      toast.error("désolé, une erreur s'est produite lors du rattachement");
    },
  });
  const smsAllowed = (candidate.flagAcceptSMS && !!candidate.phone2) ?? false;
  const callsAllowed =
    (candidate.flagAcceptSMS && (!!candidate.phone2 || !!candidate.phone1)) ?? false;
  const emailsAllowed = (candidate.flagAcceptEmailMission && !!candidate.email) ?? false;
  return (
    <PopupMenu
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      ref={ref}
      position={'left top'}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: open })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        text="voir le dossier"
        icon={<Eye />}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_RL_PUBLIC_URL}/candidate/${candidate.candidateId}/file/synthese`
          );
        }}
      />
      <PopupMenu
        position={'left top'}
        arrow={false}
        trigger={<PopupMenu.Item icon={<Bubble />} text="contacter" keepOpenOnClick hasSubMenu />}
      >
        <CandidateContactPopup
          contact={candidate}
          target={EnumCreateActionBodyActionTarget.CANDIDATE}
          smsAllowed={smsAllowed}
          callsAllowed={callsAllowed}
          emailsAllowed={emailsAllowed}
        />
      </PopupMenu>
      <PopupMenu.Item
        text="rattacher"
        icon={<Flux />}
        onClick={() => {
          addToMissionMutation.mutate({
            candidateId: candidate.candidateId ?? '',
            missionIds: [missionId],
          });
        }}
      />
      <PopupMenu.Item
        text="refuser"
        icon={<CircleError />}
        onClick={() =>
          navigate(MODAL_ROUTES.REFUSE_SUGGESTED_CANDIDATE.route.buildPath({}), {
            state: {
              background: location,
              modalParameters: {
                ...candidate,
                id: candidate.candidateId,
                companyName: companyName,
                missionId: missionId,
              },
            },
          })
        }
      />
    </PopupMenu>
  );
};

export default SuggestedCandidateActions;
