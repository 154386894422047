import { useMemo, useState } from 'react';

import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { throttle } from 'lodash-es';
import { useFetchQualifications } from 'src/Hooks/Qualifications/useFetchQualifications';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { Props } from './SelectQualification.types';

import styles from './SelectQualification.module.scss';

const SelectQualification = ({
  selectedQualification,
  setSelectedQualification,
  canBeReset,
  societyCode,
  brand,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  const { data, isSuccess, isError, isLoading } = useFetchQualifications({
    keyword,
    societyCode,
    brand,
  });

  return (
    <ComboBox
      id="SelectQualification"
      placeholder="1 caractère minimum"
      className={styles.qualificationsBox}
      disableClearable={!canBeReset}
      value={selectedQualification ?? null}
      searchResults={data ?? []}
      onSearch={keyword => throttledSetKeyword(keyword)}
      keyValueExtractor={({ id, label }) => ({
        key: id ?? 'unknown',
        value: label ?? 'Qualification inconnue',
      })}
      onChange={searchResult => {
        setSelectedQualification(searchResult ?? undefined);
      }}
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={1}
    />
  );
};

export default SelectQualification;
