import { FetchCdiiSort, FetchCdiiSortCriteria } from 'src/Hooks/Candidate/useFetchAgencyCdiis';
import { SortTypeEnum } from 'src/Services/API';
import { z } from 'zod';

export interface AgencyCdiiListProps {
  globalFilter: string;
  sort: FetchCdiiSort;
}

export const cdiiAgencySortSchema: z.Schema<FetchCdiiSort> = z.object({
  sortCriteria: z.nativeEnum(FetchCdiiSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultAgencySort: z.infer<typeof cdiiAgencySortSchema> = {
  sortCriteria: FetchCdiiSortCriteria.AVAILABILITY,
  sortType: SortTypeEnum.ASC,
};
