import { Check, Parameters } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button, Chip, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo, useState } from 'react';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { RegionalTemporaryWorkersFiltersProps } from './RegionalTemporaryWorkersFilters.types';
import { RegionalTemporaryWorkersFiltersModalContent } from './RegionalTemporaryWorkersFiltersModalContent';
import { DISTANCE_VALUES } from './RegionalTemporaryWorkersFiltersModalContent/RegionalTemporaryWorkersFiltersModalContent.types';

export const RegionalTemporaryWorkersFilters = ({
  filters,
  setFilters,
}: RegionalTemporaryWorkersFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersCount = useMemo(
    () => +Boolean(filters.qualifications.length) + 1, // for distanceInkm
    [filters.qualifications.length]
  );

  return (
    <>
      {Boolean(filters.qualifications.length) && (
        <Chip onClick={() => setIsOpen(true)} selected>
          <Check />
          {pluralFormat(filters.qualifications.length, 'qualification')}
        </Chip>
      )}
      <Chip onClick={() => setIsOpen(true)} selected>
        <Check />
        {filters.distanceInKm ?? Math.max(...DISTANCE_VALUES.filter(Boolean)).toString() + '+ '}km
      </Chip>

      <Button variant="tertiary" size="medium" onClick={() => setIsOpen(true)}>
        <Parameters />
        <Badge color="currentColor" size="xsmall">
          {filtersCount}
        </Badge>
      </Button>

      <Modal size="medium" open={isOpen} onClose={() => setIsOpen(false)}>
        <RegionalTemporaryWorkersFiltersModalContent
          onClose={() => setIsOpen(false)}
          filters={filters}
          setFilters={setFilters}
        />
      </Modal>
    </>
  );
};
