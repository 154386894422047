import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import {
  ArchiveWorkAccidentAnalysisParams,
  WorkAccidentAnalysisService,
  WorkAccidentAnalysisWithoutDetail,
} from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

/**
 * TODO have a single route for this in the backend
 */
export const useArchiveWorkAccidentAnalysis = (
  workAccidentAnalysis: WorkAccidentAnalysisWithoutDetail,
  status: 'TODO' | 'IN_PROGRESS',
  extraOptions?: UseMutationOptions<void, AxiosError, ArchiveWorkAccidentAnalysisParams, unknown>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.archiveWorkAccidentAnalysis],
    async body => {
      if (status === 'TODO') {
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerCreateWorkAccident({
          id: workAccidentAnalysis.id,
          body: {
            brandCode,
            agencyId,
            accidentId: workAccidentAnalysis.workAccidentId ?? '',
            candidateId: workAccidentAnalysis.candidate?.id ?? '',
            firstName: workAccidentAnalysis.candidate?.firstName ?? '',
            lastName: workAccidentAnalysis.candidate?.lastName ?? '',
            startDate: workAccidentAnalysis.startDate ?? new Date(),
            endDate: workAccidentAnalysis.endDate ?? new Date(),
            stoppageDuration: workAccidentAnalysis.stoppageDuration ?? 0,
            archivedComment: body.archivedComment,
            archivedReason: body.archivedReason,
          },
        });
      } else {
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerArchiveWorkAccidentAnalysis(
          {
            analysisId: workAccidentAnalysis.id,
            body,
          }
        );
      }
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentAnalyses]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
