import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, SearchInput } from '@randstad-lean-mobile-factory/react-components-core';
import { RegionalTemporaryWorkersFilters } from './RegionalTemporaryWorkersFilters';
import { RegionalTemporaryWorkersSortingMenu } from './RegionalTemporaryWorkersSortingMenu';
import { RegionalTemporaryWorkersTopBarContentProps } from './RegionalTemporaryWorkersTopBarContent.types';

export const RegionalTemporaryWorkersTopBarContent = ({
  globalFilter,
  setGlobalFilter,
  filters,
  setFilters,
  sort,
  setSort,
}: RegionalTemporaryWorkersTopBarContentProps) => (
  <>
    <RegionalTemporaryWorkersFilters filters={filters} setFilters={setFilters} />
    <SearchInput
      size="small"
      value={globalFilter}
      onValueChange={setGlobalFilter}
      placeholder="rechercher"
      collapsed
    />
    <RegionalTemporaryWorkersSortingMenu
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending />
        </Button>
      }
      sort={sort}
      setSort={setSort}
    />
  </>
);
