import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  CronJob,
  CronJobConfig,
  CronJobDetails,
  CronJobsService,
  UpdateCronJobConfigParams,
} from 'src/Services/API';
import { QueryKeys } from '../types';

export const useUpdateCronJobConfig = (
  cronJobId: CronJob,
  extraOptions?: UseMutationOptions<void, AxiosError, UpdateCronJobConfigParams, any>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async params => {
      await CronJobsService.cronJobsControllerUpdateCronJobConfig({
        cronJob: cronJobId,
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<CronJobConfig[]>([QueryKeys.fetchCronJobConfigs], data => {
          if (!data) return [{ cronJobId, ...variables }];
          return data.map(cronJob => {
            if (cronJob.cronJobId !== cronJobId) return cronJob;
            return { cronJobId, ...variables };
          });
        });
        queryClient.setQueryData<CronJobDetails>(
          [QueryKeys.fetchCronJobDetails, cronJobId],
          data => {
            if (!data) return;
            return { ...data, ...variables };
          }
        );
        queryClient.invalidateQueries([QueryKeys.fetchCronJobConfigs]);
        queryClient.invalidateQueries([QueryKeys.fetchCronJobDetails, cronJobId]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
