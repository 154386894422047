import {
  Badge,
  BadgeDeprecated,
  Button,
  HorizontalTextIconCard,
  SegmentedMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { getStatusColor, getStatusLabel, getStatusStyle } from 'src/Services/Missions';
import { convertPropertyToMoment } from 'src/Utils/moment';
import styles from './MissionDetail.module.scss';
import {
  GreenDarkBullet,
  InfoOutline,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Danger, Folder, UserAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { MISSION_TAB, Props, SKILL_MATCHING_MISSION_TAB } from './MissionDetail.types';
import MissionInformations from 'src/Components/MissionDetail/MissionInformations/MissionInformations.component';
import { pluralFormat } from 'src/Utils/pluralFormat';
import MissionTalents from 'src/Components/MissionDetail/MissionTalents';
import { DocumentLine } from 'src/Assets_DEPRECATED';
import { useDispatch } from 'react-redux';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { useFetchCandidateContracts } from 'src/Hooks/Candidate/useFetchCandidateContracts';
import TabSkillMatching from 'src/Routes/Talents/Cdii/Repositioning/RepositioningSkillMatching/TabSkillMatching/TabSkillMatching.component';
import { useFetchBlacklistedCompaniesByCandidate } from 'src/Hooks/Candidate/useFetchBlacklistedCompaniesByCandidate';
import { formatName } from 'src/Utils/formatName';

function formatDateRanges(startDate?: moment.Moment, endDate?: moment.Moment): string {
  return endDate
    ? `du ${startDate ? startDate.format('DD/MM/YYYY') : '--/--/--'} au ${endDate.format(
        'DD/MM/YYYY'
      )}`
    : `à partir du ${startDate ? startDate.format('DD/MM/YYYY') : '--/--/--'}`;
}

const LoadingMissionDetail = () => (
  <div className={styles.container} style={{ marginTop: '1rem' }}>
    <ContentLoader uniqueKey="loadingMissionDetail" height="20rem" width="100%">
      {[
        { width: '30%', height: '2rem', y: '0' },
        { width: '15%', height: '0.65rem', y: '3.5rem' },
        { width: '30%', height: '0.75rem', y: '4.65rem' },
        { width: '15%', height: '0.65rem', x: '85%', y: '3.5rem' },
        { width: '30%', height: '0.75rem', x: '70%', y: '4.65rem' },
        { width: '30%', height: '0.65rem', y: '6.4rem' },
        { width: '100%', height: '3rem', y: '7.55rem' },
        { width: '30%', height: '2rem', y: '13.05rem' },
        { width: '10%', height: '0.65rem', y: '16.05rem' },
        { width: '100%', height: '3rem', y: '17.2rem' },
      ].map((props, idx) => (
        <rect key={idx} x="0" rx="4" ry="4" {...props} />
      ))}
    </ContentLoader>
  </div>
);

const MissionDetail = ({
  missionId,
  candidateId,
  isSkillMatching,
  isRepositioningTalent,
  SkillMatchingMission,
}: Props) => {
  const mission_tab = isSkillMatching ? SKILL_MATCHING_MISSION_TAB : MISSION_TAB;
  const [selectedTab, setSelectedTab] = useState(mission_tab.TALENTS);
  const missionDetails = useFetchMissionDetails(missionId);
  const candidateContracts = useFetchCandidateContracts(candidateId ?? '');
  const candidatePastCompanies = candidateContracts.data
    ? candidateContracts.data?.map(contract => {
        return contract.companyName;
      })
    : undefined;

  const { data: blacklistedCompaniesByCandidate } =
    useFetchBlacklistedCompaniesByCandidate(candidateId);

  const isBlacklisted = useMemo(() => {
    const companyId = missionDetails.data?.company?.companyId;
    if (!companyId) return undefined;
    return blacklistedCompaniesByCandidate?.some(company => company.company?.id === companyId);
  }, [blacklistedCompaniesByCandidate, missionDetails.data?.company?.companyId]);

  const dispatch = useDispatch();
  const mission = useMemo(
    () =>
      missionDetails.data
        ? {
            ...convertPropertyToMoment(missionDetails.data, 'startDate', 'endDate'),
            candidates: missionDetails.data.candidates?.map(candidate => ({
              ...candidate,
              lastContact:
                candidate.lastContact && convertPropertyToMoment(candidate.lastContact, 'at'),
            })),
            contact: {
              ...missionDetails.data.contact,
              lastContact:
                missionDetails.data.contact?.lastContact &&
                convertPropertyToMoment(missionDetails.data.contact.lastContact, 'at'),
            },
            lastClientContact:
              missionDetails.data.lastClientContact &&
              convertPropertyToMoment(missionDetails.data.lastClientContact, 'at'),
          }
        : undefined,
    [missionDetails.data]
  );
  dispatch(missionsActions.setCurrentMissionId(missionId));

  const displayedSection = () => {
    switch (selectedTab) {
      case MISSION_TAB.INFORMATIONS:
        return (
          <>
            {missionDetails.isSuccess && <MissionInformations mission={missionDetails.data} />}
            {missionDetails.isLoading && <LoadingMissionDetail />}
          </>
        );

      case MISSION_TAB.TALENTS:
        return (
          <>
            {missionDetails.isSuccess && (
              <MissionTalents mission={mission} isRepositioningTalent={isRepositioningTalent} />
            )}
            {missionDetails.isLoading && <LoadingMissionDetail />}
          </>
        );
      case SKILL_MATCHING_MISSION_TAB.SKILL_MATCHING:
        return (
          <>
            {missionDetails.isSuccess && (
              <TabSkillMatching skillMatchingMission={SkillMatchingMission} />
            )}
            {missionDetails.isLoading && <LoadingMissionDetail />}
          </>
        );
    }
  };
  const redirectRecrutLiveURL = useCallback(() => {
    const baseUrl = process.env.REACT_APP_RL_PUBLIC_URL + '/?missionId=' + mission?.id;
    window.open(baseUrl);
  }, [mission?.id]);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionTitleContainer}>
          {mission?.company?.name && <GreenDarkBullet className={styles.greenBullet} />}
          <span className={styles.sectionTitle}> {mission?.company?.name}</span>

          {candidatePastCompanies?.includes(mission?.company?.name) && (
            <BadgeDeprecated
              leftIcon={<InfoOutline />}
              value={'déjà collaboré'}
              className={styles.badgeCollab}
            />
          )}
          <div className={styles.sectionTitleRight}>
            {isBlacklisted && (
              <Badge color="yellow" size="medium">
                <Danger variant="line" className={styles.dangerColor} />
                <span className={styles.textBlacklistBadge}>
                  ne plus déléguer
                  <br />
                  chez ce client
                </span>
              </Badge>
            )}
            <Button
              variant="secondary"
              className={!isRepositioningTalent ? styles.scopeButton : styles.scopeButtonTalent}
              leftIcon={!isRepositioningTalent ? <DocumentLine /> : <Folder />}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_SCOPE_DEEPLINK_SCHEME}://account?accountId=${mission?.company?.companyId}`
                );
              }}
            />
            {!isRepositioningTalent && (
              <Button.Primary className={styles.addCandidate} onClick={redirectRecrutLiveURL}>
                <UserAdd variant="line" className={styles.userAddLine} /> rattacher un talent
              </Button.Primary>
            )}
          </div>
        </div>

        <div className={styles.spaceBetweenLine} style={{ marginBottom: '1rem' }}>
          {mission?.company && (
            <div>
              <p className={styles.siretClass} style={{ textAlign: 'left' }}>
                {`num SIRET : ${mission?.company?.siret}  |  commande : ${
                  mission?.id
                }  |  ${formatDateRanges(mission?.startDate, mission?.endDate)} ${
                  mission?.endDate && mission?.startDate
                    ? `(${pluralFormat(
                        moment(mission.endDate).diff(moment(mission.startDate), 'day'),
                        'jour'
                      )})`
                    : ``
                }`}
              </p>
              <p className={styles.siretClass}>
                {`suivie par : ${
                  mission.monitoredByConsultant?.name
                    ? formatName({
                        firstName: mission.monitoredByConsultant?.firstName,
                        lastName: mission.monitoredByConsultant?.name,
                      })
                    : 'aucun consultant'
                } | service: ${mission?.service?.name ?? 'aucun service renseigné'}`}
              </p>
            </div>
          )}
          {!mission?.company && (
            <ContentLoader
              key={'company-infos-loader'}
              height={70}
              width="100%"
              uniqueKey={'company-infos-loader'}
            >
              <rect x="1%" y="10" rx="4" ry="4" width="50%" height="16" />
            </ContentLoader>
          )}
        </div>
        {missionDetails.isSuccess && (
          <div className={styles.topContainer}>
            <HorizontalTextIconCard
              labelClassName={styles.qualificationLabel}
              containerClassName={styles.qualificationContainer}
              label={mission?.qualification?.label ?? 'pas de qualification'}
              rightComponent={
                <Badge
                  className={classnames(getStatusStyle(mission?.salesPhase?.id), styles.badge)}
                  color={getStatusColor(mission?.salesPhase?.id)}
                >
                  <div>{getStatusLabel(mission?.salesPhase?.id)}</div>
                </Badge>
              }
            />
            {selectedTab !== SKILL_MATCHING_MISSION_TAB.SKILL_MATCHING && (
              <div className={styles.row}>
                <div className={styles.informationCard}>
                  <div className={styles.informationCardOverLabel}>lieu de délégation</div>
                  <div className={styles.informationCardLabel}>{`${
                    mission?.workLocation?.cityName &&
                    mission?.workLocation.zipCode &&
                    mission?.workLocation.address
                      ? `${mission?.workLocation ? mission?.workLocation?.zipCode : ''}, ${
                          mission?.workLocation ? mission?.workLocation?.cityName : ''
                        }, ${mission?.workLocation ? mission?.workLocation?.address : ''}`
                      : 'aucun lieu de délégation renseigné'
                  }`}</div>
                </div>
                <div className={styles.informationCard}>
                  <div className={styles.informationCardOverLabel}>salaire</div>
                  <div className={styles.informationCardLabel}>{`${
                    mission?.salary ? `${mission.salary}' €/h' ` : 'aucun salaire renseigné'
                  }`}</div>
                </div>
                <div className={styles.informationCard}>
                  <div className={styles.informationCardOverLabel}>début commande / horaires</div>
                  <div
                    className={classnames(
                      styles.informationCardLabel,
                      styles.informationCardStartDate
                    )}
                  >
                    <p>{`${
                      mission?.startDate
                        ? moment(mission?.startDate).format('DD/MM/YYYY')
                        : 'aucune date de début renseignée'
                    }`}</p>
                    <p>{` ${mission?.missionHours ?? 'aucun horaire renseigné'}`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {missionDetails.isLoading && (
          <>
            {[{ loaderKey: 1 }, { loaderKey: 2 }].map(element => {
              return (
                <ContentLoader
                  height="14rem"
                  width="100%"
                  className={styles.carousel}
                  key={`carousel-loader-${element.loaderKey}`}
                  uniqueKey={`carousel-loader-${element.loaderKey}`}
                >
                  <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
                  <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
                  <rect x="2%" y="50" rx="4" ry="4" width="80%" height="10" />
                </ContentLoader>
              );
            })}
          </>
        )}
      </div>

      <div
        style={{
          marginBottom: `24px`,
        }}
      >
        {isSkillMatching ? (
          <SegmentedMenu
            controls={Object.values(SKILL_MATCHING_MISSION_TAB)}
            selected={selectedTab}
            onSelectionChange={setSelectedTab}
            keyValueExtractor={key => {
              return {
                key: key,
                value: key,
                rightIcon:
                  key === SKILL_MATCHING_MISSION_TAB.TALENTS &&
                  missionDetails.isSuccess &&
                  !missionDetails.isError ? (
                    <div
                      className={styles.badgeContainer}
                      title={`${pluralFormat(
                        mission?.attachedCandidatesCount ?? 0,
                        'talent validé'
                      )} sur les ${pluralFormat(
                        mission?.numberPositions ?? 0,
                        'poste'
                      )} à pourvoir`}
                    >
                      <BadgeDeprecated
                        value={`${mission?.attachedCandidatesCount}/${mission?.numberPositions ?? 0}`}
                        className={styles.countBadge}
                      />
                    </div>
                  ) : undefined,
              };
            }}
            bottomBarClassName={styles.bottomMenuBar}
            controlItemClassName={styles.controlsLabel}
            className={styles.menuContainer}
          />
        ) : (
          <SegmentedMenu
            displayVerticalSeparator={true}
            controls={Object.values(MISSION_TAB)}
            selected={selectedTab}
            onSelectionChange={setSelectedTab}
            keyValueExtractor={key => {
              return {
                key: key,
                value: key,
                rightIcon:
                  key === MISSION_TAB.TALENTS &&
                  missionDetails.isSuccess &&
                  !missionDetails.isError ? (
                    <div
                      className={styles.badgeContainer}
                      title={`${pluralFormat(
                        mission?.attachedCandidatesCount ?? 0,
                        'talent validé'
                      )} sur les ${pluralFormat(
                        mission?.numberPositions ?? 0,
                        'poste'
                      )} à pourvoir`}
                    >
                      <BadgeDeprecated
                        value={`${mission?.attachedCandidatesCount}/${mission?.numberPositions ?? 0}`}
                        className={styles.countBadge}
                      />
                    </div>
                  ) : undefined,
              };
            }}
            bottomBarClassName={styles.bottomMenuBar}
            controlItemClassName={styles.controlsLabel}
            className={styles.menuContainer}
          />
        )}
      </div>
      {displayedSection()}

      <div className={styles.footer} />
    </div>
  );
};

export default MissionDetail;
