import { useEffect, useMemo, useState } from 'react';

import {
  CircleAdd,
  CircleCheck,
  Dustbin,
  Eye,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  DatePickerInputDeprecated,
  DropDown,
  Modal,
  ModalActions,
  ModalContent,
  RadioButtonGroup,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment/moment';
import { useController, UseFormReturn } from 'react-hook-form';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useFinalizeWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFinalizeWorkAccidentAnalysis';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumWorkAccidentAnalysisStatus, SigningStatusEnum } from 'src/Services/API';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { openFile } from 'src/Utils/openFile';
import styles from './Conclusion.module.scss';
import { conclusionSchema, ConclusionSchemaType } from './Conclusion.schema';
import {
  ClientSigningStatusRadioButtons,
  Signatory,
  VictimSigningStatusRadioButtons,
} from './Conclusion.types';
import { CreateSignatureModal } from './SignatureModals/CreateSignatureModal';
import { DeleteSignatureModal } from './SignatureModals/DeleteSignatureModal';
import { formatName } from 'src/Utils/formatName';
import { useFetchWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalysis';
import { DeleteAlerte } from '@randstad-lean-mobile-factory/react-assets/dist/headers';

export const ConclusionRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONCLUSION
  );
  const currentAnalysis = useFetchWorkAccidentAnalysis(analysisId);
  const isEditable = currentAnalysis.data?.status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;

  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis(analysisId);
  const [showAdditionalSigningPersonForm, setShowAdditionalSigningPersonForm] = useState(false);
  const [additionalSignataireName, setAdditionalSignataireName] = useState('');
  const [additionalSignataireRole, setAdditionalSignataireRole] = useState('');
  const [isCreateConsultantSignatureModalOpen, setIsCreateConsultantSignatureModalOpen] =
    useState(false);
  const [isCreateClientSignatureModalOpen, setIsCreateClientSignatureModalOpen] = useState(false);
  const [isCreateVictimSignatureModalOpen, setIsCreateVictimSignatureModalOpen] = useState(false);
  const [isCreateAdditionalSignatureModalOpen, setIsCreateAdditionalSignatureModalOpen] =
    useState(false);
  const [isDeleteConsultantSignatureModalOpen, setIsDeleteConsultantSignatureModalOpen] =
    useState(false);
  const [isDeleteClientSignatureModalOpen, setIsDeleteClientSignatureModalOpen] = useState(false);
  const [isDeleteVictimSignatureModalOpen, setIsDeleteVictimSignatureModalOpen] = useState(false);
  const [isDeleteAdditionalSignatureModalOpen, setIsDeleteAdditionalSignatureModalOpen] =
    useState(false);

  const randstadContacts = useFetchAgencyConsultants(
    currentAnalysis.data?.agencyId ?? '',
    currentAnalysis.data?.brandCode
  );
  const clientContacts = useFetchCompanyContacts(currentAnalysis.data?.company?.id ?? '');

  const finalizeWorkAccidentAnalysis = useFinalizeWorkAccidentAnalysis(analysisId, {
    onSuccess: () => {
      setTimeout(() => setIsFinalizeModalOpen(false), 500);
    },
    onError: () => {
      setTimeout(() => finalizeWorkAccidentAnalysis.reset(), 500);
    },
  });

  const { control, setValue, handleSubmit, watch } = useFormWithZodResolver({
    schema: conclusionSchema,
    defaultValues: {
      comments: undefined,
      dateOfSignature: undefined,
      victimSigningPerson: undefined,
      randstadSigningPerson: undefined,
      clientSigningPerson: undefined,
      additionalSigningPerson: undefined,
    },
  }) as UseFormReturn<ConclusionSchemaType>;

  const submitConclusion = useMemo(
    () =>
      handleSubmit(async (values: ConclusionSchemaType) => {
        if (isEditable) {
          updateWorkAnalysisMutation.mutate({
            conclusion: {
              comments: values.comments,
              dateOfSignature: values.dateOfSignature,
              victimSigningPerson: values.victimSigningPerson,
              randstadSigningPerson: values.randstadSigningPerson,
              clientSigningPerson: values.clientSigningPerson,
              additionalSigningPerson: values.additionalSigningPerson,
            },
          });
        }
      }),
    [handleSubmit, isEditable, updateWorkAnalysisMutation]
  );

  const comments = useController({ control, name: 'comments' });
  const dateOfSignature = useController({ control, name: 'dateOfSignature' });
  const victimSigningPerson = useController({ control, name: 'victimSigningPerson' });
  const randstadSigningPerson = useController({ control, name: 'randstadSigningPerson' });
  const clientSigningPerson = useController({ control, name: 'clientSigningPerson' });
  const additionalSigningPerson = useController({ control, name: 'additionalSigningPerson' });

  const hasDateOfSignature = !!dateOfSignature.field.value;
  const hasValidConsultantSignature = !!currentAnalysis?.data?.randstadConsultantSignature;
  const hasValidClientSignature = !!currentAnalysis?.data?.clientSignature;
  const hasValidVictimSignature = !!currentAnalysis?.data?.victimSignature;
  const hasValidAdditionalSignature =
    !showAdditionalSigningPersonForm || !!currentAnalysis?.data?.additionalSignature;
  const hasUnsigningClient =
    clientSigningPerson.field.value?.clientSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    clientSigningPerson.field.value?.clientSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const hasUnsigningVictim =
    victimSigningPerson.field.value?.victimSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    victimSigningPerson.field.value?.victimSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const isDisabled = !(
    hasDateOfSignature &&
    hasValidAdditionalSignature &&
    (hasValidVictimSignature || hasUnsigningVictim) &&
    hasValidConsultantSignature &&
    (hasValidClientSignature || hasUnsigningClient)
  );

  useEffect(() => {
    if (currentAnalysis.data?.conclusion !== undefined) {
      setValue('comments', currentAnalysis.data?.conclusion.comments);
      setValue(
        'dateOfSignature',
        currentAnalysis.data?.conclusion?.dateOfSignature
          ? new Date(currentAnalysis.data?.conclusion.dateOfSignature)
          : new Date()
      );

      setValue('randstadSigningPerson', {
        personName: currentAnalysis.data.conclusion.randstadSigningPerson?.personName,
        id: currentAnalysis.data.conclusion.randstadSigningPerson?.id,
        moment: currentAnalysis.data.conclusion?.randstadSigningPerson?.moment
          ? new Date(currentAnalysis.data.conclusion.randstadSigningPerson.moment)
          : undefined,
        signed: !!currentAnalysis.data.clientSignature,
      });
      setValue('clientSigningPerson', {
        personName: currentAnalysis.data.conclusion.clientSigningPerson?.personName,
        id: currentAnalysis.data.conclusion.clientSigningPerson?.id,
        moment: currentAnalysis.data.conclusion.clientSigningPerson?.moment
          ? new Date(currentAnalysis.data.conclusion.clientSigningPerson.moment)
          : undefined,
        signed: !!currentAnalysis.data.clientSignature,
        clientSigningStatus:
          currentAnalysis.data.conclusion.clientSigningPerson?.clientSigningStatus,
      });
      setValue('victimSigningPerson', {
        moment: currentAnalysis.data.conclusion.victimSigningPerson?.moment
          ? new Date(currentAnalysis.data.conclusion.victimSigningPerson.moment)
          : undefined,
        signed: !!currentAnalysis.data.victimSignature,
        victimSigningStatus:
          currentAnalysis.data.conclusion.victimSigningPerson?.victimSigningStatus,
      });
      setValue('additionalSigningPerson', {
        personName: currentAnalysis.data.conclusion.additionalSigningPerson?.personName,
        personRole: currentAnalysis.data.conclusion.additionalSigningPerson?.personRole,
        moment: currentAnalysis.data.conclusion.additionalSigningPerson?.moment
          ? new Date(currentAnalysis.data.conclusion.additionalSigningPerson.moment)
          : undefined,
        signed: !!currentAnalysis.data.additionalSignature,
      });
    } else if (currentAnalysis.isSuccess) {
      setValue('dateOfSignature', new Date());
    }
  }, [currentAnalysis.data, currentAnalysis.isSuccess, setValue]);

  useEffect(() => {
    const subscription = watch(() => submitConclusion());
    return () => subscription.unsubscribe();
  }, [submitConclusion, watch]);

  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>5.</div>
      <div className={styles.content}>
        <div className={styles.title}>conclusion</div>
        <div className={styles.subTitle}>observations, commentaires (EU, TT, agence)</div>
        <WithLightTitle
          title="ajouter toutes observations ou commentaires nécessaires des différentes parties"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable && (
              <div className={styles.numberOfChar}>
                {comments.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            )
          }
        >
          {isEditable ? (
            <TextArea
              value={comments.field.value}
              className={styles.textArea}
              placeholder={'en quelques mots…'}
              onChange={(event: any) =>
                comments.field.onChange((event.target as HTMLTextAreaElement).value)
              }
            />
          ) : (
            <div>{comments.field.value ?? 'non renseigné'}</div>
          )}
        </WithLightTitle>
        <div className={styles.divider} />
        <div className={styles.subTitle}>signatures</div>
        <WithLightTitle
          title="date"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <DatePickerInputDeprecated
                date={dateOfSignature.field.value}
                onSelectDate={date => dateOfSignature.field.onChange(date)}
                minDate={moment().subtract(1, 'month').toDate()}
                maxDate={moment().add(1, 'month').toDate()}
              />
            ) : (
              <div>
                {dateOfSignature.field.value
                  ? moment(dateOfSignature.field.value).format('DD/MM/YYYY')
                  : 'non renseigné'}
              </div>
            )
          }
        />
        <WithLightTitle title="signataire randstad" className={styles.lightTitle}>
          {currentAnalysis?.data?.randstadConsultantSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data.conclusion?.randstadSigningPerson?.moment
                  ? moment(currentAnalysis.data.conclusion?.randstadSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data.conclusion?.randstadSigningPerson?.personName
                  ? capitalizeNames(
                      currentAnalysis.data.conclusion?.randstadSigningPerson?.personName
                    )
                  : 'une personne inconnue'}
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data.randstadConsultantSignature?.buffer as
                    | string
                    | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteConsultantSignatureModalOpen(true)}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : (
            <>
              <DropDown
                items={randstadContacts.data?.map(formatName).filter(Boolean) ?? []}
                placeholder="nom d'un consultant Randstad"
                canBeReset={true}
                className={styles.dropdown}
                childrenClassName={styles.childDropdown}
                keyValueExtractor={item => ({ key: item, value: item })}
                selectedItem={randstadSigningPerson.field.value?.personName}
                onSelectItem={(contactName?: string) =>
                  randstadSigningPerson.field.onChange({
                    ...randstadSigningPerson.field.value,
                    personName: contactName,
                    id: randstadContacts.data?.find(contact => formatName(contact) === contactName)
                      ?.consultantId,
                  })
                }
              />
              <Button
                variant="primary"
                onClick={() => setIsCreateConsultantSignatureModalOpen(true)}
                disabled={!currentAnalysis.data?.conclusion?.randstadSigningPerson?.id}
              >
                signature consultant
              </Button>
            </>
          )}
          <CreateSignatureModal
            open={isCreateConsultantSignatureModalOpen}
            onClose={() => setIsCreateConsultantSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CONSULTANT}
          />
          <DeleteSignatureModal
            open={isDeleteConsultantSignatureModalOpen}
            onClose={() => setIsDeleteConsultantSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CONSULTANT}
            personName={currentAnalysis.data?.conclusion?.randstadSigningPerson?.personName}
          />
        </WithLightTitle>

        <WithLightTitle title="signataire côté client" className={styles.lightTitle}>
          {currentAnalysis.data?.clientSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data.conclusion?.clientSigningPerson?.moment
                  ? moment(currentAnalysis.data.conclusion?.clientSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data.conclusion?.clientSigningPerson?.personName !== undefined
                  ? capitalizeNames(currentAnalysis.data.conclusion?.clientSigningPerson.personName)
                  : 'une personne inconnue'}
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data.clientSignature?.buffer as string | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setIsDeleteClientSignatureModalOpen(true);
                  }}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : clientSigningPerson.field.value?.clientSigningStatus ===
              SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
            <div>Le client ne peut pas signer.</div>
          ) : clientSigningPerson.field.value?.clientSigningStatus ===
              SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
            <div>Le client ne veut pas signer.</div>
          ) : (
            <>
              <RadioButtonGroup
                name="clientSigningStatus"
                radioButtonsInfo={ClientSigningStatusRadioButtons}
                onChange={(_, id) =>
                  clientSigningPerson.field.onChange({
                    ...clientSigningPerson.field.value,
                    clientSigningStatus:
                      ClientSigningStatusRadioButtons.find(button => button.id === id)?.value ??
                      undefined,
                  })
                }
                selected={clientSigningPerson.field.value?.clientSigningStatus}
              />
              {clientSigningPerson.field.value?.clientSigningStatus === SigningStatusEnum.SIGNS && (
                <>
                  <DropDown
                    items={clientContacts.data?.map(formatName).filter(Boolean) ?? []}
                    placeholder="nom d'un interlocuteur"
                    canBeReset={true}
                    className={styles.dropdown}
                    childrenClassName={styles.childDropdown}
                    keyValueExtractor={item => ({ key: item, value: item })}
                    selectedItem={clientSigningPerson.field.value?.personName}
                    onSelectItem={(item?: string) =>
                      clientSigningPerson.field.onChange({
                        ...clientSigningPerson.field.value,
                        personName: item,
                        id: clientContacts.data?.find(contact => formatName(contact) === item)
                          ?.contactId,
                      })
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => setIsCreateClientSignatureModalOpen(true)}
                    disabled={!currentAnalysis.data?.conclusion?.clientSigningPerson?.id}
                  >
                    signature client
                  </Button>
                </>
              )}
            </>
          )}
          <CreateSignatureModal
            open={isCreateClientSignatureModalOpen}
            onClose={() => setIsCreateClientSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CLIENT}
          />
          <DeleteSignatureModal
            open={isDeleteClientSignatureModalOpen}
            onClose={() => setIsDeleteClientSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CLIENT}
            personName={currentAnalysis.data?.conclusion?.clientSigningPerson?.personName}
          />
        </WithLightTitle>

        <WithLightTitle title="signataire intérimaire" className={styles.lightTitle}>
          {currentAnalysis.data?.victimSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data.conclusion?.victimSigningPerson?.moment
                  ? moment(currentAnalysis.data.conclusion?.victimSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {formatName(currentAnalysis.data.candidate) ?? 'une personne inconnue'}
                <Button
                  variant="tertiary"
                  onClick={() => {
                    const data = currentAnalysis.data.victimSignature?.buffer as string | undefined;
                    if (!data) return;
                    openFile(Buffer.from(data, 'base64'), 'image/png');
                  }}
                >
                  <Eye />
                </Button>
                {isEditable && (
                  <Button
                    variant="tertiary"
                    onClick={() => setIsDeleteVictimSignatureModalOpen(true)}
                  >
                    <Dustbin variant="line" color="blue" />
                  </Button>
                )}
              </div>
            </div>
          ) : victimSigningPerson.field.value?.victimSigningStatus ===
              SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
            <div>L'intérimaire ne peut pas signer.</div>
          ) : victimSigningPerson.field.value?.victimSigningStatus ===
              SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
            <div>L'intérimaire ne veut pas signer.</div>
          ) : (
            <>
              <div>
                <RadioButtonGroup
                  name="victimSigningStatus"
                  radioButtonsInfo={VictimSigningStatusRadioButtons}
                  onChange={(_, id) =>
                    victimSigningPerson.field.onChange({
                      ...victimSigningPerson.field.value,
                      victimSigningStatus:
                        VictimSigningStatusRadioButtons.find(button => button.id === id)?.value ??
                        undefined,
                    })
                  }
                  selected={victimSigningPerson.field.value?.victimSigningStatus}
                />
              </div>
              {isEditable &&
                victimSigningPerson.field.value?.victimSigningStatus ===
                  SigningStatusEnum.SIGNS && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsCreateVictimSignatureModalOpen(true);
                    }}
                  >
                    signature intérimaire
                  </Button>
                )}
            </>
          )}
          <CreateSignatureModal
            open={isCreateVictimSignatureModalOpen}
            onClose={() => setIsCreateVictimSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.VICTIM}
          />
          <DeleteSignatureModal
            open={isDeleteVictimSignatureModalOpen}
            onClose={() => setIsDeleteVictimSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.VICTIM}
            personName={formatName(currentAnalysis.data?.candidate)}
          />
        </WithLightTitle>

        <WithLightTitle
          title="signataire supplémentaire"
          className={styles.additionalSigningPerson}
        >
          {currentAnalysis.data?.additionalSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data.conclusion?.additionalSigningPerson?.moment
                  ? moment(currentAnalysis.data.conclusion?.additionalSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data.conclusion?.additionalSigningPerson?.personName
                  ? capitalizeNames(
                      currentAnalysis.data.conclusion?.additionalSigningPerson?.personName
                    )
                  : 'une personne inconnue'}{' '}
                (rôle :{' '}
                {currentAnalysis.data.conclusion?.additionalSigningPerson?.personRole ?? 'inconnu'})
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data.additionalSignature?.buffer as
                    | string
                    | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteAdditionalSignatureModalOpen(true)}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : (
            isEditable &&
            (showAdditionalSigningPersonForm ? (
              <>
                <div className={styles.additionalSigningPersonTextInputContainer}>
                  <TextInput
                    type="text"
                    value={additionalSignataireName}
                    onValueChange={newValue => {
                      setAdditionalSignataireName(newValue);
                      additionalSigningPerson.field.onChange({
                        ...additionalSigningPerson.field.value,
                        personName: newValue,
                      });
                    }}
                    placeholder="nom du signataire supplémentaire"
                    containerClassName={styles.additionalSigningPersonTextInput}
                  />
                  <TextInput
                    type="text"
                    value={additionalSignataireRole}
                    onValueChange={newValue => {
                      setAdditionalSignataireRole(newValue);
                      additionalSigningPerson.field.onChange({
                        ...additionalSigningPerson.field.value,
                        personRole: newValue,
                      });
                    }}
                    placeholder="rôle du signataire supplémentaire"
                    containerClassName={styles.additionalSigningPersonTextInput}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() => setIsCreateAdditionalSignatureModalOpen(true)}
                  disabled={
                    !currentAnalysis.data?.conclusion?.additionalSigningPerson?.personName ||
                    !currentAnalysis.data?.conclusion?.additionalSigningPerson?.personRole
                  }
                >
                  signature additionnelle
                </Button>
              </>
            ) : (
              <Button variant="tertiary" onClick={() => setShowAdditionalSigningPersonForm(true)}>
                <CircleAdd variant="fill" /> ajouter une signature
              </Button>
            ))
          )}
          <CreateSignatureModal
            open={isCreateAdditionalSignatureModalOpen}
            onClose={() => setIsCreateAdditionalSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.ADDITIONAL}
          />
          <DeleteSignatureModal
            open={isDeleteAdditionalSignatureModalOpen}
            onClose={() => setIsDeleteAdditionalSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.ADDITIONAL}
            personName={currentAnalysis.data?.conclusion?.additionalSigningPerson?.personName}
          />
        </WithLightTitle>

        {isEditable && (
          <Button
            variant="secondary"
            onClick={() => setIsFinalizeModalOpen(true)}
            disabled={isDisabled}
            className={styles.lightTitle}
          >
            clôturer l'analyse AT
          </Button>
        )}
      </div>

      <Modal size="medium" open={isFinalizeModalOpen} onClose={() => setIsFinalizeModalOpen(false)}>
        <ModalContent header={<DeleteAlerte />} title="attention">
          <WithLightTitle title={moment().format('[le] L [à] LT')}>
            Vous vous apprêtez à finaliser cette analyse AT, elle va maintenant être transmise au
            service at et une copie va être ajoutée au dossier osmose du client
          </WithLightTitle>
        </ModalContent>
        <ModalActions side="right">
          <Button variant="secondary" onClick={() => setIsFinalizeModalOpen(false)}>
            annuler
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() =>
              finalizeWorkAccidentAnalysis.mutate({
                candidateName: formatName(currentAnalysis.data?.candidate) ?? '',
                companyId: currentAnalysis.data?.company?.id ?? '',
                createdAt: moment(new Date()).toISOString(),
                agencyId: currentAnalysis.data?.agencyId ?? '',
                randstadSignatory:
                  currentAnalysis.data?.conclusion?.randstadSigningPerson?.id ?? '',
                clientSignatory: currentAnalysis.data?.conclusion?.clientSigningPerson?.id ?? '',
              })
            }
            mutationStatus={finalizeWorkAccidentAnalysis.status}
          >
            confirmer
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
