import { Filtres } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  ModalActions,
  ModalContent,
  Slider,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import SelectMultipleQualifications from 'src/Components/Selects/SelectMultipleQualifications';
import { defaultRegionalFilters } from '../../../RegionalTemporaryWorkersList/RegionalTemporaryWorkersList.types';
import styles from './RegionalTemporaryWorkersFiltersModalContent.module.scss';
import {
  DISTANCE_VALUES,
  RegionalTemporaryWorkersFiltersModalProps,
} from './RegionalTemporaryWorkersFiltersModalContent.types';

export const RegionalTemporaryWorkersFiltersModalContent = ({
  filters,
  setFilters,
  onClose,
}: RegionalTemporaryWorkersFiltersModalProps) => {
  const [localQualifications, setLocalQualifications] = useState(filters.qualifications);
  const [localDistance, setLocalDistance] = useState(
    DISTANCE_VALUES.includes(filters.distanceInKm)
      ? DISTANCE_VALUES.findIndex(d => d === filters.distanceInKm)
      : 4
  );

  return (
    <>
      <ModalContent header={<Filtres />} title="filtres talents de ma région">
        <div className={styles.label}>
          quelles sont les qualifications que vous souhaitez voir en priorité ? (10 maximum)
        </div>
        <SelectMultipleQualifications
          selectedQualifications={localQualifications}
          setSelectedQualifications={setLocalQualifications}
        />

        <div className={styles.label}>
          quelle distance kilométrique maximum entre votre agence et l'agence de rattachement du
          talent ?
        </div>
        <Slider
          min={-0.5}
          max={7.5}
          defaultValue={0}
          value={localDistance}
          onChange={(_, value) => {
            setLocalDistance(value as number);
          }}
          marks={DISTANCE_VALUES.map((value, index) => ({
            value: index,
            label:
              value?.toString() ?? Math.max(...DISTANCE_VALUES.filter(Boolean)).toString() + '+',
          }))}
          step={null}
        />
      </ModalContent>
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setLocalQualifications(defaultRegionalFilters.qualifications);
            setLocalDistance(
              DISTANCE_VALUES.includes(defaultRegionalFilters.distanceInKm)
                ? DISTANCE_VALUES.findIndex(d => d === defaultRegionalFilters.distanceInKm)
                : 4
            );
          }}
        >
          tout réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          onClick={() => {
            const qualifications = localQualifications;
            const distanceInKm = DISTANCE_VALUES[localDistance];
            setFilters({ qualifications, distanceInKm });
            onClose?.();
          }}
        >
          valider
        </Button>
      </ModalActions>
    </>
  );
};
