import { FetchCdiiSort, FetchCdiiSortCriteria } from 'src/Hooks/Candidate/useFetchAgencyCdiis';
import { SortTypeEnum } from 'src/Services/API';
import { z } from 'zod';

export interface RepositioningCdiiListProps {
  globalFilter: string;
  sort: FetchCdiiSort;
}

export const cdiiRepositioningSortSchema: z.Schema<FetchCdiiSort> = z.object({
  sortCriteria: z.nativeEnum(FetchCdiiSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultRepositioningSort: z.infer<typeof cdiiRepositioningSortSchema> = {
  sortCriteria: FetchCdiiSortCriteria.AVAILABILITY,
  sortType: SortTypeEnum.ASC,
};
