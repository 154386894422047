import { uniqBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFetchAgencyCdiis } from 'src/Hooks/Candidate/useFetchAgencyCdiis';
import { CdiiSource } from '../CdiiList.types';
import { CdiiTable } from '../CdiiTable';
import styles from './AgencyCdiiList.module.scss';
import { AgencyCdiiListProps } from './AgencyCdiiList.types';

export const AgencyCdiiList = ({ globalFilter, sort }: AgencyCdiiListProps) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useFetchAgencyCdiis({ sort });

  const cdiis = useMemo(
    () =>
      uniqBy(
        data?.pages.flatMap(page => page.cdiis),
        cdii => cdii.id
      ),
    [data]
  );

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll, globalFilter]);

  useEffect(() => {
    if (cdiis?.length === 0 && hasNextPage) fetchNextPage();
  }, [cdiis, hasNextPage, fetchNextPage]);

  return (
    <div className={styles.table} onScroll={handleScroll} ref={scrollViewRef}>
      <CdiiTable
        globalFilter={globalFilter}
        source={CdiiSource.agency}
        cdiis={cdiis}
        isLoading={isLoading}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </div>
  );
};
