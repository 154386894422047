import styles from './AgencyCard.module.scss';
import { Button, Checkbox, Table } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './AgencyCard.types';
import { CGCAgencyPropositionModal } from '../CGCAgencyPropositionModal';
import { useState } from 'react';
import { AgencyWithDistance } from 'src/Services/API';
import { formatCity } from 'src/Utils/formatCity';
import { formatAddress } from 'src/Utils/formatAddress';

/** @deprecated we should use a DataTable instead */
export const AgencyCard = ({
  agencies,
  candidateData,
  selectedAgencies,
  setSelectedAgencies,
}: Props) => {
  const ppaHeaders = [
    { label: '' },
    { label: "nom de l'unité" },
    { label: 'qualification potentielle' },
    { label: 'adresse unité' },
    { label: 'actions' },
  ];

  const [contactedAgency, setContactedAgency] = useState<AgencyWithDistance>();

  return (
    <div>
      <Table
        headers={ppaHeaders}
        className={styles.table}
        data={agencies.map(agency => {
          const isSelected = selectedAgencies.some(
            agencyData => agencyData.agencyId === agency.agencyId
          );
          return [
            <div className={styles.checkbox} key={0}>
              <Checkbox
                checked={isSelected}
                onClick={() => {
                  isSelected
                    ? setSelectedAgencies(
                        selectedAgencies.filter(
                          agencyItem => agency.agencyId !== agencyItem.agencyId
                        )
                      )
                    : setSelectedAgencies([...selectedAgencies, agency]);
                }}
              />
            </div>,
            <div className={styles.columnCell} key={1}>
              <p className={styles.textContainerGrey}>{agency.brandName}</p>
              <p className={styles.textContainer}>{agency.serviceCode}</p>
            </div>,
            <p className={styles.textContainerQualification} key={2}>
              pas de qualification pour le moment
            </p>,
            <div className={styles.columnCell} key={3}>
              <p className={styles.addressContainer}>
                {agency.distance !== undefined && (
                  <>
                    <div className={styles.textContainer}>{agency.distance} km</div>
                    <div className={styles.verticalSeparator} />
                    <div className={styles.textContainer}>
                      {formatAddress(agency) ?? 'adresse non renseignée'}
                    </div>
                  </>
                )}
              </p>
              <p className={styles.textContainer}>{formatCity(agency) ?? 'ville non renseigné'}</p>
            </div>,
            <Button
              key={4}
              size="medium"
              variant="tertiary"
              onClick={event => {
                event.stopPropagation();
                setContactedAgency(agency);
              }}
            >
              contacter l'agence
            </Button>,
          ];
        })}
      />
      <CGCAgencyPropositionModal
        candidateData={candidateData}
        selectedAgencies={contactedAgency ? [contactedAgency] : []}
        open={Boolean(contactedAgency)}
        onClose={() => setContactedAgency(undefined)}
      />
    </div>
  );
};
