import {
  BubblesChat,
  DocumentPdf,
  Email,
  Inversion,
  OtherHorizontal,
  Refresh,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Menu,
  MenuActions,
  MenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGeneratePreventionSheet } from 'src/Hooks/Candidate/useGeneratePreventionSheet';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { CandidateWithMoment, FETCH_STATUS } from 'src/Redux/Types';
import {
  EnumCandidateCandidateAdvancement,
  EnumCandidateCompanyAdvancement,
  EnumCreateActionBodyActionTarget,
  MissionDetail,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { openFile } from 'src/Utils/openFile';
import { useFetchMissionDetails } from '../../Hooks/Missions/useFetchMissionDetails';
import CandidateContactPopup from '../CandidateContactPopup';
import styles from './PopOverContact.module.scss';
import { Props } from './PopOverContact.types';

const PopOverContact = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const generatePreventionSheet = useGeneratePreventionSheet();
  const ref = useRef<MenuActions>(null);

  const { data, isSuccess } = useFetchMissionDetails(
    props.target === EnumCreateActionBodyActionTarget.CANDIDATE ? props.missionId : undefined
  );

  let smsAllowed = true;
  let callsAllowed = true;
  let emailsAllowed = true;
  if (props.target === EnumCreateActionBodyActionTarget.CONTACT) {
    smsAllowed = !props.contact.dontCallMe && !!props.contact.mobilePhoneNumber;
    callsAllowed =
      !props.contact.dontCallMe && (!!props.contact.phone || !!props.contact.mobilePhoneNumber);
    emailsAllowed = !props.contact.dontMailMe && !!props.contact.email;
  }
  if (props.target === EnumCreateActionBodyActionTarget.CANDIDATE) {
    smsAllowed = (props.contact.flagAcceptSMS && !!props.contact.phone2) ?? false;
    callsAllowed =
      (props.contact.flagAcceptSMS && (!!props.contact.phone2 || !!props.contact.phone1)) ?? false;
    emailsAllowed = (props.contact.flagAcceptEmailMission && !!props.contact.email) ?? false;
  }
  const notDisabled = smsAllowed || callsAllowed || emailsAllowed;

  const PDFIcon = () =>
    toFetchStatus(generatePreventionSheet) === FETCH_STATUS.PENDING ? (
      <Refresh variant="line" className={styles.loading} />
    ) : (
      <DocumentPdf variant="line" />
    );

  const handleMailTo = (candidate: CandidateWithMoment, mission: MissionDetail) => {
    window.location.href = `mailto:${candidate.email}?subject=Ordre de Mission&body=Bonjour ${
      candidate.firstName
    } ${candidate.name},%0D%0A%0D%0A
    Je vous informe que nous avons une mission pour vous à compter du ${moment(
      mission.startDate
    ).format('DD/MM/YYYY')}.%0D%0A
    Lieu de mission : ${
      mission.workLocation?.address ? mission.workLocation.address : 'adresse inconnue'
    }, ${mission.workLocation?.cityName ? mission.workLocation.cityName : 'ville inconnue'}, ${
      mission.workLocation?.zipCode ? mission.workLocation.zipCode : 'code postal inconnu'
    }%0D%0A
    En qualité de ${mission.qualification?.label}%0D%0A
    Horaires : ${mission.missionHours ? mission.missionHours : 'non définis'}%0D%0A
    Dates : ${
      mission.endDate
        ? `du ${moment(mission.startDate).format('DD/MM/YYYY')} au ${moment(mission.endDate).format(
            'DD/MM/YYYY'
          )}`
        : `à partir du ${moment(mission.startDate).format('DD/MM/YYYY')} jusqu'à une date inconnue`
    }%0D%0A
    La rémunération est ${mission.salary ? `de ${mission.salary}` : 'non définie'}%0D%0A%0D%0A
    Nous comptons sur votre présence.`;
  };

  return props.target === EnumCreateActionBodyActionTarget.CANDIDATE ? (
    <Menu
      ref={ref}
      trigger={
        props.triggerButton ?? (
          <Button.Tertiary className={classnames(styles.button)} disabled={!notDisabled}>
            <OtherHorizontal variant="fill" />
          </Button.Tertiary>
        )
      }
    >
      {props.enableEditMissionStatus && (
        <>
          <MenuItem
            onClick={() =>
              navigate(MODAL_ROUTES.STATUS_MODAL_CONTENT.route.buildPath({}), {
                state: {
                  candidateId: props.contact.candidateId,
                  candidateStatus: props.contact.candidateAdvancement,
                  companyStatus: props.contact.companyAdvancement,
                  background: location,
                },
              })
            }
          >
            <Inversion variant="line" /> changer le statut de la commande
          </MenuItem>
          {props.contact.companyAdvancement === EnumCandidateCompanyAdvancement.accepté &&
            props.contact.candidateAdvancement === EnumCandidateCandidateAdvancement.accepté &&
            isSuccess && (
              <MenuItem onClick={() => handleMailTo(props.contact, data)}>
                <Email variant="line" /> envoyer l'ordre de mission
              </MenuItem>
            )}
        </>
      )}
      <Menu
        trigger={
          <MenuItem hasSubMenu>
            <BubblesChat variant="line" /> contacter
          </MenuItem>
        }
      >
        <CandidateContactPopup
          contact={props.contact}
          target={props.target}
          smsAllowed={smsAllowed}
          callsAllowed={callsAllowed}
          emailsAllowed={emailsAllowed}
        />
      </Menu>
      {props.positionStudyId && (
        <MenuItem
          onClick={() => {
            generatePreventionSheet.mutate(props.positionStudyId ?? '', {
              onSuccess: (buffer: Buffer) => openFile(buffer, 'application/pdf'),
            });
          }}
        >
          <PDFIcon />
          PDF fiche prévention
        </MenuItem>
      )}
    </Menu>
  ) : (
    <Menu
      trigger={
        props.triggerButton ?? <Button.Tertiary disabled={!notDisabled}>contacter</Button.Tertiary>
      }
    >
      <MenuItem>
        <CandidateContactPopup
          contact={props.contact}
          target={props.target}
          smsAllowed={smsAllowed}
          callsAllowed={callsAllowed}
          emailsAllowed={emailsAllowed}
        />
      </MenuItem>
    </Menu>
  );
};

export default PopOverContact;
