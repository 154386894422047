import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  Option,
  Select,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import styles from './ArchiveWorkAccidentAnalysisModal.module.scss';

import { DeleteAlerte } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import invariant from 'invariant';
import { useArchiveWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useArchiveWorkAccidentAnalysis';
import { ArchiveATReason } from 'src/Services/API';
import { ArchiveWorkAccidentAnalysisModalProps } from './ArchiveWorkAccidentAnalysisModal.types';

const ArchiveWorkAccidentAnalysisModalContent = ({
  workAccidentAnalysis,
  status,
  onClose,
}: ArchiveWorkAccidentAnalysisModalProps) => {
  const [archivedReason, setArchivedReason] = useState<ArchiveATReason>();
  const [archivedComment, setArchivedComment] = useState('');
  const archiveWorkAccidentAnalysis = useArchiveWorkAccidentAnalysis(workAccidentAnalysis, status, {
    onSuccess: () => {
      setTimeout(() => onClose?.(), 500);
    },
    onError: () => {
      setTimeout(() => archiveWorkAccidentAnalysis.reset(), 500);
    },
  });

  return (
    <>
      <ModalContent header={<DeleteAlerte />} title="justification">
        <WithLightTitle title="motif">
          <Select
            size="medium"
            value={archivedReason}
            onChange={setArchivedReason}
            placeholder="choisir un motif"
          >
            <Option value={ArchiveATReason.DUPLICATE}>doublon</Option>
            <Option value={ArchiveATReason.AT_ALREADY_SENT}>analyse déjà envoyée</Option>
            <Option value={ArchiveATReason.CLIENT_SIGN_REFUSAL}>refus signature client</Option>
            <Option value={ArchiveATReason.CLIENT_REFUSAL}>refus réalisation client</Option>
          </Select>
        </WithLightTitle>
        <WithLightTitle title="message" className={styles.commentContainer}>
          <TextArea
            className={styles.comment}
            value={archivedComment}
            onChange={e => setArchivedComment(e.currentTarget.value)}
          />
        </WithLightTitle>
      </ModalContent>
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setArchivedComment('');
            setArchivedReason(undefined);
          }}
        >
          réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          disabled={!archivedComment || !archivedReason}
          mutationStatus={archiveWorkAccidentAnalysis.status}
          onClick={() => {
            invariant(archivedReason, 'archivedReason is missing');
            archiveWorkAccidentAnalysis.mutate({ archivedComment, archivedReason });
          }}
        >
          valider
        </Button>
      </ModalActions>
    </>
  );
};

export const ArchiveWorkAccidentAnalysisModal = ({
  workAccidentAnalysis,
  status,
  open,
  onClose,
}: ArchiveWorkAccidentAnalysisModalProps) => (
  <Modal size="medium" open={open} onClose={onClose}>
    <ArchiveWorkAccidentAnalysisModalContent
      workAccidentAnalysis={workAccidentAnalysis}
      status={status}
      open={open}
      onClose={onClose}
    />
  </Modal>
);
