import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import { useEffect } from 'react';
import {
  DatePickerInputDeprecated,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import TimePicker from 'src/Components/TimePicker';
import styles from './../Context.module.scss';
import { AccidentLocation, CausedByThirdParty } from 'src/Services/API';
import { AccidentProps } from '../Context.types';
import { useController } from 'react-hook-form';
import moment from 'moment';

const causedByThirdPartyValues = [
  { key: CausedByThirdParty.YES, label: 'oui' },
  { key: CausedByThirdParty.NO, label: 'non' },
  { key: CausedByThirdParty.UNKNOWN, label: 'info inconnue' },
];

const accidentLocationValues = [
  { key: AccidentLocation.WORKSHOP, label: 'atelier' },
  { key: AccidentLocation.SERVICE, label: 'service' },
  { key: AccidentLocation.CONSTRUCTION_SITE, label: 'chantier' },
  { key: AccidentLocation.MISSION, label: 'mission' },
  { key: AccidentLocation.JOURNEY, label: 'trajet' },
];

const Accident = ({ isEditable, control, handleSubmit, accidentAddress }: AccidentProps) => {
  const accidentDate = useController({ control, name: 'accidentDate' });
  const accidentTime = useController({ control, name: 'accidentTime' });
  const accidentLocation = useController({ control, name: 'accidentLocation' });
  const causedByThirdParty = useController({ control, name: 'causedByThirdParty' });
  const personLastName = useController({ control, name: 'personLastName' });
  const personFirstName = useController({ control, name: 'personFirstName' });
  const companyName = useController({ control, name: 'companyName' });
  const victimPhoneNumber = useController({ control, name: 'victimPhoneNumber' });
  const workingDayStartTime = useController({ control, name: 'workingDayStartTime' });
  const workingDayEndTime = useController({ control, name: 'workingDayEndTime' });
  const daysOff = useController({ control, name: 'daysOff' });

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (causedByThirdParty.field.value !== CausedByThirdParty.YES) {
      personLastName.field.onChange(undefined);
      personFirstName.field.onChange(undefined);
      companyName.field.onChange(undefined);
      victimPhoneNumber.field.onChange(undefined);
    }
    // eslint-disable-next-line
  }, [causedByThirdParty.field.value]);
  if (daysOff.field.value && daysOff.field.value < 0) {
    daysOff.field.value = 0;
  }

  return (
    <div>
      <div className={styles.subTitle}>accident</div>
      <div className={styles.subSection}>
        <WithLightTitle
          title="date de l'accident"
          className={styles.lightTitleDatePicker}
          children={
            isEditable ? (
              <DatePickerInputDeprecated
                date={accidentDate.field.value}
                onSelectDate={date => accidentDate.field.onChange(date)}
              />
            ) : (
              <div>
                {accidentDate.field.value
                  ? moment(accidentDate.field.value).format('DD/MM/YYYY')
                  : 'non renseigné'}
              </div>
            )
          }
        />

        <div onBlur={handleSubmit()}>
          <WithLightTitle
            title="heure"
            className={styles.lightTitleTimePicker}
            children={
              isEditable ? (
                <TimePicker
                  value={accidentTime.field.value}
                  onChange={event =>
                    accidentTime.field.onChange((event.target as HTMLInputElement).value)
                  }
                />
              ) : (
                <div>{accidentTime.field.value ?? 'non renseigné'}</div>
              )
            }
          />
        </div>
      </div>

      <div className={styles.subSection} onBlur={handleSubmit()}>
        <WithLightTitle
          title="horaires de travail le jour de l'accident"
          className={styles.lightTitleShiftTimePicker}
          children={
            isEditable ? (
              <>
                <TimePicker
                  value={workingDayStartTime.field.value}
                  onChange={event =>
                    workingDayStartTime.field.onChange((event.target as HTMLInputElement).value)
                  }
                />
                <TimePicker
                  value={workingDayEndTime.field.value}
                  onChange={event =>
                    workingDayEndTime.field.onChange((event.target as HTMLInputElement).value)
                  }
                />
              </>
            ) : (
              <div>{`${workingDayStartTime.field.value ?? 'non renseigné'} - ${
                workingDayEndTime.field.value ?? 'non renseigné'
              }`}</div>
            )
          }
        />
        <WithLightTitle
          title="nombre de jours d'arret connu"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <TextInput
                type="number"
                value={daysOff.field.value}
                onValueChange={value => daysOff.field.onChange(+value)}
              />
            ) : (
              <div>{daysOff.field.value ?? 'non renseigné'}</div>
            )
          }
        />
      </div>

      <WithLightTitle
        title="lieu d'accident"
        className={styles.lightTitle}
        children={
          isEditable ? (
            <ExclusiveSelectionGroup
              name="accidentLocation"
              className={styles.multipleChoices}
              getSelectedFormValue={value => value?.key}
              getKey={({ key }) => key}
              getStringValue={({ label }) => label}
              values={accidentLocationValues}
              control={control}
              getSelectedValue={value => accidentLocationValues.find(({ key }) => key === value)}
            />
          ) : (
            <div>
              {accidentLocationValues.find(
                location => location.key === accidentLocation.field.value
              )?.label ?? 'non renseigné'}
            </div>
          )
        }
      />

      <WithLightTitle
        title="adresse de l'accident"
        className={styles.lightTitle}
        children={<div>{accidentAddress}</div>}
      />

      <WithLightTitle
        title="l'accident a été causé par un tiers ?"
        className={styles.lightTitle}
        children={
          isEditable ? (
            <ExclusiveSelectionGroup
              name="causedByThirdParty"
              className={styles.multipleChoices}
              getSelectedFormValue={value => value?.key}
              getKey={({ key }) => key}
              getStringValue={({ label }) => label}
              values={causedByThirdPartyValues}
              control={control}
              getSelectedValue={value => causedByThirdPartyValues.find(({ key }) => key === value)}
            />
          ) : (
            <div>
              {causedByThirdPartyValues.find(value => value.key === causedByThirdParty.field.value)
                ?.label ?? 'non renseigné'}
            </div>
          )
        }
      />
      {causedByThirdParty.field.value === CausedByThirdParty.YES && (
        <div>
          <div className={styles.subSection} onBlur={handleSubmit()}>
            <WithLightTitle
              title="nom de la personne"
              className={styles.lightTitleAccidents}
              children={
                isEditable ? (
                  <TextInput
                    type="text"
                    value={personLastName.field.value}
                    onChange={(event: any) =>
                      personLastName.field.onChange((event.target as HTMLTextAreaElement).value)
                    }
                  />
                ) : (
                  <div>{personLastName.field.value ?? 'non renseigné'}</div>
                )
              }
            />

            <WithLightTitle
              title="prénom de la personne"
              className={styles.lightTitleAccidents}
              children={
                isEditable ? (
                  <TextInput
                    type="text"
                    value={personFirstName.field.value}
                    onChange={(event: any) =>
                      personFirstName.field.onChange((event.target as HTMLTextAreaElement).value)
                    }
                  />
                ) : (
                  <div>{personFirstName.field.value ?? 'non renseigné'}</div>
                )
              }
            />
          </div>

          <div className={styles.subSection} onBlur={handleSubmit()}>
            <WithLightTitle
              title="nom de la société"
              className={styles.lightTitleAccidents}
              children={
                isEditable ? (
                  <TextInput
                    type="text"
                    value={companyName.field.value}
                    onChange={(event: any) =>
                      companyName.field.onChange((event.target as HTMLTextAreaElement).value)
                    }
                  />
                ) : (
                  <div>{companyName.field.value ?? 'non renseigné'}</div>
                )
              }
            />

            <WithLightTitle
              title="numéro de téléphone"
              className={styles.lightTitlePhone}
              children={
                isEditable ? (
                  <TextInput
                    type="text"
                    value={victimPhoneNumber.field.value}
                    onChange={(event: any) =>
                      victimPhoneNumber.field.onChange((event.target as HTMLTextAreaElement).value)
                    }
                  />
                ) : (
                  <div>{victimPhoneNumber.field.value ?? 'non renseigné'}</div>
                )
              }
            />
          </div>
        </div>
      )}
      <div className={styles.divider} />
    </div>
  );
};

export default Accident;
