import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { AttachCandidateToMissionsBody, MissionsService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useAddCandidateToMissions = (
  extraOptions?: UseMutationOptions<
    void,
    AxiosError,
    Omit<AttachCandidateToMissionsBody, 'brandCode' | 'agencyId'>
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateCandidateStatus],
    async params => {
      return MissionsService.missionsControllerAddCandidateToMissions({
        body: {
          candidateId: params.candidateId,
          missionIds: params.missionIds,
          isRepositioningTalent: params.isRepositioningTalent,
          brandCode,
          agencyId,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchRepositioningMissions]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
