/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url,
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static consultantsControllerGetConsultantDetails(
    params: {
      /**  */
      consultantIds: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/consultants';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { consultantIds: params['consultantIds'] };

      axios(configs, resolve, reject);
    });
  }
}

export class ActionsService {
  /**
   *
   */
  static actionsControllerGetAction(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Action> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actionsControllerUpdateAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WorkAccidentAnalysisService {
  /**
   *
   */
  static workAccidentAnalysisControllerGetCandidatesAccidentsUnderMinStoppageDuration(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkStoppage[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/workAccidentsUnderMinStoppageDuration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentsByAgencies(
    params: {
      /** requestBody */
      body?: GetWorkAccidentsByAgenciesQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyWorkAccidents[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/workAccidentsByAgencies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentAnalyses(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FetchWorkAccidentsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentsActionPlans(
    params: {
      /**  */
      agencies: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentActionPlan[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/actionPlans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencies: params['agencies'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentActionPlan(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentActionPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/actionPlan/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerCreateWorkAccident(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateWorkAccidentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/create';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerCloseWorkAccidentActionPlan(
    params: {
      /**  */
      workAccidentId: string;
      /**  */
      actionId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/actionPlan/{actionId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        workAccidentId: params['workAccidentId'],
        actionId: params['actionId'],
        brandCode: params['brandCode'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerEditWorkAccidentActionPlan(
    params: {
      /** requestBody */
      body?: EditWorkAccidentAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/actionPlan/{actionId}';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentAnalysis(
    params: {
      /**  */
      analysisId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentAnalysis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{analysisId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { analysisId: params['analysisId'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUpdateWorkAccidentById(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateWorkAccidentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentAnalysis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerArchiveWorkAccidentAnalysis(
    params: {
      /**  */
      analysisId: string;
      /** requestBody */
      body?: ArchiveWorkAccidentAnalysisParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{analysisId}/archive';
      url = url.replace('{analysisId}', params['analysisId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUnarchiveWorkAccidentAnalysis(
    params: {
      /**  */
      analysisId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{analysisId}/unarchive';
      url = url.replace('{analysisId}', params['analysisId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUpdateWorkAccidentConclusionSignature(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
      /**  */
      signatory: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/updateWorkAccidentConclusionSignature';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      if (params['signatory']) {
        if (Object.prototype.toString.call(params['signatory']) === '[object Array]') {
          for (const item of params['signatory']) {
            data.append('signatory', item as any);
          }
        } else {
          data.append('signatory', params['signatory'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentFileData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUploadWorkAccidentFile(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentFileData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentFileData(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/file/{id}/data';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkingAnalysePdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerDeleteWorkAccidentFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerFinalizeWorkAccidentAnalysis(
    params: {
      /**  */
      analysisId: string;
      /** requestBody */
      body?: FinalizeWorkAccidentAnalysisParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{analysisId}/finalize';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { analysisId: params['analysisId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AgenciesService {
  /**
   *
   */
  static agenciesControllerGetAgencyConsultants(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Consultant[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/consultants';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetRelatedCgc(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CGCIdLabel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{id}/associatedCGC';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
}

export class SkillsManagementCentersService {
  /**
   *
   */
  static skillsManagementCentersControllerGetIsCgc(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills-management-centers/{agencyId}/isCGC';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerGetCgcAgenciesWithDistance(
    params: {
      /**  */
      cgcId: string;
      /**  */
      postalCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyWithDistance[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills-management-centers/{cgcId}/agencies-with-distance';
      url = url.replace('{cgcId}', params['cgcId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { postalCode: params['postalCode'] };

      axios(configs, resolve, reject);
    });
  }
}

export class CandidatesService {
  /**
   *
   */
  static candidatesControllerGetAgencyCandidates(
    params: {
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyCandidatesWithCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getAgencyCandidates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        agencyId: params['agencyId'],
        keyword: params['keyword'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidatesActivityPlanning(
    params: {
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
      /**  */
      startDate: string;
      /**  */
      loadActivityPlannings: boolean;
      /**  */
      candidateId?: string;
      /**  */
      offset?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateSearchActivityPlaning[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getCandidatesActivityPlanning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        agencyId: params['agencyId'],
        startDate: params['startDate'],
        loadActivityPlannings: params['loadActivityPlannings'],
        candidateId: params['candidateId'],
        offset: params['offset'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateActivityPlanning(
    params: {
      /**  */
      startDate: string;
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateSearchActivityPlaning> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getCandidateActivityPlanning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { startDate: params['startDate'], candidateId: params['candidateId'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerFetchSuggestedCandidates(
    params: {
      /**  */
      agencyId: string;
      /**  */
      qualificationId?: string;
      /**  */
      zipCode?: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SuggestedCandidate[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchSuggestedCandidates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        qualificationId: params['qualificationId'],
        zipCode: params['zipCode'],
        brandCode: params['brandCode'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidate(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCandidate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetTemporaryWorkerDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TemporaryWorkerDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/temporaryWorkerDetail';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateCurrentContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCandidateCurrentContractWithEndorsements> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/currentContract';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLatestCdiContract(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateCDIContract> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cdiContract';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateContract(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateContract[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contracts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCvCompany(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cvcompany';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateQualifications(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateQualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/qualifications';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateHabilitations(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateHabilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/habilitations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateMemos(
    params: {
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateMemo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/memos';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerCreateCandidateMemo(
    params: {
      /**  */
      candidateId: string;
      /** requestBody */
      body?: CreateMemoParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/memos';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerDeleteCandidateMemo(
    params: {
      /**  */
      memoId: string;
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/memos/{memoId}';
      url = url.replace('{memoId}', params['memoId'] + '');
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerEditCandidateMemo(
    params: {
      /**  */
      memoId: string;
      /**  */
      candidateId: string;
      /** requestBody */
      body?: EditMemoParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/memos/{memoId}';
      url = url.replace('{memoId}', params['memoId'] + '');
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLastContractEndDate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateEntity> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/lastContractEndDate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLastMedicalVisit(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateEntity> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/lastMedicalVisit';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateSensibilisationTesting(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateTesting[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sensibilisationTesting';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetFirstContractDate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateFirstContractDate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contractsInformation';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerSendToCgc(
    params: {
      /**  */
      id: string;
      /**  */
      cgcId: string;
      /**  */
      agencyId: string;
      /**  */
      candidateName: string;
      /**  */
      candidateFirstName: string;
      /**  */
      sosComment: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sendToCgc';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        cgcId: params['cgcId'],
        agencyId: params['agencyId'],
        candidateName: params['candidateName'],
        candidateFirstName: params['candidateFirstName'],
        sosComment: params['sosComment'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerSendSuggestionToAgencies(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: SuggestedMissionsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sendSuggestionToAgencies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerRefuseSuggestedCandidate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: RefuseSuggestedCandidateParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/refuseSuggestedCandidate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCdiContractDetails(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCdiContractDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cdiContractDetails';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerChangeManuallyRepoStatus(
    params: {
      /** requestBody */
      body?: ChangeRepoStatusParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/changeManuallyRepoStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerSuggestCandidateToAgencies(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: SuggestCandidateToAgenciesParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/suggestCandidateToAgencies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerFetchAgencyTemporaryWorkers(
    params: {
      /** requestBody */
      body?: FetchTemporaryWorkersAgencyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedTemporaryWorkersWithoutDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchAgencyTemporaryWorkers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerFetchRegionalTemporaryWorkers(
    params: {
      /** requestBody */
      body?: FetchTemporaryWorkersRegionalParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedTemporaryWorkersWithoutDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchRegionalTemporaryWorkers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetMissionsCandidate(
    params: {
      /** requestBody */
      body?: GetMissionsCandidateParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getMissionsCandidate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetAgenciesByCandidates(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/getAgenciesCandidate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateSubscription(
    params: {
      /** requestBody */
      body?: UpdateSubscriptionParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/updateSubscription';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetBlacklistedCompaniesByCandidate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BlacklistedCompanies[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/blacklistedCompanies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerAddBlacklistedCompany(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: BlacklistedCompanyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/blacklistedCompanies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContactsService {
  /**
   *
   */
  static contactsControllerGetContact(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RawContact> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contacts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetYouplanCompanies(
    params: {
      /** requestBody */
      body?: GetYouplanCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanCompany[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/youplan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetServices(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/services';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/contacts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetRequestReasons(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyRequestReason[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/requestreasons';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyLastActions(
    params: {
      /**  */
      companyId: string;
      /** requestBody */
      body?: GetCompaniesActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyAction[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}/lastActions';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerAddNonExistentCompanyQualification(
    params: {
      /**  */
      companyId: string;
      /** requestBody */
      body?: PatchCompanyQualification;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyNewQualification> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}/addQualification';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetServicesBySiret(
    params: {
      /**  */
      siret: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/by-siret/{siret}/services';
      url = url.replace('{siret}', params['siret'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
}

export class YouplanMissionsService {
  /**
   *
   */
  static youplanMissionsControllerGetYouplanMissions(
    params: {
      /**  */
      agencyId: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      companyId: string;
      /**  */
      sortBy?: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        offset: params['offset'],
        limit: params['limit'],
        companyId: params['companyId'],
        sortBy: params['sortBy'],
        brandCode: params['brandCode'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerSetProcessingDateYouPlanMission(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionAction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/SetProcessingDate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerGetYouplanMissionDetail(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerCreateMissionFromYouplan(
    params: {
      /**  */
      brandCode: string;
      /** requestBody */
      body?: CreateMissionFromYouplanParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateMissionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/youplan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerMarkAsRead(
    params: {
      /** requestBody */
      body?: MarkMissionAsReadBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionsActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/markAsRead';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerResolveRenewalConflict(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/resolveRenewalConflict';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerUpdateRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/updateRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerCancelRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/cancelRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerRefuseCandidateFromYouplanRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      candidateId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/refuseCandidateFromRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { candidateId: params['candidateId'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
}

export class ExternalDemandsService {
  /**
   *
   */
  static externalDemandsControllerGetAgencyBrandCode(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/brandCode/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerCreateAgencyJournal(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: CreateAgencyJournalParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/brandCode/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerFetchExternalDemands(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: FetchExternalDemandsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetExternalDemandResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/{agencyId}/demands';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerFetchUniqueColumnValues(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UniqueColumnValues> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/{agencyId}/uniqueColumnValues';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerCountExternalDemands(
    params: {
      /** requestBody */
      body?: CountCommandsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountCommandsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/demands/count';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerArchiveManuallyDemands(
    params: {
      /** requestBody */
      body?: ArchiveManuallyEventParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/{agencyId}/archive/{eventId}';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerFetchUniqueSirets(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UniqueSiret[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/{agencyId}/sirets';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerGetMailParametersBySiret(
    params: {
      /**  */
      agencyId: string;
      /**  */
      siret: string;
      /**  */
      source: AppelMedicalEventSourceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MailParameter[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/{agencyId}/mailParameters/{siret}';
      url = url.replace('{agencyId}', params['agencyId'] + '');
      url = url.replace('{siret}', params['siret'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { source: params['source'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerReplayCommandsError(
    params: {
      /** requestBody */
      body?: ReplayExternalDemandParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/replay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerUpdateMailParameter(
    params: {
      /** requestBody */
      body?: MailParametersToUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/mailParameter';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MissionsService {
  /**
   *
   */
  static missionsControllerGetMissions(
    params: {
      /** requestBody */
      body?: SearchMissionsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionsWithCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerGetMissionsInfos(
    params: {
      /**  */
      missionIds: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/missionsDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { missionIds: params['missionIds'], brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerGetMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerCreateMissionAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}/action';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerCreateMissionWithoutSchedule(
    params: {
      /**  */
      brandCode: string;
      /** requestBody */
      body?: CreateCoreMission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateMissionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerUpdateCandidateStatus(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCandidateStatusBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}/changeCandidateStatus';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerAddCandidateToMissions(
    params: {
      /** requestBody */
      body?: AttachCandidateToMissionsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/addCandidate';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PositionstudiesService {
  /**
   *
   */
  static positionStudiesControllerGetPositionStudiesCount(
    params: {
      /**  */
      since?: string;
      /**  */
      brandCode?: string;
      /**  */
      companyId: string;
      /**  */
      agencyId?: string;
      /**  */
      isVisible?: boolean;
      /**  */
      qualificationId: string;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        since: params['since'],
        brandCode: params['brandCode'],
        companyId: params['companyId'],
        agencyId: params['agencyId'],
        isVisible: params['isVisible'],
        qualificationId: params['qualificationId'],
        serviceId: params['serviceId'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudies(
    params: {
      /**  */
      agencyId: string;
      /**  */
      since?: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      brandCode: string;
      /**  */
      qualificationId: string;
      /**  */
      companyId: string;
      /**  */
      isVisible?: boolean;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudy[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        since: params['since'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        isVisible: params['isVisible'],
        serviceId: params['serviceId'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyById(
    params: {
      /**  */
      brandCode: string;
      /**  */
      positionStudyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/positionStudy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        positionStudyId: params['positionStudyId'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPreventionSheetPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPreventionSheetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RepositoryService {
  /**
   *
   */
  static repositoryControllerGetQualifications(
    params: {
      /**  */
      keyword?: string;
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        societyCode: params['societyCode'],
        brand: params['brand'],
        brandCode: params['brandCode'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetDomains(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Domain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/domains';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetSalesphases(options: IRequestOptions = {}): Promise<Salesphase[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/salesphases';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetDepartments(options: IRequestOptions = {}): Promise<Department[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetBusinessQualifications(
    params: {
      /** requestBody */
      body?: GetBusinessQualificationsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/jobQualifications';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetQualificationsDetails(
    params: {
      /** requestBody */
      body?: GetQualificationsDetailsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/qualifications-details';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetRisks(options: IRequestOptions = {}): Promise<Risk[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/risks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryNatures(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryNatures[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryNatures';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryLocations(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryLocations[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryLocations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryLocationDetails(
    params: {
      /**  */
      brandCode?: string;
      /**  */
      injuryLocationId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryLocationDetails[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryLocationsDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        injuryLocationId: params['injuryLocationId'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetProtectionEquipments(
    options: IRequestOptions = {}
  ): Promise<ProtectionEquipments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/protectionEquipments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetCgcRepository(options: IRequestOptions = {}): Promise<CGC[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/cgc';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetCities(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<City[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };

      axios(configs, resolve, reject);
    });
  }
}

export class ContractsService {
  /**
   *
   */
  static contractsControllerGetContracts(
    params: {
      /**  */
      agencyId: string;
      /**  */
      startDate: string;
      /**  */
      endDate?: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      brandCode: string;
      /**  */
      qualificationId?: string;
      /**  */
      companyId?: string;
      /**  */
      serviceId?: string;
      /**  */
      statusCode?: string;
      /**  */
      sortBy?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Contract[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        startDate: params['startDate'],
        endDate: params['endDate'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        serviceId: params['serviceId'],
        statusCode: params['statusCode'],
        sortBy: params['sortBy'],
      };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contractsControllerGetContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contractsControllerCreateContractAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}/action';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contractsControllerRenewContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /** requestBody */
      body?: RenewContractBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}/renew';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetPotentialQualificationsClients(
    params: {
      /** requestBody */
      body?: GetPotentialQualificationsClientsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PotentialQualificationClient[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/potentialQualificationsClients';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static qualificationsControllerGetMissionsBySkillMatching(
    params: {
      /** requestBody */
      body?: GetMissionsBySkillMatchingParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SkillMatchingMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/missionsBySkillMatching';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VariablesimulatorService {
  /**
   *
   */
  static variableSimulatorControllerGetConsultantInformation(
    params: {
      /**  */
      siid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantInformation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/siid/{siid}';
      url = url.replace('{siid}', params['siid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerGetFunctionCategories(
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/functionCategories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerGetUploadedFilesStatus(
    options: IRequestOptions = {}
  ): Promise<UploadedFilesStatus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadedFilesStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerSimulate(
    params: {
      /** requestBody */
      body?: PostVSSimulateBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/simulate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadRhpiFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadRhpiFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadUnitFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadUnitFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadCoefficientFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadCoefficientFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadTrancheFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadTrancheFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadRhpiFunctionFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadRhpiFunctionFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerInitializeExternalDemandsExport(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: FetchExternalDemandsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/{agencyId}/external-demands';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FeatureFlagsService {
  /**
   *
   */
  static featureFlagsControllerFetchFeatureFlags(
    params: {
      /** requestBody */
      body?: FetchFeatureFlagParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResolvedFeatureFlag[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feature-flags';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static featureFlagsControllerFetchFeatureFlagsConfigs(
    options: IRequestOptions = {}
  ): Promise<FeatureFlagConfig[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feature-flags/configs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static featureFlagsControllerUpdateFeatureFlagConfig(
    params: {
      /**  */
      featureFlag: string;
      /** requestBody */
      body?: UpdateFeatureFlagConfigParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feature-flags/configs/{featureFlag}';
      url = url.replace('{featureFlag}', params['featureFlag'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EstablishmentsService {
  /**
   *
   */
  static establishmentControllerFetchEstablishments(
    options: IRequestOptions = {}
  ): Promise<EstablishmentRegion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/establishments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
}

export class AdminService {
  /**
   *
   */
  static adminControllerGetIsAdmin(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
}

export class CronJobsService {
  /**
   *
   */
  static cronJobsControllerFetchCronJobConfigs(
    options: IRequestOptions = {}
  ): Promise<CronJobConfig[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cron-jobs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cronJobsControllerGetCronJobDetails(
    params: {
      /**  */
      cronJob: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CronJobDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cron-jobs/{cronJob}';
      url = url.replace('{cronJob}', params['cronJob'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cronJobsControllerUpdateCronJobConfig(
    params: {
      /**  */
      cronJob: string;
      /** requestBody */
      body?: UpdateCronJobConfigParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cron-jobs/{cronJob}';
      url = url.replace('{cronJob}', params['cronJob'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cronJobsControllerRunCronJobManually(
    params: {
      /**  */
      cronJob: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cron-jobs/{cronJob}/run';
      url = url.replace('{cronJob}', params['cronJob'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
}

export interface OSMConsultantModelDtoBrandDto {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface PerimeterCompany {
  /**  */
  brand?: OSMConsultantModelDtoBrandDto;

  /**  */
  name: string;

  /**  */
  brandCodeApiHeader: string;

  /**  */
  agencies: string[];
}

export interface ConsultantPerimeter {
  /**  */
  branchId?: string;

  /**  */
  regionId?: string;

  /**  */
  zoneId?: string;

  /**  */
  defaultAgencyId?: string;

  /**  */
  defaultCompanyId?: string;

  /**  */
  companies: PerimeterCompany[];
}

export interface OSMConsultantModelDtoFullConsultantDto {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;
}

export interface ConsultantInfo {
  /**  */
  consultant?: OSMConsultantModelDtoFullConsultantDto;
}

export interface OSMActionModelDtoCompanyDto {
  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface OSMActionModelDtoContactDto {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  contactId?: string;
}

export interface OSMActionModelDtoCandidateDto {
  /**  */
  candidateId?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone1?: string;

  /**  */
  phone2?: string;

  /**  */
  email?: string;
}

export interface OSMActionModelDtoActorDto {
  /**  */
  name?: string;

  /**  */
  id?: string;
}

export interface OSMActionModelDtoAttachedDocumentDto {
  /**  */
  attachementDate?: Date;

  /**  */
  attachedBy?: string;

  /**  */
  attachementDocumentId?: string;

  /**  */
  title?: string;

  /**  */
  fileName?: string;

  /**  */
  fileType?: string;

  /**  */
  binaryFile?: string;
}

export interface OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto {
  /**  */
  label?: string;

  /**  */
  isActive?: boolean;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelActionStateRepositoryDtoActionStateDto {
  /**  */
  label?: string;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  isActive?: boolean;
}

export interface OSMCommonModelDateDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMRepositoriesModelGoalRepositoryDtoGoalDto {
  /**  */
  label?: string;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto {
  /**  */
  familyGoals?: OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto;

  /**  */
  goals?: OSMRepositoriesModelGoalRepositoryDtoGoalDto[];
}

export interface Action {
  /**  */
  actionId?: string;

  /**  */
  company?: OSMActionModelDtoCompanyDto;

  /**  */
  contacts?: OSMActionModelDtoContactDto[];

  /**  */
  candidate?: OSMActionModelDtoCandidateDto;

  /**  */
  actors?: OSMActionModelDtoActorDto[];

  /**  */
  attachedDocuments?: OSMActionModelDtoAttachedDocumentDto[];

  /**  */
  type?: OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto;

  /**  */
  status?: OSMRepositoriesModelActionStateRepositoryDtoActionStateDto;

  /**  */
  target?: OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  startTime?: number;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  endTime?: number;

  /**  */
  agencies?: string[];

  /**  */
  goalsByFamily?: OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto[];

  /**  */
  report?: string;

  /**  */
  creationDate?: Date;

  /**  */
  syncGoogleCal?: boolean;

  /**  */
  appointment?: boolean;

  /**  */
  seqAdress?: string;

  /**  */
  competitionIdentification?: boolean;

  /**  */
  title?: string;

  /**  */
  isEditable?: boolean;
}

export interface UpdateActionBody {
  /**  */
  report: string;

  /**  */
  brandCode: string;
}

export interface OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  lastName?: string;
}

export interface WorkStoppage {
  /**  */
  id: string;

  /**  */
  workStoppageTypeCode?: string;

  /**  */
  candidate?: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto;

  /**  */
  workAccidentId?: string;

  /**  */
  stoppageDuration?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  agencyId?: string;
}

export interface GetWorkAccidentsByAgenciesQueryParams {
  /**  */
  dateFilterStrategy?: DateFilterStrategy;

  /**  */
  agencyIds: string[];

  /**  */
  brandCode: string;

  /**  */
  startDate: Date;

  /**  */
  endDate?: Date;
}

export interface OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCandidateDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCompanyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoAccidentPlaceDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoMaterialElementDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface AgencyWorkAccidents {
  /**  */
  accidentId?: string;

  /**  */
  candidate?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCandidateDto;

  /**  */
  company?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCompanyDto;

  /**  */
  date?: OSMCommonModelDateDto;

  /**  */
  place?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoAccidentPlaceDto;

  /**  */
  materialElement?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoMaterialElementDto;
}

export interface WorkAccidentAnalysisWithoutDetailCandidate {
  /**  */
  id?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentCompanyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  siret?: string;

  /**  */
  acronym?: string;

  /**  */
  service?: string;

  /**  */
  country?: string;

  /**  */
  phone?: string;

  /**  */
  fax?: string;

  /**  */
  address?: OSMWorkAccidentModelDtoWorkAccidentCompanyAddressDto;

  /**  */
  contact?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface WorkAccidentAnalysisWithoutDetail {
  /**  */
  id: string;

  /**  */
  workStoppageTypeCode?: string;

  /**  */
  candidate: WorkAccidentAnalysisWithoutDetailCandidate;

  /**  */
  company?: OSMWorkAccidentModelDtoWorkAccidentCompanyDto;

  /**  */
  contractQualification?: OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto;

  /**  */
  workAccidentId?: string;

  /**  */
  stoppageDuration?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  agencyId?: string;
}

export interface FetchWorkAccidentsResponse {
  /**  */
  todo: WorkAccidentAnalysisWithoutDetail[];

  /**  */
  inProgress: WorkAccidentAnalysisWithoutDetail[];

  /**  */
  done: WorkAccidentAnalysisWithoutDetail[];

  /**  */
  archived: WorkAccidentAnalysisWithoutDetail[];
}

export interface WhyAction {
  /**  */
  delay: WhyActionDelayEnum;

  /**  */
  actorType: WhyActionActorsEnum;

  /**  */
  category: WhyActionCategoriesEnum;

  /**  */
  name: WhyActionsEnum;

  /**  */
  status: WhyActionsStatusEnum;

  /**  */
  osmoseActionId?: string;

  /**  */
  description: string;

  /**  */
  actor: string;

  /**  */
  actorName: string;

  /**  */
  consultantId?: string;

  /**  */
  realisationDate?: Date;
}

export interface WorkAccidentActionPlan {
  /**  */
  candidate?: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  agencyId?: string;

  /**  */
  accidentDate?: Date;

  /**  */
  companyReferent?: string;

  /**  */
  accidentAddress?: string;

  /**  */
  whyActions?: WhyAction[];

  /**  */
  workAccidentId?: string;

  /**  */
  companyId?: string;

  /**  */
  contractQualification?: OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto;

  /**  */
  dateOfSignature?: Date;
}

export interface CreateWorkAccidentBody {
  /**  */
  archivedReason?: ArchiveATReason;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  candidateId: string;

  /**  */
  accidentId: string;

  /**  */
  agencyId: string;

  /**  */
  brandCode: string;

  /**  */
  stoppageDuration: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  archivedComment?: string;
}

export interface EditWorkAccidentAction {
  /**  */
  workAccidentId: string;

  /**  */
  osmoseActionId: string;

  /**  */
  brandCode: string;

  /**  */
  description?: string;

  /**  */
  actor?: string;

  /**  */
  actorName?: string;

  /**  */
  realisationDate?: Date;

  /**  */
  companyId?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentCandidateDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone1?: string;

  /**  */
  phone2?: string;

  /**  */
  socialSecurityId?: string;

  /**  */
  socialSecurityKey?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentDateAndLocationAccidentAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentDateAndLocationWorkHoursDto {
  /**  */
  startTime1?: string;

  /**  */
  endTime1?: string;

  /**  */
  startTime2?: string;

  /**  */
  endTime2?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentDateAndLocationDto {
  /**  */
  accidentDate?: OSMCommonModelDateDto;

  /**  */
  accidentTime?: string;

  /**  */
  accidentLocation?: string;

  /**  */
  accidentSpecificity?: string;

  /**  */
  accidentAddress?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationAccidentAddressDto;

  /**  */
  workHours?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationWorkHoursDto;
}

export interface OSMWorkAccidentModelDtoWorkAccidentCircumstancesAndConsequencesDto {
  /**  */
  materialElements?: string;

  /**  */
  details?: string;

  /**  */
  victimActivity?: string;

  /**  */
  victimTransportedTo?: string;

  /**  */
  accidentNature?: string;

  /**  */
  injuryObject?: string;

  /**  */
  otherVictim?: string;

  /**  */
  consequences?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentToAgencyDto {
  /**  */
  flagKnownAccident?: boolean;

  /**  */
  agencyDate?: OSMCommonModelDateDto;

  /**  */
  agencyTime?: string;

  /**  */
  agencyOrigin?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentCustomerSide {
  /**  */
  flagSeenByCustomer?: boolean;

  /**  */
  customerDate?: OSMCommonModelDateDto;

  /**  */
  customerTime?: string;

  /**  */
  customerOrigin?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsDto {
  /**  */
  accidentCausedByAnotherPerson?: boolean;

  /**  */
  name?: string;

  /**  */
  insurance?: string;

  /**  */
  police?: string;

  /**  */
  address?: OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsAddressDto;
}

export interface OSMWorkAccidentModelDtoWorkAccidentPoliceReportAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentPoliceReportDto {
  /**  */
  policeStation?: string;

  /**  */
  minutes?: string;

  /**  */
  address?: OSMWorkAccidentModelDtoWorkAccidentPoliceReportAddressDto;
}

export interface OSMWorkAccidentModelDtoWorkAccidentWitnessesTypeDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;
}

export interface OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsDto {
  /**  */
  name?: string;

  /**  */
  type?: OSMWorkAccidentModelDtoWorkAccidentWitnessesTypeDto;

  /**  */
  address?: OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsAddressDto;
}

export interface Referent {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name: string;

  /**  */
  position: string;
}

export interface Witness {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name: string;

  /**  */
  position: string;

  /**  */
  societyId?: string;

  /**  */
  societyName: string;

  /**  */
  phoneNumber?: string;
}

export interface Context {
  /**  */
  laterality?: Laterality;

  /**  */
  firstAidPersonType?: FirstAidPersonType;

  /**  */
  accidentLocation?: AccidentLocation;

  /**  */
  causedByThirdParty?: CausedByThirdParty;

  /**  */
  seniorityAtClient?: number;

  /**  */
  seniorityAtRandstad?: number;

  /**  */
  registeredRisk?: boolean;

  /**  */
  existingRisk?: boolean;

  /**  */
  trainingEvaluation?: boolean;

  /**  */
  referents?: Referent[];

  /**  */
  hasWitnesses?: boolean;

  /**  */
  witnesses?: Witness[];

  /**  */
  welcomeDate?: Date;

  /**  */
  trainingDate?: Date;

  /**  */
  positionStudyDate?: Date;

  /**  */
  accidentDate?: Date;

  /**  */
  accidentAddress?: string;

  /**  */
  daysOff?: number;

  /**  */
  accidentTime?: string;

  /**  */
  workingDayStartTime?: string;

  /**  */
  workingDayEndTime?: string;

  /**  */
  otherFirstAidPerson?: string;

  /**  */
  personLastName?: string;

  /**  */
  personFirstName?: string;

  /**  */
  companyName?: string;

  /**  */
  victimPhoneNumber?: string;
}

export interface MaseItem {
  /**  */
  id: string;

  /**  */
  label?: string;
}

export interface Injury {
  /**  */
  injuryLaterality?: LateralityInjury;

  /**  */
  injuryLocation?: MaseItem;

  /**  */
  injuryLocationDetails?: MaseItem;

  /**  */
  injuryNatures?: MaseItem[];
}

export interface Epi {
  /**  */
  whoGaveIt: WhoGaveEpi;

  /**  */
  inContract: YesNoUnknown;

  /**  */
  hasBeenWorn: boolean;

  /**  */
  id: string;

  /**  */
  label?: string;
}

export interface Description {
  /**  */
  hasEpis?: YesNoUnknown;

  /**  */
  hasEpcs?: YesNoUnknown;

  /**  */
  beforeAccident?: string;

  /**  */
  duringAccident?: string;

  /**  */
  afterAccident?: string;

  /**  */
  otherRisks?: string;

  /**  */
  risks?: MaseItem[];

  /**  */
  environmentRisks?: MaseItem[];

  /**  */
  injuries?: Injury[];

  /**  */
  epis?: Epi[];

  /**  */
  mainEpcs?: MaseItem[];

  /**  */
  otherEpcs?: string;
}

export interface FiveWhyElement {
  /**  */
  value?: string;

  /**  */
  rootCauseName?: string;

  /**  */
  isRootCause?: boolean;

  /**  */
  whyIndex?: number;
}

export interface WhyAnalysis {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  fiveWhys?: FiveWhyElement[];

  /**  */
  actions?: WhyAction[];
}

export interface SigningVictim {
  /**  */
  victimSigningStatus?: SigningStatusEnum;

  /**  */
  isUnableToSign?: boolean;

  /**  */
  moment?: Date;

  /**  */
  signed?: boolean;
}

export interface SigningPerson {
  /**  */
  personName?: string;

  /**  */
  id?: string;

  /**  */
  moment?: Date;

  /**  */
  signed?: boolean;
}

export interface ClientSigningPerson {
  /**  */
  clientSigningStatus?: SigningStatusEnum;

  /**  */
  personName?: string;

  /**  */
  id?: string;

  /**  */
  moment?: Date;

  /**  */
  signed?: boolean;
}

export interface SigningAdditionalPerson {
  /**  */
  personName?: string;

  /**  */
  personRole?: string;

  /**  */
  moment?: Date;

  /**  */
  signed?: boolean;
}

export interface Conclusion {
  /**  */
  comments?: string;

  /**  */
  dateOfSignature?: Date;

  /**  */
  victimSigningPerson?: SigningVictim;

  /**  */
  randstadSigningPerson?: SigningPerson;

  /**  */
  clientSigningPerson?: ClientSigningPerson;

  /**  */
  additionalSigningPerson?: SigningAdditionalPerson;
}

export interface Signature {
  /**  */
  originalname: string;

  /**  */
  size: number;

  /**  */
  encoding: string;

  /**  */
  mimetype: string;

  /**  */
  buffer: object;
}

export interface WorkAccidentAnalysis {
  /**  */
  archivedReason?: ArchiveATReason;

  /**  */
  analyseId: string;

  /**  */
  workAccidentId: string;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  status: EnumWorkAccidentAnalysisStatus;

  /**  */
  agencyId: string;

  /**  */
  stoppageDuration: number;

  /**  */
  brandCode: string;

  /**  */
  candidate?: OSMWorkAccidentModelDtoWorkAccidentCandidateDto;

  /**  */
  company?: OSMWorkAccidentModelDtoWorkAccidentCompanyDto;

  /**  */
  accidentDateAndLocation?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationDto;

  /**  */
  accidentCircumstancesAndConsequences?: OSMWorkAccidentModelDtoWorkAccidentCircumstancesAndConsequencesDto;

  /**  */
  toAgency?: OSMWorkAccidentModelDtoWorkAccidentToAgencyDto;

  /**  */
  customerSide?: OSMWorkAccidentModelDtoWorkAccidentCustomerSide;

  /**  */
  contractId?: string;

  /**  */
  contractQualification?: OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto;

  /**  */
  cdiContractId?: string;

  /**  */
  otherPersonInformations?: OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsDto;

  /**  */
  policeReport?: OSMWorkAccidentModelDtoWorkAccidentPoliceReportDto;

  /**  */
  witnessesInformations?: OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsDto;

  /**  */
  context?: Context;

  /**  */
  description?: Description;

  /**  */
  whyAnalysis: WhyAnalysis;

  /**  */
  conclusion?: Conclusion;

  /**  */
  randstadConsultantSignature?: CombinedRandstadConsultantSignatureTypes;

  /**  */
  clientSignature?: CombinedClientSignatureTypes;

  /**  */
  victimSignature?: CombinedVictimSignatureTypes;

  /**  */
  additionalSignature?: CombinedAdditionalSignatureTypes;

  /**  */
  archivedComment?: string;
}

export interface UpdateWorkAccidentBody {
  /**  */
  status?: AnalysisStatus;

  /**  */
  context?: Context;

  /**  */
  description?: Description;

  /**  */
  whyAnalysis?: WhyAnalysis;

  /**  */
  conclusion?: Conclusion;
}

export interface ArchiveWorkAccidentAnalysisParams {
  /**  */
  archivedReason: ArchiveATReason;

  /**  */
  archivedComment: string;
}

export interface Buffer {}

export interface WorkAccidentFileData {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  size: number;

  /**  */
  path: string;

  /**  */
  encoding: string;

  /**  */
  mimetype: string;

  /**  */
  data: Buffer;
}

export interface AuthenticatedUser {
  /**  */
  givenName?: string;

  /**  */
  familyName?: string;

  /**  */
  siid?: string;

  /**  */
  email?: string;
}

export interface GetPdfQueryParams {
  /**  */
  brandCode: string;

  /**  */
  user: AuthenticatedUser;
}

export interface FinalizeWorkAccidentAnalysisParams {
  /**  */
  user: AuthenticatedUser;

  /**  */
  candidateName: string;

  /**  */
  agencyId: string;

  /**  */
  companyId: string;

  /**  */
  createdAt: string;

  /**  */
  randstadSignatory: string;

  /**  */
  clientSignatory: string;

  /**  */
  brandCode: EnumFinalizeWorkAccidentAnalysisParamsBrandCode;
}

export interface Consultant {
  /**  */
  consultantId: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  email?: string;
}

export interface CGCIdLabel {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface AgencyWithDistance {
  /**  */
  distance?: number;

  /**  */
  agencyId: string;

  /**  */
  name?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;

  /**  */
  isOpen?: boolean;

  /**  */
  openingDate?: Date;

  /**  */
  closingDate?: Date;

  /**  */
  society: string;

  /**  */
  brandCode?: string;

  /**  */
  brandName?: string;

  /**  */
  level?: number;

  /**  */
  serviceCode: string;

  /**  */
  serviceTypeLabel?: string;

  /**  */
  serviceTypeCode?: string;

  /**  */
  fax?: string;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoCandidateStatusDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoCandidateTargetGroupDto {
  /**  */
  candidateAgencyId?: string;

  /**  */
  targetGroup?: string;

  /**  */
  agencyId?: string;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoLastContractDto {
  /**  */
  id?: string;

  /**  */
  companyId?: string;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  realEndDate?: OSMCommonModelDateDto;

  /**  */
  processingEndDate?: OSMCommonModelDateDto;
}

export interface OSMCandidateModelSearchMultiCriteriaDtoCandidateSearchMultiCriteriaDto {
  /**  */
  candidateId?: string;

  /**  */
  gender?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  candidateStatus?: OSMCandidateModelSearchMultiCriteriaDtoCandidateStatusDto;

  /**  */
  qualification?: OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto;

  /**  */
  firstContractStartDate?: Date;

  /**  */
  targetGroups?: OSMCandidateModelSearchMultiCriteriaDtoCandidateTargetGroupDto[];

  /**  */
  workteams?: OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto[];

  /**  */
  availabilityForConsultantDate?: OSMCommonModelDateDto;

  /**  */
  city?: string;

  /**  */
  zipCode?: string;

  /**  */
  mainAgencyId?: string;

  /**  */
  lastContract?: OSMCandidateModelSearchMultiCriteriaDtoLastContractDto;
}

export interface AgencyCandidatesWithCount {
  /**  */
  count?: number;

  /**  */
  candidates: OSMCandidateModelSearchMultiCriteriaDtoCandidateSearchMultiCriteriaDto[];
}

export interface OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelActivityPlanningDtoActivityPlanningLastContractQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelActivityPlanningDtoLastContractAgencyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto {
  /**  */
  id?: string;

  /**  */
  qualification?: OSMCandidateModelActivityPlanningDtoActivityPlanningLastContractQualificationDto;

  /**  */
  agency?: OSMCandidateModelActivityPlanningDtoLastContractAgencyDto;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  realEndDate?: OSMCommonModelDateDto;

  /**  */
  processingEndDate?: OSMCommonModelDateDto;
}

export interface OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto {
  /**  */
  id?: string;

  /**  */
  companyId?: string;

  /**  */
  startDate?: OSMCommonModelDateDto;
}

export interface ActivityPlanningDay {
  /**  */
  date?: Date;

  /**  */
  status?: EnumActivityPlanningDayStatus;
}

export interface OSMCandidateModelResidencePermitDtoResidencePermitTypesDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelResidencePermitDtoClaimantAgencyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelResidencePermitDtoPrefectureClaimDto {
  /**  */
  claimDate?: OSMCommonModelDateDto;

  /**  */
  claimantAgency?: OSMCandidateModelResidencePermitDtoClaimantAgencyDto;

  /**  */
  sendingMode?: string;
}

export interface OSMCandidateModelResidencePermitDtoVerdictAgencyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelResidencePermitDtoPrefectureVerdictDto {
  /**  */
  verdict?: string;

  /**  */
  verdictDate?: OSMCommonModelDateDto;

  /**  */
  verdictAgency?: OSMCandidateModelResidencePermitDtoVerdictAgencyDto;

  /**  */
  returnMode?: string;
}

export interface OSMCandidateModelResidencePermitDtoResidencePermitDto {
  /**  */
  residencePermitTypes?: OSMCandidateModelResidencePermitDtoResidencePermitTypesDto;

  /**  */
  number?: string;

  /**  */
  deliveredBy?: string;

  /**  */
  issueDate?: OSMCommonModelDateDto;

  /**  */
  expirationDate?: OSMCommonModelDateDto;

  /**  */
  prefectureClaim?: OSMCandidateModelResidencePermitDtoPrefectureClaimDto;

  /**  */
  prefectureVerdict?: OSMCandidateModelResidencePermitDtoPrefectureVerdictDto;
}

export interface CandidateAddress {
  /**  */
  candidatePostalCode?: string;

  /**  */
  candidateCityName?: string;

  /**  */
  distance?: number;

  /**  */
  address?: string;
}

export interface OSMCandidateModelSkillsDtoCandidateQualificationsBusinessSkillsDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelSkillsDtoCandidateQualificationsSectorDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  business?: OSMCandidateModelSkillsDtoCandidateQualificationsBusinessSkillsDto;

  /**  */
  nbContracts?: number;

  /**  */
  nbCompany?: number;

  /**  */
  main?: boolean;

  /**  */
  sectors?: OSMCandidateModelSkillsDtoCandidateQualificationsSectorDto[];
}

export interface CandidateSearchActivityPlaning {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  GMMR?: number;

  /**  */
  cPBalance?: number;

  /**  */
  cETBalance?: number;

  /**  */
  mainQualification?: OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto;

  /**  */
  lastContract?: OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto;

  /**  */
  currentCompanyFirstContract?: OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto;

  /**  */
  cdiiStatus?: DBCDIIStatus;

  /**  */
  activityPlanning?: ActivityPlanningDay[];

  /**  */
  residencePermit?: OSMCandidateModelResidencePermitDtoResidencePermitDto;

  /**  */
  lastMedicalVisit?: Date;

  /**  */
  candidateAddress?: CandidateAddress;

  /**  */
  attachedMissionIds?: string[];

  /**  */
  suggestedMissionIds?: string[];

  /**  */
  suggestedAgencyIds?: string[];

  /**  */
  sosAgencyId?: string;

  /**  */
  sosComment?: string;

  /**  */
  candidateQualifications?: OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto[];
}

export interface OSMCandidateModelCandidateDtoMainAgencyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface SuggestedCandidate {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  GMMR?: number;

  /**  */
  cPBalance?: number;

  /**  */
  cETBalance?: number;

  /**  */
  mainQualification?: OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto;

  /**  */
  lastContract?: OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto;

  /**  */
  currentCompanyFirstContract?: OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto;

  /**  */
  cdiiStatus?: DBCDIIStatus;

  /**  */
  id: string;

  /**  */
  candidateAddress: CandidateAddress;

  /**  */
  mainAgency?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  activityPlanning?: ActivityPlanningDay[];

  /**  */
  residencePermit?: OSMCandidateModelResidencePermitDtoResidencePermitDto;

  /**  */
  lastMedicalVisit?: Date;

  /**  */
  attachedMissionIds?: string[];

  /**  */
  suggestedMissionIds?: string[];

  /**  */
  suggestedAgencyIds?: string[];

  /**  */
  sosAgencyId?: string;

  /**  */
  sosComment?: string;

  /**  */
  candidateQualifications?: OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto[];
}

export interface OSMCandidateModelCandidateDtoAssesmentDto {
  /**  */
  date?: string;

  /**  */
  author?: string;

  /**  */
  comment?: string;
}

export interface QualificationInCandidateDetails {
  /**  */
  qualificationId?: string;

  /**  */
  label?: string;
}

export interface OSMCandidate {
  /**  */
  gender: string;

  /**  */
  name: string;

  /**  */
  firstName: string;

  /**  */
  phone1: string;

  /**  */
  phone2: string;

  /**  */
  email: string;

  /**  */
  address: string;

  /**  */
  address0?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  inseeCode: string;

  /**  */
  zipCode: string;

  /**  */
  city?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  qualificationId: string;

  /**  */
  contractAccepted: string[];

  /**  */
  nbHoursWorked: number;

  /**  */
  nbMissions: number;

  /**  */
  nbCompanies: number;

  /**  */
  skillUri: string;

  /**  */
  levelOfStudy: string;

  /**  */
  agenciesUri: string;

  /**  */
  mainAgency?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  statusCode: string;

  /**  */
  flagAcceptSMSMission: boolean;

  /**  */
  flagAcceptNotifMission?: boolean;

  /**  */
  flagAcceptEmailMission?: boolean;

  /**  */
  dateAcceptationCguAppli?: Date;

  /**  */
  candidateType?: string;

  /**  */
  assesment?: OSMCandidateModelCandidateDtoAssesmentDto;

  /**  */
  flagAcceptSMS?: boolean;

  /**  */
  facebookApplication?: boolean;

  /**  */
  smartphoneApplication?: boolean;

  /**  */
  conveyed?: boolean;

  /**  */
  lastMissionAgencyId?: string;

  /**  */
  countryId?: string;

  /**  */
  isBorderWorker?: boolean;

  /**  */
  domicilePaySendingFlag?: boolean;

  /**  */
  dependantsNumber?: number;

  /**  */
  birthName?: string;

  /**  */
  temporaryNumber?: string;

  /**  */
  disabilityWorkerConsentStatus?: string;

  /**  */
  mobility?: string[];

  /**  */
  availabilityForConsultantDate?: OSMCommonModelDateDto;

  /**  */
  newGraduate?: boolean;

  /**  */
  registrationDate?: OSMCommonModelDateDto;

  /**  */
  creator?: string;

  /**  */
  haveWorkedForTheSpecifiedCompany?: boolean;

  /**  */
  lastModificationDate?: OSMCommonModelDateDto;

  /**  */
  candidateId: string;

  /**  */
  qualification: QualificationInCandidateDetails;
}

export interface CandidateQualification {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelResidencePermitResidencePermitResponse {
  /**  */
  residencePermit?: OSMCandidateModelResidencePermitDtoResidencePermitDto;
}

export interface CandidateHabilitation {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface OSMCommonModelOnMissionCandidateBaseDto {
  /**  */
  candidateId: string;

  /**  */
  acceptCandidate?: boolean;

  /**  */
  acceptCustomer?: boolean;
}

export interface OSMCommonModelLocationModelOutputLocationDto {
  /**  */
  address?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;

  /**  */
  inseeCode?: string;
}

export interface OSMCompanyModelMissionCompanyResponse {
  /**  */
  companyId?: string;

  /**  */
  name?: string;

  /**  */
  location?: OSMCommonModelLocationModelOutputLocationDto;
}

export interface OSMCandidateModelCommonDtoQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateMission {
  /**  */
  missionId: string;

  /**  */
  starDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  missionDuration?: number;

  /**  */
  statutMissionId?: string;

  /**  */
  candidate?: OSMCommonModelOnMissionCandidateBaseDto;

  /**  */
  companies?: OSMCompanyModelMissionCompanyResponse;

  /**  */
  qualification?: OSMCandidateModelCommonDtoQualificationDto;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;
}

export interface CandidateMemo {
  /**  */
  memoId: string;

  /**  */
  candidateId: string;

  /**  */
  comment: string;

  /**  */
  isBehaviorMemo: boolean;

  /**  */
  date?: Date;

  /**  */
  consultant?: OSMConsultantModelDtoFullConsultantDto;
}

export interface TemporaryWorkerDetail {
  /**  */
  temporaryWorkerStatus: TemporaryWorkerStatus;

  /**  */
  candidateId?: string;

  /**  */
  gender?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  subscribedAgencies: string[];

  /**  */
  candidateStatus?: OSMCandidateModelSearchMultiCriteriaDtoCandidateStatusDto;

  /**  */
  qualification?: OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto;

  /**  */
  firstContractStartDate?: Date;

  /**  */
  targetGroups?: OSMCandidateModelSearchMultiCriteriaDtoCandidateTargetGroupDto[];

  /**  */
  workteams?: OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto[];

  /**  */
  type?: string;

  /**  */
  lastContractEndDate?: Date;

  /**  */
  candidateAddress?: CandidateAddress;

  /**  */
  availabilityForConsultantDate?: Date;

  /**  */
  qualifications?: CandidateQualification[];

  /**  */
  qualificationId?: string;

  /**  */
  residencePermit?: OSMCandidateModelResidencePermitResidencePermitResponse;

  /**  */
  nbCompanies?: number;

  /**  */
  nbHours?: number;

  /**  */
  nbMissions?: number;

  /**  */
  phoneNumber1?: string;

  /**  */
  lastMedicalVisit?: Date;

  /**  */
  habilitations?: CandidateHabilitation[];

  /**  */
  acceptedMobility?: string[];

  /**  */
  candidateType?: string;

  /**  */
  mainAgencyCode?: string;

  /**  */
  missions?: CandidateMission[];

  /**  */
  memos?: CandidateMemo[];

  /**  */
  talentId?: string;
}

export interface OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMContratModelDtoEndorsementModalityTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMContratModelDtoEndorsementForCandidateDto {
  /**  */
  id?: string;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  clientValidationDate?: OSMCommonModelDateDto;

  /**  */
  status?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  modalityType?: OSMContratModelDtoEndorsementModalityTypeDto;
}

export interface OSMCandidateCurrentContractWithEndorsements {
  /**  */
  companyName?: string;

  /**  */
  missionId?: string;

  /**  */
  contractId?: string;

  /**  */
  endorsements?: OSMContratModelDtoEndorsementForCandidateDto[];

  /**  */
  statusCode?: string;

  /**  */
  statusLabel?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  missionStartDate?: Date;

  /**  */
  missionActualEndDate?: Date;

  /**  */
  isRenewable: boolean;
}

export interface OSMContratModelDtoContractTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateCDIContract {
  /**  */
  contractId?: string;

  /**  */
  endorsementId?: string;

  /**  */
  contractType?: OSMContratModelDtoContractTypeDto;

  /**  */
  statusCode?: string;

  /**  */
  statusLabel?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;
}

export interface CandidateContract {
  /**  */
  companyName?: string;

  /**  */
  missionId?: string;

  /**  */
  contractId?: string;

  /**  */
  endorsementId?: string;

  /**  */
  statusCode?: string;

  /**  */
  statusLabel?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  dateStartMission?: Date;

  /**  */
  dateFinReelMission?: Date;
}

export interface CreateMemoParams {
  /**  */
  comment: string;

  /**  */
  isBehaviorMemo: boolean;

  /**  */
  brandCode: string;
}

export interface EditMemoParams {
  /**  */
  comment: string;

  /**  */
  isBehaviorMemo: boolean;

  /**  */
  brandCode: string;
}

export interface DateEntity {
  /**  */
  date?: Date;
}

export interface OSMCandidateModelETestingTestsDtoGoalDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateTesting {
  /**  */
  testType?: string;

  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  goal?: OSMCandidateModelETestingTestsDtoGoalDto;

  /**  */
  agencyId?: string;

  /**  */
  completionRate?: string;

  /**  */
  completedOn?: Date;
}

export interface CandidateFirstContractDate {
  /**  */
  firstContractStartDate?: Date;
}

export interface SendSuggestionToAgenciesParams {
  /**  */
  agencyId: string;

  /**  */
  missionNumber: string;

  /**  */
  clientName: string;

  /**  */
  qualificationName: string;

  /**  */
  startDate: string;
}

export interface SuggestedMissionsParams {
  /**  */
  cgcId: string;

  /**  */
  cc: string[];

  /**  */
  suggestedMissions: SendSuggestionToAgenciesParams[];

  /**  */
  comment: string;

  /**  */
  candidateFullName: string;

  /**  */
  candidateMainQualification: string;

  /**  */
  lastContractEndDate: string;

  /**  */
  lastMedicalVisit: string;
}

export interface RefuseSuggestedCandidateParams {
  /**  */
  comment: string;

  /**  */
  suggestedMissionsIds: string[];

  /**  */
  cgcId: string;

  /**  */
  agencyId: string;

  /**  */
  companiesNames?: string[];

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  gender?: string;

  /**  */
  reason?: string;

  /**  */
  refuseAll?: boolean;
}

export interface OSMCdiContractModelDtoCandidateDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface OSMCdiContractModelDtoContractStatusDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCdiContractModelDtoTrialPeriodDateDto {
  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMCdiContractModelDtoWorkingConditionDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCdiContractModelDtoContractMobilityDto {
  /**  */
  geographicalZone?: string;

  /**  */
  distance?: number;

  /**  */
  timeTravel?: number;
}

export interface OSMCdiContractModelDtoContractSalaryDto {
  /**  */
  monthlyDuration?: string;

  /**  */
  minimumMonthlySalary?: number;
}

export interface OSMCdiContractModelDtoContractJobDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  main?: boolean;
}

export interface OSMCdiContractDetails {
  /**  */
  candidate?: OSMCdiContractModelDtoCandidateDto;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  startingHour?: number;

  /**  */
  status?: OSMCdiContractModelDtoContractStatusDto;

  /**  */
  trialPeriod?: OSMCdiContractModelDtoTrialPeriodDateDto;

  /**  */
  workingConditions?: OSMCdiContractModelDtoWorkingConditionDto[];

  /**  */
  mobility?: OSMCdiContractModelDtoContractMobilityDto;

  /**  */
  salary?: OSMCdiContractModelDtoContractSalaryDto;

  /**  */
  jobList?: OSMCdiContractModelDtoContractJobDto[];

  /**  */
  agencyId?: string;

  /**  */
  lastEndorsementAgencyId?: string;
}

export interface ChangeRepoStatusParams {
  /**  */
  comment: string;

  /**  */
  suggestedMissionsIds: string[];

  /**  */
  cgcId: string;

  /**  */
  agencyId: string;

  /**  */
  companiesNames?: string[];

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  gender?: string;

  /**  */
  reason?: string;

  /**  */
  refuseAll?: boolean;

  /**  */
  oldStatus: string;

  /**  */
  newStatus: string;

  /**  */
  candidateId: string;

  /**  */
  suggestedMissionsToAttach?: string[];
}

export interface SuggestCandidateToAgenciesParams {
  /**  */
  suggestedAgencyIds: string[];

  /**  */
  cgcId: string;

  /**  */
  cc: string[];

  /**  */
  comment: string;

  /**  */
  suggestedMissions?: string[];
}

export interface FetchTemporaryWorkersSort {
  /**  */
  sortCriteria: FetchTemporaryWorkersSortCriteria;

  /**  */
  sortType: SortTypeEnum;
}

export interface FetchTemporaryWorkersAgencyFilters {
  /**  */
  status: TemporaryWorkerStatus[];

  /**  */
  qualifications: string[];

  /**  */
  availabilityDateInterval: number;
}

export interface FetchTemporaryWorkersAgencyParams {
  /**  */
  page: number;

  /**  */
  brandCode: string;

  /**  */
  agency: string;

  /**  */
  sort: FetchTemporaryWorkersSort;

  /**  */
  filters: FetchTemporaryWorkersAgencyFilters;
}

export interface TemporaryWorkerWithoutDetail {
  /**  */
  temporaryWorkerStatus: TemporaryWorkerStatus;

  /**  */
  candidateId: string;

  /**  */
  availabilityForConsultantDate?: Date;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  qualification?: OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto;

  /**  */
  agencyId?: string;

  /**  */
  lastContractEndDate?: Date;

  /**  */
  city?: string;

  /**  */
  zipCode?: string;
}

export interface PaginatedTemporaryWorkersWithoutDetail {
  /**  */
  temporaryWorkers: TemporaryWorkerWithoutDetail[];

  /**  */
  nextPage?: number;
}

export interface FetchTemporaryWorkersRegionalFilters {
  /**  */
  qualifications: string[];

  /**  */
  distanceInKm?: number;
}

export interface FetchTemporaryWorkersRegionalParams {
  /**  */
  page: number;

  /**  */
  brandCode: string;

  /**  */
  agency: string;

  /**  */
  sort: FetchTemporaryWorkersSort;

  /**  */
  filters: FetchTemporaryWorkersRegionalFilters;
}

export interface GetMissionsCandidateParams {
  /**  */
  candidateId: string;

  /**  */
  startDate: string;

  /**  */
  endDate: string;
}

export interface UpdateSubscriptionParams {
  /**  */
  candidateId: string;

  /**  */
  decryptedAgenciesIds: string[];
}

export interface OSMCandidateModelBlackListedCompaniesDtoBlackListedCompanyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelBlackListedCompaniesDtoBlackListedServiceDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface BlacklistedCompanies {
  /**  */
  company?: OSMCandidateModelBlackListedCompaniesDtoBlackListedCompanyDto;

  /**  */
  allServicesBlocked?: boolean;

  /**  */
  service?: OSMCandidateModelBlackListedCompaniesDtoBlackListedServiceDto;

  /**  */
  comment?: string;
}

export interface BlacklistedCompanyParams {
  /**  */
  companyId: string;
}

export interface OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto {
  /**  */
  id?: number;

  /**  */
  label?: string;
}

export interface RawContact {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  position?: string;

  /**  */
  statut?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  dontMailMe?: boolean;

  /**  */
  dontCallMe?: boolean;

  /**  */
  dontPostMe?: boolean;

  /**  */
  dontPlaceMe?: boolean;

  /**  */
  civility?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;
}

export interface GetCompaniesBody {
  /**  */
  keyword: string;

  /**  */
  agencies: string[];

  /**  */
  brandCode: string;
}

export interface OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface OSMCompanyModelSearchDtoVisitDto {
  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;
}

export interface OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CompanySearchResult {
  /**  */
  societyStatus?: string;

  /**  */
  solvency?: SolvencyEnum;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  lastVisit?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  hasFrameworkAgreement?: boolean;

  /**  */
  delegationPotential?: number;

  /**  */
  externalCode?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  timeTrackingTool?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;
}

export interface GetYouplanCompaniesBody {
  /**  */
  agencies: string[];

  /**  */
  brandCode: string;
}

export interface YouplanCompany {
  /**  */
  societyStatus?: string;

  /**  */
  solvency?: SolvencyEnum;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  lastVisit?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  hasFrameworkAgreement?: boolean;

  /**  */
  delegationPotential?: number;

  /**  */
  externalCode?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  timeTrackingTool?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  activeMissionsCount?: number;
}

export interface CompanyService {
  /**  */
  active: boolean;

  /**  */
  serviceId?: string;

  /**  */
  name?: string;
}

export interface OSMCompanyModelCompanyDtoCompanyContactServicesDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CompanyContact {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  position?: string;

  /**  */
  statut?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  isActiveOnCurrentCompany?: boolean;

  /**  */
  mainContact?: boolean;

  /**  */
  myContact?: boolean;

  /**  */
  dontMailMe?: boolean;

  /**  */
  dontCallMe?: boolean;

  /**  */
  dontPostMe?: boolean;

  /**  */
  dontPlaceMe?: boolean;

  /**  */
  civility?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  services?: OSMCompanyModelCompanyDtoCompanyContactServicesDto[];

  /**  */
  portalAccess?: boolean;
}

export interface CompanyRequestReason {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  isFreeText?: boolean;

  /**  */
  isActive?: boolean;
}

export interface GetCompaniesActionBody {
  /**  */
  brandCode: string;

  /**  */
  agency: string;
}

export interface OSMCompanyModelActionDtoCandidateInfoDto {
  /**  */
  candidateId?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone1?: string;

  /**  */
  phone2?: string;

  /**  */
  email?: string;
}

export interface OSMCompanyModelActionDtoActionTargetInfoDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  isActive?: boolean;
}

export interface CompanyAction {
  /**  */
  actionId?: string;

  /**  */
  contacts?: OSMActionModelDtoContactDto[];

  /**  */
  candidate?: OSMCompanyModelActionDtoCandidateInfoDto;

  /**  */
  actors?: OSMActionModelDtoActorDto[];

  /**  */
  attachedDocuments?: OSMActionModelDtoAttachedDocumentDto[];

  /**  */
  type?: OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto;

  /**  */
  status?: OSMRepositoriesModelActionStateRepositoryDtoActionStateDto;

  /**  */
  target?: OSMCompanyModelActionDtoActionTargetInfoDto;

  /**  */
  startDate?: Date;

  /**  */
  startTime?: number;

  /**  */
  endDate?: Date;

  /**  */
  endTime?: number;

  /**  */
  agencies?: string[];

  /**  */
  goalsByFamily?: OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto[];

  /**  */
  report?: string;

  /**  */
  creationDate?: Date;

  /**  */
  lastModificationDate?: Date;

  /**  */
  syncGoogleCal?: boolean;

  /**  */
  appointment?: boolean;
}

export interface PatchCompanyQualification {
  /**  */
  potentialQualificationId: string;
}

export interface CompanyNewQualification {
  /**  */
  isNewQualif: boolean;

  /**  */
  potentialQualificationId: string;
}

export interface OSMMissionsWithoutScheduleModelDtoOutputCompanyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  siret?: string;

  /**  */
  solvency?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoServiceDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoMissionCustomerContactDto {
  /**  */
  id?: string;

  /**  */
  civility?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoMissionConsultantDto {
  /**  */
  id?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  email?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoQualificationDto {
  /**  */
  id?: string;

  /**  */
  shortLabel?: string;

  /**  */
  label?: string;
}

export interface YouplanMissionActionSummary {
  /**  */
  action: EnumYouplanMissionActionSummaryAction;

  /**  */
  youplanMissionId: string;

  /**  */
  entityId?: string;

  /**  */
  isInConflict?: boolean;

  /**  */
  lastConflictCause?: EnumYouplanMissionActionSummaryLastConflictCause;
}

export interface CoreMissionCandidate {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  candidateAdvancement?: EnumCoreMissionCandidateCandidateAdvancement;

  /**  */
  companyAdvancement?: EnumCoreMissionCandidateCompanyAdvancement;

  /**  */
  candidateId?: string;
}

export interface YouplanMission {
  /**  */
  missionId?: string;

  /**  */
  numberPositions?: number;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  customerContact?: OSMMissionsWithoutScheduleModelDtoMissionCustomerContactDto;

  /**  */
  monitoredByConsultant?: OSMMissionsWithoutScheduleModelDtoMissionConsultantDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  statutMissionId?: string;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  agencyId?: string;

  /**  */
  dPAEAgencyId?: string;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  youplanStatus?: YouplanMissionActionSummary;

  /**  */
  relatedMissionCandidates?: string[];

  /**  */
  processingDate?: Date;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  modificationDate?: Date;

  /**  */
  candidates?: CoreMissionCandidate[];
}

export interface YouplanMissionAction {
  /**  */
  youplanMissionId: string;

  /**  */
  entityId?: string;

  /**  */
  brandCode: string;

  /**  */
  startDate: Date;

  /**  */
  endDate?: Date;

  /**  */
  action: EnumYouplanMissionActionAction;

  /**  */
  isInConflict?: boolean;

  /**  */
  lastConflictCause?: EnumYouplanMissionActionLastConflictCause;

  /**  */
  companyId: string;

  /**  */
  dpaeAgencyId: string;

  /**  */
  processingDate?: Date;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDto {
  /**  */
  siret?: string;

  /**  */
  companyId?: string;

  /**  */
  name?: string;

  /**  */
  location?: OSMCommonModelLocationModelOutputLocationDto;
}

export interface OSMMissionModelDtoReplacedPersonDto {
  /**  */
  requestReason?: string;

  /**  */
  requestReasonDetail?: string;

  /**  */
  civility?: string;

  /**  */
  firstname?: string;

  /**  */
  lastname?: string;

  /**  */
  qualificationLabel?: string;

  /**  */
  status?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoIncreaseDto {
  /**  */
  flagNight?: boolean;

  /**  */
  nightSchedule?: string;

  /**  */
  nightIncrease?: number;

  /**  */
  nightCondition?: string;

  /**  */
  flagSunday?: boolean;

  /**  */
  sundaySchedule?: string;

  /**  */
  sundayIncrease?: number;

  /**  */
  sundayCondition?: string;

  /**  */
  flagHoliday?: boolean;

  /**  */
  holidayIncrease?: number;

  /**  */
  holidayCondition?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetWorkTeam {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto {
  /**  */
  sequence?: string;

  /**  */
  label?: string;

  /**  */
  labelPriority?: string;

  /**  */
  typeCode?: string;

  /**  */
  id?: string;

  /**  */
  priorityId?: number;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto {
  /**  */
  sequence?: number;

  /**  */
  label?: string;

  /**  */
  labelPriority?: string;

  /**  */
  id: string;

  /**  */
  priorityId: number;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto {
  /**  */
  id?: string;

  /**  */
  status?: string;

  /**  */
  natureForeseeableRiskToPostList?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  followUpIndividualReinforcedList?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  workRequiringTrainingList?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  prohibitedWorksList?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  drivingLicenseList?: OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto[];

  /**  */
  welcomeById?: string;

  /**  */
  otherEquipment?: string;

  /**  */
  specialConditions?: string;

  /**  */
  instructionsToFollow?: string;

  /**  */
  painfulnessFlag?: boolean;

  /**  */
  implementedHardnessDate?: Date;

  /**  */
  habilitationAuthorizationDetail?: string;

  /**  */
  collectiveProtectionEquipment?: string;

  /**  */
  practice?: number;

  /**  */
  asbestosFlag?: boolean;

  /**  */
  otherFlag?: boolean;

  /**  */
  slingFlag?: boolean;

  /**  */
  superToleranceNoiseFlag?: boolean;

  /**  */
  helmetFlag?: boolean;

  /**  */
  shoesFlag?: boolean;

  /**  */
  requestDerogationProhibitedWorkFlag?: boolean;

  /**  */
  nilStateFlag?: boolean;

  /**  */
  companyWelcomeSheetFlag?: boolean;

  /**  */
  formalizationHomeFlag?: boolean;

  /**  */
  enhancedSecurityTrainingFlag?: boolean;

  /**  */
  glovesFlag?: boolean;

  /**  */
  habilitationAuthorizationRequiredFlag?: boolean;

  /**  */
  disabledFacilitiesFlag?: boolean;

  /**  */
  disabledTypoFlag?: boolean;

  /**  */
  languageFlag?: boolean;

  /**  */
  listRiskPositionFlag?: boolean;

  /**  */
  glassesFlag?: boolean;

  /**  */
  drivingLicenseFlag?: boolean;

  /**  */
  riskPositionFlag?: boolean;

  /**  */
  predictableNatureRiskFlag?: boolean;

  /**  */
  workAtHeightFlag?: boolean;

  /**  */
  workOnScaffoldingFlag?: boolean;

  /**  */
  prohibitedWorksFlag?: boolean;

  /**  */
  medicalSupervisionFlag?: boolean;

  /**  */
  trainingProvidedBy?: string;

  /**  */
  disabledFacilitiesDetail?: string;

  /**  */
  disabledTypoDetail?: string;

  /**  */
  natureOtherForeseeableRisks?: string;

  /**  */
  securityBusinessTest?: string;

  /**  */
  tutorOrRefereeOfTheWorker?: string;

  /**  */
  typeOfVibration?: string;

  /**  */
  jobEnvironment?: string;
}

export interface OSMRepositoriesModelBonusRatesDtoTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMRepositoriesModelBonusRatesDtoCadencyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto {
  /**  */
  id?: string;

  /**  */
  type?: OSMRepositoriesModelBonusRatesDtoTypeDto;

  /**  */
  cadency?: OSMRepositoriesModelBonusRatesDtoCadencyDto;

  /**  */
  amount?: number;

  /**  */
  label?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoCustomerContactDto {
  /**  */
  id?: string;

  /**  */
  civility?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  fax?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoMainSkillDto {
  /**  */
  typeCode?: string;

  /**  */
  id: string;

  /**  */
  priorityId?: number;

  /**  */
  label?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoSkillDto {
  /**  */
  id: string;

  /**  */
  priorityId?: number;

  /**  */
  label?: string;
}

export interface ContactingInfo {
  /**  */
  at: Date;

  /**  */
  by?: OSMConsultantModelDtoFullConsultantDto;

  /**  */
  actionId: string;
}

export interface Candidate {
  /**  */
  gender: string;

  /**  */
  name: string;

  /**  */
  firstName: string;

  /**  */
  phone1: string;

  /**  */
  phone2: string;

  /**  */
  email: string;

  /**  */
  address: string;

  /**  */
  address0?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  inseeCode: string;

  /**  */
  zipCode: string;

  /**  */
  city?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  qualificationId: string;

  /**  */
  contractAccepted: string[];

  /**  */
  nbHoursWorked: number;

  /**  */
  nbMissions: number;

  /**  */
  nbCompanies: number;

  /**  */
  skillUri: string;

  /**  */
  levelOfStudy: string;

  /**  */
  agenciesUri: string;

  /**  */
  mainAgency?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  statusCode: string;

  /**  */
  flagAcceptSMSMission: boolean;

  /**  */
  flagAcceptNotifMission?: boolean;

  /**  */
  flagAcceptEmailMission?: boolean;

  /**  */
  dateAcceptationCguAppli?: Date;

  /**  */
  candidateType?: string;

  /**  */
  assesment?: OSMCandidateModelCandidateDtoAssesmentDto;

  /**  */
  flagAcceptSMS?: boolean;

  /**  */
  facebookApplication?: boolean;

  /**  */
  smartphoneApplication?: boolean;

  /**  */
  conveyed?: boolean;

  /**  */
  lastMissionAgencyId?: string;

  /**  */
  countryId?: string;

  /**  */
  isBorderWorker?: boolean;

  /**  */
  domicilePaySendingFlag?: boolean;

  /**  */
  dependantsNumber?: number;

  /**  */
  birthName?: string;

  /**  */
  temporaryNumber?: string;

  /**  */
  disabilityWorkerConsentStatus?: string;

  /**  */
  mobility?: string[];

  /**  */
  availabilityForConsultantDate?: OSMCommonModelDateDto;

  /**  */
  newGraduate?: boolean;

  /**  */
  registrationDate?: OSMCommonModelDateDto;

  /**  */
  creator?: string;

  /**  */
  haveWorkedForTheSpecifiedCompany?: boolean;

  /**  */
  lastModificationDate?: OSMCommonModelDateDto;

  /**  */
  candidateId: string;

  /**  */
  qualification: QualificationInCandidateDetails;

  /**  */
  lastContact?: ContactingInfo;

  /**  */
  candidateAdvancement?: EnumCandidateCandidateAdvancement;

  /**  */
  companyAdvancement?: EnumCandidateCompanyAdvancement;
}

export interface Contact {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  position?: string;

  /**  */
  statut?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  dontMailMe?: boolean;

  /**  */
  dontCallMe?: boolean;

  /**  */
  dontPostMe?: boolean;

  /**  */
  dontPlaceMe?: boolean;

  /**  */
  civility?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  lastContact?: ContactingInfo;
}

export interface StructuredMissionHours {
  /**  */
  days: number[];

  /**  */
  startTime: number;

  /**  */
  endTime: number;
}

export interface MissionDetail {
  /**  */
  id?: string;

  /**  */
  startHour?: number;

  /**  */
  missionDuration?: number;

  /**  */
  numberPositions?: number;

  /**  */
  statusForRd?: string;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDto;

  /**  */
  companyComment?: string;

  /**  */
  accordMissionUri?: string;

  /**  */
  agencyMissionUri?: string;

  /**  */
  reasonId?: string;

  /**  */
  reasonLabel?: string;

  /**  */
  replacedPerson?: OSMMissionModelDtoReplacedPersonDto;

  /**  */
  dPAEAgencyId?: string;

  /**  */
  increase?: OSMMissionsWithoutScheduleModelDtoIncreaseDto;

  /**  */
  workTeam?: OSMMissionsWithoutScheduleModelDtoGetDtoGetWorkTeam;

  /**  */
  positionStudyId?: string;

  /**  */
  monitoredByConsultant?: OSMMissionsWithoutScheduleModelDtoMissionConsultantDto;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  creationDate?: Date;

  /**  */
  origin?: string;

  /**  */
  modificationDate?: Date;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  siretPosteId?: number;

  /**  */
  mase?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto;

  /**  */
  isVisible?: boolean;

  /**  */
  bonusRates?: OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto[];

  /**  */
  customerContact?: OSMMissionsWithoutScheduleModelDtoCustomerContactDto;

  /**  */
  agencyId?: string;

  /**  */
  receptionTime?: number;

  /**  */
  behavioralSkill?: string;

  /**  */
  technicalSkill?: string;

  /**  */
  otherSkill?: string;

  /**  */
  comment?: string;

  /**  */
  privateComment?: string;

  /**  */
  contactId?: string;

  /**  */
  getInTouchWith?: string;

  /**  */
  missionHours?: string;

  /**  */
  sirOriginCode?: string;

  /**  */
  salary?: string;

  /**  */
  salaryHourBase?: number;

  /**  */
  personalGear?: string;

  /**  */
  weekHours?: number;

  /**  */
  positionFeatures?: string;

  /**  */
  positionStatus?: string;

  /**  */
  resumeKeywords?: string;

  /**  */
  isDelegationMode?: boolean;

  /**  */
  mainSkill?: OSMMissionsWithoutScheduleModelDtoMainSkillDto[];

  /**  */
  languages?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  diplomas?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  habilitations?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  workingConditions?: string[];

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  rdiProgress?: DTORdiProgress;

  /**  */
  suggestedCandidates?: OSMCandidate[];

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  candidates?: Candidate[];

  /**  */
  lastClientContact?: ContactingInfo;

  /**  */
  contact?: Contact;

  /**  */
  structuredMissionHours: StructuredMissionHours[];

  /**  */
  structuredIndividualEquipement?: string[];

  /**  */
  structuredSecurityBusinessTest?: string[];

  /**  */
  structuredCollectiveEquipment?: string[];
}

export interface ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateMissionConfigApiDto {}

export interface ServiceApiVirtualAgencyModelsMissionModelDtoReplacedPersonDto {}

export interface ServiceApiVirtualAgencyModelsCommonModelLocationModelInputLocationDto {}

export interface ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateBonusRatesDto {}

export interface ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoUpdateMaseDto {}

export interface ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto {}

export interface CreateCoreMission {
  /**  */
  startHour?: number;

  /**  */
  missionDuration?: number;

  /**  */
  numberPositions?: number;

  /**  */
  companyComment?: string;

  /**  */
  startDate: string;

  /**  */
  endDate?: string;

  /**  */
  qualificationId: string;

  /**  */
  reasonId?: number;

  /**  */
  configAPI?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateMissionConfigApiDto;

  /**  */
  dPAEAgencyId?: string;

  /**  */
  companyId?: string;

  /**  */
  serviceId?: string;

  /**  */
  agencyId?: string;

  /**  */
  receptionTime?: number;

  /**  */
  behavioralSkill?: string;

  /**  */
  technicalSkill?: string;

  /**  */
  otherSkill?: string;

  /**  */
  comment?: string;

  /**  */
  contactId?: string;

  /**  */
  getInTouchWith?: string;

  /**  */
  missionHours?: string;

  /**  */
  sirOriginCode?: string;

  /**  */
  salary?: string;

  /**  */
  salaryHourBase?: number;

  /**  */
  personalGear?: string;

  /**  */
  weekHours?: number;

  /**  */
  positionFeatures?: string;

  /**  */
  positionStatus?: string;

  /**  */
  resumeKeywords?: string;

  /**  */
  isDelegationMode?: boolean;

  /**  */
  workingConditions?: string[];

  /**  */
  candidates?: object[];

  /**  */
  replacedPerson?: ServiceApiVirtualAgencyModelsMissionModelDtoReplacedPersonDto;

  /**  */
  workLocation?: ServiceApiVirtualAgencyModelsCommonModelLocationModelInputLocationDto;

  /**  */
  bonusRates?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateBonusRatesDto[];

  /**  */
  mase?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoUpdateMaseDto;

  /**  */
  mainSkill?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  languages?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  diplomas?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  habilitations?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  structuredMissionHours: StructuredMissionHours[];
}

export interface CreateMissionFromYouplanParams {
  /**  */
  mission: CreateCoreMission;

  /**  */
  youplanMissionIds: string[];
}

export interface CreateMissionResponse {
  /**  */
  missionId?: string;
}

export interface MarkMissionAsReadBody {
  /**  */
  youplanMissionIds: string[];

  /**  */
  brandCode: string;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  dpaeAgencyId: string;

  /**  */
  companyId: string;
}

export interface YouplanMissionsActionSummaryResponse {
  /**  */
  youplanActionSummary?: YouplanMissionActionSummary[];
}

export interface YouplanMissionActionSummaryResponse {
  /**  */
  youplanActionSummary?: YouplanMissionActionSummary;
}

export interface CreateAgencyJournalParams {
  /**  */
  brandCode: string;
}

export interface FetchExternalDemandsSort {
  /**  */
  sortCriteria: FetchExternalDemandsSortCriteria;

  /**  */
  sortType: SortTypeEnum;
}

export interface FetchExternalDemandsParams {
  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  displayStatus?: DisplayStatusDto;

  /**  */
  status?: ExternalDemandStatusDto[];

  /**  */
  clientName?: string[];

  /**  */
  service?: string[];

  /**  */
  qualification?: string[];

  /**  */
  missionStartDate?: string;

  /**  */
  missionEndDate?: string;

  /**  */
  limitStartDate?: string;

  /**  */
  limitEndDate?: string;

  /**  */
  globalFilter?: string;

  /**  */
  sort?: FetchExternalDemandsSort;

  /**  */
  page?: number;

  /**  */
  limit?: number;
}

export interface ExternalDemandEvent {
  /**  */
  status: AppelMedicalEventStatusDto;

  /**  */
  displayStatus: DisplayStatusDto;

  /**  */
  sourceId: string;

  /**  */
  dateMission: Date;

  /**  */
  limitDate?: Date;

  /**  */
  schedule?: string;

  /**  */
  cause?: string;

  /**  */
  motive?: string;

  /**  */
  rattachementLink?: string;

  /**  */
  updatedAt: Date;
}

export interface ExternalDemand {
  /**  */
  status: ExternalDemandStatusDto;

  /**  */
  commandId: string;

  /**  */
  clientName?: string;

  /**  */
  siret?: string;

  /**  */
  service?: string;

  /**  */
  domain?: string;

  /**  */
  qualification?: string;

  /**  */
  dateMission?: Date;

  /**  */
  limitDate?: Date;

  /**  */
  events: ExternalDemandEvent[];
}

export interface GetExternalDemandResponse {
  /**  */
  demands: ExternalDemand[];

  /**  */
  count: number;
}

export interface UniqueColumnValues {
  /**  */
  clientNames: string[];

  /**  */
  services: string[];

  /**  */
  qualifications: string[];
}

export interface CountCommandsParams {
  /**  */
  displayStatus: DisplayStatusDto;

  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  agencyId: string;
}

export interface CountCommandsResponse {
  /**  */
  count: number;
}

export interface ExternalDemandDto {}

export interface ArchiveManuallyEventParams {
  /**  */
  externalDemands: ExternalDemandDto[];

  /**  */
  agencyId: string;

  /**  */
  brandCode: string;
}

export interface ParameterStats {
  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  missing: number;

  /**  */
  total: number;
}

export interface UniqueSiret {
  /**  */
  siret: string;

  /**  */
  clientName?: string;

  /**  */
  parameterStats: ParameterStats[];
}

export interface MailParameter {
  /**  */
  typeField: TypeFieldDto;

  /**  */
  id: string;

  /**  */
  sourceLabel: string;

  /**  */
  osmoseLabel?: string;
}

export interface ReplayExternalDemandParams {
  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  agencyId: string;

  /**  */
  sourceIds: string[];
}

export interface MailParameterToUpdateDto {
  /**  */
  typeField: TypeFieldDto;

  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  agencyId: string;

  /**  */
  siret: string;

  /**  */
  sourceLabel: string;

  /**  */
  osmoseLabel?: string;

  /**  */
  updatedOsmoseLabel?: string;

  /**  */
  id: string;
}

export interface MailParametersToUpdateDto {
  /**  */
  source: AppelMedicalEventSourceDto;

  /**  */
  agencyId: string;

  /**  */
  mailParametersToUpdate: MailParameterToUpdateDto[];
}

export interface SearchMissionsBody {
  /**  */
  agencyIds?: string[];

  /**  */
  startDate: string;

  /**  */
  endDate?: string;

  /**  */
  offset: number;

  /**  */
  limit?: number;

  /**  */
  brandCode: string;

  /**  */
  qualificationIds?: string[];

  /**  */
  monitoredByConsultantId?: string;

  /**  */
  candidateIds?: string[];

  /**  */
  companyId?: string;

  /**  */
  serviceId?: string;

  /**  */
  salesphases?: string[];

  /**  */
  sortBy?: EnumSearchMissionsBodySortBy;

  /**  */
  sortOrder?: EnumSearchMissionsBodySortOrder;

  /**  */
  departmentIds?: string[];

  /**  */
  withCount?: boolean;

  /**  */
  candidateIdAlreadyOnMissions?: string;

  /**  */
  search?: boolean;
}

export interface Mission {
  /**  */
  missionId?: string;

  /**  */
  numberPositions?: number;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  customerContact?: OSMMissionsWithoutScheduleModelDtoMissionCustomerContactDto;

  /**  */
  monitoredByConsultant?: OSMMissionsWithoutScheduleModelDtoMissionConsultantDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  statutMissionId?: string;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  agencyId?: string;

  /**  */
  dPAEAgencyId?: string;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  suggestedCandidateCount?: number;

  /**  */
  distanceToTT?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  modificationDate?: Date;

  /**  */
  candidates?: CoreMissionCandidate[];
}

export interface MissionsWithCount {
  /**  */
  count?: number;

  /**  */
  missions?: Mission[];
}

export interface CreateActionBody {
  /**  */
  actionType: EnumCreateActionBodyActionType;

  /**  */
  actionTarget: EnumCreateActionBodyActionTarget;

  /**  */
  report: string;

  /**  */
  targetId: string;

  /**  */
  agencyId: string;

  /**  */
  companyId: string;

  /**  */
  createdAt: string;

  /**  */
  brandCode: EnumCreateActionBodyBrandCode;
}

export interface CreateActionResponse {
  /**  */
  actionId: string;
}

export interface CandidateAdvancementInfo {
  /**  */
  candidateAdvancement?: EnumCandidateAdvancementInfoCandidateAdvancement;

  /**  */
  companyAdvancement?: EnumCandidateAdvancementInfoCompanyAdvancement;

  /**  */
  candidateId: string;
}

export interface UpdateCandidateStatusBody {
  /**  */
  rdiProgress?: EnumUpdateCandidateStatusBodyRdiProgress;

  /**  */
  candidates: CandidateAdvancementInfo[];

  /**  */
  brandCode: string;
}

export interface AttachCandidateToMissionsBody {
  /**  */
  agencyId: string;

  /**  */
  missionIds: string[];

  /**  */
  candidateId: string;

  /**  */
  brandCode: string;

  /**  */
  isRepositioningTalent?: boolean;
}

export interface OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase {
  /**  */
  companyId?: string;

  /**  */
  name?: string;

  /**  */
  location?: OSMCommonModelLocationModelOutputLocationDto;
}

export interface PositionStudy {
  /**  */
  positionStudyId?: string;

  /**  */
  positionStudyName?: string;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  creationDate?: Date;

  /**  */
  origin?: string;

  /**  */
  modificationDate?: Date;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  siretPosteId?: number;

  /**  */
  mase?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto;

  /**  */
  isVisible?: boolean;

  /**  */
  bonusRates?: OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto[];

  /**  */
  customerContact?: OSMMissionsWithoutScheduleModelDtoCustomerContactDto;

  /**  */
  agencyId?: string;

  /**  */
  receptionTime?: number;

  /**  */
  behavioralSkill?: string;

  /**  */
  technicalSkill?: string;

  /**  */
  otherSkill?: string;

  /**  */
  comment?: string;

  /**  */
  privateComment?: string;

  /**  */
  contactId?: string;

  /**  */
  getInTouchWith?: string;

  /**  */
  missionHours?: string;

  /**  */
  sirOriginCode?: string;

  /**  */
  salary?: string;

  /**  */
  salaryHourBase?: number;

  /**  */
  personalGear?: string;

  /**  */
  weekHours?: number;

  /**  */
  positionFeatures?: string;

  /**  */
  positionStatus?: string;

  /**  */
  resumeKeywords?: string;

  /**  */
  isDelegationMode?: boolean;

  /**  */
  mainSkill?: OSMMissionsWithoutScheduleModelDtoMainSkillDto[];

  /**  */
  languages?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  diplomas?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  habilitations?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  workingConditions?: string[];

  /**  */
  structuredMissionHours: StructuredMissionHours[];

  /**  */
  structuredIndividualEquipement?: string[];

  /**  */
  structuredSecurityBusinessTest?: string[];

  /**  */
  structuredCollectiveEquipment?: string[];

  /**  */
  completionStatus: EnumPositionStudyCompletionStatus;
}

export interface GetPreventionSheetPdfQueryParams {
  /**  */
  user: AuthenticatedUser;

  /**  */
  brandCode: string;
}

export interface Qualification {
  /**  */
  businessId?: string;

  /**  */
  id?: string;

  /**  */
  jobCenterBusinessCode?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  professionAndSocioProfessionalCategoryId?: string;
}

export interface Domain {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface Salesphase {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface Department {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface GetBusinessQualificationsParams {
  /**  */
  societyCode?: string;

  /**  */
  brand?: string;

  /**  */
  businessIds: string[];
}

export interface GetQualificationsDetailsParams {
  /**  */
  brandCode?: string;

  /**  */
  qualificationIds: string[];
}

export interface Risk {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface InjuryNatures {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface InjuryLocations {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface InjuryLocationDetails {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface ProtectionEquipments {
  /**  */
  epis: MaseItem[];

  /**  */
  epcs: MaseItem[];
}

export interface CGC {
  /**  */
  id?: string;

  /**  */
  societyId?: string;

  /**  */
  brandCode?: string;

  /**  */
  label?: string;

  /**  */
  isOpen?: boolean;
}

export interface City {
  /**  */
  uId?: string;

  /**  */
  insee?: string;

  /**  */
  zipCode?: string;

  /**  */
  name?: string;
}

export interface OSMContratModelDtoCompanyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  siret?: string;
}

export interface OSMContratModelDtoCandidateDto {
  /**  */
  candidateId?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface OSMContratModelDtoServiceDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMContratModelDtoQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface OSMContratModelDtoContractsMultiCriteriaEndOfMissionReasonDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMContratModelDtoModalityTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface Endorsement {
  /**  */
  id?: string;

  /**  */
  status?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  number?: string;

  /**  */
  modalityType?: OSMContratModelDtoModalityTypeDto;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  clientValidationDate?: Date;
}

export interface Contract {
  /**  */
  contractId?: string;

  /**  */
  company?: OSMContratModelDtoCompanyDto;

  /**  */
  contactId?: string;

  /**  */
  status?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  missionId?: string;

  /**  */
  candidate?: OSMContratModelDtoCandidateDto;

  /**  */
  service?: OSMContratModelDtoServiceDto;

  /**  */
  qualification?: OSMContratModelDtoQualificationDto;

  /**  */
  isDerogatoryMissionLetter?: boolean;

  /**  */
  endOfMissionReason?: OSMContratModelDtoContractsMultiCriteriaEndOfMissionReasonDto;

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  endorsements?: Endorsement[];

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  clientValidationDate?: Date;

  /**  */
  missionStartDate?: Date;

  /**  */
  missionActualEndDate?: Date;

  /**  */
  startDateFlexibility?: Date;

  /**  */
  endDateFlexibility?: Date;

  /**  */
  effectiveStartDate?: Date;

  /**  */
  effectiveEndDate?: Date;
}

export interface OSMContratModelDtoMissionRequestReasonDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  requestReason?: string;

  /**  */
  requestReasonDetail?: string;

  /**  */
  civility?: string;

  /**  */
  firstname?: string;

  /**  */
  lastname?: string;

  /**  */
  qualificationLabel?: string;

  /**  */
  status?: string;

  /**  */
  receipt?: string;
}

export interface OSMContratModelDtoDetailsContractDelegationAddressesDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  zipCode?: string;

  /**  */
  city?: string;

  /**  */
  countryId?: string;

  /**  */
  inseeCode?: string;

  /**  */
  isOnAreaDelegation?: boolean;

  /**  */
  distance?: number;

  /**  */
  travelTime?: number;
}

export interface OSMContratModelDtoDetailsContractCompanyPaySectionsDto {
  /**  */
  sectionId?: string;

  /**  */
  sectionIndex?: number;

  /**  */
  label?: string;

  /**  */
  salaryPayBasisId?: string;

  /**  */
  mtReferenceSection?: number;

  /**  */
  mtPayBasis?: number;

  /**  */
  coefficientId?: string;

  /**  */
  salaryBillBasisId?: string;

  /**  */
  mtBillBasis?: number;

  /**  */
  billedBasis?: number;

  /**  */
  payRate?: number;

  /**  */
  billRate?: number;

  /**  */
  isReferenceSection?: boolean;

  /**  */
  isBilledAccordingToRh?: boolean;

  /**  */
  isMtConverted?: boolean;

  /**  */
  isSectionEdition?: string;

  /**  */
  validityStartDate?: OSMCommonModelDateDto;
}

export interface OSMContratModelDtoWeeklyWorkTimeTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface ContractDetail {
  /**  */
  contractId?: string;

  /**  */
  company?: OSMContratModelDtoCompanyDto;

  /**  */
  contactId?: string;

  /**  */
  status?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  missionId?: string;

  /**  */
  service?: OSMContratModelDtoServiceDto;

  /**  */
  qualification?: OSMContratModelDtoQualificationDto;

  /**  */
  isDerogatoryMissionLetter?: boolean;

  /**  */
  missionRequestReason?: OSMContratModelDtoMissionRequestReasonDto;

  /**  */
  delegationAddresses?: OSMContratModelDtoDetailsContractDelegationAddressesDto[];

  /**  */
  companyPaySections?: OSMContratModelDtoDetailsContractCompanyPaySectionsDto[];

  /**  */
  weeklyWorkTimeType?: OSMContratModelDtoWeeklyWorkTimeTypeDto;

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  endorsements?: Endorsement[];

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  clientValidationDate?: Date;

  /**  */
  missionStartDate?: Date;

  /**  */
  missionActualEndDate?: Date;

  /**  */
  startDateFlexibility?: Date;

  /**  */
  endDateFlexibility?: Date;

  /**  */
  effectiveStartDate?: Date;

  /**  */
  effectiveEndDate?: Date;

  /**  */
  candidate?: Candidate;

  /**  */
  contact: Contact;

  /**  */
  lastClientContact?: ContactingInfo;

  /**  */
  isRenewable: boolean;
}

export interface RenewContractBody {
  /**  */
  renewalDate: string;

  /**  */
  contactId: string;

  /**  */
  youplanMissionId?: string;
}

export interface GetPotentialQualificationsClientsParams {
  /**  */
  agencyId: string;

  /**  */
  offset: number;

  /**  */
  limit: number;

  /**  */
  brandCode: string;

  /**  */
  mainQualifications: string[];

  /**  */
  departmentIds?: string[];
}

export interface CompanyWithContact {
  /**  */
  societyStatus?: string;

  /**  */
  solvency?: SolvencyEnum;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  lastVisit?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  hasFrameworkAgreement?: boolean;

  /**  */
  delegationPotential?: number;

  /**  */
  externalCode?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  timeTrackingTool?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  contact?: Contact;
}

export interface PotentialQualificationClient {
  /**  */
  client: CompanyWithContact;

  /**  */
  potentialQualification: Qualification;
}

export interface GetMissionsBySkillMatchingParams {
  /**  */
  agencyId: string;

  /**  */
  startDate: string;

  /**  */
  endDate: string;

  /**  */
  offset: number;

  /**  */
  limit: number;

  /**  */
  brandCode: string;

  /**  */
  mainQualifications: string[];

  /**  */
  departmentIds?: string[];

  /**  */
  salesphases?: string[];
}

export interface SkillDetail {
  /**  */
  skillLabel: string;

  /**  */
  known: boolean;
}

export interface SkillMatchingMission {
  /**  */
  missionId?: string;

  /**  */
  numberPositions?: number;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  customerContact?: OSMMissionsWithoutScheduleModelDtoMissionCustomerContactDto;

  /**  */
  monitoredByConsultant?: OSMMissionsWithoutScheduleModelDtoMissionConsultantDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  statutMissionId?: string;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  agencyId?: string;

  /**  */
  dPAEAgencyId?: string;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  clientContactingStatus: ContactingStatus;

  /**  */
  candidateContactingStatus: ContactingStatus;

  /**  */
  suggestedCandidateCount?: number;

  /**  */
  distanceToTT?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  modificationDate?: Date;

  /**  */
  candidates?: CoreMissionCandidate[];

  /**  */
  matchScore?: string;

  /**  */
  skillsDetails?: SkillDetail[];

  /**  */
  contact?: Contact;
}

export interface UnitData {
  /**  */
  code: string;

  /**  */
  typology: string;

  /**  */
  fte: number;

  /**  */
  analyticalCoef: number;
}

export interface ConsultantInformation {
  /**  */
  rhpiVersion: string;

  /**  */
  unitVersion: string;

  /**  */
  dataStatus: EnumConsultantInformationDataStatus;

  /**  */
  siid: string;

  /**  */
  numBadge: string;

  /**  */
  name: string;

  /**  */
  isAdmin: boolean;

  /**  */
  consultantFunction: string;

  /**  */
  categoryFunction: string;

  /**  */
  coefOfPresence: number;

  /**  */
  unitDatas: UnitData[];
}

export interface UploadFileResult {
  /**  */
  fileType: EnumUploadFileResultFileType;

  /**  */
  fileName: string;

  /**  */
  siid: string;

  /**  */
  uploadedAt: Date;
}

export interface UploadedFilesStatus {
  /**  */
  rhpiFile?: UploadFileResult;

  /**  */
  unitFile?: UploadFileResult;

  /**  */
  coefficientFile?: UploadFileResult;

  /**  */
  trancheFile?: UploadFileResult;

  /**  */
  rhpiFunctionFile?: UploadFileResult;
}

export interface PostVSSimulateBody {
  /**  */
  unitType: string;

  /**  */
  categoryFunction: string;

  /**  */
  fteTranche: number;

  /**  */
  coefficientOfPresence: number;

  /**  */
  analyticalCoef: number;

  /**  */
  margeBruteBaseVariable: number;

  /**  */
  margeBrutePlacement: number;
}

export interface FetchFeatureFlagParams {
  /**  */
  agencyId: string;

  /**  */
  brandCode: string;
}

export interface ResolvedFeatureFlag {
  /**  */
  featureFlagId: FeatureFlag;

  /**  */
  enabled: boolean;

  /**  */
  enabledDate?: Date;
}

export interface FeatureFlagBrand {
  /**  */
  brandCode: string;

  /**  */
  activationDate?: Date;
}

export interface FeatureFlagRegion {
  /**  */
  regionId: string;

  /**  */
  activationDate?: Date;
}

export interface FeatureFlagAgency {
  /**  */
  agencyId: string;

  /**  */
  activationDate?: Date;
}

export interface FeatureFlagConfig {
  /**  */
  featureFlagId: FeatureFlag;

  /**  */
  enabledGlobally: boolean;

  /**  */
  enabledBrands: FeatureFlagBrand[];

  /**  */
  enabledRegions: FeatureFlagRegion[];

  /**  */
  enabledAgencies: FeatureFlagAgency[];
}

export interface UpdateFeatureFlagConfigParams {
  /**  */
  enabledGlobally: boolean;

  /**  */
  enabledBrands: FeatureFlagBrand[];

  /**  */
  enabledRegions: FeatureFlagRegion[];

  /**  */
  enabledAgencies: FeatureFlagAgency[];
}

export interface EstablishmentAgency {
  /**  */
  agencyId: string;

  /**  */
  agencyTitle: string;
}

export interface EstablishmentZone {
  /**  */
  zoneId: string;

  /**  */
  zoneTitle: string;

  /**  */
  agencies: EstablishmentAgency[];
}

export interface EstablishmentRegion {
  /**  */
  regionId: string;

  /**  */
  regionTitle: string;

  /**  */
  zones: EstablishmentZone[];
}

export interface CronJobConfig {
  /**  */
  cronJobId: CronJob;

  /**  */
  cronTime: string;

  /**  */
  enabled: boolean;

  /**  */
  lastExecution?: Date;
}

export interface CronJobLog {
  /**  */
  level: CronJobLogLevel;

  /**  */
  message?: object;

  /**  */
  trace?: string;
}

export interface CronJobExecution {
  /**  */
  mode: CronJobExecutionMode;

  /**  */
  executionId: string;

  /**  */
  logs: CronJobLog[];

  /**  */
  createdAt: Date;

  /**  */
  updatedAt: Date;
}

export interface CronJobDetails {
  /**  */
  cronJobId: CronJob;

  /**  */
  cronTime: string;

  /**  */
  enabled: boolean;

  /**  */
  executions: CronJobExecution[];

  /**  */
  currentExecution?: string;
}

export interface UpdateCronJobConfigParams {
  /**  */
  cronTime: string;

  /**  */
  enabled: boolean;
}

export enum DateFilterStrategy {
  'NONE' = 'NONE',
  'STARTAFTERSTARTDATE' = 'STARTAFTERSTARTDATE',
  'ISBETWEEN' = 'ISBETWEEN',
}

export enum WhyActionDelayEnum {
  'DONE' = 'DONE',
  '1DAY' = '1DAY',
  '3DAYS' = '3DAYS',
  '1WEEK' = '1WEEK',
  '2WEEKS' = '2WEEKS',
  '1MONTH' = '1MONTH',
  '3MONTHS' = '3MONTHS',
}

export enum WhyActionActorsEnum {
  'CLIENT' = 'CLIENT',
  'AGENCY' = 'AGENCY',
}

export enum WhyActionCategoriesEnum {
  'ORGANIZATION' = 'ORGANIZATION',
  'TECHNICAL' = 'TECHNICAL',
  'MANAGEMENT' = 'MANAGEMENT',
}

export enum WhyActionsEnum {
  'LIST_RISKY_POSITIONS' = 'LIST_RISKY_POSITIONS',
  'SET_POSITION_BRIEF' = 'SET_POSITION_BRIEF',
  'REVIEW_OPERATING_MODE' = 'REVIEW_OPERATING_MODE',
  'ADD_COLLECTIVE_EPI' = 'ADD_COLLECTIVE_EPI',
  'ADD_PERSONAL_EPI' = 'ADD_PERSONAL_EPI',
  'FORMALIZE_ON_BOARDING' = 'FORMALIZE_ON_BOARDING',
  'SET_TUTOR' = 'SET_TUTOR',
  'REVIEW_HABILITATIONS' = 'REVIEW_HABILITATIONS',
  'TRAIN_TO_SECURITY' = 'TRAIN_TO_SECURITY',
  'REMIND_SECURITY_INSTRUCTIONS' = 'REMIND_SECURITY_INSTRUCTIONS',
  'GET_PREVENTION_PROCESS' = 'GET_PREVENTION_PROCESS',
  'REVIEW_POSITION_BRIEF' = 'REVIEW_POSITION_BRIEF',
  'GET_RISKY_POSITIONS_LIST' = 'GET_RISKY_POSITIONS_LIST',
  'FOLLOW_MEDICAL_MONITORING' = 'FOLLOW_MEDICAL_MONITORING',
  'CHECK_OTHERS_SENSITIZATION' = 'CHECK_OTHERS_SENSITIZATION',
  'SET_QUITUS_EPI' = 'SET_QUITUS_EPI',
  'DIPLOMAS_AND_HABILITATIONS' = 'DIPLOMAS_AND_HABILITATIONS',
  'UPDATE_CONTRACTS_DATA' = 'UPDATE_CONTRACTS_DATA',
  'REMIND_OTHERS_ALERT_RIGHT' = 'REMIND_OTHERS_ALERT_RIGHT',
  'RAISE_CANDIDATE_AWARENESS' = 'RAISE_CANDIDATE_AWARENESS',
  'SEND_SENSITIZATION_MESSAGES_TO_OTHERS' = 'SEND_SENSITIZATION_MESSAGES_TO_OTHERS',
  'SUBSTITUTE_CANDIDATE' = 'SUBSTITUTE_CANDIDATE',
}

export enum WhyActionsStatusEnum {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
}

export enum ArchiveATReason {
  'DUPLICATE' = 'DUPLICATE',
  'AT_ALREADY_SENT' = 'AT_ALREADY_SENT',
  'CLIENT_SIGN_REFUSAL' = 'CLIENT_SIGN_REFUSAL',
  'CLIENT_REFUSAL' = 'CLIENT_REFUSAL',
}

export enum Laterality {
  'LEFT_HANDED' = 'LEFT_HANDED',
  'RIGHT_HANDED' = 'RIGHT_HANDED',
  'UNKNOWN' = 'UNKNOWN',
}

export enum FirstAidPersonType {
  'FIRST_AID_RESCUE_WORKER' = 'FIRST_AID_RESCUE_WORKER',
  'NURSE' = 'NURSE',
  'EMERGENCY_SERVICES' = 'EMERGENCY_SERVICES',
  'FIREFIGHTER' = 'FIREFIGHTER',
  'OTHER' = 'OTHER',
}

export enum AccidentLocation {
  'WORKSHOP' = 'WORKSHOP',
  'SERVICE' = 'SERVICE',
  'CONSTRUCTION_SITE' = 'CONSTRUCTION_SITE',
  'MISSION' = 'MISSION',
  'JOURNEY' = 'JOURNEY',
}

export enum CausedByThirdParty {
  'YES' = 'YES',
  'NO' = 'NO',
  'UNKNOWN' = 'UNKNOWN',
}

export enum YesNoUnknown {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}

export enum LateralityInjury {
  'Gauche' = 'Gauche',
  'Droit' = 'Droit',
  'Gauche et droit' = 'Gauche et droit',
}

export enum WhoGaveEpi {
  'EU' = 'EU',
  'RI' = 'RI',
  'UNKNOWN' = 'UNKNOWN',
}

export enum SigningStatusEnum {
  'DOES_NOT_WANT_TO_SIGN' = 'DOES_NOT_WANT_TO_SIGN',
  'CANNOT_SIGN' = 'CANNOT_SIGN',
  'SIGNS' = 'SIGNS',
}
export enum EnumWorkAccidentAnalysisStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
  'ARCHIVED' = 'ARCHIVED',
}
export type CombinedRandstadConsultantSignatureTypes = Signature;
export type CombinedClientSignatureTypes = Signature;
export type CombinedVictimSignatureTypes = Signature;
export type CombinedAdditionalSignatureTypes = Signature;
export enum AnalysisStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
  'ARCHIVED' = 'ARCHIVED',
}
export enum EnumFinalizeWorkAccidentAnalysisParamsBrandCode {
  '001VB' = '001VB',
  '307EE' = '307EE',
  '001RIS' = '001RIS',
}
export enum DBCDIIStatus {
  'available' = 'available',
  'suggested' = 'suggested',
  'processing' = 'processing',
}
export enum EnumActivityPlanningDayStatus {
  'Intermission' = 'Intermission',
  'ValidatedAbsence' = 'ValidatedAbsence',
  'UnvalidatedAbsence' = 'UnvalidatedAbsence',
  'CompletedTimeTrackings' = 'CompletedTimeTrackings',
  'UncompletedTimeTrackings' = 'UncompletedTimeTrackings',
  'ValidatedAbsenceCompletedTimeTrackings' = 'ValidatedAbsenceCompletedTimeTrackings',
  'UnvalidatedAbsenceCompletedTimeTrackings' = 'UnvalidatedAbsenceCompletedTimeTrackings',
  'ValidatedAbsenceUncompletedTimeTrackings' = 'ValidatedAbsenceUncompletedTimeTrackings',
  'UnvalidatedAbsenceUncompletedTimeTrackings' = 'UnvalidatedAbsenceUncompletedTimeTrackings',
  'OutOfPermanentContract' = 'OutOfPermanentContract',
  'Weekend' = 'Weekend',
  'BankHoliday' = 'BankHoliday',
  'Day' = 'Day',
  'Suspension' = 'Suspension',
  'Capped' = 'Capped',
  'HrAbsence' = 'HrAbsence',
}
export enum TemporaryWorkerStatus {
  'IN_MISSION' = 'IN_MISSION',
  'SOON_AVAILABLE' = 'SOON_AVAILABLE',
  'INTERMISSION' = 'INTERMISSION',
  'ABSENT' = 'ABSENT',
}

export enum FetchTemporaryWorkersSortCriteria {
  'ZipCode' = 'ZipCode',
  'MainAgency' = 'MainAgency',
  'AvailabilityForConsultantDate' = 'AvailabilityForConsultantDate',
}

export enum SortTypeEnum {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export enum SolvencyEnum {
  'Green' = 'Green',
  'Orange' = 'Orange',
  'Red' = 'Red',
  'Black' = 'Black',
  'None' = 'None',
}

export enum ContactingStatus {
  'NONE' = 'NONE',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'COMPLETED' = 'COMPLETED',
}
export enum EnumYouplanMissionActionSummaryAction {
  'MISSION_CREATION' = 'MISSION_CREATION',
  'CONTRACT_RENEWAL' = 'CONTRACT_RENEWAL',
  'MARKED_AS_READ' = 'MARKED_AS_READ',
}
export enum EnumYouplanMissionActionSummaryLastConflictCause {
  'CANCELLED' = 'CANCELLED',
  'MODIFIED' = 'MODIFIED',
  'REJECTED' = 'REJECTED',
}
export enum EnumCoreMissionCandidateCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCoreMissionCandidateCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum EnumYouplanMissionActionAction {
  'MISSION_CREATION' = 'MISSION_CREATION',
  'CONTRACT_RENEWAL' = 'CONTRACT_RENEWAL',
  'MARKED_AS_READ' = 'MARKED_AS_READ',
}
export enum EnumYouplanMissionActionLastConflictCause {
  'CANCELLED' = 'CANCELLED',
  'MODIFIED' = 'MODIFIED',
  'REJECTED' = 'REJECTED',
}
export enum DTORdiProgress {
  'CandidateSearch' = 'CandidateSearch',
  'MissionDetail' = 'MissionDetail',
  'MissionPrice' = 'MissionPrice',
  'RefineCandidateSearch' = 'RefineCandidateSearch',
  'ToSubmit' = 'ToSubmit',
}
export enum EnumCandidateCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCandidateCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum AppelMedicalEventSourceDto {
  'HUBLO' = 'HUBLO',
  'ARTEMIHS' = 'ARTEMIHS',
}

export enum DisplayStatusDto {
  'displayable' = 'displayable',
  'archived' = 'archived',
  'not displayable' = 'not displayable',
}

export enum ExternalDemandStatusDto {
  'COMPLETE' = 'COMPLETE',
  'MIXED' = 'MIXED',
  'ERROR' = 'ERROR',
}

export enum FetchExternalDemandsSortCriteria {
  'externalDemand.dateMission' = 'externalDemand.dateMission',
  'externalDemand.clientName' = 'externalDemand.clientName',
  'externalDemand.service' = 'externalDemand.service',
  'externalDemand.qualification' = 'externalDemand.qualification',
  'externalDemand.id' = 'externalDemand.id',
  'event.limitDate' = 'event.limitDate',
  'event.sourceId' = 'event.sourceId',
}

export enum AppelMedicalEventStatusDto {
  'Commande mise à jour' = 'Commande mise à jour',
  'Commande créée' = 'Commande créée',
  'Commande réactivée' = 'Commande réactivée',
  'Commande Annulée' = 'Commande Annulée',
  'Commande pourvue' = 'Commande pourvue',
  'Commande confirmée' = 'Commande confirmée',
  'Erreur annulation' = 'Erreur annulation',
  'Erreur commande pourvue' = 'Erreur commande pourvue',
  'Erreur confirmation' = 'Erreur confirmation',
  'Erreur' = 'Erreur',
  'Ignorée' = 'Ignorée',
}

export enum TypeFieldDto {
  'QUALIFICATION' = 'QUALIFICATION',
  'SERVICE' = 'SERVICE',
  'DOMAIN' = 'DOMAIN',
}
export enum EnumSearchMissionsBodySortBy {
  'date de début' = 'date de début',
  'date de fin' = 'date de fin',
  'nombre de postes' = 'nombre de postes',
}
export enum EnumSearchMissionsBodySortOrder {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}
export enum EnumCreateActionBodyActionType {
  'SMS' = 'SMS',
  'CALL' = 'CALL',
  'EMAIL' = 'EMAIL',
}
export enum EnumCreateActionBodyActionTarget {
  'CONTACT' = 'CONTACT',
  'CANDIDATE' = 'CANDIDATE',
}
export enum EnumCreateActionBodyBrandCode {
  '001VB' = '001VB',
  '307EE' = '307EE',
  '001RIS' = '001RIS',
}
export enum EnumCandidateAdvancementInfoCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCandidateAdvancementInfoCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum EnumUpdateCandidateStatusBodyRdiProgress {
  'CandidateSearch' = 'CandidateSearch',
  'MissionDetail' = 'MissionDetail',
  'MissionPrice' = 'MissionPrice',
  'RefineCandidateSearch' = 'RefineCandidateSearch',
  'ToSubmit' = 'ToSubmit',
}
export enum EnumPositionStudyCompletionStatus {
  'UP_TO_DATE' = 'UP_TO_DATE',
  'MISSING_INFO' = 'MISSING_INFO',
  'PENDING_UPDATE' = 'PENDING_UPDATE',
}
export enum EnumConsultantInformationDataStatus {
  'CONSULTANT_FOUND' = 'CONSULTANT_FOUND',
  'CONSULTANT_NOT_FOUND' = 'CONSULTANT_NOT_FOUND',
  'CONSULTANT_FOR_TEST' = 'CONSULTANT_FOR_TEST',
}
export enum EnumUploadFileResultFileType {
  'RHPI_FILE' = 'RHPI_FILE',
  'UNIT_FILE' = 'UNIT_FILE',
  'COEFFICIENT_FILE' = 'COEFFICIENT_FILE',
  'TRANCHE_FILE' = 'TRANCHE_FILE',
  'RHPI_FUNCTION_FILE' = 'RHPI_FUNCTION_FILE',
}
export enum FeatureFlag {
  'ACTIVITY' = 'ACTIVITY',
  'MISSION_CREATION_EDP_ONLY' = 'MISSION_CREATION_EDP_ONLY',
  'TALENTS' = 'TALENTS',
  'ACCIDENTOLOGY' = 'ACCIDENTOLOGY',
  'EXTERNAL_DEMANDS' = 'EXTERNAL_DEMANDS',
  'VARIABLE_SIMULATOR' = 'VARIABLE_SIMULATOR',
  'VARIABLE_SIMULATOR_DEBUG' = 'VARIABLE_SIMULATOR_DEBUG',
  'TMP_MISSION_CREATION_SHOW_NEW_SCREEN' = 'TMP_MISSION_CREATION_SHOW_NEW_SCREEN',
  'TMP_REPOSITIONING_CDII' = 'TMP_REPOSITIONING_CDII',
  'TMP_REPOSITIONING_CDII_PPA_RANDSTAD' = 'TMP_REPOSITIONING_CDII_PPA_RANDSTAD',
  'TMP_REPOSITIONING_TEMPORARY_WORKERS' = 'TMP_REPOSITIONING_TEMPORARY_WORKERS',
}

export enum CronJob {
  'DELETE_OLD_CRON_JOB_LOGS' = 'DELETE_OLD_CRON_JOB_LOGS',
  'DELETE_OLD_WORK_ACCIDENT_ANALYSIS' = 'DELETE_OLD_WORK_ACCIDENT_ANALYSIS',
  'REMIND_SUGGESTION_TO_AGENCIES' = 'REMIND_SUGGESTION_TO_AGENCIES',
  'FILL_CANDIDATE_AVAILABILITY_DATES' = 'FILL_CANDIDATE_AVAILABILITY_DATES',
  'ARCHIVE_EXTERNAL_DEMANDS' = 'ARCHIVE_EXTERNAL_DEMANDS',
}

export enum CronJobExecutionMode {
  'SCHEDULED' = 'SCHEDULED',
  'MANUAL' = 'MANUAL',
  'TESTING' = 'TESTING',
}

export enum CronJobLogLevel {
  'INFO' = 'INFO',
  'WARN' = 'WARN',
  'DEBUG' = 'DEBUG',
  'ERROR' = 'ERROR',
  'VERBOSE' = 'VERBOSE',
}
