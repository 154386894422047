import { ToggleMenu, ToggleMenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedState } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { AgencyCdiiList } from './AgencyCdiiList';
import { defaultAgencySort } from './AgencyCdiiList/AgencyCdiiList.types';
import { RegionalCdiiList } from './RegionalCdiiList';
import { defaultRegionalSort } from './RegionalCdiiList/RegionalCdiiList.types';
import styles from './CdiiList.module.scss';
import { CdiiSource } from './CdiiList.types';
import { RegionalCdiiTopBarContent } from './RegionalCdiiTopBarContent';
import { AgencyCdiiTopBarContent } from './AgencyCdiiTopBarContent';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { RepositioningCdiiList } from './RepositioningCdiiList';
import { defaultRepositioningSort } from './RepositioningCdiiList/RepositioningCdiiList.types';
import { RepositioningCdiiTopBarContent } from './RepositioningCdiiTopBarContent';

export const CdiiListRoute = () => {
  const navigate = useNavigate();
  const {
    agencySort: initialAgencySort,
    repositioningSort: initialRepositioningSort,
    regionalSort: initialRegionalSort,
  } = useTypedState(ROUTES.TALENTS.CDII);

  const userInfo = useSelector(getAnalyticsUserInfo);

  const { data: isCGC } = useIsCGC();

  const [agencyGlobalFilter, setAgencyGlobalFilter] = useState('');
  const [agencySort, setAgencySort] = useState(initialAgencySort ?? defaultAgencySort);
  const [repositioningGlobalFilter, setRepositioningGlobalFilter] = useState('');
  const [repositioningSort, setRepositioningSort] = useState(
    initialRepositioningSort ?? defaultRepositioningSort
  );
  const [regionalGlobalFilter, setRegionalGlobalFilter] = useState('');
  const [regionalSort, setRegionalSort] = useState(initialRegionalSort ?? defaultRegionalSort);

  const [selectedSource, setSelectedSource] = useState(
    useTypedHash(ROUTES.TALENTS.CDII) ?? CdiiSource.agency
  );

  useEffect(() => {
    navigate(ROUTES.TALENTS.CDII.buildPath({}, {}, selectedSource), {
      replace: true,
      state: ROUTES.TALENTS.CDII.buildState({
        agencySort,
        regionalSort,
      }),
    });
  }, [navigate, selectedSource, agencySort, regionalSort]);

  useEffect(
    () =>
      ReactGA.event(ANALYTICS_EVENT.CLICK_CDII_SCREEN, {
        ...userInfo,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <TopBar
        title={
          <>
            <ToggleMenu
              value={selectedSource}
              onChange={selectedSource => {
                setSelectedSource(selectedSource);

                if (selectedSource === CdiiSource.agency)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_REPOSITIONING, {
                    ...userInfo,
                  });
                if (selectedSource === CdiiSource.repositioning)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_REPOSITIONED, {
                    ...userInfo,
                  });
                if (selectedSource === CdiiSource.regional)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_FROM_MY_REGION, {
                    ...userInfo,
                  });
              }}
            >
              <ToggleMenuItem
                value={CdiiSource.agency}
                title={`retrouvez dans cette liste tous les CDII inscrits sur votre ${
                  isCGC ? 'périmètre agence' : 'unité'
                }`}
              >
                mes cdii
              </ToggleMenuItem>
              <ToggleMenuItem
                value={CdiiSource.repositioning}
                title="retrouvez dans cette liste tous les CDII qui ont une étape de repositionnement en cours"
              >
                cdii en repositionnement
              </ToggleMenuItem>
              {isCGC === false && (
                <ToggleMenuItem
                  value={CdiiSource.regional}
                  title="retrouvez dans cette liste tous les CDII de votre région dont la date de dispo est dépassée depuis +7 jours"
                >
                  cdii de ma région
                </ToggleMenuItem>
              )}
            </ToggleMenu>
          </>
        }
        comments={
          isCGC !== undefined
            ? selectedSource === CdiiSource.agency
              ? `retrouvez dans cette liste tous les CDII inscrits sur votre ${isCGC ? 'périmètre agence' : 'unité'}`
              : selectedSource === CdiiSource.repositioning
                ? 'retrouvez dans cette liste tous les CDII qui ont une étape de repositionnement en cours'
                : 'retrouvez dans cette liste tous les CDII de votre région dont la date de dispo est dépassée depuis +7 jours'
            : undefined
        }
      >
        {selectedSource === CdiiSource.agency ? (
          <AgencyCdiiTopBarContent
            globalFilter={agencyGlobalFilter}
            setGlobalFilter={setAgencyGlobalFilter}
            sort={agencySort}
            setSort={setAgencySort}
          />
        ) : selectedSource === CdiiSource.repositioning ? (
          <RepositioningCdiiTopBarContent
            globalFilter={repositioningGlobalFilter}
            setGlobalFilter={setRepositioningGlobalFilter}
            sort={repositioningSort}
            setSort={setRepositioningSort}
          />
        ) : selectedSource === CdiiSource.regional ? (
          <RegionalCdiiTopBarContent
            globalFilter={regionalGlobalFilter}
            setGlobalFilter={setRegionalGlobalFilter}
            sort={regionalSort}
            setSort={setRegionalSort}
          />
        ) : null}
      </TopBar>
      <div className={styles.container}>
        {selectedSource === CdiiSource.agency ? (
          <AgencyCdiiList globalFilter={agencyGlobalFilter} sort={agencySort} />
        ) : selectedSource === CdiiSource.repositioning ? (
          <RepositioningCdiiList
            globalFilter={repositioningGlobalFilter}
            sort={repositioningSort}
          />
        ) : selectedSource === CdiiSource.regional ? (
          <RegionalCdiiList globalFilter={regionalGlobalFilter} sort={regionalSort} />
        ) : null}
      </div>
    </>
  );
};
