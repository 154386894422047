import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopBar } from 'src/Components/TopBar';
import { useFetchMissions } from 'src/Hooks/Missions/useFetchMissions';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getMissionsSortBy, getMissionsSortOrder } from 'src/Redux/Missions/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { getSelectedSalesphases, getStartDate } from 'src/Redux/Search/Selectors';
import { getCurrentService } from 'src/Redux/Services/Selectors';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './Missions.module.scss';
import { MissionsTable } from './MissionsTable';
import { MissionsTopBarComponent } from './MissionsTopBarComponent';
import { getCurrentCandidate } from 'src/Redux/CDICandidates/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { getSelectedAgencyConsultant } from 'src/Redux/AgencyConsultant/Selectors';

export const MissionsRoute = () => {
  const dispatch = useDispatch();
  const currentQualification = useSelector(getCurrentQualification);
  const currentAgencyConsultant = useSelector(getSelectedAgencyConsultant);
  const currentCompany = useSelector(getCurrentCompany);
  const currentCandidate = useSelector(getCurrentCandidate);
  const currentService = useSelector(getCurrentService);
  const salesphases = useSelector(getSelectedSalesphases);
  const sortBy = useSelector(getMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const pivotDate = useSelector(getStartDate);
  const setPivotDate = useCallback(
    (pivotDate: Date) => dispatch(searchActions.setStartDate(pivotDate.toISOString())),
    [dispatch]
  );

  const [globalFilter, setGlobalFilter] = useState('');

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useFetchMissions({
    startDate: pivotDate.toISOString(),
    qualificationIds: currentQualification?.id ? [currentQualification?.id] : undefined,
    monitoredByConsultantId: currentAgencyConsultant?.consultantId,
    companyId: currentCompany?.companyId,
    candidateIds: currentCandidate?.candidateId ? [currentCandidate.candidateId] : undefined,
    serviceId: currentService?.serviceId,
    salesphases: salesphases.map(salesphase => salesphase.id).filter(Boolean),
    sortBy,
    sortOrder,
  });

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll, globalFilter]);

  const missionsList = useMemo(
    () => data?.pages.flatMap(page => page.missions) ?? [],
    [data?.pages]
  );

  const totalMissions = useMemo(
    () => data?.pages.map(page => page.totalMissions).reduce((a, b) => a + b, 0) ?? 0,
    [data?.pages]
  );

  return (
    <div className={styles.container}>
      <TopBar
        title={
          !data?.pages.length
            ? 'commandes'
            : hasNextPage
              ? `${totalMissions}+ ${pluralFormat(totalMissions, 'commande', false)}`
              : pluralFormat(totalMissions, 'commande')
        }
      >
        <MissionsTopBarComponent
          pivotDate={pivotDate}
          setPivotDate={setPivotDate}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </TopBar>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            chargement des commandes en cours
          </div>
        ) : !data ? (
          <div className={styles.error}>
            <ServerErrorRed />
            Une erreur s'est produite lors du chargement des commandes
          </div>
        ) : data.pages.length === 0 ? (
          <div className={styles.empty}>
            <VidePasteque />
            Aucune commande ne correspond à votre recherche
          </div>
        ) : (
          <div className={styles.scrollContainer} onScroll={handleScroll} ref={scrollViewRef}>
            <MissionsTable missions={missionsList} globalFilter={globalFilter} />
            {isFetching && (
              <div className={styles.loadingNext}>
                <Loader heightInRem={4.5} className={styles.loader} />
                <div>
                  chargement
                  <br />
                  des commandes suivantes
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
