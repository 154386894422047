import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { SkillsManagementCentersService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useIsCGC } from './useIsCGC';

export const useFetchCgcAgenciesWithDistance = (postalCode?: string) => {
  const agencyId = useSelector(getCurrentAgency);

  const { data: isCgc } = useIsCGC();

  return useQuery(
    [QueryKeys.fetchCgcAgenciesWithDistance, postalCode],
    async () => {
      return await SkillsManagementCentersService.skillsManagementCentersControllerGetCgcAgenciesWithDistance(
        { cgcId: agencyId, postalCode }
      );
    },
    {
      enabled: Boolean(isCgc),
    }
  );
};
