import { Validation } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  FetchButton,
  Modal,
  ModalActions,
  ModalContent,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchCandidateLastMedicalVisit } from 'src/Hooks/Candidate/useFetchCandidateLastMedicalVisit';
import { useSendSuggestionToAgencies } from 'src/Hooks/Candidate/useSendSuggestionToAgencies';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { toDateFormatted } from 'src/Utils/datetime';
import { isInternEmails } from 'src/Utils/emails';
import { formatName } from 'src/Utils/formatName';
import { parseEmails } from 'src/Utils/parseEmails';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './CGCMissionPropositionModal.module.scss';
import { CGCMissionPropositionModalProps } from './CGCMissionPropositionModal.types';

const CGCMissionPropositionModal = ({
  candidateData,
  selectedMissions,
  statut,
  open,
  onClose,
}: CGCMissionPropositionModalProps) => {
  const navigate = useNavigate();
  const cgcId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const { data: lastMedicalVisit } = useFetchCandidateLastMedicalVisit(candidateData.id);

  const sendSuggestionToAgencies = useSendSuggestionToAgencies();
  const sendSuggestionToAgenciesFetchStatus = toFetchStatus(sendSuggestionToAgencies);

  const [cc, setCc] = useState<string>('');

  const [message, setMessage] = useState<string>(
    candidateData.sosComment
      ? `message de l'agence ${candidateData.sosAgencyId} lors du sos :
${candidateData.sosComment}`
      : ''
  );

  const ccMailsAreValid = useMemo(() => {
    const emails = parseEmails(cc);
    if (emails[0] === '') return true;
    return isInternEmails(emails);
  }, [cc]);

  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<Validation />} title="suggestions aux agences">
        <p className={styles.text}>
          Vous avez suggéré le talent sur {pluralFormat(selectedMissions.length, 'commande')}.
          Veuillez saisir un message, des remarques à propos de ce cdii à destination des agences.
        </p>
        <WithLightTitle title="cc (mails séparés d'une virgule)" className={styles.checkText}>
          <TextInput value={cc} error={!ccMailsAreValid} onValueChange={setCc} />
        </WithLightTitle>
        {!ccMailsAreValid && (
          <div className={styles.warningMessage}>
            une ou plusieurs adresses mails sont invalides
          </div>
        )}
        <WithLightTitle title="message (min 10 caractères)" className={styles.checkText}>
          <TextArea
            value={message}
            onChange={event => setMessage((event.target as HTMLTextAreaElement).value)}
            className={styles.reportTextArea}
            maxLength={REPORT_MAX_LENGTH}
            placeholder={
              selectedMissions.length > 1
                ? 'les raisons de ces suggestions'
                : 'les raisons de cette suggestion'
            }
          />
        </WithLightTitle>
      </ModalContent>
      <ModalActions side="right">
        <Button variant="secondary" onClick={() => onClose?.()}>
          annuler
        </Button>
        <FetchButton
          fetchStatus={sendSuggestionToAgenciesFetchStatus}
          title="envoyer"
          onClick={() => {
            return sendSuggestionToAgencies.mutate({
              cgcId,
              cc: parseEmails(cc),
              comment: message,
              candidateId: candidateData.id ?? '',
              candidateFullName: formatName(candidateData) ?? '',
              candidateMainQualification: candidateData.mainQualification?.label ?? 'inconnue',
              lastContractEndDate:
                toDateFormatted(candidateData.lastContract?.endDate, 'L') ?? 'non renseignée',
              lastMedicalVisit: lastMedicalVisit
                ? moment(lastMedicalVisit).format('DD/MM/YYYY')
                : 'inconnue',
              suggestedMissions: selectedMissions.map(mission => {
                return {
                  clientName: mission.company?.name ?? 'inconnu',
                  agencyId: mission.agencyId ?? 'inconnu',
                  missionNumber: mission.missionId ?? 'inconnu',
                  qualificationName: mission.qualification?.label ?? 'inconnue',
                  startDate: mission?.startDate
                    ? moment(mission.startDate).format('DD/MM/YYYY')
                    : 'inconnue',
                };
              }),
            });
          }}
          disabled={message.length < 10 || !ccMailsAreValid}
          onSuccess={() => {
            ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_SUGGEST, {
              ...userInfo,
              suggestedOrderCount: selectedMissions.length,
              statut,
            });
            onClose?.();
            navigate(ROUTES.TALENTS.CDII.buildPath({}));
          }}
        />
      </ModalActions>
    </Modal>
  );
};

export default CGCMissionPropositionModal;
