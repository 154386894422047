import { ExternalDemandElement, isDemandEvent } from './ExternalDemandsList.types';
import {
  AppelMedicalEventStatusDto,
  ExternalDemandStatusDto,
  FetchExternalDemandsParams,
  FetchExternalDemandsSort,
  FetchExternalDemandsSortCriteria,
  SortTypeEnum,
} from 'src/Services/API';

import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

export const ROULEMENT_ERROR = 'Erreur: un roulement est déjà positionné pour ce jour';
export const TECHNICAL_ERROR = 'Erreur technique :';
export const CANCEL_ERROR = 'demande annulée par le client mais des Talents y sont rattachés';
export const ANNULATION_ERROR = 'Candidats non M présents';

const causeTransformer = (text?: string) => {
  if (!text) return "Contacter l'assistance";
  if (text.includes(ROULEMENT_ERROR)) return 'Un roulement est déja positionné pour ce jour';
  if (text.includes(TECHNICAL_ERROR)) return 'Problème Osmose';
  if (text.includes(CANCEL_ERROR)) return CANCEL_ERROR;
  if (text.length > 40) return "Contacter l'assistance";
  if (text.includes(ANNULATION_ERROR))
    return "erreur d'annulation: candidats non M présents, merci de les supprimer depuis osmose";
  return text;
};

export const formatStatus = (element: ExternalDemandElement) => {
  if (isDemandEvent(element)) {
    switch (element.status) {
      case AppelMedicalEventStatusDto.Ignorée:
        return 'roulement ignoré';
      case AppelMedicalEventStatusDto['Commande confirmée']:
        return 'roulement confirmé';
      case AppelMedicalEventStatusDto['Commande réactivée']:
        return 'roulement réactivé';
      case AppelMedicalEventStatusDto['Commande Annulée']:
        return 'roulement annulé';
      case AppelMedicalEventStatusDto['Commande pourvue']:
        return 'roulement pourvu';
      case AppelMedicalEventStatusDto['Commande mise à jour']:
        return 'roulement créé';
      case AppelMedicalEventStatusDto['Commande créée']:
        if (element.cause !== CANCEL_ERROR) return 'roulement créé';
        return causeTransformer(element.cause);
      default:
        return causeTransformer(element.cause);
    }
  } else {
    if (element.status === ExternalDemandStatusDto.COMPLETE) return 'demande traitée';
    if (element.status === ExternalDemandStatusDto.MIXED) return 'des erreurs se sont produites';
    return element.events.length > 1
      ? 'demande à traiter'
      : causeTransformer(element.events[0].cause);
  }
};

export const formatFilters = (
  columnFilters: ColumnFiltersState
): Pick<
  FetchExternalDemandsParams,
  | 'clientName'
  | 'service'
  | 'qualification'
  | 'missionStartDate'
  | 'missionEndDate'
  | 'limitStartDate'
  | 'limitEndDate'
  | 'status'
> => {
  const filters: Pick<
    FetchExternalDemandsParams,
    | 'clientName'
    | 'service'
    | 'qualification'
    | 'missionStartDate'
    | 'missionEndDate'
    | 'limitStartDate'
    | 'limitEndDate'
    | 'status'
  > = {};

  for (const column of columnFilters as {
    id: FetchExternalDemandsSortCriteria | string;
    value: string[];
  }[]) {
    switch (column.id) {
      case FetchExternalDemandsSortCriteria['externalDemand.dateMission']: {
        filters.missionStartDate = new Date(column.value[0]).toISOString();
        filters.missionEndDate = new Date(column.value[1]).toISOString();
        break;
      }
      case FetchExternalDemandsSortCriteria['externalDemand.clientName']: {
        filters.clientName = column.value;
        break;
      }
      case FetchExternalDemandsSortCriteria['externalDemand.service']: {
        filters.service = column.value;
        break;
      }
      case 'status': {
        const items = column.value.filter((item): item is ExternalDemandStatusDto =>
          Object.values(ExternalDemandStatusDto).includes(item)
        );
        if (items.length) filters.status = items;
        break;
      }
      case FetchExternalDemandsSortCriteria['externalDemand.qualification']: {
        filters.qualification = column.value;
        break;
      }
      case FetchExternalDemandsSortCriteria['event.limitDate']: {
        filters.limitStartDate = new Date(column.value[0]).toISOString();
        filters.limitEndDate = new Date(column.value[1]).toISOString();
        break;
      }
    }
  }

  return filters;
};

export const formatSorting = (state: SortingState): FetchExternalDemandsSort | undefined => {
  if (!state.length) return undefined;
  return {
    sortCriteria: state[0].id as FetchExternalDemandsSortCriteria,
    sortType: state[0].desc ? SortTypeEnum.DESC : SortTypeEnum.ASC,
  };
};
