import { useEffect, useMemo, useState } from 'react';

import { OtherHorizontal } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Recherche,
  ServerErrorRed,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, DataTable, Tooltip } from '@randstad-lean-mobile-factory/react-components-core';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { YouplanMissionBadge } from './YouplanMissionBadge';
import { useFetchYouplanMissions } from 'src/Hooks/Youplan/useFetchYouplanMissions';
import { useMarkYouplanMissionsAsRead } from 'src/Hooks/Youplan/useMarkYouplanMissionsAsRead';
import { MISSION_CREATION_STEP } from 'src/ModalRoutes_DEPRECATED/MissionCreationModal/MissionCreationModal.types';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { fetchYouplanMissionForNewMission } from 'src/Redux/MissionCreation/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import {
  getCurrentYouplanCompany,
  getYouplanSearchInput,
} from 'src/Redux/YouplanCompanies/Selectors';
import {
  EnumCoreMissionCandidateCandidateAdvancement,
  EnumCoreMissionCandidateCompanyAdvancement,
  EnumYouplanMissionActionSummaryAction,
  YouplanMission,
} from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import { pluralFormat } from 'src/Utils/pluralFormat';
import YouplanMissionActions from './YouplanMissionActions';
import styles from './YouplanMissionDetail.module.scss';
import YouplanMissionsFooter from './YouplanMissionsFooter';

const YouplanMissions = () => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const locationState = useMemo(
    () => location.state as { isCreationSuccess?: boolean } | undefined,
    [location]
  );
  const currentCompany = useSelector(getCurrentYouplanCompany);
  const searchInput = useSelector(getYouplanSearchInput);

  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const {
    data: missionPages,
    fetchNextPage,
    hasNextPage,
    isError,
    isSuccess,
    isFetchingNextPage,
    isLoading,
  } = useFetchYouplanMissions({
    companyId: currentCompany?.companyId,
  });
  const markYouplanMissionsAsRead = useMarkYouplanMissionsAsRead({
    onSuccess: () => {
      setRowSelection({});
    },
    onSettled: () => {
      setTimeout(() => markYouplanMissionsAsRead.reset(), 500);
    },
  });

  const missions = useMemo(() => (missionPages?.pages ?? []).flat(), [missionPages?.pages]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const selectedMissionIds = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const fullSelectedMissions = useMemo(
    () => missions.filter(mission => selectedMissionIds.includes(mission.missionId ?? '')),
    [missions, selectedMissionIds]
  );

  const isSomeCandidateSelectedMultipleTimes = useMemo(() => {
    const selectedMissionsCandidatesIds = fullSelectedMissions
      .flatMap(item => item.candidates ?? [])
      .map(item => item.candidateId)
      .filter(Boolean);
    return selectedMissionsCandidatesIds.some(
      (item, index) => selectedMissionsCandidatesIds.indexOf(item) !== index
    );
  }, [fullSelectedMissions]);
  const isSomeSelectedMissionCancelled = useMemo(
    () => fullSelectedMissions.some(mission => mission.salesPhase?.id === 'AN'),
    [fullSelectedMissions]
  );
  const isSomeSelectedMissionAlreadyCreated = useMemo(
    () =>
      fullSelectedMissions.some(
        mission =>
          mission.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.MISSION_CREATION
      ),
    [fullSelectedMissions]
  );

  useEffect(() => {
    if (locationState?.isCreationSuccess) {
      setRowSelection({});
    }
  }, [locationState]);

  useEffect(() => {
    setRowSelection({});
  }, [currentCompany]);

  const columns = useMemo(
    (): ColumnDef<YouplanMission>[] => [
      {
        id: 'name',
        header: 'nom intérimaire',
        accessorFn: mission => mission.candidates?.map(formatName).filter(Boolean).join('\n'),
        cell: ({ row }) => {
          const mission = row.original;
          const isCancelled = mission.salesPhase?.id === 'AN';
          const isOkOk =
            mission.candidates?.some(
              candidate =>
                candidate.candidateAdvancement ===
                  EnumCoreMissionCandidateCandidateAdvancement.accepté &&
                candidate.companyAdvancement === EnumCoreMissionCandidateCompanyAdvancement.accepté
            ) ?? false;

          return (
            <div>
              <div className={styles.name}>
                {formatName(mission.candidates?.at(0)) ?? "pas d'intérimaire sur cette mission"}
              </div>
              <YouplanMissionBadge
                status={mission?.youplanStatus}
                isCancelled={isCancelled}
                isOkOk={isOkOk}
              />
            </div>
          );
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: 'period',
        header: 'période',
        accessorFn: mission => {
          return mission?.startDate ? moment(mission.startDate, 'DD/MM/YYYY').valueOf() : undefined;
        },
        cell: ({ row }) => {
          const momentStartDate = moment(row.original?.startDate);
          const momentEndDate = moment(row.original?.endDate);
          return (
            <div>
              {momentStartDate.isSame(momentEndDate) ? (
                <div>{`le ${momentStartDate.format('L')}`}</div>
              ) : (
                <>
                  <div>{`du ${momentStartDate.format('L')}`}</div>
                  <div> {`au ${momentEndDate.format('L')}`}</div>
                </>
              )}
            </div>
          );
        },
        sortUndefined: false,
        meta: {
          isDate: true,
          columnSortingLabel: 'par ordre de date de début',
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'qualification',
        header: 'qualification',
        accessorFn: mission => mission?.qualification?.label,
        filterFn: 'arrIncludes',
      },
      {
        id: 'processing',
        header: 'date de traitement',
        accessorFn: mission => {
          return mission?.processingDate ? moment(mission.processingDate).valueOf() : undefined;
        },
        cell: ({ row }) => {
          return row.original?.processingDate
            ? moment(row.original?.processingDate).format('DD/MM - HH:mm')
            : null;
        },
        sortUndefined: false,
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'actions',
        header: () => <div className={styles.justifyCenter}>actions</div>,
        cell: ({ row }) => {
          return (
            <div className={styles.justifyRight}>
              <YouplanMissionActions
                mission={row.original}
                candidateId={row.original.candidates?.[0]?.candidateId!}
                trigger={
                  <Button data-no-padding variant="tertiary" size="medium">
                    <OtherHorizontal variant="fill" />
                  </Button>
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      {isSuccess && missions.length !== 0 && (
        <>
          <div className={styles.companyContainer}>
            <div className={styles.title}>{currentCompany?.companyName}</div>
            <div className={styles.siret}>{currentCompany?.siret}</div>
          </div>
          <div className={styles.actionBar}>
            <Tooltip
              title={
                isSomeCandidateSelectedMultipleTimes
                  ? 'un candidat est sélectionné dans plusieurs missions'
                  : isSomeSelectedMissionAlreadyCreated
                    ? 'une mission sélectionnée est déjà en création'
                    : isSomeSelectedMissionCancelled
                      ? 'une mission sélectionnée est en annulation'
                      : null
              }
            >
              <Button
                size="medium"
                onClick={() => {
                  dispatch(missionCreationActions.setYouplanMissionIds(selectedMissionIds));

                  dispatch(
                    fetchYouplanMissionForNewMission(fullSelectedMissions.at(0)?.missionId ?? '')
                  );
                  dispatch(missionCreationActions.setNumberPositions(selectedMissionIds.length));
                  dispatch(
                    missionCreationActions.setYouPlanMissionCandidates(
                      fullSelectedMissions.flatMap(mission => mission.candidates ?? [])
                    )
                  );
                  navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
                    state: {
                      modalParameters: {
                        openingStep: MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY,
                      },
                      background: location,
                    },
                  });
                }}
                disabled={
                  !selectedMissionIds.length ||
                  isSomeCandidateSelectedMultipleTimes ||
                  isSomeSelectedMissionAlreadyCreated ||
                  isSomeSelectedMissionCancelled
                }
              >
                créer une commande
              </Button>
            </Tooltip>
            <Tooltip
              title={
                isSomeSelectedMissionAlreadyCreated
                  ? 'une mission sélectionnée est déjà en création'
                  : null
              }
            >
              <Button
                size="medium"
                variant="secondary"
                onClick={() => {
                  markYouplanMissionsAsRead.mutate({
                    youplanMissionIds: selectedMissionIds,
                    startDate: fullSelectedMissions.at(0)?.startDate!,
                    endDate: fullSelectedMissions.at(0)?.endDate!,
                    companyId: fullSelectedMissions.at(0)?.company?.id!,
                    dpaeAgencyId: fullSelectedMissions.at(0)?.dPAEAgencyId!,
                  });
                }}
                mutationStatus={markYouplanMissionsAsRead.status}
                disabled={!selectedMissionIds.length || isSomeSelectedMissionAlreadyCreated}
              >
                marquer traité
              </Button>
            </Tooltip>
            <div className={styles.selectedMissionsNumber}>
              {pluralFormat(selectedMissionIds.length, 'commande sélectionnée')}
            </div>
          </div>
          <DataTable
            size="medium"
            columns={columns}
            data={missions}
            getRowId={mission => mission.missionId ?? ''}
            enableRowSelection
            stickyHeader
            containerClassName={styles.table}
            state={{ rowSelection, globalFilter: searchInput }}
            onRowSelectionChange={setRowSelection}
          />
        </>
      )}
      {isSuccess && !isLoading && missions.length === 0 && (
        <div className={styles.illustration}>
          <Recherche />
          pas de résultats
        </div>
      )}
      {isError && (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur est survenue
        </div>
      )}
      <YouplanMissionsFooter
        canLoadMore={hasNextPage}
        onLoadMore={fetchNextPage}
        isFetching={isLoading || isFetchingNextPage}
      />
    </div>
  );
};

export default YouplanMissions;
