import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ExternalDemand, ExternalDemandsService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useArchiveManuallyExternalDemands = (
  extraOptions?: UseMutationOptions<void, unknown, ExternalDemand[], unknown>
) => {
  const queryClient = useQueryClient();
  const agencyId = useSelector(getCurrentAgency);
  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(
    [MutationKeys.archiveManuallyExternalDemands],
    async (externalDemands: ExternalDemand[]) => {
      await ExternalDemandsService.externalDemandsControllerArchiveManuallyDemands({
        body: {
          agencyId,
          brandCode,
          externalDemands: externalDemands.map(externalDemand => ({ ...externalDemand, agencyId })),
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchExternalDemands]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
