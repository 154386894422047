import { CircleAdd, Folder } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { ServerErrorRed } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Drawer, Loader, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { TopBar } from 'src/Components/TopBar';
import { useFetchWorkAccidentAnalyses } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalyses';
import { ArchivedWorkAccidentAnalysesDrawerContent } from './ArchivedWorkAccidentAnalysesDrawerContent';
import { CreateWorkAccidentAnalysisModalContent } from './CreateWorkAccidentAnalysisModalContent';
import styles from './WorkAccidentAnalysisKanban.module.scss';
import { WorkAccidentAnalysisKanbanColumn } from './WorkAccidentAnalysisKanbanColumn';

export const WorkAccidentAnalysisKanbanRoute = (): JSX.Element => {
  const { data: workAccidentAnalyses, isLoading } = useFetchWorkAccidentAnalyses();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <TopBar title="analyses AT">
        <Button size="medium" onClick={() => setIsCreateModalOpen(true)}>
          <CircleAdd /> ajouter
        </Button>
        <Button variant="secondary" size="medium" onClick={() => setIsArchivedModalOpen(true)}>
          <Folder /> AT classées
        </Button>
      </TopBar>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des analyses AT
          </div>
        ) : !workAccidentAnalyses ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : (
          <>
            <WorkAccidentAnalysisKanbanColumn data={workAccidentAnalyses.todo} status="TODO" />
            <WorkAccidentAnalysisKanbanColumn
              data={workAccidentAnalyses.inProgress}
              status="IN_PROGRESS"
            />
            <WorkAccidentAnalysisKanbanColumn data={workAccidentAnalyses.done} status="DONE" />
          </>
        )}
      </div>
      <Modal size="medium" open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
        <CreateWorkAccidentAnalysisModalContent onClose={() => setIsCreateModalOpen(false)} />
      </Modal>
      <Drawer open={isArchivedModalOpen} onClose={() => setIsArchivedModalOpen(false)}>
        <ArchivedWorkAccidentAnalysesDrawerContent />
      </Drawer>
    </div>
  );
};
