import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { WorkAccidentAnalysisService, WorkAccidentFileData } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidentFiles = (
  analyseId: string,
  extraOptions?: UseQueryOptions<WorkAccidentFileData[], unknown, WorkAccidentFileData[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchWorkAccidentFiles, analyseId],
    async () => {
      const positionStudy =
        await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentFiles({
          id: analyseId,
        });
      return positionStudy;
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
