import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import store, { persistor } from 'src/Redux/store';
import { PersistGate } from 'redux-persist/integration/react';

export const ReduxProvider = ({ children }: PropsWithChildren) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </Provider>
);
