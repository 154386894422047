import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidatesService } from 'src/Services/API';

interface updateSubscriptionParams {
  candidateId: string;
  decryptedAgenciesIds: string[];
}

export const useUpdateCandidateSubscription = (
  extraOptions?: UseMutationOptions<void, unknown, updateSubscriptionParams, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateSubscription],
    async (params: updateSubscriptionParams) => {
      return CandidatesService.candidatesControllerUpdateSubscription({
        body: {
          candidateId: params.candidateId,
          decryptedAgenciesIds: params.decryptedAgenciesIds,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([
          QueryKeys.fetchAgenciesByCandidate,
          variables.candidateId,
        ]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
