import styles from './TopBar.module.scss';
import { TopBarProps } from './TopBar.types';

import { PropsWithChildren, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PerimeterSelectionModal } from 'src/Modals/PerimeterSelectionModal';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { ArrowLeft } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, Link } from '@randstad-lean-mobile-factory/react-components-core';

export const TopBar = ({
  children,
  title,
  goBackTo,
  disablePerimeterSelection,
  comments,
}: PropsWithChildren<TopBarProps>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <div className={styles.topBar}>
      <div className={styles.content}>
        {goBackTo && (
          <div className={styles.goBack}>
            <Button
              rounded
              size="medium"
              onClick={() => {
                if (location.key === 'default') navigate(goBackTo);
                else navigate(-1);
              }}
            >
              <ArrowLeft />
            </Button>
          </div>
        )}
        <div className={styles.titleContainer}>
          {!disablePerimeterSelection && (
            <div className={styles.brandName}>
              flash /{' '}
              <Link onClick={() => setIsPerimeterModalOpen(true)}>
                {currentBrand.name} - {currentAgency}
              </Link>
              <PerimeterSelectionModal
                open={isPerimeterModalOpen}
                onClose={() => setIsPerimeterModalOpen(false)}
              />
            </div>
          )}
          <div className={styles.title}>{title}</div>
        </div>
      </div>
      <div className={styles.children}>{children}</div>
      {comments && (
        <div className={styles.commentsContainer}>
          <div className={styles.commentsTitle}>commentaires</div>
          <div className={styles.comments}>{comments}</div>
        </div>
      )}
    </div>
  );
};
