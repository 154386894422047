import { Badge } from '@randstad-lean-mobile-factory/react-components-core';
import { TemporaryWorkerStatus } from 'src/Services/API';
import { TemporaryWorkerStatusBadgeProps } from './TemporaryWorkerStatusBadge.types';

export const TemporaryWorkerStatusBadge = ({
  status,
  ...props
}: TemporaryWorkerStatusBadgeProps) => (
  <Badge
    color={
      (
        {
          [TemporaryWorkerStatus.IN_MISSION]: 'success',
          [TemporaryWorkerStatus.SOON_AVAILABLE]: 'warning',
          [TemporaryWorkerStatus.INTERMISSION]: 'error',
          [TemporaryWorkerStatus.ABSENT]: 'navy',
        } as const
      )[status]
    }
    {...props}
  >
    {
      (
        {
          [TemporaryWorkerStatus.IN_MISSION]: 'en mission',
          [TemporaryWorkerStatus.SOON_AVAILABLE]: 'bientôt disponible',
          [TemporaryWorkerStatus.INTERMISSION]: 'intermission',
          [TemporaryWorkerStatus.ABSENT]: 'absent',
        } as const
      )[status]
    }
  </Badge>
);
