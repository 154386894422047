import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { CdiiDetailScreen } from 'src/Screens/CdiiDetail';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { CdiiSource } from '../CdiiList/CdiiList.types';
import styles from './CdiiDetail.module.scss';

export const CdiiDetailRoute = () => {
  const navigate = useNavigate();
  const { candidateId } = useTypedParams(ROUTES.TALENTS.CDII.CDII_DETAIL);
  const source = useTypedHash(ROUTES.TALENTS.CDII.CDII_DETAIL);

  const { data: isCGC } = useIsCGC();

  const userInfo = useSelector(getAnalyticsUserInfo);

  return (
    <div className={styles.container}>
      <TopBar title="détails talent" goBackTo={ROUTES.TALENTS.CDII.buildPath({})} />
      <div className={styles.detail}>
        <CdiiDetailScreen candidateId={candidateId}>
          {isCGC !== undefined &&
            (isCGC ? (
              <Button
                className={styles.repositioningButton}
                onClick={() => {
                  ReactGA.event(ANALYTICS_EVENT.CLICK_SUGGEST, {
                    ...userInfo,
                  });

                  navigate(
                    ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath(
                      { candidateId },
                      {},
                      source
                    )
                  );
                }}
              >
                suggérer
              </Button>
            ) : (
              <Button
                className={styles.repositioningButton}
                onClick={() => {
                  ReactGA.event(ANALYTICS_EVENT.CLICK_REPOSITIONING, {
                    ...userInfo,
                    toggle: source
                      ? {
                          [CdiiSource.agency]: 'mes CDII',
                          [CdiiSource.repositioning]: 'en repositionnement',
                          [CdiiSource.regional]: 'de ma région',
                        }[source]
                      : 'deeplink',
                  });

                  navigate(
                    ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath(
                      { candidateId },
                      {},
                      source
                    )
                  );
                }}
              >
                repositionner
              </Button>
            ))}
        </CdiiDetailScreen>
      </div>
    </div>
  );
};
