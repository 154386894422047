import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import { Route, Routes, useLocation } from 'react-router-dom';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { ActionPlanRoute } from './Accidentology/ActionPlan/ActionPlan.component';
import { WorkAccidentAnalysisKanbanRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisKanban';
import { WorkAccidentAnalysisDetailRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail';
import { ActionsRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Actions';
import { AnalysisRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Analysis';
import { ConclusionRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Conclusion';
import { ContextRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Context';
import { DescriptionRoute } from './Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Description';
import { ContractsRoute } from './Activity/Contracts';
import { MissionRoute } from './Activity/Mission/Mission.component';
import { MissionsRoute } from './Activity/Missions';
import { CreateMissionRoute } from './Activity/Missions/CreateMission';
import { YouplanMissionsRoute } from './Activity/YouplanMissions';
import { AppRoot } from './AppRoot';
import { ExternalDemandsRoute } from './ExternalDemands';
import { DemandsListRoute } from './ExternalDemands/DemandsList';
import { DemandsPreferencesRoute } from './ExternalDemands/DemandsPreferences';
import { Login } from './Login';
import { useShowLoginScreen } from './Login/Login.types';
import { NotFound } from './NotFound';
import { ROUTES, useHandleOldRoutes } from './Routes.types';
import { CdiiDetailRoute } from './Talents/Cdii/CdiiDetail';
import { CdiiListRoute } from './Talents/Cdii/CdiiList';
import { RepositioningRoute } from './Talents/Repositioning';
import { TalentDetailsRoute } from './Talents/TemporaryWorkers/TemporaryWorkerDetail';
import { TemporaryWorkerRepositioningRoute } from './Talents/TemporaryWorkers/TemporaryWorkerRepositioning';
import { TemporaryWorkersListRoute } from './Talents/TemporaryWorkers/TemporaryWorkersList';
import { CronJobsRoute } from './Tools/CronJobs';
import { CronJobDetailsRoute } from './Tools/CronJobs/CronJobDetails';
import { EstablishmentsRoute } from './Tools/Establishments';
import { FeatureFlagsRoute } from './Tools/FeatureFlags';
import { FeatureFlagDetailsRoute } from './Tools/FeatureFlags/FeatureFlagDetails';

export const Root = () => {
  const location = useLocation();

  const showLoginScreen = useShowLoginScreen();

  useHandleOldRoutes();

  if (showLoginScreen) return <Login />;

  return (
    <>
      {location.state?.background && (
        <Routes location={location}>
          {Object.entries(MODAL_ROUTES).map(([key, ModalDeprecated]) => (
            <Route
              key={key}
              path={ModalDeprecated.route.path}
              Component={ModalDeprecated.Component}
            />
          ))}
        </Routes>
      )}
      <Routes location={location.state?.background ?? location}>
        <Route path={ROUTES.ROOT.path} Component={AppRoot}>
          <Route path={ROUTES.ACTIVITY.path}>
            <Route path={ROUTES.ACTIVITY.MISSIONS.path}>
              <Route index Component={MissionsRoute} />
              <Route path={ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.path} Component={MissionRoute} />
              <Route path={ROUTES.ACTIVITY.MISSIONS.CREATE.path} Component={CreateMissionRoute} />
            </Route>

            <Route path={ROUTES.ACTIVITY.CONTRACTS.path} Component={ContractsRoute} />
            <Route path={ROUTES.ACTIVITY.YOUPLAN.path} Component={YouplanMissionsRoute} />
          </Route>

          <Route path={ROUTES.TALENTS.path}>
            <Route path={ROUTES.TALENTS.CDII.path}>
              <Route index Component={CdiiListRoute} />
              <Route path={ROUTES.TALENTS.CDII.CDII_DETAIL.path}>
                <Route index Component={CdiiDetailRoute} />
                <Route
                  path={ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.path}
                  Component={RepositioningRoute}
                />
              </Route>
            </Route>
            <Route path={ROUTES.TALENTS.TEMPORARY_WORKERS.path}>
              <Route index Component={TemporaryWorkersListRoute} />
              <Route path={ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.path}>
                <Route index Component={TalentDetailsRoute} />
                <Route
                  path={ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING.path}
                  Component={TemporaryWorkerRepositioningRoute}
                />
              </Route>
              <Route
                path={ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.path}
                Component={TalentDetailsRoute}
              />
            </Route>
          </Route>

          <Route path={ROUTES.ACCIDENTOLOGY.path}>
            <Route path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.path}>
              <Route index Component={WorkAccidentAnalysisKanbanRoute} />
              <Route
                path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.path}
                Component={WorkAccidentAnalysisDetailRoute}
              >
                <Route
                  path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.path}
                  Component={ContextRoute}
                />
                <Route
                  path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.DESCRIPTION.path}
                  Component={DescriptionRoute}
                />
                <Route
                  path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.WHY_ANALYSIS.path}
                  Component={AnalysisRoute}
                />
                <Route
                  path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.ACTION.path}
                  Component={ActionsRoute}
                />
                <Route
                  path={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONCLUSION.path}
                  Component={ConclusionRoute}
                />
              </Route>
            </Route>
            <Route path={ROUTES.ACCIDENTOLOGY.ACTION_PLAN.path} Component={ActionPlanRoute} />
          </Route>

          <Route path={ROUTES.EXTERNAL_DEMANDS.path} Component={ExternalDemandsRoute}>
            <Route path={ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.path} Component={DemandsListRoute} />
            <Route
              path={ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path}
              Component={DemandsListRoute}
            />
            <Route
              path={ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.path}
              Component={DemandsPreferencesRoute}
            />
          </Route>

          <Route path={ROUTES.TOOLS.ESTABLISHMENTS.path} Component={EstablishmentsRoute} />
          <Route path={ROUTES.TOOLS.FEATURE_FLAGS.path}>
            <Route index Component={FeatureFlagsRoute} />
            <Route
              path={ROUTES.TOOLS.FEATURE_FLAGS.FEATURE_FLAG_DETAILS.path}
              Component={FeatureFlagDetailsRoute}
            />
          </Route>
          <Route path={ROUTES.TOOLS.CRON_JOBS.path}>
            <Route index Component={CronJobsRoute} />
            <Route
              path={ROUTES.TOOLS.CRON_JOBS.CRON_JOB_DETAILS.path}
              Component={CronJobDetailsRoute}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer position="bottom-right" />
    </>
  );
};
