import {
  Button,
  CardCell,
  CardRow,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import styles from './TalentMissions.module.scss';
import { Props } from './TalentMissions.types';
import { getMissionStatus } from './utils';
import { Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { useState } from 'react';

const TalentMissions = ({ missions }: Props) => {
  const navigate = useNavigate();
  const [visibleMissionsCount, setVisibleMissionsCount] = useState<number>(5);
  return (
    <div className={styles.container}>
      <div className={styles.title}>commandes pour ce talent </div>
      {missions !== undefined && missions?.length > 0 ? (
        <StyledTable size="medium">
          <TableHeader>
            <TableRow>
              <TableHead>infos commande</TableHead>
              <TableHead>statut commande</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {missions.slice(0, visibleMissionsCount).map(mission => {
              const missionId = mission.missionId;
              const companyName = mission.companies?.name;
              const qualification = mission.qualification?.shortLabel;
              const startDate = mission.starDate;
              const endDate = mission.endDate;

              const formattedStartDate = startDate
                ? `débute le ${moment(startDate).format('DD/MM/YYYY')}`
                : 'date de début inconnue';
              const formattedEndDate = endDate
                ? `fin le ${moment(endDate).format('DD/MM/YYYY')}`
                : 'date de fin inconnue';

              return (
                <CardRow
                  key={mission.missionId}
                  color="greenish"
                  isClickable
                  onClick={() => {
                    navigate(
                      ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({
                        missionId: mission.missionId,
                      })
                    );
                  }}
                >
                  <CardCell>
                    <div>
                      <div className={styles.missionCodeAndCompanyName}>
                        {[missionId, companyName].filter(Boolean).join(' - ')}
                      </div>
                      <div className={styles.qualificationAndDates}>
                        {[qualification, formattedStartDate, formattedEndDate]
                          .filter(Boolean)
                          .join(', ')}
                      </div>
                    </div>
                  </CardCell>
                  <CardCell>
                    <div className={styles.statuses}>
                      <div>talent : {getMissionStatus(mission.candidate?.acceptCandidate)}</div>
                      <div>compte : {getMissionStatus(mission.candidate?.acceptCustomer)}</div>
                    </div>
                  </CardCell>
                  <CardCell>
                    <Eye className={styles.eye} />
                  </CardCell>
                </CardRow>
              );
            })}
          </TableBody>
          {missions.length > 5 && (
            <Button
              className={styles.button}
              variant="secondary"
              size="medium"
              onClick={() => {
                setVisibleMissionsCount(
                  visibleMissionsCount < missions.length ? missions.length : 5
                );
              }}
            >
              <Eye />
              afficher {visibleMissionsCount < missions.length ? 'plus' : 'moins'} de commandes
            </Button>
          )}
        </StyledTable>
      ) : (
        <div className={styles.noMissions}>Pas de commandes en cours pour ce talent</div>
      )}
    </div>
  );
};

export default TalentMissions;
