import { ThunkDispatch } from '@reduxjs/toolkit';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { MISSION_CREATION_STEP } from 'src/ModalRoutes_DEPRECATED/MissionCreationModal/MissionCreationModal.types';
import { useFetchCurrentContractOfCandidate } from 'src/Hooks/Contracts/useFetchCurrentContractOfCandidate';
import { useFetchYouplanMissionDetail } from 'src/Hooks/Youplan/useFetchYouplanMissionDetail';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { fetchYouplanMissionForNewMission } from 'src/Redux/MissionCreation/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { toFetchStatus } from 'src/Services/Async';
import { LocationState } from 'src/Services/Routing';
import YouplanMissionModalContent from './YouplanMissionModalContent.component';

const MemoYouplanMissionModalContent = memo(YouplanMissionModalContent);

const EnhancedYouplanMissionModalContent = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const state = useLocation().state as
    | (LocationState & { missionId: string; isPendingMission: boolean })
    | undefined;

  const { data: youplanMission, ...missionQueryStatus } = useFetchYouplanMissionDetail({
    missionId: state?.missionId,
  });
  const onCreateMission = useCallback(() => {
    dispatch(missionCreationActions.setYouplanMissionIds([youplanMission?.id ?? '']));
    dispatch(missionCreationActions.setYouPlanMissionCandidates(youplanMission?.candidates ?? []));
    dispatch(fetchYouplanMissionForNewMission(youplanMission?.id ?? ''));
    navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
      replace: true,
      state: {
        modalParameters: {
          openingStep: MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY,
        },
        background: state?.background,
      },
    });
  }, [youplanMission, dispatch, navigate, state]);
  const { data: candidateContract, ...contractQueryStatus } = useFetchCurrentContractOfCandidate(
    youplanMission?.candidates?.[0].candidateId
  );
  const mergedFetchStatus = toFetchStatus(missionQueryStatus, contractQueryStatus);
  return (
    <MemoYouplanMissionModalContent
      youplanMission={youplanMission}
      fetchStatus={mergedFetchStatus}
      onCreateMission={onCreateMission}
      candidateCurrentContract={candidateContract}
      isPendingMission={state?.isPendingMission}
    />
  );
};

export default EnhancedYouplanMissionModalContent;
