import {
  Comment,
  DocumentPdf,
  OtherHorizontal,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Habilitation } from '@randstad-lean-mobile-factory/react-assets/dist/pictos';
import {
  Badge,
  Button,
  CardCell,
  CardRow,
  HorizontalCardWithTitle,
  Menu,
  MenuItem,
  StyledTable,
  TableBody,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Clock, Group, Stylo } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import RecrutLiveProfile from 'src/Components/RecrutLiveProfile/RecrutLiveProfile.component';
import { useFetchLastYearCandidateContracts } from 'src/Hooks/Candidate/useFetchCandidate';
import { useFetchCandidateCurrentCDIContract } from 'src/Hooks/Candidate/useFetchCandidateCurrentCDIContract';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useFetchCandidateHabilitations } from 'src/Hooks/Candidate/useFetchCandidateHabilitations';
import { useFetchCandidateMemos } from 'src/Hooks/Candidate/useFetchCandidateMemos';
import { useFetchCandidateMissions } from 'src/Hooks/Candidate/useFetchCandidateMissions';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import { CandidateMemoModal } from 'src/Modals/CandidateMemoModal';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import CdiiLastMedicalVisit from 'src/Routes/Talents/Cdii/CdiiDetail/Components/CdiiLastMedicalVisit';
import CdiiResidencePermit from 'src/Routes/Talents/Cdii/CdiiDetail/Components/CdiiResidencePermit';
import TalentAvailabilityAndPlacementHeader from 'src/Routes/Talents/TemporaryWorkers/TemporaryWorkerDetail/Components/TalentAvailabilityAndPlacementHeader';
import TalentMissions from 'src/Routes/Talents/TemporaryWorkers/TemporaryWorkerDetail/Components/TalentMissions';
import { toDateFormatted } from 'src/Utils/datetime';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Utils/fetch-status';
import LegendPopup from '../LegendPopup/LegendPopup.component';
import CandidateCalendar from './CandidateCalendar/CandidateCalendar.component';
import styles from './CdiiDetailSection.module.scss';
import { CdiiDetailSectionProps } from './CdiiDetailSection.types';

export const CdiiDetailSection = ({
  candidateId,
  lastContractEndDate,
  fetchingStartDate,
  planningStartDate,
  setPlanningStartDate,
  activityPlanning,
}: CdiiDetailSectionProps) => {
  const qualificationQuery = useFetchCandidateQualifications(candidateId);
  const habilitationQuery = useFetchCandidateHabilitations(candidateId);

  const detailsQuery = useFetchCandidateDetails(candidateId);
  const firstContractDate = useFetchLastYearCandidateContracts(candidateId);
  const qualificationfetchStatus = toFetchStatus(qualificationQuery);
  const detailsFetchStatus = toFetchStatus(detailsQuery);
  const firstContractFetchStatus = toFetchStatus(firstContractDate);
  const fetchStatus = mergeSeveralFetchStatus([
    qualificationfetchStatus,
    detailsFetchStatus,
    firstContractFetchStatus,
  ]);
  const contractDetails = useFetchCandidateCurrentCDIContract(candidateId);
  const currentBrand = useSelector(getCurrentBrand);
  const { data: candidateMemos } = useFetchCandidateMemos(candidateId);

  const nextAvailabilityDate = activityPlanning?.activityPlanning?.find(
    day => moment(day.date).isSameOrAfter(moment(), 'day') && day.status === 'Intermission'
  )?.date;
  const candidateMissions = useFetchCandidateMissions(candidateId);
  const missionsFetchStatus = toFetchStatus(candidateMissions);

  const [isMemoModalOpen, setIsMemoModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <TalentAvailabilityAndPlacementHeader availabilityDate={nextAvailabilityDate} />
      )}
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="5.625rem" width="100%" uniqueKey="TalentAvailability">
          <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
          <rect x="1%" y="30" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="50" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="70" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="90" rx="4" ry="4" width="100%" height="10" />
        </ContentLoader>
      )}
      {missionsFetchStatus === FETCH_STATUS.FULFILLED && (
        <TalentMissions missions={candidateMissions?.data ?? []} />
      )}
      <div>
        <div className={styles.calendarContainer}>
          {activityPlanning && (
            <>
              <div className={styles.calendarTitle}>vision planning</div>
              <CandidateCalendar
                candidate={activityPlanning}
                startDate={planningStartDate}
                setStartDate={setPlanningStartDate}
              />

              <div className={styles.popupContainer}>
                <LegendPopup />
              </div>
            </>
          )}
          {!activityPlanning && (
            <ContentLoader height="10rem" width="100%" uniqueKey="calendar">
              <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
              <rect x="1%" y="30" rx="4" ry="4" width="100%" height="10" />
              <rect x="1%" y="50" rx="4" ry="4" width="100%" height="10" />
              <rect x="1%" y="70" rx="4" ry="4" width="100%" height="10" />
              <rect x="1%" y="90" rx="4" ry="4" width="100%" height="10" />
            </ContentLoader>
          )}
        </div>
      </div>

      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.talentInfoContainer}>
            <div className={styles.title}>informations talent</div>
            <div className={styles.talentInfoButtons}>
              <div className={styles.buttons}>
                <RecrutLiveProfile candidateId={candidateId} text={'consulter dossier talent'} />
                <Menu
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  trigger={
                    <Button variant="tertiary" size="medium">
                      <OtherHorizontal variant="fill" />
                    </Button>
                  }
                >
                  <MenuItem
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RL_PUBLIC_URL}/candidate/${candidateId}/resume/qualifications`
                      )
                    }
                  >
                    <DocumentPdf /> générer le CV
                  </MenuItem>
                  <MenuItem onClick={() => setIsMemoModalOpen(true)}>
                    <Comment /> mémos
                    <Badge
                      color={candidateMemos?.some(memo => memo.isBehaviorMemo) ? 'error' : 'blue'}
                    >
                      {candidateMemos?.length ?? 0}
                    </Badge>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          <div className={styles.child}>
            <div className={styles.informationContainer}>
              <div className={styles.informationSubContainer}>
                <WithLightTitle
                  title="qualifications principales et secondaires"
                  className={styles.list}
                >
                  <p key="main"># principale :</p>
                  <p>{detailsQuery.data?.qualification.label}</p>
                  <br />
                  <p key="secondary"># secondaire :</p>
                  {qualificationQuery.data
                    ?.filter(
                      qualification =>
                        qualification.id !== detailsQuery.data?.qualification.qualificationId
                    )
                    .map(qualification => <p key={qualification.id}>{qualification.label}</p>)}
                </WithLightTitle>
              </div>
              <div className={styles.informationSubContainer}>
                <WithLightTitle title="métiers">
                  {contractDetails.isSuccess &&
                    contractDetails.data.jobList?.map(job => <p key={job.id}>• {job.label}</p>)}
                </WithLightTitle>
                <WithLightTitle title="date de fin du dernier contrat">
                  {toDateFormatted(lastContractEndDate, 'L') ?? 'non renseignée'}
                </WithLightTitle>
                {activityPlanning?.residencePermit && (
                  <CdiiResidencePermit residencePermit={activityPlanning.residencePermit} />
                )}
              </div>
              <div className={styles.informationSubContainer}>
                <div className={styles.bottomLineContainer}>
                  <div className={styles.imageIndicator}>
                    <Clock />
                    <div className={styles.column}>
                      <div className={styles.indicatorValue}>
                        {Math.round(detailsQuery.data?.nbHoursWorked ?? 0)}
                      </div>
                      <div className={styles.indicatorUnits}>hrs</div>
                    </div>
                  </div>
                  <div className={styles.separator} />

                  <div className={styles.imageIndicator}>
                    <Group />
                    <div className={styles.column}>
                      <div className={styles.indicatorValue}>{detailsQuery.data?.nbCompanies}</div>
                      <div className={styles.indicatorUnits}>clients</div>
                    </div>
                  </div>
                  <div className={styles.separator} />

                  <div className={styles.imageIndicator}>
                    <Stylo />
                    <div className={styles.column}>
                      <div className={styles.indicatorValue}>{detailsQuery.data?.nbMissions}</div>
                      <div className={styles.indicatorUnits}>contrats</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottomInformationContainer}>
              <div className={styles.informationSubContainer}>
                <WithLightTitle title="mobilité acceptée sur le contrat">
                  {contractDetails.isSuccess && contractDetails.data.mobility && (
                    <div>{`${contractDetails.data.mobility.distance} KM`}</div>
                  )}
                </WithLightTitle>
                <WithLightTitle title="ville de résidence">
                  {detailsQuery.isSuccess && detailsQuery.data.city && (
                    <div>{detailsQuery.data.city}</div>
                  )}
                </WithLightTitle>
              </div>
              <div className={styles.informationSubContainer}>
                <CdiiLastMedicalVisit lastMedicalVisitDate={activityPlanning?.lastMedicalVisit} />
                <WithLightTitle title="code postal de résidence">
                  {detailsQuery.isSuccess && detailsQuery.data.zipCode && (
                    <div>{detailsQuery.data.zipCode}</div>
                  )}
                </WithLightTitle>
              </div>
              <div className={styles.informationSubContainer}>
                <WithLightTitle title={`date de 1er contrat chez ${currentBrand.name}`}>
                  {moment(firstContractDate.data?.firstContractStartDate).format('L')}
                </WithLightTitle>
                <WithLightTitle title="numéro de téléphone">
                  {detailsQuery.data?.phone1 ?? 'non renseigné'}
                </WithLightTitle>
              </div>
            </div>
            <div>
              <div className={styles.habilitationsHeader}>
                <div className={styles.habilitationsHeaderLabel}>habilitations</div>
                <Badge
                  size="xsmall"
                  color="navy"
                  saturation="medium"
                  className={styles.habilitationsBadge}
                >
                  {habilitationQuery.data ? habilitationQuery.data.length : 0}
                </Badge>
              </div>
              {habilitationQuery.data && habilitationQuery.data.length !== 0 ? (
                <div>
                  <StyledTable size="medium" color="beige">
                    <TableBody>
                      {habilitationQuery.data.map(habilitation => {
                        return (
                          <CardRow key={habilitation.id}>
                            <CardCell>
                              <Habilitation />
                              <div className={styles.habilitation}>{habilitation.label}</div>
                            </CardCell>
                          </CardRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </div>
              ) : (
                <div className={styles.noHabilitationsText}>
                  aucune habilitation n'a été renseignée
                </div>
              )}
            </div>
            <WithLightTitle title="horaires acceptés">
              <div className={styles.columnList}>
                {contractDetails.isSuccess &&
                  contractDetails.data.workingConditions &&
                  contractDetails.data.workingConditions.map(condition => (
                    <HorizontalCardWithTitle
                      title={condition.label ?? ''}
                      className={styles.conditionCardContainer}
                      titleClassname={styles.conditionCardTitle}
                      key={condition.id}
                    />
                  ))}
              </div>
            </WithLightTitle>
          </div>
        </>
      )}
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="cdiiDetails">
          <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
          <rect x="1%" y="30" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="50" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="70" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="90" rx="4" ry="4" width="100%" height="10" />
        </ContentLoader>
      )}
      <CandidateMemoModal
        candidateId={candidateId}
        open={isMemoModalOpen}
        onClose={() => setIsMemoModalOpen(false)}
      />
    </div>
  );
};
