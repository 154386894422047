import {
  Calendar,
  CircleAdd,
  Parameters,
  SortDescending,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Badge,
  Button,
  Chip,
  Modal,
  ModalActions,
  ModalContent,
  SearchInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { getSelectedSalesphases } from 'src/Redux/Search/Selectors';
import { FilterMissionsModal } from 'src/Routes/Activity/Missions/MissionsTopBarComponent/FilterMissionsModal';
import { MissionSortMenu } from 'src/Routes/Activity/Missions/MissionsTopBarComponent/SortMissionsMenu';
import { PivotDatePicker } from './PivotDatePicker';
import { MissionsTopBarProps } from './MissionsTopBar.types';
import { getCurrentCandidate } from 'src/Redux/CDICandidates/Selectors';
import moment from 'moment';
import { ROUTES } from 'src/Routes/Routes.types';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';
import { FeatureFlag } from 'src/Services/API';
import { getSelectedAgencyConsultant } from 'src/Redux/AgencyConsultant/Selectors';

export const MissionsTopBarComponent = ({
  pivotDate,
  setPivotDate,
  globalFilter,
  setGlobalFilter,
}: MissionsTopBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const qualification = useSelector(getCurrentQualification);
  const agencyConsultant = useSelector(getSelectedAgencyConsultant);
  const candidate = useSelector(getCurrentCandidate);
  const salesphases = useSelector(getSelectedSalesphases);
  const company = useSelector(getCurrentCompany);

  const newMissionCreationScreen = useFeatureFlag(FeatureFlag.TMP_MISSION_CREATION_SHOW_NEW_SCREEN);

  const [isFilterMissionsModalOpen, setIsFilterMissionsModalOpen] = useState(false);
  const [isMissionCreationSelectionOpen, setIsMissionCreationSelectionOpen] = useState(false);

  const numberOfFilters =
    1 + // there is always a date
    +Boolean(salesphases.length) +
    +Boolean(qualification) +
    +Boolean(company) +
    +Boolean(candidate) +
    +Boolean(agencyConsultant);

  return (
    <>
      <PivotDatePicker
        trigger={
          <Chip size="large" rounded={false}>
            <Calendar variant="fill" />
            commandes en cours le {moment(pivotDate).format('DD/MM/YYYY')}
          </Chip>
        }
        pivotDate={pivotDate}
        setPivotDate={setPivotDate}
      />
      <FilterMissionsModal
        isOpen={isFilterMissionsModalOpen}
        onClose={() => setIsFilterMissionsModalOpen(false)}
      />
      <Button variant="tertiary" size="medium" onClick={() => setIsFilterMissionsModalOpen(true)}>
        <Parameters />
        <Badge color="currentColor" size="xsmall">
          {numberOfFilters}
        </Badge>
      </Button>
      <MissionSortMenu
        trigger={
          <Button variant="tertiary" size="medium">
            <SortDescending />
          </Button>
        }
      />
      <SearchInput
        size="small"
        value={globalFilter}
        onValueChange={setGlobalFilter}
        placeholder="rechercher"
        collapsed
      />
      <Button
        size="medium"
        onClick={() => {
          if (newMissionCreationScreen) setIsMissionCreationSelectionOpen(true);
          else
            navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
              state: {
                background: location,
              },
            });
        }}
      >
        <CircleAdd /> créer une commande
      </Button>

      <Modal
        size="small"
        open={isMissionCreationSelectionOpen}
        onClose={() => setIsMissionCreationSelectionOpen(false)}
      >
        <ModalContent
          title="créer une commande"
          description="Pendant le développement de la nouvelle interface de création de commande, l'ancienne interface est toujours disponible"
        />
        <ModalActions side="right">
          <Button
            variant="secondary"
            onClick={() => {
              setIsMissionCreationSelectionOpen(false);
              navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
                state: {
                  background: location,
                },
              });
            }}
          >
            ancienne interface
          </Button>
          <Button
            onClick={() => {
              setIsMissionCreationSelectionOpen(false);
              navigate(ROUTES.ACTIVITY.MISSIONS.CREATE.buildPath({}));
            }}
          >
            nouvelle interface
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
