import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hashValues, route, string } from 'react-router-typesafe-routes/dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { cdiiAgencySortSchema } from './Talents/Cdii/CdiiList/AgencyCdiiList/AgencyCdiiList.types';
import { CdiiSource } from './Talents/Cdii/CdiiList/CdiiList.types';
import { cdiiRegionalSortSchema } from './Talents/Cdii/CdiiList/RegionalCdiiList/RegionalCdiiList.types';
import { cdiiRepositioningSortSchema } from './Talents/Cdii/CdiiList/RepositioningCdiiList/RepositioningCdiiList.types';
import {
  temporaryWorkersAgencyFiltersSchema,
  temporaryWorkersAgencySortSchema,
} from './Talents/TemporaryWorkers/TemporaryWorkersList/AgencyTemporaryWorkersList/AgencyTemporaryWorkersList.types';
import {
  temporaryWorkersRegionalFiltersSchema,
  temporaryWorkersRegionalSortSchema,
} from './Talents/TemporaryWorkers/TemporaryWorkersList/RegionalTemporaryWorkersList/RegionalTemporaryWorkersList.types';
import { TemporaryWorkersSource } from './Talents/TemporaryWorkers/TemporaryWorkersList/TemporaryWorkersList.types';

export const ROUTES = {
  ROOT: route(''),
  ACTIVITY: route(
    'activity',
    {},
    {
      MISSIONS: route(
        'missions',
        {},
        {
          MISSION_DETAIL: route(':missionId'),
          CREATE: route('create'),
        }
      ),
      CONTRACTS: route('contracts'),
      YOUPLAN: route('youplan'),
    }
  ),
  TALENTS: route(
    'talents',
    {},
    {
      CANDIDATES: route('candidates'),
      CDII: route(
        'cdii',
        {
          hash: hashValues(...Object.values(CdiiSource)),
          state: {
            agencySort: zod(cdiiAgencySortSchema),
            repositioningSort: zod(cdiiRepositioningSortSchema),
            regionalSort: zod(cdiiRegionalSortSchema),
          },
        },
        {
          CDII_DETAIL: route(
            ':candidateId',
            {},
            {
              REPOSITIONING: route('repositioning'),
            }
          ),
        }
      ),
      TEMPORARY_WORKERS: route(
        'temporary-workers',
        {
          hash: hashValues(...Object.values(TemporaryWorkersSource)),
          state: {
            agencyFilters: zod(temporaryWorkersAgencyFiltersSchema),
            agencySort: zod(temporaryWorkersAgencySortSchema),
            regionalFilters: zod(temporaryWorkersRegionalFiltersSchema),
            regionalSort: zod(temporaryWorkersRegionalSortSchema),
          },
        },
        {
          TEMPORARY_WORKER_DETAIL: route(
            ':talentId',
            {},
            {
              REPOSITIONING: route('repositioning'),
            }
          ),
        }
      ),
    }
  ),
  ACCIDENTOLOGY: route(
    'accidentology',
    {},
    {
      WORK_ACCIDENT: route(
        'work-accident',
        {},
        {
          WORK_ACCIDENT_DETAIL: route(
            ':analysisId',
            {},
            {
              CONTEXT: route('context'),
              DESCRIPTION: route('description'),
              WHY_ANALYSIS: route('why_analysis'),
              ACTION: route('action'),
              CONCLUSION: route('conclusion'),
            }
          ),
        }
      ),
      ACTION_PLAN: route('action-plan'),
    }
  ),
  EXTERNAL_DEMANDS: route(
    'external-demands',
    {},
    {
      DEMANDS_LIST: route(
        'demands-list',
        {},
        {
          ARCHIVED: route('archived'),
        }
      ),
      DEMANDS_PREFERENCES: route('demands-preferences', {
        state: { siret: string() },
      }),
    }
  ),
  TOOLS: route(
    'tools',
    {},
    {
      ESTABLISHMENTS: route('establishments'),
      FEATURE_FLAGS: route(
        'feature-flags',
        {},
        {
          FEATURE_FLAG_DETAILS: route(':featureFlagId'),
        }
      ),
      CRON_JOBS: route(
        'cron-jobs',
        {},
        {
          CRON_JOB_DETAILS: route(':cronJobId'),
        }
      ),
    }
  ),
};

const oldRoutes = [
  {
    oldPath: '/login',
    route: ROUTES.ROOT,
  },
  {
    oldPath: '/temporaryWorkers/commandes',
    route: ROUTES.ACTIVITY.MISSIONS,
  },
  {
    oldPath: '/temporaryWorkers/youplan',
    route: ROUTES.ACTIVITY.YOUPLAN,
  },
  {
    oldPath: '/temporaryWorkers/contracts',
    route: ROUTES.ACTIVITY.CONTRACTS,
  },
  {
    oldPath: '/cdi/candidates',
    route: ROUTES.TALENTS.CANDIDATES,
  },
  {
    oldPath: '/cdi/talents',
    route: ROUTES.TALENTS.TEMPORARY_WORKERS,
  },
  {
    oldPath: '/accident/analyse_AT',
    route: ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT,
  },
  {
    oldPath: '/accident/plan_d_actions',
    route: ROUTES.ACCIDENTOLOGY.ACTION_PLAN,
  },
  {
    oldPath: '/externalDemands/liste_demandes',
    route: ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST,
  },
  {
    oldPath: '/externalDemands/preferences_demandes',
    route: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES,
  },
];

export const useHandleOldRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    for (const oldRoute of oldRoutes) {
      if (location.pathname.startsWith(oldRoute.oldPath)) {
        navigate(oldRoute.route.buildPath({}) + location.pathname.slice(oldRoute.oldPath.length), {
          replace: true,
        });
      }
    }
  }, [location.pathname, navigate]);
};
