import { throttle } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useFetchAgencyCandidates } from 'src/Hooks/Candidate/useFetchAgencyCandidates';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { Props } from './SelectCandidate.types';
import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { formatName } from 'src/Utils/formatName';

const SelectCandidate = ({ selectedCandidate, setSelectedCandidate, canBeReset }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchAgencyCandidates(keyword);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  return (
    <ComboBox
      id="SelectCandidate"
      disableClearable={!canBeReset}
      placeholder="3 caractères minimum"
      value={selectedCandidate}
      onSearch={keyword => throttledSetKeyword(keyword)}
      searchResults={data?.candidates ?? []}
      keyValueExtractor={candidate => ({
        key: candidate.candidateId ?? '',
        value: formatName(candidate) ?? '',
      })}
      onChange={searchResult => {
        setSelectedCandidate(searchResult ?? undefined);
      }}
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={3}
    />
  );
};

export default SelectCandidate;
