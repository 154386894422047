import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getMissionCreationFetchStatus } from 'src/Redux/MissionCreation/Selectors';
import MissionCreationSuccess from './MissionCreationSuccess.component';

const MemoMissionCreationSuccess = memo(MissionCreationSuccess);

const EnhancedMissionCreationSuccess = () => {
  const _getMissionCreationFetchStatus = useSelector(getMissionCreationFetchStatus);
  return <MemoMissionCreationSuccess missionCreationFetchStatus={_getMissionCreationFetchStatus} />;
};

export default EnhancedMissionCreationSuccess;
