import { Button, SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch, useSelector } from 'react-redux';
import { PointingFinger } from 'src/Assets_DEPRECATED';
import { TopBar } from 'src/Components/TopBar';
import { useCreateExternalDemandsJournal } from 'src/Hooks/ExternalDemands/useCreateExternalDemandsJournal';
import { getSelectedSource } from 'src/Redux/ExternalDemands/Selectors';
import { externalDemandsActions } from 'src/Redux/ExternalDemands/Slice';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { AppelMedicalEventSourceDto } from 'src/Services/API';
import styles from './DemandsInit.module.scss';

export const DemandsInit = () => {
  const dispatch = useDispatch();
  const selectedSource = useSelector(getSelectedSource);

  const agencyId = useSelector(getCurrentAgency);

  const updateCreateExternalDemandsJournal = useCreateExternalDemandsJournal({
    onError: () => {
      setTimeout(() => updateCreateExternalDemandsJournal.reset(), 500);
    },
  });

  return (
    <>
      <div className={styles.container}>
        <TopBar title="demandes externes" />
        <div className={styles.segmentedControlContainer}>
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.values(AppelMedicalEventSourceDto)}
            selected={selectedSource}
            getValue={(dashboardMenuItem: AppelMedicalEventSourceDto) => {
              return <div className={styles.segmentContent}>{dashboardMenuItem.toLowerCase()}</div>;
            }}
            onSelectionChange={dashboardMenuItem =>
              dispatch(externalDemandsActions.setSelectedSource(dashboardMenuItem))
            }
          />
        </div>
        <div className={styles.contentContainer}>
          <PointingFinger />
          <div className={styles.content}>
            <div className={styles.text}>Je souhaite connecter mon unité {agencyId} à Hublo</div>
            <div className={styles.buttons}>
              <Button
                mutationStatus={updateCreateExternalDemandsJournal.status}
                onClick={() => updateCreateExternalDemandsJournal.mutate()}
              >
                c'est parti !
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
