import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { FetchCdiiSort, sortCdi } from './useFetchAgencyCdiis';

export const useFetchRegionalCdiis = (params: { sort: FetchCdiiSort }) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchRegionalCdiis, params],
    async ({ pageParam = 0 }) => {
      // TODO: replace this with a paginated route
      const cdiis = await CandidatesService.candidatesControllerFetchSuggestedCandidates({
        agencyId,
        brandCode,
      });
      return {
        cdiis: sortCdi(cdiis, params.sort.sortCriteria, params.sort.sortType),
        nextPage: undefined as number | undefined,
      };
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
