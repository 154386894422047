import {
  NavigateDown,
  NavigateUp,
  Play,
  Settings,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { ServerErrorRed } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Link,
  Loader,
  StyledTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchCronJobDetails } from 'src/Hooks/CronJobs/useFetchCronJobDetails';
import { useRunCronJobManually } from 'src/Hooks/CronJobs/useRunCronJobManually';
import { ROUTES } from 'src/Routes/Routes.types';
import { CronJobConfigModal } from './CronJobConfigModal';
import styles from './CronJobDetails.module.scss';

export const CronJobDetailsRoute = () => {
  const { cronJobId } = useTypedParams(ROUTES.TOOLS.CRON_JOBS.CRON_JOB_DETAILS);

  const { data: cronJobDetails, isLoading } = useFetchCronJobDetails(cronJobId);

  const runCronJobManually = useRunCronJobManually(cronJobId, {
    onSuccess: executionId => {
      setSelectedExecution(executionId);
    },
    onSettled: () => {
      setTimeout(() => runCronJobManually.reset(), 500);
    },
  });

  const [selectedExecution, setSelectedExecution] = useState<string>();
  const [selectedTrace, setSelectedTrace] = useState<number>();
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  const ref = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (selectedExecution) {
      ref.current
        ?.querySelector(`[data-execution-id="${selectedExecution}"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedExecution]);

  return (
    <div className={styles.container}>
      <TopBar title={cronJobId} goBackTo={ROUTES.TOOLS.CRON_JOBS.buildPath({})} />
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des tâches
          </div>
        ) : !cronJobDetails ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : (
          <>
            <h2 className={styles.sectionTitle}>
              Configuration
              <Button size="small" variant="secondary" onClick={() => setIsConfigModalOpen(true)}>
                <Settings /> mettre à jour
              </Button>
            </h2>
            <div className={styles.config}>
              La tâche programmée {cronJobId} est{' '}
              {cronJobDetails.enabled ? 'activée' : 'désactivée'}
            </div>
            <div className={styles.config}>
              La programmation de {cronJobId} est réglée sur {cronJobDetails.cronTime}
              <Button
                variant="tertiary"
                size="small"
                onClick={() =>
                  window.open('https://cron.help/#' + cronJobDetails.cronTime.replaceAll(' ', '_'))
                }
              >
                plus d'informations
              </Button>
            </div>
            <h2 className={styles.sectionTitle}>Dernières exécutions</h2>
            <div>
              <Button
                size="medium"
                onClick={() => runCronJobManually.mutate()}
                mutationStatus={
                  cronJobDetails?.currentExecution ? 'loading' : runCronJobManually.status
                }
                disabled={!cronJobDetails}
              >
                <Play variant="fill" /> lancer manuellement
              </Button>
            </div>
            <StyledTable size="small" className={styles.executionTable}>
              <TableHeader>
                <TableRow>
                  <TableHead>date</TableHead>
                  <TableHead>mode d'exécution</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody ref={ref}>
                {cronJobDetails.executions.map(execution => (
                  <Fragment key={execution.executionId}>
                    <TableRow
                      isSelected={selectedExecution === execution.executionId}
                      onClick={event => {
                        if (selectedExecution === execution.executionId)
                          setSelectedExecution(undefined);
                        else {
                          setSelectedExecution(execution.executionId);
                          setSelectedTrace(undefined);
                        }
                      }}
                      isClickable
                      data-execution-id={execution.executionId}
                    >
                      <TableCell>{moment(execution.createdAt).format('LLLL')}</TableCell>
                      <TableCell>{execution.mode}</TableCell>
                      <TableCell>
                        {selectedExecution === execution.executionId ? (
                          <NavigateUp color="blue" size="medium" />
                        ) : (
                          <NavigateDown color="blue" size="medium" />
                        )}
                      </TableCell>
                    </TableRow>
                    {selectedExecution === execution.executionId && (
                      <tr>
                        <td colSpan={3}>
                          <StyledTable color="white" size="small" className={styles.logTable}>
                            <TableHeader>
                              <TableRow color="white">
                                <TableHead>level</TableHead>
                                <TableHead>message</TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {execution.logs.map((log, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{log.level}</TableCell>
                                  <TableCell tdClassName={styles.log}>
                                    {typeof log.message === 'string'
                                      ? log.message
                                      : JSON.stringify(log.message, undefined, 2)}
                                    {log.trace &&
                                      (selectedTrace === idx ? (
                                        <>
                                          <Link
                                            size="xsmall"
                                            onClick={() => setSelectedTrace(undefined)}
                                          >
                                            voir moins
                                          </Link>
                                          {log.trace}
                                        </>
                                      ) : (
                                        <Link size="xsmall" onClick={() => setSelectedTrace(idx)}>
                                          voir plus
                                        </Link>
                                      ))}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {cronJobDetails.currentExecution === execution.executionId && (
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    <Loader size="xsmall" />
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </StyledTable>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </StyledTable>
            <CronJobConfigModal
              open={isConfigModalOpen}
              onClose={() => setIsConfigModalOpen(false)}
              cronJobConfig={cronJobDetails}
            />
          </>
        )}
      </div>
    </div>
  );
};
