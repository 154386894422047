import {
  AddButton,
  Button,
  ComboBox,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import { CompanyContact, Referent, Witness } from 'src/Services/API';
import { addPeople } from 'src/Assets_DEPRECATED';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { toFetchStatus } from 'src/Utils/fetch-status';
import styles from './WorkAccidentAnalysisContactModal.module.scss';
import { MoinsCircle, PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Props } from './WorkAccidentAnalysisContactModal.types';
import NewContactSelection from './NewContactSelection/NewContactSelection.component';

const WorkAccidentAnalysisContactModal = ({
  contactLabel,
  selectedContacts,
  companyId,
  companyName,
  onValidate,
}: Props) => {
  const ref = useRef<PopupActions>(null);
  const fetchCompanyContacts = useFetchCompanyContacts(companyId);
  const companyContactsFetchStatus = toFetchStatus(fetchCompanyContacts);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Referent | Witness | undefined>(undefined);
  const [addingNewPerson, setAddingNewPerson] = useState(false);

  const onOpen = () => {
    setSelectedContact(undefined);
    setAddingNewPerson(false);
  };
  const onClose = () => {
    setIsOpen(false);
    setSelectedContact(undefined);
    setAddingNewPerson(false);
    ref.current?.close();
  };

  return (
    <ModalDeprecated
      ref={ref}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={<AddButton text={`ajouter un ${contactLabel}`} icon={<PlusCircle />} />}
      icon={<img src={addPeople} alt="no-result" className={styles.image} />}
      title={`ajouter un ${contactLabel}`}
      key={`${contactLabel}modification`}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          disabled={
            selectedContact === undefined ||
            !(
              selectedContact.name?.length &&
              selectedContact.position?.length &&
              (!('societyName' in selectedContact) || selectedContact.societyName.length)
            )
          }
          onClick={() => {
            selectedContact && onValidate(selectedContact);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title="parmi la liste">
        <ComboBox
          id="selectContact"
          useNativeOptionFiltering
          placeholder={`choisissez un ${contactLabel}...`}
          searchResults={
            (fetchCompanyContacts.data?.filter(
              contact =>
                !(
                  selectedContacts.map(
                    (selectedContact: Referent | Witness) => selectedContact.contactId
                  ) ?? []
                ).includes(contact.contactId ?? '')
            ) ?? []) as (Referent | Witness)[]
          }
          fetchStatus={companyContactsFetchStatus}
          keyValueExtractor={(item: Referent | Witness) => ({
            key: item.contactId ?? '',
            value: `${item.name !== undefined ? item.name : ''} ${
              item.firstName !== undefined ? item.firstName : ''
            }`,
          })}
          value={!addingNewPerson && selectedContact ? selectedContact : null}
          onChange={(element: CompanyContact | null) =>
            setSelectedContact(
              element
                ? contactLabel === 'interlocuteur'
                  ? ({
                      contactId: element.contactId,
                      firstName: element.firstName,
                      name: element.name,
                      position: element.position,
                    } as Referent)
                  : ({
                      contactId: element.contactId,
                      firstName: element.firstName,
                      name: element.name,
                      position: element.position,
                      societyId: companyId,
                      societyName: companyName,
                      phoneNumber: element.phone,
                    } as Witness)
                : undefined
            )
          }
          disabled={addingNewPerson}
        />
      </WithLightTitle>
      <AddButton
        icon={addingNewPerson ? <MoinsCircle /> : <PlusCircle />}
        text={addingNewPerson ? 'effacer la nouvelle personne' : 'ajouter une nouvelle personne'}
        onClick={() => {
          setAddingNewPerson(!addingNewPerson);
          setSelectedContact(undefined);
        }}
      />
      {addingNewPerson && (
        <NewContactSelection
          selectedContact={
            selectedContact ??
            (contactLabel === 'interlocuteur'
              ? ({
                  firstName: '',
                  name: '',
                  position: '',
                } as Referent)
              : ({
                  firstName: '',
                  name: '',
                  position: '',
                  societyName: '',
                  phoneNumber: '',
                } as Witness))
          }
          setSelectedContact={setSelectedContact}
        />
      )}
    </ModalDeprecated>
  );
};

export default WorkAccidentAnalysisContactModal;
