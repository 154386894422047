import { SortDown, SortUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { FetchCdiiSortCriteria } from 'src/Hooks/Candidate/useFetchAgencyCdiis';
import { SortTypeEnum } from 'src/Services/API';
import styles from './RepositioningCdiiSortingMenu.module.scss';
import { RepositioningCdiiSortingMenuProps } from './RepositioningCdiiSortingMenu.types';

export const RepositioningCdiiSortingMenu = ({
  trigger,
  sort,
  setSort,
}: RepositioningCdiiSortingMenuProps) => (
  <Menu
    trigger={trigger}
    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <MenuItem
      selected={sort.sortType === SortTypeEnum.ASC}
      onClick={() => setSort(sort => ({ ...sort, sortType: SortTypeEnum.ASC }))}
      keepOpenOnClick
    >
      <SortUp size="xsmall" />
      ascendant
    </MenuItem>
    <MenuItem
      selected={sort.sortType === SortTypeEnum.DESC}
      onClick={() => setSort(sort => ({ ...sort, sortType: SortTypeEnum.DESC }))}
      keepOpenOnClick
    >
      <SortDown size="xsmall" />
      descendant
    </MenuItem>

    <div className={styles.menuGroup}>trier par</div>

    <MenuItem
      selected={sort.sortCriteria === FetchCdiiSortCriteria.AGENCY}
      onClick={() => setSort(sort => ({ ...sort, sortCriteria: FetchCdiiSortCriteria.AGENCY }))}
      keepOpenOnClick
    >
      agence
    </MenuItem>
    <MenuItem
      selected={sort.sortCriteria === FetchCdiiSortCriteria.LAST_CONTRACT_END_DATE}
      onClick={() =>
        setSort(sort => ({ ...sort, sortCriteria: FetchCdiiSortCriteria.LAST_CONTRACT_END_DATE }))
      }
      keepOpenOnClick
    >
      date
    </MenuItem>
    <MenuItem
      selected={sort.sortCriteria === FetchCdiiSortCriteria.STATUS}
      onClick={() => setSort(sort => ({ ...sort, sortCriteria: FetchCdiiSortCriteria.STATUS }))}
      keepOpenOnClick
    >
      statut
    </MenuItem>
    <MenuItem
      selected={sort.sortCriteria === FetchCdiiSortCriteria.AVAILABILITY}
      onClick={() =>
        setSort(sort => ({ ...sort, sortCriteria: FetchCdiiSortCriteria.AVAILABILITY }))
      }
      keepOpenOnClick
    >
      prochaine dispo
    </MenuItem>
  </Menu>
);
