import { ThunkDispatch } from '@reduxjs/toolkit';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';
import {
  getPositionStudiesCountSelector,
  getPreviousPositionStudies,
  getPreviousPositionStudiesFetchStatus,
  getSelectedCompany,
  getSelectedPreviousPositionStudy,
  getSelectedQualification,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { searchPreviousPositionStudies } from 'src/Redux/MissionCreation/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import { CompanySearchResult, FeatureFlag, PositionStudy, Qualification } from 'src/Services/API';
import PreviousPositionStudies from './PreviousPositionStudies.component';

const MemoPreviousPositionStudies = memo(PreviousPositionStudies);

const EnhancedPreviousPositionStudies = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const isPilotUnit = useFeatureFlag(FeatureFlag.MISSION_CREATION_EDP_ONLY);
  const positionStudiesCount = useSelector(getPositionStudiesCountSelector);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const previousPositionStudies = useSelector(getPreviousPositionStudies);
  const selectedPreviousPositionStudy = useSelector(getSelectedPreviousPositionStudy);
  const previousPositionStudiesFetchStatus = useSelector(getPreviousPositionStudiesFetchStatus);

  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => {
      dispatch(missionCreationActions.setSelectedCompany(company));
      dispatch(missionCreationActions.resetSelectedPreviousPositionStudy());
      dispatch(searchPreviousPositionStudies());
    },
    [dispatch]
  );
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) => {
      dispatch(missionCreationActions.setSelectedQualification(qualification));
      dispatch(missionCreationActions.resetSelectedPreviousPositionStudy());
      dispatch(searchPreviousPositionStudies());
    },
    [dispatch]
  );
  const setSelectedPreviousPositionStudy = useCallback(
    (previousPositionStudy?: PositionStudy) => {
      dispatch(missionCreationActions.setSelectedPreviousPositionStudy(previousPositionStudy));
    },
    [dispatch]
  );

  return (
    <MemoPreviousPositionStudies
      isPilotUnit={isPilotUnit}
      positionStudiesCount={positionStudiesCount}
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      previousPositionStudies={previousPositionStudies}
      previousPositionStudiesFetchStatus={previousPositionStudiesFetchStatus}
      selectedPreviousPositionStudy={selectedPreviousPositionStudy}
      setSelectedCompany={setSelectedCompany}
      setSelectedQualification={setSelectedQualification}
      setSelectedPreviousPositionStudy={setSelectedPreviousPositionStudy}
    />
  );
};

export default EnhancedPreviousPositionStudies;
