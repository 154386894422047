import { Information, SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Badge,
  Button,
  Popover,
  SearchInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import { RepositioningCdiiSortingMenu } from './RepositioningCdiiSortingMenu';
import { RepositioningCdiiTopBarContentProps } from './RepositioningCdiiTopBarContent.types';
import styles from './RepositioningCdiiTopBarContent.module.scss';

export const RepositioningCdiiTopBarContent = ({
  globalFilter,
  setGlobalFilter,
  sort,
  setSort,
}: RepositioningCdiiTopBarContentProps) => (
  <>
    <SearchInput
      size="small"
      value={globalFilter}
      onValueChange={setGlobalFilter}
      placeholder="rechercher"
      collapsed
    />
    <RepositioningCdiiSortingMenu
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending />
        </Button>
      }
      sort={sort}
      setSort={setSort}
    />
    <Popover
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Button variant="tertiary" size="medium">
          <Information />
        </Button>
      }
      className={styles.popover}
    >
      <Badge color="blue">sos envoyé</Badge>
      L'agence est en attente du traitement du SOS par le CGC
      <Badge color="greenish">suggéré</Badge>
      Le talent a été poussé vers des commandes ou vers des agences par le CGC
      <Badge color="information">en traitement</Badge>
      Le talent a été rattaché à des commandes après suggestion par le CGC
    </Popover>
  </>
);
