import classnames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalysis';
import { ROUTES } from 'src/Routes/Routes.types';
import { formatName } from 'src/Utils/formatName';
import { SidebarAT } from './SidebarWorkAccidentAnalysis';
import styles from './WorkAccidentAnalysisDetail.module.scss';

export const WorkAccidentAnalysisDetailRoute = () => {
  const { analysisId } = useTypedParams(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL);

  const workAccident = useFetchWorkAccidentAnalysis(analysisId);

  return (
    <div className={styles.mainContainer}>
      <TopBar goBackTo={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({})} title="analyse AT" />
      <div className={classnames(styles.container, styles.page)}>
        <div className={styles.containerAT}>
          <div className={styles.sidebar}>
            <SidebarAT
              candidateId={workAccident.data?.candidate?.id ?? ''}
              candidateFullName={formatName(workAccident.data?.candidate) ?? ''}
              qualification={workAccident.data?.contractQualification?.label ?? ''}
            />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
