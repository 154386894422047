import { Mission } from 'src/Services/API';

export interface MissionsTableProps {
  missions: Mission[];
  globalFilter: string;
}

export enum Solvency {
  VERT = 'VERT',
  ROUGE = 'ROUGE',
  ORANGE = 'ORANGE',
  NOIR = 'NOIR',
}

export const solvencyColor: Record<Solvency, 'navy' | 'success' | 'warning' | 'danger'> = {
  [Solvency.VERT]: 'success',
  [Solvency.ORANGE]: 'warning',
  [Solvency.ROUGE]: 'danger',
  [Solvency.NOIR]: 'navy',
};

export const solvencyLabel: Record<Solvency, string> = {
  [Solvency.VERT]: 'Solvabilité bonne',
  [Solvency.ORANGE]: 'Solvabilité moyenne',
  [Solvency.ROUGE]: 'Solvabilité mauvaise',
  [Solvency.NOIR]: 'Solvabilité bloquée',
};
