import {
  BadgeDeprecated,
  CardClickable,
  CardContent,
  CustomDataTable,
  Tooltip,
} from '@randstad-lean-mobile-factory/react-components-core';
import { MissionsTableProps, Solvency, solvencyColor, solvencyLabel } from './MissionsTable.types';
import styles from './MissionsTable.module.scss';
import moment from 'moment';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { getStatusLabel, getStatusStyle } from 'src/Services/Missions';
import { Circle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Mission } from 'src/Services/API';

export const MissionsTable = ({ missions, globalFilter }: MissionsTableProps) => {
  const navigate = useNavigate();
  const { data: isCGC } = useIsCGC();

  const columns = useMemo(
    () =>
      [
        { id: 'missionId', accessorKey: 'missionId' } as const,
        { id: 'agencyId', accessorKey: 'agencyId' } as const,
        { id: 'companyName', accessorFn: (mission: Mission) => mission.company?.name } as const,
        {
          id: 'solvency',
          accessorFn: (mission: Mission) =>
            Object.values(Solvency).find(s => s === mission.company?.solvency),
          enableGlobalFilter: false,
        } as const,
        { id: 'serviceName', accessorFn: (mission: Mission) => mission.service?.name } as const,
        {
          id: 'qualificationLabel',
          accessorFn: (mission: Mission) => mission.qualification?.label,
        } as const,
        {
          id: 'startDate',
          accessorFn: (mission: Mission) =>
            mission.startDate ? moment(mission.startDate).format('L') : undefined,
        } as const,
        {
          id: 'positionsCount',
          accessorFn: (mission: Mission) => mission.numberPositions || undefined,
          enableGlobalFilter: false,
        } as const,
        {
          id: 'salesPhase',
          accessorFn: (mission: Mission) => mission.salesPhase?.id,
          enableGlobalFilter: false,
        } as const,
      ] satisfies ColumnDef<Mission>[],
    []
  );

  return (
    <div className={styles.container}>
      <CustomDataTable columns={columns} data={missions} state={{ globalFilter }}>
        {(columnValues, row) => (
          <CardClickable
            key={row.id}
            color="white"
            onClick={() =>
              columnValues.missionId &&
              navigate(
                ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({
                  missionId: columnValues.missionId,
                })
              )
            }
          >
            <CardContent
              overline={[
                isCGC ? columnValues.agencyId : undefined,
                [columnValues.companyName, columnValues.serviceName].filter(Boolean).join(' - '),
              ]
                .filter(Boolean)
                .join(' | ')}
              title={
                (
                  <>
                    {columnValues.solvency !== undefined && (
                      <Tooltip title={solvencyLabel[columnValues.solvency]}>
                        <Circle
                          variant="fill"
                          size="small"
                          color={solvencyColor[columnValues.solvency]}
                        />
                      </Tooltip>
                    )}
                    {columnValues.qualificationLabel}
                  </>
                ) as any
              }
              supportingLines={[
                [
                  columnValues.startDate && `débute le ${columnValues.startDate}`,
                  columnValues.positionsCount && pluralFormat(columnValues.positionsCount, 'poste'),
                ]
                  .filter(Boolean)
                  .join(' | '),
              ].filter(Boolean)}
            />
            {columnValues.salesPhase && (
              <BadgeDeprecated
                value={getStatusLabel(columnValues.salesPhase)}
                className={getStatusStyle(columnValues.salesPhase)}
              />
            )}
          </CardClickable>
        )}
      </CustomDataTable>
    </div>
  );
};
