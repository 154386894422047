import { addDays, lastDayOfWeek, startOfWeek } from 'date-fns';
import { Props } from './CalendarWeeks.types';
import styles from './CalendarWeeks.module.scss';
import CalendarDay from './CalendarDay';

const CalendarWeeks = ({ currentMonth }: Props) => {
  const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });

  const week = [];
  const nextWeek = [];
  let days = [];
  let nextDays = [];
  let day = startDate;

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      days.push(<CalendarDay day={day} startDate={startDate} iteration={i} key={`${day} ${i}`} />);
      nextDays.push(
        <CalendarDay
          day={addDays(day, 7)}
          startDate={startDate}
          iteration={i}
          key={`${addDays(day, 7)} ${i}`}
        />
      );
      day = addDays(day, 1);
    }

    week.push(
      <div className={styles.row} key={day.getUTCDate().toString()}>
        {days}
      </div>
    );
    nextWeek.push(
      <div className={styles.row} key={addDays(day, 7).getUTCDate().toString()}>
        {nextDays}
      </div>
    );
    days = [];
    nextDays = [];
  }
  return (
    <div className={styles.weeksContainer}>
      <div className={styles.weekContainer}>{week}</div>
      <div className={styles.verticalSeparator} />
      <div className={styles.weekContainer}>{nextWeek}</div>
    </div>
  );
};

export default CalendarWeeks;
