import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';
import { AuthenticationProvider } from './Authentication';
import { ReactElement } from 'react';

const Provider = ({ children }: { children: ReactElement }) => (
  <AuthenticationProvider>
    <ReactQueryProvider>
      <ReduxProvider>{children}</ReduxProvider>
    </ReactQueryProvider>
  </AuthenticationProvider>
);

export default Provider;
