import { useState, useCallback, FocusEvent, FormEvent } from 'react';

import { Props } from './NumberPicker.types';
import styles from './NumberPicker.module.scss';
import { Button, TextInput } from '@randstad-lean-mobile-factory/react-components-core';

const NumberPicker = (props: Props) => {
  const [showNumberInput, setShowNumberInput] = useState(props.value > 5 ? true : false);
  const [customNumberInput, setCustomNumberInput] = useState(
    props.value > 5 ? props.value.toString() : ''
  );

  const onInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement, Element>) => {
      if (customNumberInput.length === 0) {
        setShowNumberInput(false);
      }
    },
    [customNumberInput.length]
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;
      if ((event.target as HTMLInputElement).value === '') {
        setCustomNumberInput((event.target as HTMLInputElement).value);
        props.onSelect(1);
      } else if (re.test((event.target as HTMLInputElement).value)) {
        setCustomNumberInput((event.target as HTMLInputElement).value);
        props.onSelect(parseFloat((event.target as HTMLInputElement).value));
      }
    },
    [props]
  );

  return (
    <div className={styles.container}>
      {Array(props.maxPreset)
        .fill(null)
        .map((_, i) => i + 1)
        .map(preset => (
          <div key={preset} className={styles.numberButton}>
            {preset === props.value ? (
              <Button>{preset}</Button>
            ) : (
              <Button.Secondary
                onClick={() => {
                  setCustomNumberInput('');
                  setShowNumberInput(false);
                  props.onSelect(preset);
                }}
                className={styles.unselected}
              >
                {preset}
              </Button.Secondary>
            )}
          </div>
        ))}
      {showNumberInput ? (
        <div className={styles.candidateInput}>
          <TextInput
            onBlur={onInputBlur}
            onChange={onInputChange}
            value={customNumberInput}
            autoFocus
          />
        </div>
      ) : (
        <div className={styles.addMore} onClick={() => setShowNumberInput(true)}>
          {props.entityIcon}
          <>{`plus de ${props.entityName}`}</>
        </div>
      )}
    </div>
  );
};

export default NumberPicker;
