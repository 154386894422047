import { useMemo } from 'react';

import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  CompanyContact,
  EnumWorkAccidentAnalysisStatus,
  WhyActionActorsEnum,
  WhyActionCategoriesEnum,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import Action from './Action';
import styles from './Actions.module.scss';
import {
  UpdateActionParams,
  WhyActionsActorsTranslation,
  WhyActionsCategoriesTranslation,
  whyActionsIcons,
  whyActionsModalIcons,
  whyActionsTree,
} from './Actions.types';
import { useFetchWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalysis';

export const ActionsRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.ACTION
  );
  const fetchCurrentAnalysis = useFetchWorkAccidentAnalysis(analysisId);
  const isEditable =
    fetchCurrentAnalysis.data?.status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis(analysisId);
  const updateWorkAnalysisMutationFetchStatus = toFetchStatus(updateWorkAnalysisMutation);
  const fetchAgencyConsultants = useFetchAgencyConsultants(
    fetchCurrentAnalysis.data?.agencyId ?? '',
    fetchCurrentAnalysis.data?.brandCode
  );

  const agencyConsultantsContacts = useMemo(
    () =>
      fetchAgencyConsultants.data?.map(
        consultant =>
          ({
            contactId: consultant.consultantId,
            firstName: consultant.firstName,
            name: consultant.name,
          }) as CompanyContact
      ),
    [fetchAgencyConsultants]
  );

  const fetchCompanyContacts = useFetchCompanyContacts(
    fetchCurrentAnalysis.data?.company?.id ?? ''
  );
  const currentWhy = fetchCurrentAnalysis.data?.whyAnalysis ?? {};
  const currentWhyActions = currentWhy.actions ?? [];

  const updateAction = ({ newAction, actionKeyName }: UpdateActionParams) => {
    if (isEditable) {
      if (!currentWhy) return;

      let updatedActions = currentWhyActions;

      if (actionKeyName) {
        updatedActions = updatedActions.filter(action => action.name !== actionKeyName);
      }

      if (newAction) {
        updatedActions = [...updatedActions, newAction];
      }

      const updatedWhyAnalyses = { ...currentWhy, actions: updatedActions };

      updateWorkAnalysisMutation.mutate({ whyAnalysis: updatedWhyAnalyses });
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>4.</div>
      <div className={styles.content}>
        <div className={styles.title}>actions client et agence</div>
        {fetchCurrentAnalysis.data && (
          <div key={`${currentWhy.name} container`}>
            {Object.keys(whyActionsTree).map(actionsActorKey => {
              return (
                <div key={`${currentWhy.name} ${actionsActorKey}`}>
                  <div className={styles.elementRow}>
                    <div className={styles.subTitle}>
                      {`actions ${
                        WhyActionsActorsTranslation[actionsActorKey as WhyActionActorsEnum]
                      }`}
                    </div>
                  </div>
                  {Object.keys(whyActionsTree[actionsActorKey as WhyActionActorsEnum]).map(
                    actionCategoryKey => {
                      return (
                        <WithLightTitle
                          key={`${currentWhy.name} ${actionsActorKey} ${actionCategoryKey}`}
                          title={
                            WhyActionsCategoriesTranslation[
                              actionCategoryKey as WhyActionCategoriesEnum
                            ]
                          }
                          className={styles.lightTitle}
                        >
                          {whyActionsTree[actionsActorKey as WhyActionActorsEnum][
                            actionCategoryKey as WhyActionCategoriesEnum
                          ].map(actionKey => {
                            return (
                              <div key={actionKey}>
                                <Action
                                  isEditable={isEditable}
                                  modalIcon={
                                    whyActionsModalIcons[actionsActorKey as WhyActionActorsEnum][
                                      actionCategoryKey as WhyActionCategoriesEnum
                                    ]
                                  }
                                  icon={
                                    whyActionsIcons[actionsActorKey as WhyActionActorsEnum][
                                      actionCategoryKey as WhyActionCategoriesEnum
                                    ]
                                  }
                                  actionKey={actionKey}
                                  actionCategoryKey={
                                    WhyActionCategoriesEnum[
                                      actionCategoryKey as keyof typeof WhyActionCategoriesEnum
                                    ]
                                  }
                                  actionsActorKey={
                                    WhyActionActorsEnum[
                                      actionsActorKey as keyof typeof WhyActionActorsEnum
                                    ]
                                  }
                                  possibleActors={
                                    (actionsActorKey === WhyActionActorsEnum.CLIENT
                                      ? fetchCompanyContacts.data
                                      : agencyConsultantsContacts) ?? []
                                  }
                                  action={currentWhyActions?.find(
                                    action => action.name === actionKey
                                  )}
                                  updateAction={updateAction}
                                  updateWorkAnalysisMutationFetchStatus={
                                    updateWorkAnalysisMutationFetchStatus
                                  }
                                />
                              </div>
                            );
                          })}
                        </WithLightTitle>
                      );
                    }
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
