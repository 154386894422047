import { CircleAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, Tab, Tabs } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TopBar } from 'src/Components/TopBar';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { FeatureFlag } from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import { CdiiSource } from '../CdiiList/CdiiList.types';
import styles from './Repositioning.module.scss';
import { Props, REPOSITIONING_CDII_TAB } from './Repositioning.types';
import { RepositioningInternPotentialOffers } from './RepositioningInternPotentialOffers';
import { RepositioningMissions } from './RepositioningMissions';
import { RepositioningPotentialOffers } from './RepositioningPotentialOffers';
import { RepositioningSkillMatching } from './RepositioningSkillMatching';

const Repositioning = ({ candidateId, candidateData, statut, source }: Props) => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();

  const isRepositioningPpaRandstadEnabled = useFeatureFlag(
    FeatureFlag.TMP_REPOSITIONING_CDII_PPA_RANDSTAD
  );

  const dispatch = useDispatch();

  const { data: isCGC } = useIsCGC();

  const [selectedTab, setSelectedTab] = useState(REPOSITIONING_CDII_TAB.COMMANDES);

  return (
    <div className={styles.container}>
      <TopBar
        title={`repositionnement de ${formatName(candidateData)} (${
          candidateData.lastContract?.agency?.id ?? 'non précisé'
        }) - ${candidateData.candidateAddress?.candidateCityName} (${
          candidateData.candidateAddress?.candidatePostalCode
        })`}
        goBackTo={ROUTES.TALENTS.CDII.buildPath({}, {}, source)}
        disablePerimeterSelection
      >
        {!isCGC && (
          <Button
            size="medium"
            onClick={() => {
              dispatch(missionCreationActions.setCandidateId(candidateId));
              navigate(
                MODAL_ROUTES.REPOSITIONING_CDII_MISSION_CREATION.route.buildPath({
                  candidateId,
                }),
                {
                  state: {
                    background: location,
                    modalParameters: {
                      isFromMyRegion: source === CdiiSource.regional,
                    },
                  },
                }
              );
            }}
          >
            <CircleAdd />
            nouvelle commande
          </Button>
        )}
      </TopBar>

      {isCGC && (
        <Tabs size="medium" value={selectedTab} onChange={setSelectedTab} className={styles.tabBar}>
          <Tab value={REPOSITIONING_CDII_TAB.COMMANDES}>mes commandes</Tab>
          {isRepositioningPpaRandstadEnabled && (
            <Tab value={REPOSITIONING_CDII_TAB.QUALIFICATION_RANDSTAD}>ppa Randstad</Tab>
          )}
          <Tab value={REPOSITIONING_CDII_TAB.QUALIFICATION_INTERNE}>ppa interne</Tab>
          <Tab value={REPOSITIONING_CDII_TAB.SKILL_MATCHING}>matching de compétences</Tab>
        </Tabs>
      )}

      {selectedTab === REPOSITIONING_CDII_TAB.COMMANDES ? (
        <RepositioningMissions
          candidateId={candidateId}
          statut={statut}
          candidateData={candidateData}
          source={source}
        />
      ) : selectedTab === REPOSITIONING_CDII_TAB.QUALIFICATION_RANDSTAD ? (
        <RepositioningPotentialOffers candidateId={candidateId} candidateData={candidateData} />
      ) : selectedTab === REPOSITIONING_CDII_TAB.QUALIFICATION_INTERNE ? (
        <RepositioningInternPotentialOffers candidateData={candidateData} />
      ) : selectedTab === REPOSITIONING_CDII_TAB.SKILL_MATCHING ? (
        <RepositioningSkillMatching
          candidateId={candidateId}
          candidateData={candidateData}
          statut={statut}
        />
      ) : null}
    </div>
  );
};

export default Repositioning;
