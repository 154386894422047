import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useFetchCgcAgenciesWithDistance } from 'src/Hooks/SkillsManagementCenters/useFetchCgcAgenciesWithDistance';
import { CGCAgencyPropositionModal } from './CGCAgencyPropositionModal';
import { AgencyCard } from './AgencyCard';
import styles from './RepositioningInternPotentialOffers.module.scss';
import { Props } from './RepositioningInternPotentialOffers.types';
import { AgencyWithDistance } from 'src/Services/API';

export const RepositioningInternPotentialOffers = ({ candidateData }: Props) => {
  const { data: agencies, isLoading } = useFetchCgcAgenciesWithDistance(
    candidateData.candidateAddress?.candidatePostalCode
  );

  const [selectedAgencies, setSelectedAgencies] = useState<AgencyWithDistance[]>([]);
  const [isAgencySuggestionOpen, setIsAgencySuggestionOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Checkbox
          checked={selectedAgencies.length !== 0}
          halfChecked={selectedAgencies.length > 0}
          onClick={() => {
            selectedAgencies.length ? setSelectedAgencies([]) : setSelectedAgencies(agencies ?? []);
          }}
        />
        <Button
          size="medium"
          variant="secondary"
          disabled={selectedAgencies.length === 0}
          onClick={() => setIsAgencySuggestionOpen(true)}
        >
          {selectedAgencies.length > 1 ? 'contacter les agences' : "contacter l'agence"}
        </Button>
      </div>
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des agences
        </div>
      ) : !agencies ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !agencies.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Il n'y a aucun agence correspondant aux critères
        </div>
      ) : (
        <AgencyCard
          agencies={agencies}
          candidateData={candidateData}
          selectedAgencies={selectedAgencies}
          setSelectedAgencies={setSelectedAgencies}
        />
      )}
      <CGCAgencyPropositionModal
        candidateData={candidateData}
        selectedAgencies={selectedAgencies}
        open={isAgencySuggestionOpen}
        onClose={() => setIsAgencySuggestionOpen(false)}
      />
    </div>
  );
};
