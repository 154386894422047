import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { SkillsManagementCentersService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useIsCGC = () => {
  const agencyId = useSelector(getCurrentAgency);

  return useQuery([QueryKeys.isCGC], async () => {
    const isCGC = await SkillsManagementCentersService.skillsManagementCentersControllerGetIsCgc({
      agencyId,
    });

    return isCGC;
  });
};
