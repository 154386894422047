import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CDII_STATUS } from 'src/Routes/Talents/Cdii/CdiiList/CdiiTable/CdiiTable.types';
import { getEndMission } from 'src/Routes/Talents/Cdii/utils';
import { CandidateSearchActivityPlaning, CandidatesService, SortTypeEnum } from 'src/Services/API';
import { QueryKeys } from '../types';

/** @deprecated TODO import this from src/Services/API when the route is updated to handle sorting in backend */
export enum FetchCdiiSortCriteria {
  AGENCY = 'AGENCY',
  LAST_CONTRACT_END_DATE = 'LAST_CONTRACT_END_DATE',
  STATUS = 'STATUS',
  AVAILABILITY = 'AVAILABILITY',
}

/** @deprecated TODO import this from src/Services/API when the route is updated to handle sorting in backend */
export interface FetchCdiiSort {
  sortCriteria: FetchCdiiSortCriteria;
  sortType: SortTypeEnum;
}

/** @deprecated TODO perform this sort in backend */
export const sortCdi = (
  cdii: CandidateSearchActivityPlaning[],
  sortCriteria: FetchCdiiSortCriteria,
  sortType: SortTypeEnum
): CandidateSearchActivityPlaning[] => {
  const today = moment();
  switch (sortCriteria) {
    case FetchCdiiSortCriteria.AGENCY:
      return cdii;
    case FetchCdiiSortCriteria.LAST_CONTRACT_END_DATE:
      const cdiiWithEndDate = cdii?.filter(
        candidate => candidate.lastContract?.endDate !== undefined
      );
      return [
        ...(cdiiWithEndDate ?? []).sort((a, b) => {
          const aEndDate = a.lastContract?.realEndDate ?? a.lastContract?.endDate;
          const bEndDate = b.lastContract?.realEndDate ?? b.lastContract?.endDate;
          return sortType === SortTypeEnum.ASC
            ? moment(`${aEndDate?.year}/${aEndDate?.month}/${aEndDate?.day}`, 'YYYY/MM/DD').isAfter(
                moment(`${bEndDate?.year}/${bEndDate?.month}/${bEndDate?.day}`, 'YYYY/MM/DD')
              )
              ? 1
              : -1
            : moment(
                  `${aEndDate?.year}/${aEndDate?.month}/${aEndDate?.day}`,
                  'YYYY/MM/DD'
                ).isBefore(
                  moment(`${bEndDate?.year}/${bEndDate?.month}/${bEndDate?.day}`, 'YYYY/MM/DD')
                )
              ? 1
              : -1;
        }),
        ...(cdii?.filter(candidate => candidate.lastContract?.endDate === undefined) ?? []),
      ];
    case FetchCdiiSortCriteria.STATUS:
      return [
        ...(cdii ?? []).sort((a, b) => {
          const statutA = getEndMission(a.lastContract?.realEndDate ?? a.lastContract?.endDate);
          const statutB = getEndMission(b.lastContract?.realEndDate ?? b.lastContract?.endDate);
          const nextAvailabilityDateA = moment(
            a.activityPlanning?.find(
              day =>
                moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
                day.status === 'Intermission'
            )?.date ?? moment().add(1, 'month')
          );
          const nextAvailabilityDateB = moment(
            b.activityPlanning?.find(
              day =>
                moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
                day.status === 'Intermission'
            )?.date ?? moment().add(1, 'month')
          );

          const customValuesA =
            statutA === CDII_STATUS.INTERMISSION
              ? 0
              : statutA === CDII_STATUS.ABSENT
                ? 1
                : statutA === CDII_STATUS.SOON_AVAILABLE
                  ? 2
                  : statutA === CDII_STATUS.MISSION
                    ? 3
                    : 4;
          const customValuesB =
            statutB === CDII_STATUS.INTERMISSION
              ? 0
              : statutB === CDII_STATUS.ABSENT
                ? 1
                : statutB === CDII_STATUS.SOON_AVAILABLE
                  ? 2
                  : statutB === CDII_STATUS.MISSION
                    ? 3
                    : 4;
          return sortType === SortTypeEnum.ASC
            ? customValuesA > customValuesB ||
              (customValuesA === customValuesB &&
                nextAvailabilityDateA.isAfter(nextAvailabilityDateB))
              ? 1
              : -1
            : customValuesA < customValuesB ||
                (customValuesA === customValuesB &&
                  nextAvailabilityDateA.isBefore(nextAvailabilityDateB))
              ? 1
              : -1;
        }),
      ];
    case FetchCdiiSortCriteria.AVAILABILITY:
      return [
        ...(cdii ?? []).sort((a, b) => {
          const nextAvailabilityDateA = a.activityPlanning?.find(
            day =>
              moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
              day.status === 'Intermission'
          )?.date;
          const nextAvailabilityDateB = b.activityPlanning?.find(
            day =>
              moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
              day.status === 'Intermission'
          )?.date;

          return sortType === SortTypeEnum.ASC
            ? !nextAvailabilityDateA
              ? 1
              : !nextAvailabilityDateB
                ? -1
                : moment(nextAvailabilityDateA).isAfter(moment(nextAvailabilityDateB))
                  ? 1
                  : -1
            : !nextAvailabilityDateA
              ? -1
              : !nextAvailabilityDateB
                ? 1
                : moment(nextAvailabilityDateA).isBefore(moment(nextAvailabilityDateB))
                  ? 1
                  : -1;
        }),
      ];
  }
};

export const useFetchAgencyCdiis = (params: { sort: FetchCdiiSort }) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchAgencyCdiis, params],
    async ({ pageParam = 0 }) => {
      // TODO: replace this with a paginated route
      const cdiis = await CandidatesService.candidatesControllerGetCandidatesActivityPlanning({
        agencyId,
        brandCode,
        startDate: moment().toISOString(),
        loadActivityPlannings: true,
      });
      const agencyCdiis = cdiis.filter(cdii => !cdii.cdiiStatus);
      return {
        cdiis: sortCdi(agencyCdiis, params.sort.sortCriteria, params.sort.sortType),
        nextPage: undefined as number | undefined,
      };
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
