import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CronJobDetails, CronJobExecutionMode, CronJobsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useRunCronJobManually = (
  cronJobId: string,
  extraOptions?: UseMutationOptions<string, AxiosError>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      return await CronJobsService.cronJobsControllerRunCronJobManually({ cronJob: cronJobId });
    },
    {
      ...extraOptions,
      onSuccess: (executionId, variables, context) => {
        queryClient.setQueryData<CronJobDetails>(
          [QueryKeys.fetchCronJobDetails, cronJobId],
          data => {
            if (!data) return;
            return {
              ...data,
              executions: [
                {
                  executionId,
                  mode: CronJobExecutionMode.MANUAL,
                  logs: [],
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
                ...data.executions,
              ],
              currentExecution: executionId,
            };
          }
        );
        extraOptions?.onSuccess?.(executionId, variables, context);
      },
    }
  );
};
