import {
  Action,
  Analysis,
  InterApp,
  License,
  List,
  Megaphone,
  Parameters,
  People,
  Profil,
  Settings,
  Tools,
  Youplan,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import {
  RandstadAppsPopup,
  Sidebar as SidebarPrimitive,
  SidebarItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getAnalyticsUserInfo, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { FeatureFlag } from 'src/Services/API';
import { ParametersPopup } from './ParametersPopup';
import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const currentSection = location.pathname.split('/').slice(0, 3).join('/');

  const userInfo = useSelector(getAnalyticsUserInfo);
  const brandCode = useSelector(getCurrentBrandCode);

  const enableActivity = useFeatureFlag(FeatureFlag.ACTIVITY);
  const enableTalents = useFeatureFlag(FeatureFlag.TALENTS);
  const enableAccidentology = useFeatureFlag(FeatureFlag.ACCIDENTOLOGY);
  const enableExternalDemands = useFeatureFlag(FeatureFlag.EXTERNAL_DEMANDS);
  const enableVariableSimulator = useFeatureFlag(FeatureFlag.VARIABLE_SIMULATOR);

  const enableCdii = useFeatureFlag(FeatureFlag.TMP_REPOSITIONING_CDII);
  const enableTemporaryWorkers = useFeatureFlag(FeatureFlag.TMP_REPOSITIONING_TEMPORARY_WORKERS);

  const sections = useMemo(
    () => [
      enableActivity && {
        title: "suivi d'activité",
        items: [
          {
            Icon: Megaphone,
            title: 'commandes',
            selected: currentSection === ROUTES.ACTIVITY.MISSIONS.buildPath({}),
            onClick: () => navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({})),
          },
          {
            Icon: License,
            title: 'contrats',
            selected: currentSection === ROUTES.ACTIVITY.CONTRACTS.buildPath({}),
            onClick: () => navigate(ROUTES.ACTIVITY.CONTRACTS.buildPath({})),
          },
          {
            Icon: Youplan,
            title: 'youplan',
            selected: currentSection === ROUTES.ACTIVITY.YOUPLAN.buildPath({}),
            onClick: () => navigate(ROUTES.ACTIVITY.YOUPLAN.buildPath({})),
          },
        ],
      },
      enableTalents && {
        title: 'planning de mission',
        items: [
          enableCdii && {
            Icon: People,
            title: 'cdii',
            selected: currentSection === ROUTES.TALENTS.CDII.buildPath({}),
            onClick: () => navigate(ROUTES.TALENTS.CDII.buildPath({})),
          },
          enableTemporaryWorkers && {
            Icon: Profil,
            title: 'intérimaires',
            selected: currentSection === ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}),
            onClick: () => {
              navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}));
              ReactGA.event(ANALYTICS_EVENT.CLICK_TALENT_SCREEN, {
                ...userInfo,
              });
            },
          },
        ],
      },
      enableAccidentology && {
        title: 'accidentologie',
        items: [
          {
            Icon: Analysis,
            title: 'analyse at',
            selected: currentSection === ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}),
            onClick: () => navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({})),
          },
          {
            Icon: Action,
            title: "plans d'action",
            selected: currentSection === ROUTES.ACCIDENTOLOGY.ACTION_PLAN.buildPath({}),
            onClick: () => navigate(ROUTES.ACCIDENTOLOGY.ACTION_PLAN.buildPath({})),
          },
        ],
      },
      enableExternalDemands && {
        title: 'demandes externes',
        items: [
          {
            Icon: List,
            title: 'liste demandes',
            selected: currentSection === ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({}),
            onClick: () => navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({})),
          },
          {
            Icon: Parameters,
            title: 'correspondances',
            selected: currentSection === ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}),
            onClick: () => navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({})),
          },
        ],
      },
    ],
    [
      currentSection,
      enableAccidentology,
      enableActivity,
      enableCdii,
      enableExternalDemands,
      enableTemporaryWorkers,
      navigate,
      userInfo,
    ]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const edpId = params.get('edpId');
    const items = sections
      .filter(Boolean)
      .flatMap(section => section.items)
      .filter(Boolean);
    if (
      !items.some(item => item.selected) &&
      !currentSection.startsWith(ROUTES.TOOLS.buildPath({})) &&
      edpId === null
    ) {
      items.at(0)?.onClick();
    }
  }, [currentSection, navigate, sections]);

  return (
    <SidebarPrimitive data-theme="dark" AppLogo={Flash} appName="flash" sections={sections}>
      {enableVariableSimulator && (
        <SidebarItem
          Icon={Tools}
          title="outils"
          onClick={() =>
            navigate(MODAL_ROUTES.VARIABLE_SIMULATOR.route.buildPath({}), {
              state: { background: location },
            })
          }
        />
      )}
      <RandstadAppsPopup
        isProd={process.env.REACT_APP_ENV === 'production'}
        brandCode={brandCode}
        trigger={<SidebarItem Icon={InterApp} title="applications" />}
        triggerClassName={styles.trigger}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      />
      <ParametersPopup
        trigger={<SidebarItem Icon={Settings} title="paramètres" />}
        triggerClassName={styles.trigger}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      />
    </SidebarPrimitive>
  );
};
