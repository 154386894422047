import { AxiosError, AxiosRequestConfig } from 'axios';

import DefaultInterceptors from './default';
import SecurityInterceptors from './security';
import { AxiosInterceptors } from './types';

const axiosInterceptors: AxiosInterceptors[] = [DefaultInterceptors, SecurityInterceptors];

export async function requestInterceptor(
  initialConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  return axiosInterceptors.reduce(async (config, { requestInterceptor }) => {
    return requestInterceptor?.(await config) ?? config;
  }, Promise.resolve(initialConfig));
}

export async function errorInterceptor(initialError: AxiosError): Promise<AxiosError> {
  return axiosInterceptors.reduce(async (error, { errorInterceptor }) => {
    return errorInterceptor?.(await error) ?? error;
  }, Promise.resolve(initialError));
}
