import {
  Badge,
  Loader,
  SearchInput,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopBar } from 'src/Components/TopBar';
import { useFetchUniqueSirets } from 'src/Hooks/MailParameters/useFetchUniqueSirets';
import { getSelectedSource } from 'src/Redux/ExternalDemands/Selectors';
import { externalDemandsActions } from 'src/Redux/ExternalDemands/Slice';
import { MailParametersList } from 'src/Routes/ExternalDemands/DemandsPreferences/MailParametersList';
import { AppelMedicalEventSourceDto } from 'src/Services/API';
import styles from './DemandsPreferences.module.scss';

export const DemandsPreferencesRoute = () => {
  const selectedSource = useSelector(getSelectedSource);
  const dispatch = useDispatch();

  const [globalFilter, setGlobalFilter] = useState('');
  const { data, isLoading } = useFetchUniqueSirets();

  return (
    <>
      <div className={styles.container}>
        <TopBar title="table de correspondances">
          <SearchInput
            size="medium"
            value={globalFilter}
            onValueChange={setGlobalFilter}
            placeholder="siret ou client"
            collapsed
          />
        </TopBar>
        <ToggleMenu
          value={selectedSource}
          onChange={source => dispatch(externalDemandsActions.setSelectedSource(source))}
          className={styles.toggleMenu}
        >
          {[AppelMedicalEventSourceDto.ARTEMIHS, AppelMedicalEventSourceDto.HUBLO].map(source => {
            const total = data
              ?.flatMap(
                siret => siret.parameterStats.find(item => item.source === source)?.total ?? 0
              )
              .reduce((a, b) => a + b, 0);
            const missing = data?.some(
              siret => (siret.parameterStats.find(item => item.source === source)?.missing ?? 0) > 0
            );

            return (
              <ToggleMenuItem value={source}>
                {source.toLowerCase()}
                {isLoading ? (
                  <Loader size="xsmall" />
                ) : (
                  total !== undefined && <Badge color="currentColor">{total}</Badge>
                )}
                {missing && <div className={styles.bullet} />}
              </ToggleMenuItem>
            );
          })}
        </ToggleMenu>

        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <div>chargement des correspondances, cela peut prendre du temps</div>
          </div>
        ) : !data ? (
          <div className={styles.error}>
            Une erreur s'est produite pendant la recherche des correspondances
          </div>
        ) : (
          <MailParametersList
            uniqueSirets={data}
            source={selectedSource}
            globalFilter={globalFilter}
          />
        )}
      </div>
    </>
  );
};
