import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  BadgeDeprecated,
  DataTable,
  Loader,
  Tooltip,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef, Table } from '@tanstack/react-table';
import classnames from 'classnames';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { CandidateSearchActivityPlaning, DBCDIIStatus } from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import { getEndMission, getRepositionedStatus } from '../../utils';
import { CdiiSource } from '../CdiiList.types';
import { CdiiTableProps, CDII_STATUS } from './CdiiTable.types';
import styles from './CdiiTable.module.scss';

export const CdiiTable = ({
  source,
  cdiis,
  globalFilter,
  isFetching,
  isLoading,
  hasNextPage,
}: CdiiTableProps) => {
  const navigate = useNavigate();

  const columns = useMemo((): ColumnDef<CandidateSearchActivityPlaning>[] => {
    const columns: (ColumnDef<CandidateSearchActivityPlaning> | false)[] = [
      {
        id: 'name',
        header: 'nom',
        accessorFn: formatName,
        cell: ({ getValue }) => <span className={styles.name}>{getValue() as string}</span>,
      },
      {
        id: 'agency',
        header: 'unité du dernier contrat',
        accessorFn: cdii => cdii.lastContract?.agency?.id,
      },
      {
        id: 'qualification',
        header: 'qualification dernier contrat',
        accessorFn: cdii => cdii.lastContract?.qualification?.label?.toLowerCase(),
      },
      {
        id: 'address',
        header: 'lieu de résidence',
        accessorFn: cdii =>
          [cdii.candidateAddress?.candidatePostalCode, cdii.candidateAddress?.candidateCityName]
            .filter(Boolean)
            .join(' '),
      },
      {
        id: 'lastContractEndDate',
        header: 'date fin contrat',
        accessorFn: cdii => {
          const endDate = cdii.lastContract?.realEndDate ?? cdii.lastContract?.endDate;
          if (!endDate) return;
          return moment(`${endDate?.year}/${endDate?.month}/${endDate?.day}`, 'YYYY/MM/DD').format(
            'L'
          );
        },
      },
      {
        id: 'availability',
        header: 'prochaine dispo',
        accessorFn: cdii => {
          const availabilityDate = cdii.activityPlanning?.find(
            day => moment(day.date).isSameOrAfter(moment(), 'day') && day.status === 'Intermission'
          )?.date;
          if (!availabilityDate) return;
          return moment(availabilityDate).format('L');
        },
      },
      {
        id: 'status',
        header: 'statut',
        cell: ({ row }) => {
          const cdiiStatus = getEndMission(
            row.original.lastContract?.realEndDate ?? row.original.lastContract?.endDate,
            row.original.activityPlanning?.find(day => moment(day.date).isSame(moment(), 'day'))
              ?.status
          );
          return (
            <BadgeDeprecated
              value={cdiiStatus}
              className={classnames({
                [styles.badgeInMission]: cdiiStatus === CDII_STATUS.MISSION,
                [styles.badgeSoonAvailable]: cdiiStatus === CDII_STATUS.SOON_AVAILABLE,
                [styles.badgeIntermission]: cdiiStatus === CDII_STATUS.INTERMISSION,
                [styles.badgeAbsent]: cdiiStatus === CDII_STATUS.ABSENT,
              })}
            />
          );
        },
      },
      source !== CdiiSource.agency && {
        id: 'repositioningStatus',
        header: 'étape repositionnement',
        cell: ({ row }) => {
          if (!row.original.cdiiStatus) return null;
          const repositionedCdiiStatus = getRepositionedStatus(row.original.cdiiStatus);
          return (
            <Tooltip
              title={
                row.original.cdiiStatus === DBCDIIStatus.suggested
                  ? 'Le talent a été poussé vers des commandes ou vers des agences par le CGC'
                  : row.original.cdiiStatus === DBCDIIStatus.available
                    ? "L'agence est en attente du traitement du SOS par le CGC"
                    : row.original.cdiiStatus === DBCDIIStatus.processing
                      ? 'Le talent a été rattaché à des commandes après suggestion par le CGC'
                      : undefined
              }
            >
              <BadgeDeprecated
                value={repositionedCdiiStatus ?? CDII_STATUS.ABSENT}
                className={classnames({
                  [styles.badgeSuggested]: row.original.cdiiStatus === DBCDIIStatus.suggested,
                  [styles.badgeAvailable]: row.original.cdiiStatus === DBCDIIStatus.available,
                  [styles.badgeProcessing]: row.original.cdiiStatus === DBCDIIStatus.processing,
                })}
              />
            </Tooltip>
          );
        },
      },
    ];
    return columns.filter(Boolean);
  }, [source]);

  const table = useRef<Table<CandidateSearchActivityPlaning>>(null);

  return (
    <>
      {isLoading || (cdiis?.length === 0 && hasNextPage) ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des CDII-TT, cela peut prendre du temps
        </div>
      ) : !cdiis ? (
        <div className={styles.illustration}>
          <ServerErrorYellow />
          désolé, une erreur s'est produite lors du chargement des CDII-TT
        </div>
      ) : (
        <>
          <DataTable
            size="medium"
            columns={columns}
            data={cdiis}
            getRowId={candidate => candidate.id ?? ''}
            rowStyle="cards"
            enableColumnFilters={false}
            enableSorting={false}
            state={{ globalFilter }}
            empty={
              <>
                {isFetching ? (
                  <div className={styles.illustration}>
                    <Loader size="large" />
                    chargement des CDII-TT, cela peut prendre du temps
                  </div>
                ) : (
                  <div className={styles.illustration}>
                    <VidePasteque />
                    nous n'avons trouvé aucun CDII-TT correspondant à votre recherche
                  </div>
                )}
              </>
            }
            hideHeadersIfEmpty
            onRowClick={row =>
              navigate(
                ROUTES.TALENTS.CDII.CDII_DETAIL.buildPath(
                  { candidateId: row.original.id ?? '' },
                  undefined,
                  source
                )
              )
            }
            ref={table}
          />
          {!!table.current?.getRowCount() && isFetching && (
            <div className={styles.illustration}>
              <Loader size="small" />
              chargement des CDII-TT suivants
            </div>
          )}
        </>
      )}
    </>
  );
};
