import { Liste } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader, ModalContent } from '@randstad-lean-mobile-factory/react-components-core';
import { useFetchWorkAccidentAnalyses } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentAnalyses';
import { WorkAccidentAnalysisCard } from '../WorkAccidentAnalysisCard';
import styles from './ArchivedWorkAccidentAnalysesDrawerContent.module.scss';

export const ArchivedWorkAccidentAnalysesDrawerContent = () => {
  const { data: workAccidents, isLoading } = useFetchWorkAccidentAnalyses();

  return (
    <ModalContent header={<Liste />} title="liste des AT classées">
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des analyses AT classées
        </div>
      ) : !workAccidents ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !workAccidents.archived.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          aucune analyse AT classée
        </div>
      ) : (
        workAccidents.archived.map(workAccidentAnalysis => (
          <WorkAccidentAnalysisCard
            key={workAccidentAnalysis.id}
            color="beige"
            workAccidentAnalysis={workAccidentAnalysis}
            status="ARCHIVED"
          />
        ))
      )}
    </ModalContent>
  );
};
