import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

/** @deprecated TODO replace with a paginated route */
export const useFetchCandidatesActivityPlanning = () => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchCandidatesActivityPlanning],
    async () => {
      const candidates = await CandidatesService.candidatesControllerGetCandidatesActivityPlanning({
        agencyId,
        brandCode,
        startDate: moment().toISOString(),
        loadActivityPlannings: true,
      });
      return candidates;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
