import { Fragment } from 'react';

import { Props } from './DayTimePicker.types';
import styles from './DayTimePicker.module.scss';
import { Button, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import TimePicker from '../TimePicker';

const weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const DayTimePicker = (props: Props) => {
  return (
    <div className={styles.container}>
      <WithLightTitle title="heure">
        <TimePicker
          left
          value={props.dayTimes.startTime}
          onChange={event => {
            const newDayTimes = Object.assign({}, props.dayTimes);
            newDayTimes.startTime = (event.target as HTMLInputElement).value;
            props.onUpdate(newDayTimes);
          }}
        />
        <TimePicker
          right
          value={props.dayTimes.endTime}
          onChange={event => {
            const newDayTimes = Object.assign({}, props.dayTimes);
            newDayTimes.endTime = (event.target as HTMLInputElement).value;
            props.onUpdate(newDayTimes);
          }}
        />
      </WithLightTitle>
      <div className={styles.daysPicker}>
        <WithLightTitle title="jours">
          <div className={styles.daysTable}>
            {Array(weekDays.length)
              .fill(null)
              .map((_, index) => (
                <Fragment key={index}>
                  {props.dayTimes.selectedDays.includes(index + 1) ? (
                    <Button
                      className={classnames(styles.dayButton, {
                        [styles.selectedLastDay]: index === weekDays.length - 1,
                        [styles.firstDay]: index === 0,
                      })}
                      onClick={() => {
                        const newDayTimes = {
                          ...props.dayTimes,
                          selectedDays: [] as number[],
                        };
                        props.dayTimes.selectedDays.forEach(element => {
                          if (element !== index + 1) newDayTimes.selectedDays.push(element);
                        });
                        props.onUpdate(newDayTimes);
                      }}
                    >
                      {weekDays[index][0]}
                    </Button>
                  ) : (
                    <Button.Secondary
                      className={classnames(styles.unselectedDayButton, {
                        [styles.lastDay]: index === weekDays.length - 1,
                        [styles.firstDay]: index === 0,
                      })}
                      onClick={() => {
                        const newDayTimes = {
                          ...props.dayTimes,
                          selectedDays: [...props.dayTimes.selectedDays],
                        };
                        newDayTimes.selectedDays.push(index + 1);
                        props.onUpdate(newDayTimes);
                      }}
                    >
                      {weekDays[index][0]}
                    </Button.Secondary>
                  )}
                </Fragment>
              ))}
          </div>
        </WithLightTitle>
      </div>
    </div>
  );
};

export default DayTimePicker;
