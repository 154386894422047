import styles from './MissionCardLoader.module.scss';
import ContentLoader from 'react-content-loader';
import { Props } from './MissionCardLoader.types';

const MissionCardLoader = (props: Props) => (
  <div className={styles.container}>
    <ContentLoader height="4.5rem" width="40%" uniqueKey={props.uniqueKey + '_left'}>
      <rect x="1%" y="10" rx="4" ry="4" width="50%" height="18" />
      <rect x="1%" y="30" rx="4" ry="4" width="80%" height="18" />
    </ContentLoader>
    <ContentLoader height="4.5rem" width="30%" uniqueKey={props.uniqueKey + '_right'}>
      <rect x="0" y="20" rx="4" ry="4" width="90%" height="10" />
      <rect x="20%" y="36" rx="4" ry="4" width="50%" height="10" />
    </ContentLoader>
  </div>
);

export default MissionCardLoader;
