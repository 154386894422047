import { SigningStatusEnum } from 'src/Services/API';
import { z } from 'zod';

export const conclusionSchema = z.object({
  comments: z.string().optional(),
  dateOfSignature: z.date().optional(),
  randstadSigningPerson: z
    .object({
      personName: z.string().optional(),
      id: z.string().optional(),
      moment: z.date().optional(),
      signed: z.boolean().optional(),
    })
    .optional(),
  clientSigningPerson: z
    .object({
      personName: z.string().optional(),
      id: z.string().optional(),
      moment: z.date().optional(),
      signed: z.boolean().optional(),
      clientSigningStatus: z.nativeEnum(SigningStatusEnum).optional(),
    })
    .optional(),
  victimSigningPerson: z
    .object({
      moment: z.date().optional(),
      signed: z.boolean().optional(),
      victimSigningStatus: z.nativeEnum(SigningStatusEnum).optional(),
    })
    .optional(),
  additionalSigningPerson: z
    .object({
      personName: z.string().optional(),
      moment: z.date().optional(),
      signed: z.boolean().optional(),
      personRole: z.string().optional(),
    })
    .optional(),
});

export type ConclusionSchemaType = z.infer<typeof conclusionSchema>;
