export function formatCity(address: undefined): undefined;
export function formatCity(address: { city: string; postalCode?: string }): string;
export function formatCity(address: { city?: string; postalCode: string }): string;
export function formatCity(address: { city?: string; postalCode?: string }): string | undefined;
export function formatCity(
  address:
    | {
        city?: string;
        postalCode?: string;
      }
    | undefined
): string | undefined;
export function formatCity(
  address:
    | {
        city?: string;
        postalCode?: string;
      }
    | undefined
): string | undefined {
  if (!address) return;
  if (!address.city && !address.postalCode) return;
  if (address.city && address.postalCode) return `${address.city} (${address.postalCode})`;
  return [address.city, address.postalCode].filter(Boolean).join(' ');
}
