import { ThunkDispatch } from '@reduxjs/toolkit';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { RootState } from 'src/Redux/RootReducer';
import { useSearchYouplanCompaniesWithReset } from 'src/Redux/Search/Hooks';
import { searchActions } from 'src/Redux/Search/Slice';
import {
  getCurrentYouplanCompany,
  getYouplanCompanies,
  getYouplanFetchStatus,
} from 'src/Redux/YouplanCompanies/Selectors';
import { youplanCompaniesActions } from 'src/Redux/YouplanCompanies/Slice';
import { EnumSearchMissionsBodySortBy } from 'src/Services/API';
import ListYouplanMissions from './ListYouplanMissions.component';
import { pluralFormat } from 'src/Utils/pluralFormat';

const MemoYouplanList = memo(ListYouplanMissions);

const EnhancedYouplanList = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const youplanCompanies = useSelector(getYouplanCompanies);
  const youplanCompaniesFetchStatus = useSelector(getYouplanFetchStatus);
  const currentCompany = useSelector(getCurrentYouplanCompany);

  const searchWithReset = useSearchYouplanCompaniesWithReset();

  useEffect(() => {
    searchWithReset();
    dispatch(searchActions.setSortMode(EnumSearchMissionsBodySortBy['date de début']));
  }, [dispatch, searchWithReset]);

  const onLoadMore = useCallback(() => {
    dispatch(searchActions.incrementOffset());
    searchWithReset();
  }, [dispatch, searchWithReset]);

  return (
    <MemoYouplanList
      fetchStatus={youplanCompaniesFetchStatus}
      selectedId={currentCompany?.companyId ?? null}
      cards={youplanCompanies.map(company => ({
        id: company.companyId ?? '',
        title: company.companyName ?? 'Compte inconnu',
        firstSubtitle: company.siret ?? '',
        description: company.activeMissionsCount
          ? pluralFormat(company.activeMissionsCount, 'commande', true)
          : '',
        borderLeftColor: 'red',
        onClick: () => {
          dispatch(youplanCompaniesActions.setCurrentYouplanCompany(company));
        },
      }))}
      onLoadMore={onLoadMore}
    />
  );
};

export default EnhancedYouplanList;
