import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { WorkAccidentAnalysisService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useUnarchiveWorkAccidentAnalysis = (
  analysisId: string,
  extraOptions?: UseMutationOptions<void, AxiosError, void, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.unarchiveWorkAccidentAnalysis],
    async () => {
      await WorkAccidentAnalysisService.workAccidentAnalysisControllerUnarchiveWorkAccidentAnalysis(
        { analysisId }
      );
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentAnalyses]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
