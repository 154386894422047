import {
  AppelMedicalEventSourceDto,
  ExternalDemand,
  ExternalDemandEvent,
  UniqueColumnValues,
} from 'src/Services/API';
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';

export interface Props {
  data: ExternalDemand[];
  source: AppelMedicalEventSourceDto;
  globalFilter: string;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  count: number;
  uniqueColumnValues: UniqueColumnValues | undefined;
}

export interface ExternalDemandChild extends ExternalDemandEvent {
  parent: ExternalDemand;
}

export type ExternalDemandElement = ExternalDemand | ExternalDemandChild;

export const isDemandEvent = (element: ExternalDemandElement): element is ExternalDemandChild =>
  !(element as ExternalDemand).events;

export const isDemandDto = (element: ExternalDemandElement): element is ExternalDemand =>
  Boolean((element as ExternalDemand).events);

export const isDemandDtoWithChildren = (
  element: ExternalDemandElement
): element is ExternalDemand =>
  Boolean((element as ExternalDemand).events) && (element as ExternalDemand).events.length > 1;
