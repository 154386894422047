import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { FeatureFlagsService, ResolvedFeatureFlag } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useAuth0 } from '@auth0/auth0-react';

export const useFetchFeatureFlags = () => {
  const { isAuthenticated } = useAuth0();

  const agencyId = useSelector(getCurrentAgency);
  const brandCode = useSelector(getCurrentBrand)?.brandCodeApiHeader;

  return useQuery<ResolvedFeatureFlag[], AxiosError>(
    // This is the only query that needs to have the current agency id and current brand code in the query key, because it is not reset on perimeter change
    [QueryKeys.fetchFeatureFlags, agencyId, brandCode],
    async () => {
      return await FeatureFlagsService.featureFlagsControllerFetchFeatureFlags({
        body: { agencyId, brandCode },
      });
    },
    {
      retry: false,
      enabled: Boolean(isAuthenticated && agencyId && brandCode),
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const usePrefetchFeatureFlags = (agencyId: string, brandCode: string) => {
  const { isAuthenticated } = useAuth0();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isAuthenticated) return;
    queryClient.prefetchQuery<ResolvedFeatureFlag[], AxiosError>(
      [QueryKeys.fetchFeatureFlags, agencyId, brandCode],
      async () => {
        return await FeatureFlagsService.featureFlagsControllerFetchFeatureFlags({
          body: { agencyId, brandCode },
        });
      },
      {
        retry: false,
        cacheTime: Infinity,
        staleTime: Infinity,
      }
    );
  }, [agencyId, brandCode, isAuthenticated, queryClient]);
};
