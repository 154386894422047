import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  CardCell,
  CardRow,
  Loader,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFetchFeatureFlagConfigs } from 'src/Hooks/FeatureFlags/useFetchFeatureFlagConfigs';

import { TableRow } from '@mui/material';
import moment from 'moment';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './FeatureFlags.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Routes.types';
import { TopBar } from 'src/Components/TopBar';

export const FeatureFlagsRoute = () => {
  const navigate = useNavigate();

  const { data: featureFlags, isLoading } = useFetchFeatureFlagConfigs();

  return (
    <div className={styles.container}>
      <TopBar disablePerimeterSelection title="feature flags" />
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des feature flags
          </div>
        ) : !featureFlags ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : featureFlags.length === 0 ? (
          <div className={styles.illustration}>
            <VidePasteque />
            aucun feature flag en cours
          </div>
        ) : (
          <StyledTable size="small">
            <TableHeader>
              <TableRow>
                <TableHead>feature flag</TableHead>
                <TableHead>activation</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {featureFlags.map(featureFlag => {
                const activation = (() => {
                  if (featureFlag.enabledGlobally) return 'activé';
                  const brands = featureFlag.enabledBrands.filter(
                    brand => !brand.activationDate || moment().isAfter(brand.activationDate)
                  ).length;
                  const regions = featureFlag.enabledRegions.filter(
                    region => !region.activationDate || moment().isAfter(region.activationDate)
                  ).length;
                  const agencies = featureFlag.enabledAgencies.filter(
                    agency => !agency.activationDate || moment().isAfter(agency.activationDate)
                  ).length;
                  if (!brands && !regions && !agencies) return 'désactivé';
                  return (
                    'activé sur ' +
                    [
                      brands && pluralFormat(brands, 'marque'),
                      regions && pluralFormat(regions, 'région'),
                      agencies && pluralFormat(agencies, 'agence'),
                    ]
                      .filter(Boolean)
                      .join(' et ')
                  );
                })();
                return (
                  <CardRow
                    color="white"
                    key={featureFlag.featureFlagId}
                    onClick={() =>
                      navigate(
                        ROUTES.TOOLS.FEATURE_FLAGS.FEATURE_FLAG_DETAILS.buildPath({
                          featureFlagId: featureFlag.featureFlagId,
                        })
                      )
                    }
                    isClickable
                  >
                    <CardCell>{featureFlag.featureFlagId}</CardCell>
                    <CardCell>{activation}</CardCell>
                  </CardRow>
                );
              })}
            </TableBody>
          </StyledTable>
        )}
      </div>
    </div>
  );
};
