import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { formatName } from 'src/Utils/formatName';
import { toFetchStatus } from 'src/Services/Async';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { Props } from './SelectAgencyConsultant.types';

const SelectAgencyConsultant = ({
  selectedAgencyConsultant,
  setSelectedAgencyConsultant,
}: Props) => {
  const agencyId = useSelector(getCurrentAgency);
  const agencyConsultants = useFetchAgencyConsultants(agencyId);
  const contactsFetchStatus = toFetchStatus();

  return (
    <ComboBox
      id="SelectContact"
      disableClearable={false}
      value={selectedAgencyConsultant}
      useNativeOptionFiltering
      placeholder="nom consultant"
      multiple={false}
      searchResults={agencyConsultants.data ?? []}
      keyValueExtractor={consultant => ({
        key: consultant.consultantId,
        value: formatName({ firstName: consultant.firstName, name: consultant.name }) ?? '',
      })}
      onChange={searchResult => setSelectedAgencyConsultant(searchResult ?? undefined)}
      fetchStatus={contactsFetchStatus}
      minLengthToSearch={0}
    />
  );
};

export default SelectAgencyConsultant;
