import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { FetchWorkAccidentsResponse, WorkAccidentAnalysisService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidentAnalyses = (
  extraOptions?: Omit<
    UseQueryOptions<FetchWorkAccidentsResponse, unknown, FetchWorkAccidentsResponse, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchWorkAccidentAnalyses],
    async () => {
      return WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentAnalyses({
        agencyId,
        brandCode,
      });
    },
    { ...extraOptions }
  );
};
