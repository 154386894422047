import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  TextInput,
  ToggleMenu,
  ToggleMenuItem,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useUpdateCronJobConfig } from 'src/Hooks/CronJobs/useUpdateCronJobConfig';
import { CronJobConfigModalProps } from './CronJobConfigModal.types';

const CronJobConfigModalContent = ({ cronJobConfig, onClose }: CronJobConfigModalProps) => {
  const [localEnabled, setLocalEnabled] = useState(cronJobConfig.enabled);
  const [localCronTime, setLocalCronTime] = useState(cronJobConfig.cronTime);

  const updateCronJobConfig = useUpdateCronJobConfig(cronJobConfig.cronJobId, {
    onSuccess: () => {
      setTimeout(() => onClose?.(), 500);
    },
    onError: () => {
      setTimeout(() => updateCronJobConfig.reset(), 500);
    },
  });

  return (
    <>
      <ModalContent title={cronJobConfig.cronJobId}>
        <WithLightTitle title="activation de la tâche">
          <ToggleMenu
            value={localEnabled.toString()}
            onChange={value => setLocalEnabled(value === 'true')}
          >
            <ToggleMenuItem value={'true'}>activée</ToggleMenuItem>
            <ToggleMenuItem value={'false'}>désactivée</ToggleMenuItem>
          </ToggleMenu>
        </WithLightTitle>
        <WithLightTitle
          title="fréquence de la tâche"
          rightTitleComponent={
            <Button
              variant="tertiary"
              size="small"
              onClick={() => window.open('https://cronify.zimo.li/')}
            >
              aide avec la syntaxe cron
            </Button>
          }
        >
          <TextInput value={localCronTime} onValueChange={setLocalCronTime} />
        </WithLightTitle>
      </ModalContent>
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setLocalEnabled(cronJobConfig.enabled);
            setLocalCronTime(cronJobConfig.cronTime);
          }}
        >
          réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={() => onClose?.()}>
          annuler
        </Button>
        <Button
          onClick={() =>
            updateCronJobConfig.mutate({ enabled: localEnabled, cronTime: localCronTime })
          }
          mutationStatus={updateCronJobConfig.status}
        >
          enregistrer
        </Button>
      </ModalActions>
    </>
  );
};

export const CronJobConfigModal = ({ cronJobConfig, open, onClose }: CronJobConfigModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <CronJobConfigModalContent cronJobConfig={cronJobConfig} open={open} onClose={onClose} />
    </Modal>
  );
};
