import { WhoGaveEpi, YesNoUnknown } from 'src/Services/API';
import { z } from 'zod';

export const EpiSchema = z.object({
  hasBeenWorn: z.boolean(),
  whoGaveIt: z.nativeEnum(WhoGaveEpi),
  inContract: z.nativeEnum(YesNoUnknown),
});

export type EpiSchemaType = z.infer<typeof EpiSchema>;
