import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import {
  EnumCandidateCandidateAdvancement,
  EnumCandidateCompanyAdvancement,
  MissionDetail,
  MissionsService,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export interface MissionDetailWithCandidateCount extends MissionDetail {
  attachedCandidatesCount: number;
}

export const useFetchMissionDetails = (missionId?: string) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchMissionDetails, missionId],

    async () => {
      invariant(missionId, 'missionId is missing');
      const mission = await MissionsService.missionsControllerGetMission({
        id: missionId,
        brandCode,
      });

      const attachedCandidatesCount =
        mission.candidates?.filter(
          candidate =>
            candidate.candidateAdvancement === EnumCandidateCandidateAdvancement.accepté &&
            candidate.companyAdvancement === EnumCandidateCompanyAdvancement.accepté
        ).length ?? 0;
      const isPartiallyFulfilled =
        attachedCandidatesCount > 0 &&
        mission?.numberPositions &&
        attachedCandidatesCount < mission?.numberPositions;
      return {
        ...mission,
        attachedCandidatesCount,
        salesPhase:
          mission.salesPhase?.id === 'AS' && isPartiallyFulfilled
            ? { id: 'SP', label: 'servie partiellement' }
            : mission.salesPhase,
      };
    },
    {
      enabled: Boolean(missionId),
      refetchOnWindowFocus: false,
    }
  );
};
