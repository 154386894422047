import { Button, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchTemporaryWorkerDetail } from 'src/Hooks/Candidate/useFetchTemporaryWorkerDetail';
import { ROUTES } from 'src/Routes/Routes.types';
import ResidencePermit from './Components/ResidencePermit';
import TalentAcceptedMobility from './Components/TalentAcceptedMobility';
import TalentAddress from './Components/TalentAddress';
import TalentAvailabilityAndPlacementHeader from './Components/TalentAvailabilityAndPlacementHeader';
import TalentDetailsHabilitations from './Components/TalentDetailsHabilitations';
import TalentDetailsHeader from './Components/TalentDetailsHeader';
import TalentDetailsQualifications from './Components/TalentDetailsQualifications';
import TalentFirstContractStartDate from './Components/TalentFirstContractStartDate';
import TalentInformationHeader from './Components/TalentInformationHeader';
import TalentLastContractEndDate from './Components/TalentLastContractEndDate';
import TalentLastMedicalVisit from './Components/TalentLastMedicalVisit';
import TalentMissions from './Components/TalentMissions';
import TalentPhoneNumber from './Components/TalentPhoneNumber';
import TalentStatistics from './Components/TalentStatistics/TalentStatistics.component';
import TalentZipCodeAndCity from './Components/TalentZipCodeAndCity';
import styles from './TemporaryWorkerDetail.module.scss';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { useSelector } from 'react-redux';

export const TalentDetailsRoute = () => {
  const navigate = useNavigate();
  const source = useTypedHash(ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const { talentId } = useTypedParams(ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL);
  const {
    data: talent,
    isSuccess: talentSuccess,
    isLoading: talentLoading,
    isError: talentError,
  } = useFetchTemporaryWorkerDetail(talentId);

  return (
    <>
      <TopBar title="détails talent" goBackTo={ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({})} />
      <div className={styles.content}>
        <div className={styles.container}>
          {talentLoading ? (
            <div className={styles.loadingScreen}>
              <Loader heightInRem={4} className={styles.loader} />
              <p>chargement des détails du talent, cela peut prendre du temps</p>
            </div>
          ) : talentSuccess ? (
            <>
              <div className={styles.header}>
                <TalentDetailsHeader talent={talent} />
                <Button.Primary
                  className={styles.repositioningButton}
                  onClick={() => {
                    navigate(
                      ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING.buildPath(
                        { talentId },
                        undefined,
                        source
                      )
                    );
                    ReactGA.event(ANALYTICS_EVENT.CLICK_TT_REPOSITIONING, {
                      ...userInfo,
                      toggle: source ?? 'inconnu',
                      statut: talent.temporaryWorkerStatus,
                    });
                  }}
                >
                  repositionner
                </Button.Primary>
              </div>
              <div className={styles.separator}></div>
              <div className={styles.detailsBody}>
                <TalentAvailabilityAndPlacementHeader
                  availabilityDate={talent.availabilityForConsultantDate}
                />
                <TalentMissions missions={talent.missions} />
                <TalentInformationHeader talent={talent} candidateId={talentId} />
                <div className={styles.firstRowContainer}>
                  <TalentDetailsQualifications
                    qualifications={talent?.qualifications}
                    mainQualificationId={talent?.qualificationId}
                  />
                  <div className={styles.firstRowSecondColumnContainer}>
                    <TalentLastContractEndDate lastContractEndDate={talent?.lastContractEndDate} />
                    {talent?.residencePermit?.residencePermit !== undefined && (
                      <ResidencePermit residencePermit={talent.residencePermit.residencePermit} />
                    )}
                  </div>
                  <TalentStatistics
                    nbHours={talent?.nbHours}
                    nbCompanies={talent?.nbCompanies}
                    nbMissions={talent?.nbMissions}
                  />
                </div>
                <div className={styles.secondRowContainer}>
                  <TalentAcceptedMobility acceptedMobility={talent.acceptedMobility} />
                  <TalentLastMedicalVisit lastMedicalVisitDate={talent.lastMedicalVisit} />
                  <TalentFirstContractStartDate
                    firstContractStartDate={talent.firstContractStartDate}
                  />
                </div>
                <div className={styles.thirdRowContainer}>
                  <TalentAddress address={talent?.candidateAddress?.address} />
                  <TalentZipCodeAndCity
                    zipCode={talent?.candidateAddress?.candidatePostalCode ?? 'code postal inconnu'}
                    city={talent?.candidateAddress?.candidateCityName ?? 'ville inconnue'}
                  />
                  <TalentPhoneNumber phoneNumber1={talent?.phoneNumber1} />
                </div>
                <TalentDetailsHabilitations habilitations={talent?.habilitations ?? []} />
              </div>
            </>
          ) : (
            talentError && <div className={styles.noResult}>désolé, une erreur s'est produite</div>
          )}
        </div>
      </div>
    </>
  );
};
