import { useRef, useState } from 'react';
import moment from 'moment';

import styles from './SingleDatePickerPopOver.module.scss';
import {
  Button,
  DatePicker,
  Popover,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import SingleDatePickerButton from './SingleDatePickerButton';
import { useSingleDatePicker } from 'src/Redux/Search/Hooks';
import { Props } from './SingleDatePickerPopOver.types';

/** @deprecated */
const SingleDatePickerPopOver = ({ calendar, onChange }: Props) => {
  const ref = useRef<PopoverActions | null>(null);
  const [activeStartDate, onDateChange] = useSingleDatePicker();
  const [startDate, setStartDate] = useState<moment.Moment | null>(activeStartDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  return (
    <Popover
      ref={ref}
      trigger={
        <span>
          <SingleDatePickerButton
            icon={calendar}
            isDatePickerOpen={isDatePickerOpen}
            setIsDatePickerOpen={setIsDatePickerOpen}
          />
        </span>
      }
      onOpen={() => {
        setStartDate(activeStartDate);
      }}
    >
      <div className={styles.container}>
        <DatePicker
          selected={startDate?.toDate()}
          onChange={startDate => {
            setStartDate(startDate ? moment(startDate) : null);
          }}
        />
        <div className={styles.footer}>
          <Button variant="secondary" onClick={() => ref.current?.close()}>
            annuler
          </Button>
          <Button
            disabled={startDate === null}
            onClick={() => {
              onDateChange(startDate);
              startDate && onChange?.(startDate);
              ref.current?.close();
            }}
          >
            appliquer
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default SingleDatePickerPopOver;
