import { TopBar } from 'src/Components/TopBar';
import styles from './Mission.module.scss';
import MissionDetail from '../Missions/MissionDetail';
import { ROUTES } from 'src/Routes/Routes.types';

export const MissionRoute = () => {
  return (
    <>
      <TopBar title="détails commande" goBackTo={ROUTES.ACTIVITY.MISSIONS.buildPath({})} />
      <div className={styles.content}>
        <MissionDetail />
      </div>
    </>
  );
};
