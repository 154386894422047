import {
  CreateActionResponse,
  EnumFinalizeWorkAccidentAnalysisParamsBrandCode,
  FinalizeWorkAccidentAnalysisParams,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';

export const useFinalizeWorkAccidentAnalysis = (
  analysisId: string,
  extraOptions?: UseMutationOptions<
    CreateActionResponse,
    unknown,
    Omit<FinalizeWorkAccidentAnalysisParams, 'brandCode' | 'user'>,
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const userInfo = useSelector(getAnalyticsUserInfo);
  const user = useAuthenticatedUser();

  return useMutation(
    [MutationKeys.finaliseWorkAccidentAnalysis],
    async params => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerFinalizeWorkAccidentAnalysis(
        {
          analysisId,
          body: {
            ...params,
            user,
            brandCode: EnumFinalizeWorkAccidentAnalysisParamsBrandCode['001VB'],
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentActionPlans]);
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentAnalyses]);
        ReactGA.event(ANALYTICS_EVENT.FINALISE_WORK_ACCIDENT_ANALYSIS, { ...userInfo });
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
